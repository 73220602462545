import { FC } from "react"
import { Link as LinkComponent } from "@mui/material"
import { Link } from "react-router-dom"
import { Person } from "@interfaces/person"
import { decidePersonFields } from "@functions/person"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

export const PersonText: FC<{ person: Person }> = ({ person }) => {
  const { data: currentUser } = useFetchCurrentUser()
  const decidedFields = decidePersonFields(person, false)

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Meeting Person Link Clicked", {
      personId: person.id,
      personName: person.name,
      personTitle: person.title,
      organizationName: person.organization,
    })
  }

  return (
    <>
      <LinkComponent component={Link} to={`/persons/${person.id}`} onClick={callVitallyTrack}>
        {decidedFields.name}, {decidedFields.title}, {decidedFields.organization}
      </LinkComponent>
      <br />
    </>
  )
}
