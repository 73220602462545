import { FC, useState, useEffect, useMemo } from "react"
import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import { getMeetingsInsightCost } from "@api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { getPrompts } from "@api/prompts"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreditSpentConfirmation, roundCredits } from "../../shared/CreditSpentConfirmation"
import { useToast } from "../../shared/ToastHook"
import { PersonalInsightPromptSelector } from "../../shared/PersonalInsightPromptSelector"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { UseMutateFunction } from "react-query"
import { AxiosResponse } from "axios"
import { PublicInsight } from "@interfaces/insight"
import { vitallyTrack } from "@config/vitally"
import {
  InsightAlreadyCreatedModal,
  insightAlreadyCreatedModalKey,
} from "@components/shared/InsightAlreadyCreatedModal"
import { useFetchCurrentUser } from "@api/users"

const personalInsightModalKey = "PERSONAL_INSIGHT_COST_MODAL"

export const PersonalInsightCreation: FC<{
  onConfirmCreatePrompt: UseMutateFunction<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any, any>, // these anys are copied from the actual type
    unknown,
    {
      promptIds: number[]
    },
    unknown
  >
  currentUsersInsights?: PublicInsight[]
  onSuccess?: () => void
  isLoading?: boolean
}> = ({ onConfirmCreatePrompt, currentUsersInsights, onSuccess, isLoading }) => {
  const { data: currentUser } = useFetchCurrentUser()
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: prompts, isFetched } = getPrompts("personal")
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const toast = useToast()
  const openCostModal = useOpenModalKey(personalInsightModalKey)
  const openInsightAlreadyCreatedModal = useOpenModalKey(insightAlreadyCreatedModalKey)
  const { data: insightCost } = getMeetingsInsightCost(parseInt(meetingId))

  const availablePrompts =
    prompts?.filter(({ id }) => {
      if (selectedPrompts.has(id)) {
        return false
      } else {
        return true
      }
    }) || []

  useEffect(() => {
    if (prompts && isFetched && selectedPrompts.size === 0) {
      if (availablePrompts.length === 0) {
        setSelectedPrompts(new Set())
      } else {
        setSelectedPrompts(new Set([availablePrompts[0].id]))
      }
    }
  }, [isFetched, availablePrompts.length])

  const selectedPromptsCollection = useMemo(() => {
    return prompts?.filter((prompt) => Array.from(selectedPrompts).includes(prompt.id))
  }, [selectedPrompts])

  const promptsAlreadyCreatedByUser = useMemo(() => {
    const promptsAlreadyCreated = [] as { promptCreated: boolean; promptName: string }[]

    if (currentUsersInsights && selectedPromptsCollection) {
      selectedPromptsCollection.forEach((prompt) => {
        const currentInsight = currentUsersInsights.find(
          (insight) => insight.name.trim().toLowerCase() === prompt.name.trim().toLowerCase(),
        )

        if (currentInsight) {
          promptsAlreadyCreated.push({ promptCreated: true, promptName: prompt.name })
        }
      })
    }
    return promptsAlreadyCreated
  }, [currentUsersInsights, selectedPromptsCollection])

  const promptsAlreadyCreatedNames = promptsAlreadyCreatedByUser.map((prompt) => prompt.promptName)

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Meeting Create Personal Insight", {
      meetingId,
      selectedPrompts:
        prompts?.filter((prompt) => selectedPrompts.has(prompt.id))?.map((prompt) => prompt.name) || null,
    })
  }
  return (
    <>
      <Stack spacing={1} alignItems="flex-start">
        <Typography>Add your personalized insight prompts.</Typography>
        <PersonalInsightPromptSelector selectedPrompts={selectedPrompts} setSelectedPrompts={setSelectedPrompts} />
        <Stack spacing={2} direction="row" alignItems="center">
          <Button
            variant="contained"
            startIcon={<PostAddIcon />}
            onClick={() => {
              if (promptsAlreadyCreatedByUser.length > 0) {
                openInsightAlreadyCreatedModal()
              } else {
                openCostModal()
              }
            }}
            disabled={selectedPrompts.size === 0 || isLoading}
          >
            Create Personal Insight
          </Button>
          {insightCost?.cost ? (
            <Typography>{`Credit Cost: ${roundCredits(insightCost.cost * selectedPrompts.size)}`}</Typography>
          ) : null}
          {isLoading && (
            <>
              <Typography>Insight Processing</Typography>
              <CircularProgress />
            </>
          )}
        </Stack>
      </Stack>
      {insightCost?.cost ? (
        <CreditSpentConfirmation
          modalKey={personalInsightModalKey}
          onConfirm={() => {
            callVitallyTrack()
            onConfirmCreatePrompt({ promptIds: Array.from(selectedPrompts) })
            toast("A Personal Insight is being created. We will email you when it's finished")
            if (onSuccess) {
              onSuccess()
            }
          }}
          cost={insightCost.cost * selectedPrompts.size}
        />
      ) : null}
      {promptsAlreadyCreatedByUser && (
        <InsightAlreadyCreatedModal insightNames={promptsAlreadyCreatedNames} onConfirm={openCostModal} />
      )}
    </>
  )
}
