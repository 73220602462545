import { FC, useState } from "react"
import { Button, Stack } from "@mui/material"
import { PromptContext } from "./context"
import { EditPromptModal, EDIT_PROMPT_MODAL_KEY } from "./EditPromptModal"
import { getPrompts } from "@api/admin/prompts"
import { DataTable } from "@components/shared/DataTable"
import { useOpenModalKey, useCloseModalKey } from "@components/shared/OpenModalHook"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"

const columns = [
  { id: "id", label: "Id" },
  { id: "openAction", label: "Name" },
  {
    id: "is_personal",
    label: "Is Personal",
    formatter: (isPersonal: unknown) => {
      return isPersonal ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
]

export const LegacyPrompts: FC = () => {
  const { data } = getPrompts()
  const [id, setId] = useState<number | null>(null)
  const [name, setName] = useState("")
  const [text, setText] = useState("")
  const [isPersonal, setIsPersonal] = useState(false)
  const open = useOpenModalKey(EDIT_PROMPT_MODAL_KEY)
  const close = useCloseModalKey(EDIT_PROMPT_MODAL_KEY)

  return (
    <PromptContext.Provider
      value={{
        id,
        name,
        text,
        isPersonal,
        setId,
        setName,
        setText,
        setIsPersonal,
        close: () => {
          close()
          setId(null)
          setName("")
          setText("")
          setIsPersonal(false)
        },
      }}
    >
      <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
        <Button variant="contained" onClick={open}>
          Create
        </Button>
      </Stack>
      <DataTable
        data={data?.map(({ id, name, text, is_personal }) => ({
          id,
          name,
          text,
          is_personal,
          openAction: (
            <Button
              onClick={() => {
                setId(id)
                setName(name)
                setText(text)
                setIsPersonal(is_personal)
                open()
              }}
            >
              {name}
            </Button>
          ),
        }))}
        columns={columns}
      />
      <EditPromptModal />
    </PromptContext.Provider>
  )
}
