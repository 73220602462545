import { getInsights } from "../api/insights"
import { PublicInsight } from "../interfaces/insight"
import { Organization } from "../interfaces/organization"
import { INSIGHT_PRIORITY } from "./insightConfig"

interface ShareObject {
  shareId: number
  channelName?: string
  meetingTitle?: string
  clipText?: string
}

function getInsightWithSummary(insights: PublicInsight[]) {
  let chosenInsight
  let i = 0
  // first we pick according to the priority as long as it has a summary too
  while (i < INSIGHT_PRIORITY.length && !chosenInsight) {
    chosenInsight = insights.find((insight) => insight.name === INSIGHT_PRIORITY[i] && insight.summary)
    i++
  }

  // if we can't find any, just pick a insight with a summary
  if (!chosenInsight) {
    chosenInsight = insights.find((insight) => insight.summary)
  }

  // if we still can't find any, just pick the first one
  if (!chosenInsight) {
    chosenInsight = insights[0]
  }

  if (chosenInsight.summary) {
    return chosenInsight.summary
  } else if (chosenInsight.result) {
    return `${chosenInsight.result.split(" ").slice(0, 60).join(" ")}...`
  }

  return undefined
}

export const useNavigatorShareFormatBrowser = (meetingId: unknown, email?: boolean) => {
  const { data: insightsQuery } = getInsights(new URLSearchParams({ meetings: `${meetingId}`, count: "100" }))
  const meetingSummary = insightsQuery?.briefings.length ? getInsightWithSummary(insightsQuery.briefings) : undefined

  return (shareDetails: ShareObject) => {
    const textCollection = [
      meetingSummary || "<<Your Text Here>>",
      "Add some context on this clip for your recipient.",
      `Check out this Cloverleaf AI clip. https://${window.location.hostname}/share/${shareDetails.shareId}?type=clip`,
    ]

    if (shareDetails?.channelName) {
      textCollection.push(`Government Agency: ${shareDetails.channelName}`)
    }

    if (shareDetails?.meetingTitle) {
      textCollection.push(`Meeting Name: ${shareDetails.meetingTitle}`)
    }

    if (shareDetails?.clipText) {
      textCollection.push(`Clip Text: "${shareDetails.clipText}"`)
    }

    if (email) {
      textCollection.push("Best Regards,")
    }
    return textCollection.join(email ? "%0D%0D" : "\n\n")
  }
}

export const formatChannelDetails = (organization: Organization) => {
  let text = `${organization.name} in `
  if (organization.city) {
    text += `in ${organization.city.name}, `
  } else if (organization.county) {
    text += `in ${organization.county.name}, `
  }

  if (organization?.state?.name) {
    text += `${organization.state.name}`
  }

  return text
}
