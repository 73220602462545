import { FC } from "react"
import { Grid, Tabs, Tab, Button } from "@mui/material"
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { InfoSection } from "./InfoSection"
import { MeetingsContainer } from "@components/Admin/Organizations/MeetingPage/MeetingsContainer"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import { a11yProps } from "@components/shared/config"
import Loading from "@components/shared/Loading"
import { Helmet } from "react-helmet"
import { InsightsUsersTable } from "@components/Admin/Organizations/InsightsUsersTable"
import { useFetchOrganization } from "@api/organizations"
import { useFetchCurrentUser, useIsContractor, useIsSuperAdmin } from "@api/users"
import { OrgContactsPage } from "@components/Admin/Organizations/OrgContactsPage"

const tabs = [
  { label: "Info", subUrl: "info", component: InfoSection },
  { label: "Meetings", subUrl: "meetings", component: MeetingsContainer },
  { label: "Insights Users", subUrl: "insights", component: InsightsUsersTable },
  { label: "Contacts", subUrl: "contacts", component: OrgContactsPage },
]

const createTabProps = (organizationId: string, label: string, subUrl: string) => ({
  label,
  value: subUrl,
  component: Link,
  to: `/admin/organizations/${organizationId}/${subUrl}`,
  ...a11yProps(subUrl),
  sx: {
    "&:hover": {
      textDecoration: "unset",
    },
    "&:active": {
      textDecoration: "unset",
    },
    "&:focus": {
      textDecoration: "unset",
    },
  },
})

export const OrganizationDetailPage: FC = () => {
  const { pathname } = useLocation()
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: organization } = useFetchOrganization(organizationId)

  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: isContractor } = useIsContractor()
  const { data: currentUser } = useFetchCurrentUser()

  const filteredTabs = tabs.filter(
    ({ subUrl }) =>
      isSuperAdmin ||
      (isContractor && (subUrl === "info" || subUrl === "meetings")) ||
      currentUser?.available_routes?.includes(`admin/organizations/${subUrl}`),
  )

  if ((filteredTabs.length && pathname.split("/").length === 4) || pathname.split("/")[4] === "") {
    return <Redirect to={`/admin/organizations/${organizationId}/${filteredTabs[0].subUrl}`} />
  }

  if (organization === undefined) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>{`Cloverleaf.AI | ${organization.name || "New Channel"}`}</title>
      </Helmet>
      <Grid container>
        <Grid item marginRight={4}>
          <Button component={Link} to="/admin/channels" startIcon={<ArrowBack />}>
            Back
          </Button>
        </Grid>
        <Tabs value={pathname.split("/")[4]} aria-label="settings tabs">
          {filteredTabs.map(({ label, subUrl }) => (
            <Tab {...createTabProps(organizationId, label, subUrl)} />
          ))}
        </Tabs>
      </Grid>
      <Switch>
        {filteredTabs.map(({ subUrl, component }) => (
          <Route path={`/admin/organizations/:organizationId/${subUrl}`} component={component} />
        ))}
      </Switch>
    </>
  )
}
