import { OpportunityQueryObjectWithCampaign, urlParamsToOpportunityQueryObject } from "@api/opportunities"
import useSetUrlParamState from "@components/shared/UseSetUrlParamState"
import { createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { OpportunitiesDataFiltersContainer } from "./OpportunitiesDataFiltersContainer"
import { OpportunitiesDataGrid } from "@components/Opportunities/DataGrid/OpportunitiesDataGrid"
import { Box } from "@mui/material"
import { useFetchOpportunityCampaignsMap } from "@api/opportunityCampaigns"

export interface OppportunitiesContainerContextProps {
  params: URLSearchParams
  urlParamState: OpportunityQueryObjectWithCampaign
  setUrlParamState: (newFilterParams: OpportunityQueryObjectWithCampaign) => void
  terms: Set<string>
  setTerms: Dispatch<SetStateAction<Set<string>>>
}

export const OpportunitiesContainerContext = createContext<OppportunitiesContainerContextProps>({
  params: new URLSearchParams(),
  urlParamState: {
    states: [],
    cities: [],
    counties: [],
    organizations: [],
    naics_code: [],
    industry: [],
    channel_types: [],
    terms: [],
    anchor_terms: [],
    sort: "",
    order: "",
    page: 0,
    count: 25,
    fine_tuning: "",
    generated_by_model: "",
    campaign: "",
  },
  setUrlParamState: () => {
    /* Something here for linter */
  },
  terms: new Set<string>(),
  setTerms: () => {
    /* Something here for linter */
  },
})

export const OpportunitiesContainer: FC = () => {
  const { data: opportunityCampaigns, isFetched: isOpportunityCampaignsFetched } = useFetchOpportunityCampaignsMap()
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)
  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const [terms, setTerms] = useState(new Set<string>(urlParamState.terms))
  useEffect(() => {
    setTerms(new Set<string>(urlParamState.terms))
  }, [paramsString])

  const [renderContent, setRenderContent] = useState(false)

  const campaignId = useMemo(() => {
    return urlParamState.campaign ? parseInt(urlParamState.campaign) : null
  }, [urlParamState.campaign])

  useEffect(() => {
    if (campaignId && isOpportunityCampaignsFetched) {
      setUrlParamState({
        ...urlParamState,
        anchor_terms: [],
        ...(opportunityCampaigns?.get(campaignId)?.data || {}),
        campaign: `${campaignId}`,
        page: 0,
      })
      setRenderContent(true)
    } else if (isOpportunityCampaignsFetched) {
      setRenderContent(true)
    }
  }, [campaignId, isOpportunityCampaignsFetched])

  if (renderContent) {
    return (
      <OpportunitiesContainerContext.Provider value={{ params, urlParamState, setUrlParamState, terms, setTerms }}>
        <OpportunitiesDataFiltersContainer />
        <Box marginTop={2}>
          <OpportunitiesDataGrid />
        </Box>
      </OpportunitiesContainerContext.Provider>
    )
  }

  return null
}
