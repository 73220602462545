import { Skeleton, Tabs as MuiTabs, Tab } from "@mui/material"
import { FC, useContext, useMemo } from "react"
import { useFetchContacts } from "../../../api/contacts"
import { StatisticsDetailsCardContext } from "."

export const Tabs: FC = () => {
  const { meeting, tabValue, setTabValue } = useContext(StatisticsDetailsCardContext)
  const {
    data: contactsData,
    isLoading: isContactsLoading,
    isError: isContactsError,
  } = useFetchContacts(meeting.organization_id)
  const renderTabs = useMemo(() => {
    const tabs = []

    if (contactsData && contactsData.length > 0) {
      tabs.push(<Tab key="contacts" label="Contacts" value="contacts" iconPosition="end" />)
    }
    tabs.push(<Tab key="details" label="Channel Details" value="details" iconPosition="end" />)

    if (isContactsLoading) {
      return <Skeleton variant="rectangular" height={40} sx={{ marginBottom: 2 }} />
    } else {
      return (
        <MuiTabs
          value={tabValue}
          onChange={(_event, value: string) => setTabValue(value)}
          sx={{ maxHeight: 60 }}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {tabs}
        </MuiTabs>
      )
    }
  }, [contactsData, isContactsLoading, isContactsError, tabValue])

  return renderTabs
}
