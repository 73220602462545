import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { PublicInsight } from "../interfaces/insight"
import { useIsInsightsEnabled } from "./users"
import { UrlBuilder } from "./urlBuilder"

const prefix = "/briefings"

export interface InsightQueryObject {
  states: number[]
  cities: number[]
  counties: number[]
  organizations: number[]
  channel_types: number[]
  meetings: number[]
  city_population: [number, number]
  county_population: [number, number]
  city_income: [number, number]
  county_income: [number, number]
  types: string[]
  users: number[]
  text: string
  sort: string
  order: string
  page: number
  count: number
  fine_tuning: string
  generated_by_model: string
}

export const urlParamsToInsightQueryObject = (params: URLSearchParams) =>
  ({
    states: params.getAll("states").map((state) => parseInt(state)),
    cities: params.getAll("cities").map((city) => parseInt(city)),
    counties: params.getAll("counties").map((county) => parseInt(county)),
    organizations: params.getAll("organizations").map((organization) => parseInt(organization)),
    channel_types: params.getAll("channel_types").map((channelType) => parseInt(channelType)),
    meetings: params.getAll("meetings").map((meeting) => parseInt(meeting)),
    city_population: params.getAll("city_population").map((cityPopulation) => parseInt(cityPopulation)) as [
      number,
      number,
    ],
    county_population: params.getAll("county_population").map((countyPopulation) => parseInt(countyPopulation)) as [
      number,
      number,
    ],
    city_income: params.getAll("city_income").map((cityIncome) => parseInt(cityIncome)) as [number, number],
    county_income: params.getAll("county_income").map((countyIncome) => parseInt(countyIncome)) as [number, number],
    types: params.getAll("types"),
    users: params.getAll("users").map((user) => parseInt(user)),
    text: params.get("text") || "",
    sort: params.get("sort") || "",
    order: params.get("order") || "",
    page: parseInt(params.get("page") ?? "0"),
    count: parseInt(params.get("count") ?? "25"),
    fine_tuning: params.get("fine_tuning") ?? "",
    generated_by_model: params.get("generated_by_model") || "",
  }) as InsightQueryObject

export class InsightUrlBuilder extends UrlBuilder {
  collectionParams = ["states", "cities", "counties", "organizations", "meetings", "types", "users"]
  stringParams = ["text", "sort", "order", "generated_by_model"]
  numberParams = [
    { name: "page", defaultValue: 0 },
    { name: "count", defaultValue: 25 },
  ]
  onOffParams = ["fine_tuning"]
}

export const getInsights = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isInsightsEnabled } = useIsInsightsEnabled()

  return useQuery(
    ["insights", params && urlParamsToInsightQueryObject(params)],
    async () => {
      const url = new InsightUrlBuilder(prefix).build(params)
      const response = await (await axiosInstance).get<{ total: number; briefings: PublicInsight[] }>(url)

      return response.data
    },
    {
      enabled: Boolean(isInsightsEnabled),
    },
  )
}

export const useRetryInsight = (id: unknown, meetingId: unknown) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post<PublicInsight>(`${prefix}/${id}/retry`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("insights")
        queryClient.invalidateQueries(["meetings", `${meetingId}`, "insight"])
        queryClient.invalidateQueries(["meetings", `${meetingId}`, "personalInsight"])
      },
    },
  )
}
