import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Stack,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material"
import { FC, useContext, useState } from "react"
import { FetchedBookmarksItem, useUpdateBookmark } from "@api/bookmarks"
import { useCloseModalKey, useModalKey } from "../shared/OpenModalHook"
import { useToast } from "../shared/ToastHook"
import { visibleMessage, hiddenMessage } from "./config"
import { useFetchCurrentUser } from "@api/users"
import { callVitallyTrack } from "./callVitallyTrack"
import { BookmarkContext } from "@pages/BookmarksPage"

export const EditBookmarkModal: FC<{ bookmark: FetchedBookmarksItem }> = ({ bookmark }) => {
  const { data: currentUser } = useFetchCurrentUser()
  const { tab } = useContext(BookmarkContext)
  const editBookmarkKey = `EDIT_TITLE_${bookmark.id}`
  const open = useModalKey(editBookmarkKey)
  const closeModal = useCloseModalKey(editBookmarkKey)
  const [title, setTitle] = useState(bookmark?.title || "")
  const [visible, setVisibility] = useState<boolean>(bookmark?.visible ? true : false)
  const setToast = useToast()
  const { mutate: updateBookmark } = useUpdateBookmark()

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%", paddingX: 6, paddingTop: 6, paddingBottom: 6 } }}
    >
      <DialogTitle>
        <Typography variant="h3" component="div">
          Edit Folder
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Edit your folder, then click save.</Typography>
        <Stack direction="column" sx={{ paddingTop: 2 }} spacing={2}>
          <TextField
            label="Title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          <FormControlLabel
            control={<Switch checked={visible} onChange={() => setVisibility(!visible)} />}
            label={visible ? "Public" : "Private"}
            componentsProps={{ typography: { variant: "body1" } }}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontWeight: 500,
              },
            }}
          />
          <Typography>{visible ? visibleMessage : hiddenMessage}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            callVitallyTrack({ currentUser, bookmark, tab, event: "Bookmarks Update Folder" })
            updateBookmark({ id: bookmark.id, title: title, visible: visible })
            closeModal()
            setToast("Bookmark Updated", 1750)
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
