import { FC, ReactNode, useEffect, useState } from "react"
import {
  Autocomplete,
  TextField,
  TextFieldProps,
  SxProps,
  Theme,
  AutocompleteProps,
  Chip,
  CircularProgress,
} from "@mui/material"
import { useSearchPersonsById, useSearchPersonsByName, useSearchPersonsByVoiceId } from "@api/persons"
import { PersonWithVoiceIds } from "@interfaces/person"
import { UseQueryResult } from "react-query"
import { useGetFeatureFlagByName } from "@api/feature_flags"

type PersonSelectorOption = PersonWithVoiceIds

// This is a workaround for the unnecessary warning in MUI 5.x mentioned here:
// - https://github.com/mui/material-ui/issues/18514
// - https://github.com/mui/material-ui/pull/43314
// @TODO - we should remove this code ass soon as we upgrade MUI to 6.x or higher
const mockPersonSelectorOption: PersonWithVoiceIds = {
  id: -2,
  created_at: "",
  discarded_at: "",
  updated_at: "",
  name: "[mock]",
  title: "",
  organization: "",
  voices: [],
}

interface PersonSelectorMultipleProps {
  setSelectedPeopleVoiceIds: (newData: number[]) => void
  setSelectedPeopleIds: (newData: Set<number>) => void
  options?: PersonSelectorOption[]
  selectedOptions?: PersonWithVoiceIds[]
  updateSelectedOptions?: (selectedOptions: PersonWithVoiceIds[]) => void
  loading?: boolean
  textFieldProps?: TextFieldProps
  sx?: SxProps<Theme>
  size?: "medium" | "small"
}

interface PersonSelectorMultipleWrapperProps extends PersonSelectorMultipleProps {
  selectedPeopleVoiceIds?: Set<number> | number[]
  selectedPeopleIds?: Set<number> | number[]
  loadPeopleByVoiceId?: boolean
  labelElement?: ReactNode
}

const generateSelectedPeopleIds = (selectedOptionsSet: Set<PersonSelectorOption>) => {
  return new Set(
    Array.from(selectedOptionsSet)
      .map((el) => {
        return el.id
      })
      .flat(),
  )
}

export const PersonSelectorMultipleWrapper: FC<PersonSelectorMultipleWrapperProps> = ({
  selectedPeopleVoiceIds: selectedPeopleVoiceIds = [],
  selectedPeopleIds: selectedPeopleIds = [],
  loadPeopleByVoiceId: loadPeopleByVoiceId = false,
  setSelectedPeopleVoiceIds,
  setSelectedPeopleIds,
  labelElement,
  textFieldProps,
  sx,
  size,
}) => {
  const { data: speakerIdPersonSelectorFlagData } = useGetFeatureFlagByName("SPEAKER_ID_PERSON_SELECTOR")
  const [triggerSearchByPersonsId, setTriggerSearchByPersonsId] = useState(!loadPeopleByVoiceId)
  const [triggerSearchByVoiceId, setTriggerSearchByVoiceId] = useState(loadPeopleByVoiceId)

  const { data: fetchedSelectedPeopleDetailsById } = useSearchPersonsById(selectedPeopleIds, triggerSearchByPersonsId)
  const { data: fetchedSelectedPeopleDetailsByVoiceId } = useSearchPersonsByVoiceId(
    selectedPeopleVoiceIds,
    triggerSearchByVoiceId,
  )

  useEffect(() => {
    setTriggerSearchByPersonsId(!loadPeopleByVoiceId)
    setTriggerSearchByVoiceId(loadPeopleByVoiceId)
  }, [loadPeopleByVoiceId])

  const shouldShowComponent =
    typeof speakerIdPersonSelectorFlagData == "object" && speakerIdPersonSelectorFlagData["value"] == "true"
  const [selectedPeopleOptions, setSelectedPeopleOptions] = useState<PersonWithVoiceIds[]>([])

  // these conditions are required to set the selectedPeopleOptions correctly
  // when something triggers a redraw of this component and we've previously fetched the data using useQuery,
  // because the setters in useEffect blocks below trigger only when the fetched data changes
  if (
    !loadPeopleByVoiceId &&
    fetchedSelectedPeopleDetailsById &&
    selectedPeopleOptions != fetchedSelectedPeopleDetailsById
  ) {
    setSelectedPeopleOptions(fetchedSelectedPeopleDetailsById)
  }
  if (
    loadPeopleByVoiceId &&
    fetchedSelectedPeopleDetailsByVoiceId &&
    selectedPeopleOptions != fetchedSelectedPeopleDetailsByVoiceId
  ) {
    setSelectedPeopleOptions(fetchedSelectedPeopleDetailsByVoiceId)
  }

  useEffect(() => {
    if (fetchedSelectedPeopleDetailsById) {
      setTriggerSearchByPersonsId(false)
      setSelectedPeopleOptions(fetchedSelectedPeopleDetailsById)
    }
  }, [fetchedSelectedPeopleDetailsById])

  useEffect(() => {
    if (fetchedSelectedPeopleDetailsByVoiceId) {
      setSelectedPeopleOptions(fetchedSelectedPeopleDetailsByVoiceId)
    }
  }, [fetchedSelectedPeopleDetailsByVoiceId])

  return (
    <>
      {shouldShowComponent && (
        <>
          {labelElement}
          <PersonSelectorMultiple
            setSelectedPeopleIds={setSelectedPeopleIds}
            setSelectedPeopleVoiceIds={setSelectedPeopleVoiceIds}
            selectedOptions={selectedPeopleOptions}
            updateSelectedOptions={setSelectedPeopleOptions}
            textFieldProps={textFieldProps}
            size={size}
            sx={sx}
          />
        </>
      )}
    </>
  )
}

export const PersonSelectorMultiple: FC<PersonSelectorMultipleProps> = ({
  setSelectedPeopleVoiceIds,
  setSelectedPeopleIds,
  options: initialOptions,
  selectedOptions: initialSelectedOptions,
  updateSelectedOptions,
  textFieldProps,
  sx,
  size,
}) => {
  initialSelectedOptions = initialSelectedOptions || []

  const [isOpen, setIsOpen] = useState(false)
  // the mockPersonSelectorOption should always be last
  const [options, setOptions] = useState<PersonSelectorOption[]>([...(initialOptions || []), mockPersonSelectorOption])
  const [inputValue, setInputValue] = useState("")
  const [debouncedInputValue, setDebouncedInputValue] = useState("")
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [selectedOptionsSet, setSelectedOptionsSet] = useState(new Set<PersonSelectorOption>())

  useEffect(() => {
    if (initialSelectedOptions) {
      setSelectedOptionsSet(new Set(initialSelectedOptions))
    }
  }, [initialSelectedOptions])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedInputValue(inputValue)
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue])

  const searchResult: UseQueryResult<PersonWithVoiceIds[]> = useSearchPersonsByName(debouncedInputValue, triggerSearch)

  useEffect(() => {
    setTriggerSearch(false)
    setOptions([...(searchResult.data || []), mockPersonSelectorOption])
  }, [searchResult.data])

  useEffect(() => {
    if (debouncedInputValue !== "") {
      setTriggerSearch(true)
    }
  }, [debouncedInputValue])

  const loading = searchResult?.isLoading || false

  const updateSelectedOptionsState = (newSelectedOptionsSet: Set<PersonSelectorOption>) => {
    const voiceIds = Array.from(newSelectedOptionsSet)
      .map((el) => {
        return el.voices.map((el) => el.id)
      })
      .flat()

    setSelectedOptionsSet(newSelectedOptionsSet)
    updateSelectedOptions?.(Array.from(newSelectedOptionsSet))

    setSelectedPeopleIds(generateSelectedPeopleIds(newSelectedOptionsSet))
    setSelectedPeopleVoiceIds(voiceIds)
  }

  const handleOnChange: AutocompleteProps<PersonSelectorOption, true, true, false>["onChange"] = (
    event,
    newValue: PersonSelectorOption[],
  ) => {
    const newSelectedOptionsSet = new Set<PersonSelectorOption>()
    if (newValue.length > 0) {
      newValue.forEach((item) => {
        newSelectedOptionsSet.add(item)
      })
    }
    updateSelectedOptionsState(newSelectedOptionsSet)
  }

  const handleOnDelete = (newValue: PersonSelectorOption) => {
    const newSelectedOptionsSet = new Set<PersonSelectorOption>(Array.from(selectedOptionsSet))
    if (newValue) {
      newSelectedOptionsSet.delete(newValue)
      updateSelectedOptionsState(newSelectedOptionsSet)
    }
  }

  const currentSelectedOptions = Array.from(selectedOptionsSet.size > 0 ? selectedOptionsSet : initialSelectedOptions)

  return (
    <>
      <Autocomplete<PersonSelectorOption, true, true, false>
        multiple
        autoComplete
        limitTags={3}
        // IMPORTANT: clearOnBlur set to false will ensure that input works with a blank list
        clearOnBlur={false}
        freeSolo={false}
        sx={sx || { minWidth: 300 }}
        size={size || "medium"}
        open={isOpen}
        options={options}
        filterOptions={(options) => options.filter((option) => option.id !== -2)}
        loading={loading}
        getOptionLabel={(option: PersonSelectorOption) => option.name}
        isOptionEqualToValue={(option: PersonSelectorOption, value: PersonSelectorOption) => {
          return option.id === value.id || option.id == -2
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        value={currentSelectedOptions}
        onChange={handleOnChange}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        renderTags={(options: PersonSelectorOption[]) => {
          return options.map((person) => {
            return (
              <Chip variant="outlined" label={person.name} key={person.id} onDelete={() => handleOnDelete(person)} />
            )
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Person"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            {...textFieldProps}
          />
        )}
        noOptionsText={"Input your search phrase"}
        renderOption={(props, option: PersonSelectorOption) => {
          const title = option.title.toLowerCase() == "unknown" ? " " : option.title
          const organization = option.organization.toLowerCase() == "unknown" ? " " : option.organization
          return (
            <li {...props} key={option.id}>
              {option.name} - {title}, {organization}
            </li>
          )
        }}
      />
    </>
  )
}
