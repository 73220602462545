import { Chip, Stack } from "@mui/material"
import { SelectedPrompt } from "../../../shared/PromptsAutocomplete"

export const InsightsUsersColumns = [
  { id: "email", label: "Email" },
  {
    id: "prompts",
    label: "Prompts",
    formatter: (prompts: SelectedPrompt[]) => (
      <Stack direction="row" spacing={1} maxWidth={400} flexWrap="wrap" useFlexGap>
        {prompts.map((prompt) => (
          <Chip key={prompt.id} label={prompt.name} />
        ))}
      </Stack>
    ),
  },
  { id: "actions", label: "" },
]
