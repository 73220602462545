import { createContext, Dispatch, SetStateAction } from "react"
import { SelectedPrompt } from "../../../shared/PromptsAutocomplete"

interface ContextData {
  openModal: string
  id: number | null
  email: string
  userId: number | null
  promptsInput: SelectedPrompt[]
  setId: Dispatch<SetStateAction<number | null>>
  setUserId: Dispatch<SetStateAction<number | null>>
  setPromptsInput: Dispatch<SetStateAction<SelectedPrompt[]>>
  close: () => void
}
const contextData: ContextData = {
  openModal: "",
  id: null,
  userId: null,
  email: "",
  promptsInput: [],
  setId: () => {
    /* placeholder for typescript */
  },
  setUserId: () => {
    /* placeholder for typescript */
  },
  setPromptsInput: () => {
    /* placeholder for typescript */
  },
  close: () => {
    /* placeholder for typescript */
  },
}
export const InsightsUsersContext = createContext(contextData)
