import { FetchedBookmarksItem } from "@api/bookmarks"
import { vitallyTrack } from "@config/vitally"
import { TabsEnum } from "@pages/BookmarksPage"
import { SystemUserData } from "@src/providers/userContextProvider"

interface CallVitallyTrackParams {
  currentUser: SystemUserData | undefined
  bookmark: FetchedBookmarksItem
  tab: TabsEnum
  event: string
}

export const callVitallyTrack = ({ currentUser, bookmark, tab, event }: CallVitallyTrackParams) => {
  let params = {}
  params = {
    bookmarkId: bookmark.id,
    bookmarkTitle: bookmark.title,
    tab,
  }

  if (event !== "Bookmarks Delete Folder") {
    params = { ...params, bookmarkVisible: bookmark.visible }

    if (event === "Bookmarks Export") {
      params = { ...params, bookmarkClips: bookmark.clips.map((clip) => clip.id) }
    }
  }

  vitallyTrack(currentUser, event, params)
}
