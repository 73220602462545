import { FC, useState, useEffect } from "react"
import { Stack, Typography, TextField, Button } from "@mui/material"
import { AboutData } from "../../interfaces/aboutData"
import { UseMutateFunction } from "react-query"
import { AddRemoveFormComponent } from "./AddRemoveFormComponent"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

const defaultFormObject: AboutData = {
  jobTitle: "",
  industry: "",
  competitors: [],
  goals: [],
}

export const UserOrganizationAboutForm: FC<{
  aboutData: Record<string, unknown> | null
  setAbout: UseMutateFunction<
    any,
    unknown,
    {
      formData: unknown
    },
    unknown
  >
}> = ({ aboutData, setAbout }) => {
  const { data: currentUser } = useFetchCurrentUser()
  const [formData, setFormData] = useState(defaultFormObject)

  useEffect(() => {
    if (aboutData) {
      setFormData({
        ...formData,
        ...aboutData,
      })
    }
  }, [aboutData])

  let changed = false
  if (
    !aboutData ||
    aboutData.jobTitle !== formData.jobTitle ||
    aboutData.industry !== formData.industry ||
    !Array.isArray(aboutData.competitors) ||
    aboutData.competitors.length !== formData.competitors.length ||
    aboutData.competitors.some((competitor, index) => competitor !== formData.competitors[index]) ||
    !Array.isArray(aboutData.goals) ||
    aboutData.goals.length !== formData.goals.length ||
    aboutData.goals.some((goal, index) => goal !== formData.goals[index])
  ) {
    changed = true
  }

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Account About Updated", {
      jobTitle: formData.jobTitle,
      industry: formData.industry,
      competitors: formData.competitors,
      goals: formData.goals,
    })
  }

  return (
    <Stack alignItems="flex-start" sx={{ width: "100%" }}>
      <Typography marginBottom={1}>Job title:</Typography>
      <TextField
        size="small"
        value={formData.jobTitle}
        label="Job Title"
        fullWidth
        onChange={(event) => setFormData((prevValue) => ({ ...prevValue, jobTitle: event.target.value }))}
      />
      <Typography marginBottom={1}>What is your primary industry? Please be a specific as possible:</Typography>
      <TextField
        size="small"
        value={formData.industry}
        label="Industry"
        multiline
        fullWidth
        onChange={(event) => setFormData((prevValue) => ({ ...prevValue, industry: event.target.value }))}
      />
      <AddRemoveFormComponent
        fieldName="Focus Area"
        data={formData.goals}
        handleChange={(data) => setFormData((prevValue) => ({ ...prevValue, goals: data }))}
        textFieldProps={{ multiline: true }}
      />
      <AddRemoveFormComponent
        fieldName="Competitor"
        data={formData.competitors}
        handleChange={(data) => setFormData((prevValue) => ({ ...prevValue, competitors: data }))}
        textFieldProps={{ multiline: true }}
      />
      {changed && (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={() => {
              callVitallyTrack()
              setAbout({ formData })
            }}
          >
            Save
          </Button>
          <Button
            onClick={() =>
              setFormData({
                ...defaultFormObject,
                ...aboutData,
              })
            }
          >
            Cancel
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
