import { FC, useContext } from "react"
import { AiChainRunnableDisplay } from "./AiChainRunnableDisplay"
import { RowContainer } from "./RowContainer"
import { FunnelOrchestratorContext } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { AiChainRunnable, AiChainRunnableSegmentTypes } from "@interfaces/AiProducts/models"
import { AiChainEditModal } from "./EditorWizard/AiChainEditModal"

export const SequentialRunnableRow: FC<{
  deletePrompt: (index: number) => void
  segmentType: AiChainRunnableSegmentTypes.MIDDLE | AiChainRunnableSegmentTypes.TAIL
}> = ({ deletePrompt, segmentType }) => {
  const rowWidth = "min-content"
  const { configuration } = useContext(FunnelOrchestratorContext)

  if (segmentType === AiChainRunnableSegmentTypes.TAIL) {
    return (
      <RowContainer width={rowWidth} direction="row">
        <AiChainRunnableDisplay
          deletePrompt={deletePrompt}
          index={0}
          aiChainRunnable={configuration.tailSegment as AiChainRunnable}
          segmentType={AiChainRunnableSegmentTypes.TAIL}
        />
        <AiChainEditModal
          key={`${0}-${AiChainRunnableSegmentTypes.TAIL}-modal`}
          index={0}
          aiChainRunnable={configuration.tailSegment as AiChainRunnable}
          segmentType={AiChainRunnableSegmentTypes.TAIL}
        />
      </RowContainer>
    )
  } else {
    return (
      <>
        {configuration.middleSegment.map((segment, index) => (
          <RowContainer width={rowWidth} direction="row" key={index}>
            <AiChainRunnableDisplay
              deletePrompt={deletePrompt}
              key={index}
              index={index}
              aiChainRunnable={segment}
              segmentType={AiChainRunnableSegmentTypes.MIDDLE}
            />
            <AiChainEditModal
              key={`${index}-${AiChainRunnableSegmentTypes.MIDDLE}-modal`}
              index={index}
              aiChainRunnable={segment}
              segmentType={AiChainRunnableSegmentTypes.MIDDLE}
            />
          </RowContainer>
        ))}
      </>
    )
  }
}
