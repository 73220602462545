import { FC, useContext } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { ParallelRunnableRow } from "../ParallelRunnableRow"
import { SequentialRunnableRow } from "../SequentialRunnableRow"
import { AddCard } from "../../AddCard"
import { addTailSegment, deleteMiddleSegment, deleteTailSegment } from "./segmentCrud"
import {
  editableInitialAiChain,
  FunnelOrchestratorContext,
} from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { AiChainRunnableSegmentTypes } from "@interfaces/AiProducts/models"
import { ActionBar } from "../ActionBar"
import { FeatureContext } from "../Features/FeatureContext"
import { transformOrchestratorToAiRunnables } from "../Orchestrators/configurationTransforms"
import { DisplayDataContextProvider } from "../Contexts/DisplayDataContext"

export const FunnelContainer: FC = () => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)

  const addPromptToTail = () => {
    const newConfiguration = addTailSegment(configuration, editableInitialAiChain)
    updateState(newConfiguration)
  }

  const deleteMiddleSegmentPrompt = (index: number) => {
    const newConfiguration = deleteMiddleSegment(configuration, index)
    updateState(newConfiguration)
  }

  const deleteTailSegmentPrompt = () => {
    const newConfiguration = deleteTailSegment(configuration, 0)
    updateState(newConfiguration)
  }

  const { feature } = useContext(FeatureContext)

  return (
    <DisplayDataContextProvider>
      <Stack direction="row" spacing={2} paddingTop={1} alignItems="flex-end">
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            padding: 0,
            borderRadius: 1,
            boxShadow: 0,
            fontSize: 200,
          }}
        >
          <Typography
            fontSize={50}
            fontWeight={700}
            variant="h1"
            color="primary"
            fontStyle={feature?.featureName ? "regular" : "italic"}
          >
            {feature?.featureName ? feature?.featureName : "Untitled"}
          </Typography>
        </Box>
        <ActionBar aiChainRunnables={transformOrchestratorToAiRunnables(configuration)} />
      </Stack>
      <Stack spacing={2} direction="column" paddingTop={4}>
        <ParallelRunnableRow />
        {configuration.middleSegment.length > 0 && (
          <SequentialRunnableRow
            deletePrompt={deleteMiddleSegmentPrompt}
            segmentType={AiChainRunnableSegmentTypes.MIDDLE}
          />
        )}
        {configuration.tailSegment && (
          <SequentialRunnableRow
            deletePrompt={deleteTailSegmentPrompt}
            segmentType={AiChainRunnableSegmentTypes.TAIL}
          />
        )}
        <AddCard actionText="Add to Chain" addAction={() => addPromptToTail()} />
      </Stack>
    </DisplayDataContextProvider>
  )
}
