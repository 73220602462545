import { PublicInsight } from "@interfaces/insight"
import { User } from "@interfaces/user"

export const constructInsightsCollection = (insights: PublicInsight[], currentUser: User) => {
  const ownInsights = [] as PublicInsight[]
  const othersInsights = [] as PublicInsight[]

  // order insights by date descending
  insights.sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf())

  insights.forEach((insight) => {
    if (insight.user_email?.trim().toLowerCase() === currentUser.email.trim().toLowerCase()) {
      ownInsights.push(insight)
    } else {
      othersInsights.push(insight)
    }
  })

  return { ownInsights, othersInsights }
}
