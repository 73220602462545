import { FC, useState } from "react"
import { Link, CircularProgress } from "@mui/material"
import { useAuthAxios } from "../../../api/axiosInstance"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useFetchMeeting } from "../../../api/meetings"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

export const PdfButton: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const [loadingPdf, setLoadingPdf] = useState(false)
  const axiosInstance = useAuthAxios()
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeeting(meetingId)
  return (
    <Link
      sx={{ cursor: "pointer" }}
      onClick={async () => {
        if (data) {
          vitallyTrack(currentUser, "Meeting Download Pdf", {
            meetingId: meetingId,
            meetingTitle: data.meeting.title,
          })

          setLoadingPdf(true)
          const response = await (
            await axiosInstance
          ).get(`meetings/${meetingId}/pdf`, {
            responseType: "blob",
          })
          setLoadingPdf(false)
          const href = URL.createObjectURL(response.data)

          const link = document.createElement("a")
          link.href = href
          link.setAttribute("download", `${meetingId}_${data.meeting.title}.pdf`)
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        }
      }}
      target="_blank"
    >
      Full Transcript PDF
      {loadingPdf && <CircularProgress size="1em" sx={{ marginLeft: "1ch" }} />}
    </Link>
  )
}
