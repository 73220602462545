import { Stack, styled } from "@mui/material"

export const RowContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#d7d7d778",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}))
