import { Button, Card, CardActions, CardContent, Typography, Stack } from "@mui/material"
import { FC, useContext } from "react"
import { FunnelOrchestratorContext } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { AiChainRunnable, AiChainRunnableSegmentTypes } from "@interfaces/AiProducts/models"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { getModalKey } from "./EditorWizard/AiChainEditModal"
import { AiChainRunnableValidator } from "@api/admin/AiProducts/validations"
import { compareAiChainRunnable } from "@api/admin/AiProducts/comparisons"
import { useFetchAiChainRunnable } from "@api/admin/AiProducts/aiChainRunnables"
import { FeatureContext } from "./Features/FeatureContext"

export const AiChainRunnableDisplay: FC<{
  aiChainRunnable: AiChainRunnable
  deletePrompt: (index: number) => void
  index: number
  segmentType: AiChainRunnableSegmentTypes
}> = ({ deletePrompt, index, aiChainRunnable, segmentType }) => {
  const { configuration } = useContext(FunnelOrchestratorContext)
  const { feature } = useContext(FeatureContext)
  const openEditModalKey = getModalKey(index, segmentType, configuration)
  const openModal = useOpenModalKey(openEditModalKey)
  const incompleteRunnable = new AiChainRunnableValidator(aiChainRunnable).validate()
  const { data: aiChainRunnableResponse } = useFetchAiChainRunnable(aiChainRunnable.id)

  const needsUpdate =
    aiChainRunnable.id === undefined || !compareAiChainRunnable(aiChainRunnable, aiChainRunnableResponse)
  const readyForSave = new AiChainRunnableValidator(aiChainRunnable).validate() && feature.id

  return (
    <Card
      variant="outlined"
      className={incompleteRunnable ? "" : "required-input-field"}
      sx={{
        borderRadius: 4,
        width: 275,
        padding: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: needsUpdate && readyForSave ? "0 0px 30px #1B242D !important" : "",
      }}
    >
      <CardContent>
        <Stack direction="column" spacing={0} alignItems="baseline">
          <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 12, fontWeight: "bold" }}>
            Ordinal {aiChainRunnable.ordinal}
          </Typography>
          <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 12, fontWeight: "bold" }}>
            {aiChainRunnable.segmentType} Segment
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "text.secondary",
              fontSize: 12,
              alignSelf: "center",
              paddingBottom: 1,
              paddingTop: 1,
            }}
          >
            {aiChainRunnable.aiModel?.name || "None"}
          </Typography>
          <Typography variant="h4" component="div" sx={{ fontSize: 16, fontWeight: 600 }}>
            Prompt: {aiChainRunnable.aiPrompt.name || "WIP"}
          </Typography>
        </Stack>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "6",
            "-webkit-box-orient": "vertical",
            wordWrap: "break-word",
            textAlign: "left",
            fontSize: 12,
          }}
          variant="body2"
        >
          {aiChainRunnable.aiPrompt.text}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", padding: 0 }}>
        <Button size="small" onClick={openModal}>
          Edit
        </Button>
        <Button color="error" size="small" onClick={() => deletePrompt(index)}>
          Remove
        </Button>
      </CardActions>
    </Card>
  )
}
