import { DataGrid, GridPaginationModel, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid"
import { FC, useContext, useMemo, useState } from "react"
import { getAdminInsights } from "@api/admin/insights"
import { adminInsightColumns } from "@src/dataGridSchema/insights/insightColumns"
import { adminInsightRows } from "@src/dataGridSchema/insights/insightRows"
import { InsightsContainerContext } from "@src/containers/InsightContainers/InsightsContainer"
import { Toolbar } from "./Toolbar"

export const InsightsAdminDataGrid: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(InsightsContainerContext)

  let paginationModel = {
    pageSize: urlParamState.count,
    page: urlParamState.page,
  }
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: params.get("sort") ?? "created_at",
      sort: (params.get("order") as "asc" | "desc") ?? "desc",
    },
  ])

  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const paramsWithOrder = new URLSearchParams(params)
  if (!paramsWithOrder.has("sort")) {
    paramsWithOrder.set("sort", "created_at")
    paramsWithOrder.set("order", "desc")
  }
  const { data, isLoading } = getAdminInsights(paramsWithOrder)

  const columns = adminInsightColumns()
  const rows = useMemo(() => {
    if (data?.briefings) {
      return data.briefings.map((insight) => adminInsightRows(insight))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (mode: GridPaginationModel) => {
    setPaginationModel(mode)
    setUrlParamState({
      ...urlParamState,
      page: mode.page,
    })
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
    if (model.length > 0) {
      setUrlParamState({
        ...urlParamState,
        sort: model[0]?.field,
        order: model[0]?.sort,
        page: 0,
      })
    }
  }

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      columns={columns}
      disableRowSelectionOnClick
      loading={isLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={(newSelection) => setRowSelectionModel(newSelection)}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      rowHeight={100}
      rowSelectionModel={rowSelectionModel}
      slots={{ toolbar: Toolbar }}
      slotProps={{ toolbar: { rowSelectionModel, setRowSelectionModel } }}
      sortingMode="server"
      sortModel={sortModel}
      sortingOrder={["asc", "desc"]}
    />
  )
}
