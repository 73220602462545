import { AiChainRunnable } from "@interfaces/AiProducts/models"
import { FunnelOrchestratorEditor } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"

export const transformOrchestratorToAiRunnables = (configuration: FunnelOrchestratorEditor): AiChainRunnable[] => {
  const runnables: AiChainRunnable[] = []

  if (configuration.initialSegment) {
    runnables.push(...configuration.initialSegment)
  }
  if (configuration.middleSegment) {
    runnables.push(...configuration.middleSegment)
  }
  if (configuration.tailSegment) {
    runnables.push(configuration.tailSegment)
  }
  return runnables
}
