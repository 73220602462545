import { PromptingResponse } from "@api/admin/AiProducts/insights"
import { ReactNode, useState, createContext } from "react"

interface DisplayData {
  [key: string]: PromptingResponse
}
interface DisplayContextProps {
  displayData: { [key: string]: PromptingResponse }
  updateState: (modalKey: string, newDisplayData: PromptingResponse) => void
}

const defaultDisplayData: DisplayData = {}

export const DisplayDataContext = createContext<DisplayContextProps>({
  displayData: defaultDisplayData,
  updateState: () => {},
})

export const DisplayDataContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [displayData, setDisplayData] = useState<DisplayData>(defaultDisplayData)

  const updateState = (modalKey: string, newDisplayData: PromptingResponse) => {
    const currentDisplayData = { ...displayData }
    setDisplayData({ ...currentDisplayData, [modalKey]: newDisplayData })
  }

  return <DisplayDataContext.Provider value={{ displayData, updateState }}>{children}</DisplayDataContext.Provider>
}
