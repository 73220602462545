import { FC, useState, useEffect } from "react"
import { Dialog, DialogTitle, DialogActions, DialogContent, Skeleton } from "@mui/material"
import { FetchedClipShare, useCreateBookmarkAddClip } from "../../../api/bookmarks"
import { useCreateClipShare, useDeleteClipShare } from "../../../api/bookmarks/clip_shares"
import { useToast } from "../../shared/ToastHook"
import { CreateFolderEditor } from "./CreateFolderEditor"
import TitleClipStartEditor from "./TitleClipStartEditor"
import BookmarkAddSelector from "./BookmarkAddSelector"
import moment from "moment"
import { formatSeconds } from "../../shared/config"
import { ClipAttributes } from "./interface"
import { FetchedMeeting } from "../../../api/meetings"
import { Meeting } from "../../../interfaces/meeting"
import { carefulClick } from "./functions"
import { useCloseModalKey, useData, useModalKey } from "../../shared/OpenModalHook"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

export const BOOKMARK_EDITOR_MODAL_KEY = "BOOKMARK_EDITOR"
export interface BookmarkEditorData {
  meeting: FetchedMeeting | Meeting
  timestamp: string
  transcriptId: number | string
  keyword: string
  activeBookmarks: number[]
  updateBookmarks: (newBookmarks: number[]) => void
}

const BookmarkEditor: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const open = useModalKey(BOOKMARK_EDITOR_MODAL_KEY)
  const handleClose = useCloseModalKey(BOOKMARK_EDITOR_MODAL_KEY)
  const bookmarkData: BookmarkEditorData | undefined = useData()
  const { mutate: createBookmarkAddClip, isLoading: isCreatingBookmark } = useCreateBookmarkAddClip()
  const { mutate: addClipToBookmark, isLoading: isCreating } = useCreateClipShare()
  const { mutate: removeClips, isLoading: isDeleting } = useDeleteClipShare()
  const [showCreateFolder, setShowCreateFolder] = useState(false)

  const getStartTime = (timestamp: string) => {
    return formatSeconds(moment.duration(timestamp).subtract(5, "seconds").asSeconds())
  }

  const setToast = useToast()
  const [clipAttributes, setClipAttributes] = useState<ClipAttributes>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(isCreating || isCreatingBookmark || isDeleting)
  }, [isCreating, isCreatingBookmark, isDeleting])

  useEffect(() => {
    if (!bookmarkData || !open) return
    setClipAttributes({
      clipTitle: bookmarkData.meeting?.title,
      description: "",
      selectedUsers: [],
    })
  }, [bookmarkData])

  const onClickAddToBookmark = (id: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>, clip_type: string) => {
    if (!bookmarkData || !clipAttributes || !open) return

    const params = {
      clipTitle: clipAttributes.clipTitle,
      meetingId: bookmarkData.meeting.id,
      bookmarkId: id,
      startTimeInSeconds: moment.duration(getStartTime(bookmarkData.timestamp)).asSeconds(),
      transcriptId: bookmarkData.transcriptId,
      keyword: bookmarkData.keyword,
      notes: clipAttributes.description,
      taggedUsers: clipAttributes.selectedUsers,
      clip_type: clip_type,
    }
    vitallyTrack(currentUser, "Bookmark Clip", params, true)

    event.stopPropagation()
    addClipToBookmark(
      {
        optionalTitle: clipAttributes.clipTitle,
        meetingId: bookmarkData.meeting.id,
        startTimeInSeconds: moment.duration(getStartTime(bookmarkData.timestamp)).asSeconds(),
        bookmarkId: id,
        transcriptId: bookmarkData.transcriptId,
        keyword: bookmarkData.keyword,
        description: clipAttributes.description,
        taggedUsers: clipAttributes.selectedUsers,
        clip_type: clip_type,
      },
      {
        onSuccess: () => {
          setToast("This clip has been bookmarked", 1750)
          bookmarkData.updateBookmarks([...bookmarkData.activeBookmarks, id])
        },
        onError: () => {
          setToast("Failed to bookmark clip", 1750)
        },
      },
    )
    handleClose()
  }

  const onClickRemoveBookmark = (
    clip: FetchedClipShare,
    bookmarkId: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!bookmarkData) return
    event.stopPropagation()
    removeClips([clip], {
      onSuccess: () => {
        setToast("This bookmark has been removed", 1750)
        bookmarkData.updateBookmarks(bookmarkData.activeBookmarks.filter((id) => id !== bookmarkId))
      },
      onError: () => {
        setToast("Failed to remove bookmark", 1750)
      },
    })
    handleClose()
  }

  const createFolder = (title: string, visible: boolean) => {
    if (!bookmarkData || !clipAttributes || !open) return
    createBookmarkAddClip(
      {
        title: title,
        clipTitle: clipAttributes.clipTitle,
        meetingId: bookmarkData.meeting.id,
        startTimeInSeconds: moment.duration(getStartTime(bookmarkData.timestamp)).asSeconds(),
        transcriptId: bookmarkData.transcriptId,
        keyword: bookmarkData.keyword,
        description: clipAttributes.description,
        visible: visible,
        taggedUsers: clipAttributes.selectedUsers,
      },
      {
        onSuccess: () => {
          setToast("This clip has been bookmarked", 1750)
        },
        onError: () => {
          setToast("Failed to bookmark clip", 1750)
        },
      },
    )
    setShowCreateFolder(false)
    handleClose()
  }

  return (
    <Dialog
      onClose={(event: React.MouseEvent) =>
        carefulClick(event, () => {
          handleClose(), setShowCreateFolder(false)
        })
      }
      open={open}
      PaperProps={{ sx: { width: "100%", paddingX: 2, paddingTop: 2, paddingBottom: 2 } }}
    >
      {!bookmarkData || !clipAttributes ? (
        <Skeleton variant="rectangular" height={50} />
      ) : (
        <>
          <DialogTitle onClick={(event) => carefulClick(event)}>
            <TitleClipStartEditor
              clipAttributes={clipAttributes}
              setClipAttributes={setClipAttributes}
              startTime={getStartTime(bookmarkData.timestamp)}
            />
          </DialogTitle>
          <DialogContent onClick={(event) => carefulClick(event)}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={50} />
            ) : (
              <BookmarkAddSelector
                transcriptId={bookmarkData.transcriptId}
                onClickAddToBookmark={onClickAddToBookmark}
                onClickRemoveBookmark={onClickRemoveBookmark}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ marginTop: 2 }} onClick={(event) => carefulClick(event)}>
            <CreateFolderEditor
              showCreateFolder={showCreateFolder}
              createFolder={createFolder}
              setShowCreateFolder={setShowCreateFolder}
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default BookmarkEditor
