import { FC, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react"
import { CampaignDialog } from "../shared/CampaignDialog"
import { useDeleteCampaign, useCloneCampaigns } from "../../api/campaigns"
import { setSubscriptionOverride } from "../../api/subscriptions"
import { AssignDialogMenu } from "./AssignDialogMenu"
import { UnassignDialogMenu } from "./UnassignDialogMenu"
import { useAssignCampaign, useUnassignCampaign } from "../../api/campaigns"
import { getUsersToUnassign } from "./CampaignFunctions"
import { unpackSetters } from "../shared/unpackSetters"
import { EmailCadence, ICampaignState, Campaign } from "@interfaces/campaign"
import { User } from "@src/interfaces/user"
import { vitallyTrack } from "@config/vitally"
import { useFetchCurrentUser } from "@api/users"

export const ManageCampaignItems: FC<{
  campaignState: ICampaignState
  setCampaignState: Dispatch<SetStateAction<ICampaignState>>
  handleClose: () => void
  sortedCampaigns: Campaign[]
}> = ({ campaignState, setCampaignState, handleClose, sortedCampaigns }) => {
  const {
    assignDialogOpen,
    selectedUnassignUsers,
    cloneDialogOpen,
    deleteDialogOpen,
    notifyDialogOpen,
    unassignDialogOpen,
    dialogAction,
  } = campaignState
  const {
    setAssignDialogOpen,
    setSelectedUnassignUsers,
    setCloneDialogOpen,
    setDeleteDialogOpen,
    setNotifyDialogOpen,
    setUnassignDialogOpen,
  } = unpackSetters(campaignState, setCampaignState)

  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: deleteCampaign, isSuccess: deleteSuccess } = useDeleteCampaign()
  const { mutate: cloneCampaigns, isSuccess: cloneSuccess } = useCloneCampaigns()
  const { mutate: submitNotficationSettings, isSuccess: notifySuccess } = setSubscriptionOverride()
  const { mutate: assignCampaignToUser, isSuccess: assignSuccess } = useAssignCampaign()
  const { mutate: unassignCampaignToUser, isSuccess: unassignSuccess } = useUnassignCampaign()

  const [assignDialogConfirmOpen, setAssignDialogConfirmOpen] = useState(false)
  const [unassignDialogConfirmOpen, setUnassignDialogConfirmOpen] = useState(false)
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Unsubscribed")
  const [selectedAssignUsers, setSelectedAssignUsers] = useState<User[]>([])
  const usersToUnassign = getUsersToUnassign(sortedCampaigns)

  useEffect(() => {
    setSelectedUnassignUsers(usersToUnassign)
  }, [usersToUnassign])

  const selectedCampaignIds = useMemo(() => {
    if (sortedCampaigns !== undefined && sortedCampaigns[0] !== undefined) return sortedCampaigns.map((c) => c.id)
    return []
  }, [sortedCampaigns])

  const closeAndClearSelectedUsers = () => {
    handleClose()
    setAssignDialogConfirmOpen(false)
    setUnassignDialogConfirmOpen(false)
    setUnassignDialogConfirmOpen(false)
    setAssignDialogOpen(false)
    setSelectedAssignUsers([])
    setSelectedUnassignUsers(usersToUnassign || [])
    setEmailCadence("Unsubscribed")
  }

  return (
    <>
      <CampaignDialog
        requestStatus={cloneSuccess}
        dialogOpen={cloneDialogOpen}
        setDialogOpen={setCloneDialogOpen}
        onComplete={() => {
          handleClose()
          setCloneDialogOpen(false)
        }}
        onSubmit={() => cloneCampaigns({ id: selectedCampaignIds })}
        dialogAction={dialogAction}
        campaigns={sortedCampaigns}
      />
      <CampaignDialog
        requestStatus={deleteSuccess}
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        onComplete={() => {
          handleClose()
          setDeleteDialogOpen(false)
        }}
        onSubmit={() => deleteCampaign({ id: selectedCampaignIds })}
        dialogAction={dialogAction}
        campaigns={sortedCampaigns}
      />
      <CampaignDialog
        requestStatus={notifySuccess}
        dialogOpen={notifyDialogOpen}
        setDialogOpen={setNotifyDialogOpen}
        onComplete={() => {
          handleClose()
          setNotifyDialogOpen(false)
        }}
        onSubmit={(value) => submitNotficationSettings({ ids: selectedCampaignIds, emailCadence: value })}
        dialogAction={dialogAction}
        campaigns={sortedCampaigns}
        defaultValue={sortedCampaigns[0]?.email_cadence}
      />
      <CampaignDialog
        dialogOpen={assignDialogConfirmOpen}
        setDialogOpen={setAssignDialogConfirmOpen}
        onComplete={() => closeAndClearSelectedUsers()}
        onSubmit={() => {
          vitallyTrack(currentUser, "Campaigns Assign Campaign", {
            usersAssigned: selectedAssignUsers.map((user) => user.email),
            campaignsAssigned: sortedCampaigns
              .filter((campaign) => selectedCampaignIds.includes(campaign.id))
              .map((c) => c.name),
            emailCadence: emailCadence,
          })
          assignCampaignToUser({
            userIds: selectedAssignUsers.map((user) => `${user.id}`),
            campaignIds: selectedCampaignIds.map((id) => `${id}`),
            emailCadence: emailCadence,
          })
        }}
        requestStatus={assignSuccess}
        dialogAction="assign"
      />
      <AssignDialogMenu
        selectedUsers={selectedAssignUsers}
        setSelectedUsers={setSelectedAssignUsers}
        emailCadence={emailCadence}
        setEmailCadence={setEmailCadence}
        handleClose={() => {
          closeAndClearSelectedUsers()
          setAssignDialogOpen(false)
        }}
        isOpen={assignDialogOpen}
        onSave={() => {
          setAssignDialogOpen(false)
          setAssignDialogConfirmOpen(true)
        }}
        assignees={usersToUnassign || []}
      />
      <UnassignDialogMenu
        handleClose={() => {
          closeAndClearSelectedUsers()
          setUnassignDialogOpen(false)
        }}
        isOpen={unassignDialogOpen}
        availableUsers={usersToUnassign}
        onSave={() => {
          setUnassignDialogOpen(false)
          setUnassignDialogConfirmOpen(true)
        }}
        selectedUsers={selectedUnassignUsers}
        setSelectedUsers={setSelectedUnassignUsers}
      />
      <CampaignDialog
        dialogOpen={unassignDialogConfirmOpen}
        setDialogOpen={setUnassignDialogConfirmOpen}
        onComplete={() => closeAndClearSelectedUsers()}
        onSubmit={() => {
          unassignCampaignToUser({
            userIds: selectedUnassignUsers.map((user) => user.id.toString()),
            campaignIds: selectedCampaignIds.map((id) => `${id}`),
          })
        }}
        requestStatus={unassignSuccess}
        dialogAction="unassign"
      />
    </>
  )
}
