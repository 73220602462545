import { Skeleton, Stack } from "@mui/material"
import { GeographySelectorMultiple } from "../Selectors/Multiple/Geographies"
import { useFetchGeographies } from "@src/api/geographies"
import { useFetchOrganizations } from "@src/api/organizations"
import { OpportunityCampaignCardContext } from "./indexContext"
import { FC, useContext } from "react"
import { ChannelSelectorMultiple } from "../Selectors/Multiple/Channel"

export const Geographies: FC = () => {
  const { filterParams, setFilterParams } = useContext(OpportunityCampaignCardContext)
  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {!geographiesIsFetched || !geographiesData ? (
        <>
          <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
          <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
          <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
        </>
      ) : (
        <>
          <GeographySelectorMultiple
            options={geographiesData}
            label="State"
            type="states"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData}
            label="County"
            type="counties"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData}
            label="City"
            type="cities"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            sx={{ flex: "30ch 1 0" }}
          />
        </>
      )}
      {!organizationsIsFetched ? (
        <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
      ) : (
        <ChannelSelectorMultiple
          options={organizationsData ? organizationsData.organizations : []}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          sx={{ flex: "30ch 1 0" }}
        />
      )}
    </Stack>
  )
}
