import { Dispatch, FC, SetStateAction } from "react"
import { Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import { NewMeeting } from "@components/Admin/Organizations/NewMeeting"
import { Confirm } from "@components/shared/Confirm"
import {
  useDeleteMeetings,
  useDeleteTranscriptsForMeetings,
  useNewMeeting,
  useRunTranscriptionJobs,
  useUpdateMeeting,
  useUpdateSpam,
} from "@api/meetings"
import { useGenerateGeneralInsights } from "@api/admin/meetings"

export const ActionsDialogs: FC<{
  meetingIds: number[]
  handleClose: () => void
  openNewMeetingDialog: string
  setOpenNewMeetingDialog: Dispatch<SetStateAction<string>>
  openDeleteMeetingConfirm: boolean
  setOpenDeleteMeetingConfirm: Dispatch<SetStateAction<boolean>>
  openDeleteTranscriptsConfirm: boolean
  setOpenDeleteTranscriptsConfirm: Dispatch<SetStateAction<boolean>>
  openGenerateGeneralInsightsConfirm: boolean
  setOpenGenerateGeneralInsightsConfirm: Dispatch<SetStateAction<boolean>>
  openOverrideConfirm: boolean
  setOpenOverrideConfirm: Dispatch<SetStateAction<boolean>>
  openUpdateSpam: string
  setOpenUpdateSpam: Dispatch<SetStateAction<string>>
}> = ({
  meetingIds,
  handleClose,
  openNewMeetingDialog,
  setOpenNewMeetingDialog,
  openDeleteMeetingConfirm,
  setOpenDeleteMeetingConfirm,
  openDeleteTranscriptsConfirm,
  setOpenDeleteTranscriptsConfirm,
  openGenerateGeneralInsightsConfirm,
  setOpenGenerateGeneralInsightsConfirm,
  openOverrideConfirm,
  setOpenOverrideConfirm,
  openUpdateSpam,
  setOpenUpdateSpam,
}) => {
  const { organizationId } = useParams<IOrganizationRouteParams>()

  const { mutate: newMeeting } = useNewMeeting(organizationId)
  const { mutate: updateMeeting } = useUpdateMeeting(organizationId)
  const { mutate: deleteMeetings } = useDeleteMeetings(organizationId)
  const { mutate: runTranscriptionJobs } = useRunTranscriptionJobs(organizationId)
  const { mutate: deleteTranscripts } = useDeleteTranscriptsForMeetings(organizationId)
  const { mutate: updateSpam } = useUpdateSpam(organizationId)
  const { mutate: generateGeneralInsights } = useGenerateGeneralInsights()

  return (
    <>
      {openNewMeetingDialog !== "" && (
        <NewMeeting
          open={openNewMeetingDialog !== ""}
          onClose={() => setOpenNewMeetingDialog("")}
          onConfirm={openNewMeetingDialog === "new" ? newMeeting : updateMeeting}
          type={openNewMeetingDialog}
          meetingId={meetingIds[0]}
        />
      )}
      <Confirm
        onSucceed={() => {
          deleteMeetings({ orgId: organizationId, meetingIds: meetingIds })
          handleClose()
          setOpenDeleteMeetingConfirm(false)
        }}
        onClose={() => setOpenDeleteMeetingConfirm(false)}
        open={openDeleteMeetingConfirm}
        title="Delete meeting(s)"
        body="Are you sure you want to delete these meetings? This action is irreversible."
      />
      <Confirm
        onSucceed={() => {
          runTranscriptionJobs({ meetingIds, force: true })
          handleClose()
          setOpenOverrideConfirm(false)
        }}
        onClose={() => setOpenOverrideConfirm(false)}
        open={openOverrideConfirm}
        title="Force Run Transcription Jobs"
        body="Are you sure you want to force run these transcription jobs?"
        renderBody={() => (
          <Grid item marginTop={2} direction="column">
            {meetingIds.map((id) => (
              <Typography key={id}>{`#${id}`}</Typography>
            ))}
          </Grid>
        )}
      />
      <Confirm
        onSucceed={() => {
          deleteTranscripts({ meetingIds: meetingIds })
          handleClose()
          setOpenDeleteTranscriptsConfirm(false)
        }}
        onClose={() => setOpenDeleteTranscriptsConfirm(false)}
        open={openDeleteTranscriptsConfirm}
        title="Delete transcripts for meeting(s)"
        body="Are you sure you want to delete the transcripts for these meeting(s)? This action is irreversible."
        renderBody={() => (
          <Grid container item marginTop={2} direction="column">
            {meetingIds.map((id) => (
              <Typography key={id}>{`#${id}`}</Typography>
            ))}
          </Grid>
        )}
      />
      <Confirm
        onSucceed={() => {
          handleClose()
          setOpenUpdateSpam("")
          updateSpam({ ids: meetingIds.join(","), is_spam: openUpdateSpam === "spam" })
        }}
        onClose={() => setOpenUpdateSpam("")}
        open={openUpdateSpam !== ""}
        title="Update Spam Model"
        body={`Are you sure you want to mark these as ${openUpdateSpam}???`}
      />
      <Confirm
        onSucceed={() => {
          generateGeneralInsights({ id: meetingIds })
          handleClose()
          setOpenGenerateGeneralInsightsConfirm(false)
        }}
        onClose={() => setOpenGenerateGeneralInsightsConfirm(false)}
        open={openGenerateGeneralInsightsConfirm}
        title="Generate general insight(s)"
        body="Are you sure you want to generate general insights for these meeting(s)?"
        renderBody={() => (
          <Grid container item marginTop={2} direction="column">
            {meetingIds.map((id) => (
              <Typography key={id}>{`#${id}`}</Typography>
            ))}
          </Grid>
        )}
      />
    </>
  )
}
