import {
  AiChainRunnable,
  AiFeature,
  AiFeaturesMetadata,
  AiFeatureStatus,
  AiProductType,
  OrchestratorType,
  AiPrompt,
  UsersAiFeature,
} from "../../models"
import {
  AiChainRunnablesResponse,
  AiFeatureResponse,
  AiFeaturesMetadataResponse,
  AiPromptResponse,
  UsersAiFeatureResponse,
} from "../../responseObjectInterfaces"

export const serializeAiFeatureResponse: (aiFeature?: AiFeatureResponse) => AiFeature = (aiFeature) => {
  if (!aiFeature) return {} as AiFeature

  return {
    id: aiFeature.id,
    featureName: aiFeature.feature_name || "",
    productType: aiFeature.product_type as AiProductType,
    featureStatus: aiFeature.feature_status as AiFeatureStatus,
    orchestratorType: aiFeature.orchestrator_type as OrchestratorType,
    aiChainRunnablesId: aiFeature.ai_chain_runnable_id,
    createdAt: aiFeature.created_at,
    updatedAt: aiFeature.updated_at,
    aiChainRunnables: aiFeature.ai_chain_runnables?.map((runnable) => serializeAiChainRunnableResponse(runnable)) || [],
  }
}

export const serializeAiChainRunnableResponse: (response?: AiChainRunnablesResponse) => AiChainRunnable = (
  response,
) => {
  if (!response) return {} as AiChainRunnable
  return {
    id: response.id,
    aiFeatureId: response.ai_feature_id,
    aiPromptId: response.ai_prompt_id,
    aiModelId: response.ai_model_id,
    aiOutputFormatterId: response.ai_output_formatter_id,
    segmentType: response.segment_type,
    ordinal: response.ordinal,
    aiPrompt: serializeAiPromptResponse(response.ai_prompt),
    inputType: response.input_type,
  } as AiChainRunnable
}

export const serializeAiPromptResponse: (response?: AiPromptResponse) => AiPrompt = (response) => {
  if (!response) return {} as AiPrompt
  return {
    id: response.id,
    name: response.name,
    text: response.text,
  } as AiPrompt
}

export const serializeAiFeaturesMetadataResponse: (response?: AiFeaturesMetadataResponse) => AiFeaturesMetadata = (
  response,
) => {
  if (!response) return {} as AiFeaturesMetadata
  return {
    orchestratorType: response.orchestrator_type,
    productType: response.product_type,
    status: response.feature_status,
  } as AiFeaturesMetadata
}

export const serializeUsersAiFeatureResponse: (response?: UsersAiFeatureResponse) => UsersAiFeature = (response) => {
  if (!response) return {} as UsersAiFeature
  return {
    id: response.id,
    userId: response.user_id,
    aiFeatureId: response.ai_feature_id,
    inputSettings: response.input_settings,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  } as UsersAiFeature
}
