import { Slide, SxProps } from "@mui/material"
import { forwardRef } from "react"
import { TransitionProps } from "@mui/material/transitions"

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const dialogSx: SxProps = {
  height: "100%",
  "& .MuiDialog-container": {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  "& .MuiDialog-paper": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}

type Size = "large" | "medium" | "small" | "extraSmall"

export const sizes = (size: Size) => {
  const sizes: { [key in Size]: SxProps } = {
    large: { fontSize: "2.441rem !important" },
    medium: { fontSize: "1.953rem !important" },
    small: { fontSize: "1.25rem !important" },
    extraSmall: { fontSize: ".75rem !important" },
  }

  return sizes[size]
}
