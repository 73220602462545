import { FC, useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { HomePageContext, HomePageParams, HomePageQueryParams } from "@pages/HomePage"
import { defaultDateRange } from "../../shared/config"
import { ListView } from "./listView"
import { TableView } from "./tableView"
import { useSearch } from "@api/search"
import { Box, Pagination, Stack, useMediaQuery, useTheme } from "@mui/material"
import Loading from "@components/shared/Loading"
import { NoResultsContainer } from "@components/shared/NoResultsContainer"
import { Sort } from "./sort"
import { unpackSetters } from "../../shared/unpackSetters"
import { SearchOverview } from "./searchOverview"
import { View } from "./view"
import { SearchQueryParams } from "@interfaces/search"
import { useFetchMyClipSharesInBookmarks } from "@api/clip_shares"
import { BookmarkContext } from "@components/MeetingDetail/Shared/Context"
import BookmarkEditor from "@components/MeetingDetail/Bookmark/BookmarkEditor"
import { parseUniqueIntegerListParam } from "@functions/generateURL"
import { vitallyTrack } from "@config/vitally"
import { useFetchCurrentUser } from "@api/users"

export const MeetingList: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const theme = useTheme()
  const pageIsSmall = useMediaQuery(theme.breakpoints.down("md"))
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const history = useHistory()
  const initialParams: HomePageParams = {
    searchTerms: queryParams.getAll(SearchQueryParams.SearchTerms),
    mustIncludeTerms: queryParams.getAll(SearchQueryParams.MustIncludeTerms),
    base64FilterParams: queryParams.get(SearchQueryParams.FilterParams),
    dateRange: queryParams.getAll(SearchQueryParams.DateRange).map((dateString) => parseInt(dateString, 10)),
    campaignId: queryParams.get(SearchQueryParams.CampaignId),
    searchDateRange: defaultDateRange,
    displayDateRange: defaultDateRange,
    page: parseInt(queryParams.get(SearchQueryParams.Page) || "1"),
    sortBy: (queryParams.get(SearchQueryParams.SortBy) as "hits" | "date") || "hits",
    proximity: queryParams.get(SearchQueryParams.Proximity)
      ? Number(queryParams.get(SearchQueryParams.Proximity))
      : null,
    exclude: queryParams.get(SearchQueryParams.Exclude) ? queryParams.get(SearchQueryParams.Exclude) === "true" : false,
    view: (queryParams.get(HomePageQueryParams.View) as "list" | "table") || "list",
    selectedPeopleIds: parseUniqueIntegerListParam(queryParams.get(SearchQueryParams.SelectedPeopleIds) || ""),
  }
  const [homePageParams, setHomePageParams] = useState(initialParams)
  useEffect(() => {
    setHomePageParams(initialParams)
  }, [locationSearchParams])

  const { data: meetingsSearchData, isLoading, isFetching: isSearchFetching } = useSearch(queryParams)
  const { setPage } = unpackSetters(homePageParams, setHomePageParams)

  const { data: fetchMyClipsData, refetch } = useFetchMyClipSharesInBookmarks()

  useEffect(() => {
    // Refetch the data when the component mounts
    refetch()
  }, [])

  const [transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds] = useState<Record<string, number[]>>({})

  useEffect(() => {
    if (!fetchMyClipsData) {
      return
    }
    setTranscriptIdsToBookmarkIds(
      Object.values(fetchMyClipsData).reduce((acc, transcriptToBookmarkIds) => {
        Object.entries(transcriptToBookmarkIds).forEach(([transcriptId, bookmarkIds]) => {
          acc[transcriptId] = bookmarkIds
        })
        return acc
      }, {}),
    )
  }, [Boolean(fetchMyClipsData), setTranscriptIdsToBookmarkIds])

  useEffect(() => {
    if (isSearchFetching && currentUser) {
      vitallyTrack(currentUser, "Home Search", {
        searchTerms: homePageParams.searchTerms,
        anchorTerms: homePageParams.mustIncludeTerms,
        campaignId: homePageParams.campaignId,
        dateRange: homePageParams.searchDateRange,
        proximitySeconds: homePageParams.proximity,
        exclude: homePageParams.exclude,
        page: homePageParams.page,
        sortBy: homePageParams.sortBy,
        view: homePageParams.view,
        peopleIds: Array.from(homePageParams.selectedPeopleIds).join(","),
      })
    }
  }, [isSearchFetching, currentUser])

  let searchResults: JSX.Element | JSX.Element[] = []

  if (meetingsSearchData && meetingsSearchData.meetings.length === 0) {
    searchResults = <NoResultsContainer />
  } else if (isLoading) {
    searchResults = (
      <Box position="relative" flex="1">
        <Loading useCloverleafIcon />
      </Box>
    )
  } else {
    searchResults = homePageParams.view === "list" || pageIsSmall ? <ListView /> : <TableView />
  }

  return (
    <HomePageContext.Provider value={{ homePageParams, setHomePageParams, meetingsSearchData: meetingsSearchData }}>
      <BookmarkContext.Provider value={{ transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds }}>
        <Stack direction="row" flexWrap="wrap" gap={1} marginTop={2} alignItems="center">
          <SearchOverview meetingsSearchData={meetingsSearchData} />
          <Stack direction="row" spacing={3}>
            <View />
            <Sort />
          </Stack>
        </Stack>
        {searchResults}
        <BookmarkEditor />
        <Stack direction="row" justifyContent="flex-end">
          <Pagination
            count={Math.ceil((meetingsSearchData?.total_meeting_hits || 25) / 25)}
            page={homePageParams.page}
            onChange={(_, pageNumber) => {
              setPage(pageNumber)
              queryParams.set("page", pageNumber.toString())
              history.push({ pathname: window.location.pathname, search: queryParams.toString() })
            }}
          />
        </Stack>
      </BookmarkContext.Provider>
    </HomePageContext.Provider>
  )
}
