import { useState, FC } from "react"
import { Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog } from "@mui/material"
import { useStartTranscriptionUat } from "@src/api/admin/transcription_jobs"
import { useCloseModalKey, useModalKey } from "@components/shared/OpenModalHook"

export const startTranscriptionEngineUatModalKey = "START_TRANSCRIPTION_ENGINE_UAT"

const CreateUpdateModal: FC = () => {
  const open = useModalKey(startTranscriptionEngineUatModalKey)
  const close = useCloseModalKey(startTranscriptionEngineUatModalKey)

  const { mutate: startTranscriptionUat } = useStartTranscriptionUat({ onSuccess: close })

  const [count, setCount] = useState(100)

  return (
    <Dialog fullWidth open={open} onClose={close}>
      <DialogTitle>Start Transcription Engine UAT</DialogTitle>
      <DialogContent>
        <TextField
          value={count}
          onChange={(event) => setCount(parseInt(event.target.value))}
          label="Count"
          sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => startTranscriptionUat({ count, before: new Date() })} variant="contained">
          Start
        </Button>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUpdateModal
