import { AiChainRunnable, AiChainRunnableSegmentTypes } from "@interfaces/AiProducts/models"
import { FunnelOrchestratorEditor } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"

export const updateAiChainRunnable = (
  state: FunnelOrchestratorEditor,
  index: number,
  aiChainRunnable: AiChainRunnable,
  segmentType: AiChainRunnableSegmentTypes,
): FunnelOrchestratorEditor => {
  const workingAiChainRunnable = { ...aiChainRunnable }

  if (segmentType === AiChainRunnableSegmentTypes.INITIAL) {
    state.initialSegment[index] = workingAiChainRunnable
  } else if (segmentType === AiChainRunnableSegmentTypes.MIDDLE) {
    state.middleSegment[index] = workingAiChainRunnable
  } else {
    state.tailSegment = workingAiChainRunnable
  }
  return state
}
