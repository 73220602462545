import { createContext, Dispatch, SetStateAction } from "react"
import { FetchedMeeting } from "../../../api/meetings"

interface MeetingDetailState {
  videoStartTime: { startTime: number; change: boolean }
  playingVideo: boolean
  isReady: boolean
}

interface MeetingDetailContext {
  progress: [number, number]
  state: MeetingDetailState
  setProgress: Dispatch<SetStateAction<[number, number]>>
  setState: Dispatch<SetStateAction<MeetingDetailState>>
}

interface MeetingContext {
  meeting: FetchedMeeting
  setMeeting: Dispatch<SetStateAction<FetchedMeeting>>
}

export const Context = createContext<MeetingDetailContext>({
  progress: [0, 0],
  state: {
    videoStartTime: { startTime: 0, change: false },
    playingVideo: false,
    isReady: false,
  },
  setProgress: () => {
    /* placeholder for typescript */
  },
  setState: () => {
    /* placeholder for typescript */
  },
})

interface TranscriptIdsToBookmarkContext {
  transcriptIdsToBookmarkIds: Record<string, number[]>
  setTranscriptIdsToBookmarkIds: React.Dispatch<React.SetStateAction<Record<string, number[]>>>
}

export const BookmarkContext = createContext<TranscriptIdsToBookmarkContext>({
  transcriptIdsToBookmarkIds: {},
  setTranscriptIdsToBookmarkIds: () => {
    /* placedholder for typescript */
  },
})

export const MeetingContext = createContext<MeetingContext>({
  meeting: {
    id: 0,
    source_video_url: "",
    title: "",
    published_at: "",
    organization_id: 0,
    organization_name: "",
    important_words: [],
    thumbnail_url: "",
    duration_seconds: "",
    metadata: {
      word_counts: [],
    },
    created_at: "",
    updated_at: "",
    discarded_at: null,
    city: "",
    county: "",
    state: "",
    has_viewed: false,
    description: "",
    needs_run: false,
    transcript_pdf_url: null,
    is_spam: false,
    spam_certainty: 0,
    user_marked_spam: false,
    word_error_rate: null,
  },
  setMeeting: () => {
    /* placedholder for typescript */
  },
})
