import { FC, useState } from "react"
import {
  Stack,
  Skeleton,
  TextField,
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { useFetchGeographies } from "@src/api/geographies"
import { useFetchUserOrganizationUsersMapById } from "@src/api/admin/user_organizations"
import { ChipField } from "@src/components/shared/ChipField"
import { GeographySelectorMultiple } from "@src/components/shared/Selectors/Multiple/Geographies"
import { useFetchOrganizations } from "@src/api/organizations"
import { FilterParams } from "@src/components/Home/interface"
import { ChannelSelectorMultiple } from "@src/components/shared/Selectors/Multiple/Channel"
import { useCloseModalKey, useModalKey } from "@src/components/shared/OpenModalHook"
import { useAdminCreateOpportunityCampaign } from "@src/api/admin/opportunityCampaigns"
import { useParams } from "react-router-dom"
import { useToast } from "@src/components/shared/ToastHook"
import { EmailCadence } from "@src/interfaces/campaign"
import { useGetFeatureFlagByName } from "@src/api/feature_flags"
import { useFetchIndustryIdsMap } from "@src/api/industries"

export const CREATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY = "CREATE_OPPORTUNITY_CAMPAIGN"

export const CreateOpportunityCampaignModal: FC = () => {
  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)
  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const [campaignName, setCampaignName] = useState("")
  const [anchorTermsSet, setAnchorTermsSet] = useState<Set<string>>(new Set())
  const [termsSet, setTermsSet] = useState<Set<string>>(new Set())
  const [naicsCodes, setNaicsCodes] = useState<string[]>([])
  const [industries, setIndustries] = useState<string[]>([])
  const defaultFilterParams: FilterParams & { industry: number[] } = {
    organizations: [],
    cities: [],
    counties: [],
    states: [],
    channel_types: [],
    industry: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  }
  const [filterParams, setFilterParams] = useState<FilterParams>(defaultFilterParams)
  const [ownerId, setOwnerId] = useState<number | null>(null)
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Daily")

  const reset = () => {
    setFilterParams(defaultFilterParams)
    setCampaignName("")
    setAnchorTermsSet(new Set())
    setTermsSet(new Set())
  }

  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()
  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const { data: userMapById } = useFetchUserOrganizationUsersMapById(userOrganizationId)

  const toast = useToast()
  const { mutate: createOpportunityCampaign } = useAdminCreateOpportunityCampaign({
    onSuccess: (_data, variables) => {
      toast(`Campaign ${variables.name} successfully created`)
    },
  })

  const isOpen = useModalKey(CREATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY)
  const close = useCloseModalKey(CREATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY)
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        reset()
        close()
      }}
      fullWidth
    >
      <DialogTitle>Create Opportunity Campaign</DialogTitle>
      <DialogContent>
        <Stack spacing={1} marginTop={1}>
          <TextField
            value={campaignName}
            onChange={(event) => setCampaignName(event.target.value)}
            label="Campaign Name"
          />
          <ChipField terms={termsSet} setTerms={setTermsSet} placeholder="At least one of these terms:" />
          <ChipField terms={anchorTermsSet} setTerms={setAnchorTermsSet} placeholder="Anchor terms:" />
          {/* REMOVE THIS ONCE WE REMOVE NAICS CODES OPP SEARCH FEATURE FLAG */}
          {showNaicsCodesFilterLoading ? (
            <Skeleton variant="rectangular" width="100%" height={56} />
          ) : showNaicsCodesFilter === undefined || showNaicsCodesFilter.value === "true" ? (
            <Autocomplete
              multiple
              value={naicsCodes}
              onChange={(_, newValue) => setNaicsCodes(newValue)}
              getOptionLabel={(option) => {
                const naicsCode = naicsCodesMap?.get(option)
                return `${naicsCode?.code}: ${naicsCode?.title}`
              }}
              loading={showNaicsCodesFilterLoading}
              options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
              renderInput={(params) => <TextField {...params} label="NAICS Code" />}
              renderOption={(props, option) => {
                const naicsCode = naicsCodesMap?.get(option)
                return (
                  <Box component="li" {...props} key={option}>
                    {`${naicsCode?.code}: ${naicsCode?.title}`}
                  </Box>
                )
              }}
            />
          ) : (
            <Autocomplete
              multiple
              value={industries}
              onChange={(_, newValue) => setIndustries(newValue)}
              getOptionLabel={(option) => {
                const industry = industriesDataMap?.get(option)
                return industry?.name || ""
              }}
              loading={industriesLoading}
              options={industriesDataMap ? Array.from(industriesDataMap.keys()) : []}
              renderInput={(params) => <TextField {...params} label="Industry" />}
              renderOption={(props, option) => {
                const industry = industriesDataMap?.get(option)
                return (
                  <Box component="li" {...props} key={option}>
                    {industry?.name}
                  </Box>
                )
              }}
            />
          )}
          <Stack direction="row" gap={1} flexWrap="wrap">
            {!geographiesIsFetched || !geographiesData ? (
              <>
                <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
                <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
                <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
              </>
            ) : (
              <>
                <GeographySelectorMultiple
                  options={geographiesData}
                  label="State"
                  type="states"
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  sx={{ flex: "20ch 1 0" }}
                />
                <GeographySelectorMultiple
                  options={geographiesData}
                  label="County"
                  type="counties"
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  sx={{ flex: "20ch 1 0" }}
                />
                <GeographySelectorMultiple
                  options={geographiesData}
                  label="City"
                  type="cities"
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  sx={{ flex: "30ch 1 0" }}
                />
              </>
            )}
            {!organizationsIsFetched ? (
              <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
            ) : (
              <ChannelSelectorMultiple
                options={organizationsData ? organizationsData.organizations : []}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                sx={{ flex: "30ch 1 0" }}
              />
            )}
          </Stack>
          <Autocomplete
            options={userMapById ? Array.from(userMapById.keys()) : []}
            value={ownerId}
            onChange={(_, newValue) => setOwnerId(newValue)}
            getOptionLabel={(userId) => userMapById?.get(userId)?.email || ""}
            renderInput={(params) => <TextField {...params} label="User" />}
          />
          <FormControl>
            <InputLabel>Email Cadence</InputLabel>
            <Select
              sx={{ minWidth: "20ch" }}
              value={emailCadence}
              label="Email Cadence"
              onChange={(event) => {
                setEmailCadence(event.target.value as "Unsubscribed" | "Daily" | "Weekly")
              }}
            >
              <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            reset()
            close()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            createOpportunityCampaign({
              name: campaignName,
              data: {
                terms: Array.from(termsSet),
                anchor_terms: Array.from(anchorTermsSet),
                naics_code: naicsCodes,
                industry: industries,
                ...filterParams,
              },
              email_cadence: emailCadence,
              user_id: ownerId!,
            })
            reset()
            close()
          }}
          disabled={!campaignName || !termsSet.size || !ownerId}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
