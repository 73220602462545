import { Autocomplete, TextField } from "@mui/material"
import { FC, useState, useContext, useMemo, useEffect } from "react"
import { useFetchUserCampaignNotifications } from "@src/api/admin/userCampaignNotifications"
import { UsersAiSettingsContext } from "@components/Admin/AiProducts/Playground/Contexts/UsersSettingsContext"
import { useFetchUserCampaigns } from "@src/api/admin/campaigns"
import { Campaign } from "@interfaces/Serializers/campaigns"

export const CampaignIdInput: FC = () => {
  const { userSettings, setUserSettings } = useContext(UsersAiSettingsContext)
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null)
  const { data: campaignSubscriptions } = useFetchUserCampaignNotifications(userSettings?.selectedUserAiFeature?.userId)
  const { data: campaigns } = useFetchUserCampaigns(userSettings?.selectedUserAiFeature?.userId)

  useEffect(() => {
    if (userSettings.selectedUserAiFeature?.inputSettings) {
      const campaignId = userSettings.selectedUserAiFeature.inputSettings[0]
      setSelectedCampaign(campaignOptions.find((campaign) => campaign.id === parseInt(campaignId)) || null)
    }
  }, [JSON.stringify(userSettings?.selectedUserAiFeature?.inputSettings)])

  const campaignOptions = useMemo(() => {
    let options = [] as Campaign[]
    if (campaignSubscriptions) {
      options = [...options, ...campaignSubscriptions]
    } else if (campaigns) {
      options = [...options, ...campaigns]
    }

    return options
  }, [campaignSubscriptions, campaigns])

  const updateCampaign = (newValue: Campaign | null) => {
    setUserSettings({
      ...userSettings,
      selectedUserAiFeature: {
        ...userSettings.selectedUserAiFeature,
        inputSettings: newValue?.id ? [newValue.id.toString()] : [],
      },
    })
  }

  return (
    <Autocomplete
      value={selectedCampaign}
      options={campaignOptions}
      onChange={(e, newValue) => updateCampaign(newValue)}
      renderInput={(params) => <TextField {...params} label="Campaign Id" />}
      getOptionLabel={(option) => option.name || ""}
      inputValue={selectedCampaign?.name}
    />
  )
}
