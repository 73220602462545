import {
  AiChainRunnablesResponse,
  AiModelSettingsResponse,
  AiModelsResponse,
  AiFeatureResponse,
  OutputFormattersResponse,
} from "@interfaces/AiProducts/responseObjectInterfaces"
import {
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { GridApiCommunity } from "@mui/x-data-grid/internals"

type SetRowModesModel = React.Dispatch<React.SetStateAction<GridRowModesModel>>
type SetRows = (value: React.SetStateAction<AiServicesGridRow[]>) => void

export type Editable =
  | AiModelsResponse
  | OutputFormattersResponse
  | AiChainRunnablesResponse
  | AiFeatureResponse
  | AiModelSettingsResponse
export type AiServicesGridRow = Editable & GridValidRowModel

export interface CrudActions {
  delete: (id: number) => Promise<Editable>
  create: (row: Editable) => Promise<Editable>
  update: (row: Editable) => Promise<Editable>
}

export interface EditToolbarProps {
  rows: AiServicesGridRow[]
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

export const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
  if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true
  }
}

export const handleEditClick =
  (id: GridRowId, rowModesModel: GridRowModesModel, setRowModesModel: SetRowModesModel) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

export const handleSaveClick =
  (row: AiServicesGridRow, rowModesModel: GridRowModesModel, setRowModesModel: SetRowModesModel) => () => {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } })
  }

export const handleDeleteClick =
  (id: GridRowId, crudActions: CrudActions, apiRef: React.MutableRefObject<GridApiCommunity>) => () => {
    if (typeof id === "string") id = parseInt(id)
    crudActions.delete(id)
    apiRef.current.updateRows([{ id: id, _action: "delete" }]) // not handled by processRowUpdate for some reason
  }

export const handleCancelClick =
  (
    id: GridRowId,
    rowModesModel: GridRowModesModel,
    setRowModesModel: SetRowModesModel,
    rows: AiServicesGridRow[],
    setRows: SetRows,
  ) =>
  () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
    const editedRow = rows.find((row) => row.id === id)
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

// update and add, but not delete for some reason
// if async failure throws to onProcessRowUpdateError
export const processRowUpdate = async (
  newRow: AiServicesGridRow,
  originalRow: AiServicesGridRow,
  rows: AiServicesGridRow[],
  setRows: SetRows,
  crudActions: CrudActions,
) => {
  let updatedRow: AiServicesGridRow
  if (newRow.isNew) {
    updatedRow = await crudActions.create(newRow)
  } else {
    updatedRow = await crudActions.update(newRow)
  }
  setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)))

  return updatedRow
}

export const handleRowModesModelChange = (setRowModesModel: SetRowModesModel) => {
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => setRowModesModel(newRowModesModel)
  return handleRowModesModelChange
}
