import { FC } from "react"
import { Grid, SxProps, Theme, Typography } from "@mui/material"

export interface NoResultsContainerProps {
  subHeading?: string
  minHeight?: string | number
  subHeaderSx?: SxProps<Theme>
}

export const NoResultsContainer: FC<NoResultsContainerProps> = ({
  subHeading = "Change you search terms and/or filters and try again!",
  minHeight = "50vh",
  subHeaderSx,
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: minHeight,
        backgroundColor: "primary.dark",
        borderRadius: 3,
        marginTop: 1,
      }}
    >
      <Grid container direction="column" item xs={4} alignItems="center" padding={1}>
        <Typography color="neutral.light" variant="h1">
          No Results
        </Typography>
        <div onPointerEnter={(e) => (e.currentTarget.style.cursor = "pointer")}>
          <Typography color="neutral.main" variant="h4" textAlign="center" sx={subHeaderSx}>
            {subHeading}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}
