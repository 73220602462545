import { AdminContact, Contact, ContactSource, CreateContact, UpdateContact } from "@interfaces/contact"
import { Stack, styled } from "@mui/material"
import {
  DataGrid,
  GridActionsCellItemProps,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  GridToolbarProps,
  ToolbarPropsOverrides,
  GridValidRowModel,
  DataGridProps,
  GridRenderCellParams,
} from "@mui/x-data-grid"
import { v4 } from "uuid"
import { FC, JSXElementConstructor, useEffect, useState } from "react"
import { useCreateContacts, useDeleteContact, useUpdateContact } from "@api/admin/contacts"
import { useToast } from "@components/shared/ToastHook"

function createTypedDataGrid() {
  return styled(DataGrid)<DataGridProps<GridValidRowModel>>(({ theme }) => ({
    "& .greyedOut": {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  }))
}

type ContactWithOptionalMeta<T = AdminContact | Contact> = Omit<T, "id" | "created_at" | "updated_at"> & {
  id?: number | string
  created_at?: string
  updated_at?: string
}

export type RowUnionType = ContactWithOptionalMeta & GridValidRowModel & { name: string }

const StyledDataGrid = createTypedDataGrid()

interface ContactsTableOptions {
  allowCreate?: boolean
  allowUpdate?: boolean
  allowDelete?: boolean
}

export const ContactsTable: FC<{
  contacts: AdminContact[]
  loading: boolean
  customToolbar?: JSXElementConstructor<GridToolbarProps & ToolbarPropsOverrides>
  customSlotProps?: ToolbarPropsOverrides
  options?: ContactsTableOptions
}> = ({ contacts, loading, customToolbar, customSlotProps, options }) => {
  const setToast = useToast()

  const getActionItems = (params: GridRowParams<GridValidRowModel>): React.ReactElement<GridActionsCellItemProps>[] => {
    const items: React.ReactElement<GridActionsCellItemProps>[] = []
    if (options?.allowCreate) {
      items.push(
        <GridActionsCellItem
          disabled={params.row.created_at}
          label="Create"
          onClick={() => {
            handleCreate([params.row as RowUnionType])
          }}
          showInMenu
        />,
      )
    }
    if (options?.allowUpdate) {
      items.push(
        <GridActionsCellItem
          disabled={params.row.source === "power_almanac" || !params.row.created_at}
          label="Edit"
          onClick={() => {
            handleUpdate(params.row as RowUnionType)
          }}
          showInMenu
        />,
      )
    }
    if (options?.allowDelete) {
      items.push(
        <GridActionsCellItem
          disabled={params.row.source === "power_almanac" || !params.row.created_at}
          label="Delete"
          onClick={() => {
            handleDelete(params.row as RowUnionType)
          }}
          showInMenu
        />,
      )
    }
    return items
  }

  const actionsColumn: GridColDef<GridValidRowModel> | undefined = options
    ? {
        field: "actions",
        type: "actions",
        width: 60,
        getActions: (params) => {
          return getActionItems(params)
        },
      }
    : undefined

  const columns: GridColDef<GridValidRowModel>[] = [
    { field: "id", headerName: "ID", width: 80 },
    {
      field: "name",
      headerName: "Name",
      flex: 0.8,
      editable: true,
    },
    { field: "email", headerName: "Email", flex: 1, editable: true },
    { field: "phone", headerName: "Phone", width: 150, editable: true },
    { field: "role", headerName: "Role", flex: 1, editable: true },
    { field: "title", headerName: "Title", flex: 1, editable: true },
    { field: "created_at", headerName: "Created At", width: 150 },
    { field: "updated_at", headerName: "Updated At", width: 150 },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
  ]

  if (actionsColumn) {
    columns.push(actionsColumn)
  }

  const handleRows = (data: ContactWithOptionalMeta<AdminContact>[]): RowUnionType[] => {
    // TODO - allow handling of non admin contacts
    if (!data || data.length == 0) {
      return []
    }
    return data
      .map((contact) => {
        return {
          id: contact.id ?? v4(),
          name: contact.first_name + " " + contact.last_name,
          email: contact.email_address,
          phone: contact.phone_number,
          role: contact.role,
          title: contact.title,
          created_at: contact.created_at,
          updated_at: contact.updated_at,
          source: contact.source,
          organization_id: contact.organization_id,
          discarded_at: contact.discarded_at,
        }
      })
      .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at ? 1 : -1))
  }

  const handleCreate = (rows: RowUnionType[]) => {
    const creationData: CreateContact[] = rows.map((row) => {
      return {
        first_name: row.name.split(" ")[0],
        last_name: row.name.split(" ")[1],
        organization_id: row.organization_id,
        phone_number: row.phone,
        email_address: row.email,
        role: row.role,
        title: row.title,
        source: ContactSource.LLM,
      }
    })
    createContacts(creationData, {
      onSuccess: (data) => {
        const updatedContactsMap = new Map(
          data.map((contact: AdminContact) => [`${contact.first_name} ${contact.last_name}`, contact]),
        )
        setRows((prevRows) =>
          prevRows.map((row) => {
            const key = `${row.name}`
            return updatedContactsMap.has(key) ? { ...row, ...updatedContactsMap.get(key) } : row
          }),
        )
        setToast("Contacts created successfully")
      },
      onError: (error) => {
        setToast(error.message ?? "An error occurred")
      },
    })
  }

  const customSlotPropsDefault: ToolbarPropsOverrides = {
    handleOnSuccess: (data: ContactWithOptionalMeta<AdminContact>[]) => {
      setRows(handleRows(data))
    },
    handleCreate: handleCreate,
    ...customSlotProps,
  }

  const { mutate: createContacts, isLoading: createLoading } = useCreateContacts()

  const { mutate: deleteContact, isLoading: deleteLoading } = useDeleteContact()

  const { mutate: updateContact, isLoading: updateLoading } = useUpdateContact()

  const [rows, setRows] = useState<RowUnionType[]>(handleRows(contacts))

  const [dataLoading, setDataLoading] = useState(loading || createLoading || deleteLoading || updateLoading)

  useEffect(() => {
    setDataLoading(loading || createLoading || deleteLoading || updateLoading)
  }, [loading, createLoading, deleteLoading, updateLoading])

  const handleUpdate = (row: GridValidRowModel) => {
    const updateData: UpdateContact = {
      id: row.id,
      first_name: row.name.split(" ")[0],
      last_name: row.name.split(" ")[1],
      organization_id: row.organization_id,
      phone_number: row.phone,
      email_address: row.email,
      role: row.role,
      title: row.title,
    }
    updateContact(updateData, {
      onSuccess: () => {
        setToast("Contact updated successfully")
      },
      onError: (error) => {
        setToast(error.message ?? "An error occurred")
      },
    })
  }

  const handleDelete = (row: GridValidRowModel) => {
    deleteContact(row.id, {
      onSuccess: () => {
        const newRows = rows.filter((r) => r.id !== row.id)
        setRows(newRows)
        setToast("Contact deleted successfully")
      },
      onError: (error) => {
        setToast(error.message ?? "An error occurred")
      },
    })
  }

  useEffect(() => {
    setRows(handleRows(contacts))
  }, [contacts])

  const hasUnsavedRows = rows.some((row) => row.created_at === undefined)
  return (
    <Stack direction={"column"} spacing={2}>
      <StyledDataGrid
        autoHeight
        rows={rows}
        columns={columns}
        disableColumnMenu
        checkboxSelection
        getRowClassName={hasUnsavedRows ? (params) => (params.row.created_at ? "greyedOut" : "") : undefined}
        loading={dataLoading}
        isCellEditable={(params) => params.row.source !== "power_almanac"}
        slots={{
          toolbar: customToolbar ?? undefined,
        }}
        slotProps={{
          toolbar: customSlotPropsDefault,
        }}
      />
    </Stack>
  )
}
