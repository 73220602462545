import { useMutation, useQuery, useQueryClient } from "react-query"
import { getBaseUrl, useAuthAxios } from "../axiosInstance"
import { ApiErrorResponse, useSmartUpdate } from "../api"
import { BaseUser, User } from "../../interfaces/user"
import { Role } from "../../interfaces/roles"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { useState } from "react"
import { ServerEvent } from "../interfaces"
import { AxiosError } from "axios"

const prefix = "/admin/users"

export interface FetchedUsers extends BaseUser {
  user_organization_name: string
  roles: Role[]
  industry_name?: string | null
}

export const useFetchUsers = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin", "users"], async () => {
    const response = await (await axiosInstance).get<FetchedUsers[]>(`${prefix}`)

    return response.data
  })
}

export interface FetchMyUsersResponse {
  users: FetchedUsers[]
}

export const useFetchMyUsers = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin", "users"], async () => {
    const response = await (await axiosInstance).get<FetchMyUsersResponse>(`${prefix}/my_users`)

    return response.data
  })
}

export const useSendDailyDigest = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: any) => {
    return (await axiosInstance).post(`${prefix}/${params.id}/send_daily_digest?daysBefore=${params.daysBefore}`)
  })
}

export const useFetchDailyDigestPreview = (userId: any, timeValue: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<ServerEvent>({ eventName: "", data: {} })

  const fetchRequest = (token: string) => {
    const baseUrl = getBaseUrl()
    const url = `${baseUrl}/${prefix}/${userId}/daily_digest_preview?daysBefore=${timeValue}`
    setLoading(true)
    const control = new AbortController()

    fetchEventSource(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: control.signal,
      onmessage: (event) => {
        switch (event.event) {
          case "status":
            setResponse({ eventName: event.event, data: JSON.parse(event.data)["data"] })
            break
          case "response":
            setResponse({ eventName: event.event, data: JSON.parse(event.data)["data"] })
            break
        }
      },
      onclose: () => {
        setLoading(false)
      },
    })
  }

  return { fetchRequest, response, loading }
}

export const useDeleteUser = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`${prefix}/${params.ids.join(",")}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"])
      },
    },
  )
}

export interface UpdateUserParams {
  ids: number[]
  email?: string
  role_id?: number
  user_organization_id?: number
  requires_signals_notifications?: boolean
  requires_account_summary_notifications?: boolean
  credit_limit?: number
}

export const useUpdateUser = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: UpdateUserParams) => {
      return (await axiosInstance).put<User>(`${prefix}/${params.ids.join(",")}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useNewUser = (onSuccess?: () => void, onError?: (errorMessage: string) => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post<User>(prefix, params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data.errorMessage || "An unknown error occured")
        }
      },
    },
  )
}

export const useUpdateUsersRoles = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { ids: number[]; roles: Record<string, boolean> }) => {
      return (await axiosInstance).post(`${prefix}/roles`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const getAbout = (userId: number | string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["admin", "users", userId, "about"],
    async () => {
      return (await (await axiosInstance).get<Record<string, unknown> | null>(`${prefix}/${userId}/about`)).data
    },
    {
      enabled: Boolean(userId),
    },
  )
}

export const useSetAbout = (userId: number | string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { formData: unknown }) => {
      return (await (await axiosInstance).post(`${prefix}/${userId}/about`, params)).data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["admin", "users", userId, "about"], data)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useSendInsightFromUser = (userId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { meeting_id: string }) => {
      return (await axiosInstance).post(`${prefix}/${userId}/send_briefing`, params)
    },
    {
      onSuccess,
    },
  )
}

export const useSendInsightFromMeeting = (meetingId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { userId: number; prompts: number[] }) => {
      return (await axiosInstance).post(`${prefix}/${params.userId}/send_briefing`, {
        meeting_id: meetingId,
        prompt_ids: params.prompts.join(","),
      })
    },
    {
      onSuccess,
    },
  )
}

export interface SignalsUatAllowedUsers {
  id: number
  email: string
  user_id: number
}

export const useFetchSignalsUatAllowedUsers = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["signalsUatAllowedUsers"], async () => {
    const response = await (await axiosInstance).get<SignalsUatAllowedUsers[]>(`${prefix}/signals_uat_allowed_users`)

    return response.data
  })
}

export const useDeleteSignalsUatAllowedUser = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number }) => {
      return (await axiosInstance).delete(`${prefix}/signals_uat_allowed_users/${params.id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signalsUatAllowedUsers"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useAddSignalsUatAllowedUsers = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { user_ids: number[] }) => {
      return (await axiosInstance).post(`${prefix}/signals_uat_allowed_users`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signalsUatAllowedUsers"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
