import { useMutation, UseMutationOptions, useQueryClient } from "react-query"
import { useAuthAxios } from "@src/api/axiosInstance"
import { AxiosError } from "axios"
import { ApiErrorResponse } from "../api"
import { EmailCadence } from "@src/interfaces/campaign"
import { OpportunityCampaignUpdateParams } from "@src/api/opportunityCampaigns"

const prefix = "admin/opportunity_campaigns"

interface OpportunityCampaignCreateParams extends OpportunityCampaignUpdateParams {
  email_cadence: string
  user_id: number
}

export const useAdminCreateOpportunityCampaign = (
  options?: Omit<
    UseMutationOptions<unknown, AxiosError<ApiErrorResponse>, OpportunityCampaignCreateParams>,
    "mutationFn"
  >,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params) => {
      return (await axiosInstance).post(`${prefix}`, params)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        queryClient.invalidateQueries(["opportunityCampaignsUsers"])
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      },
    },
  )
}

export const useAdminUpdateOpportunityCampaigns = (
  id: unknown,
  options?: Omit<
    UseMutationOptions<unknown, AxiosError<ApiErrorResponse>, OpportunityCampaignUpdateParams>,
    "mutationFn"
  >,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      },
    },
  )
}

export const useUpdateOpportunityCampaignsRelations = (
  id: unknown,
  options?: Omit<
    UseMutationOptions<
      unknown,
      AxiosError<ApiErrorResponse>,
      { opportunity_campaign_users_data: { user_id: number; email_cadence: EmailCadence }[] }
    >,
    "mutationFn"
  >,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params) => {
      return (await axiosInstance).post(`${prefix}/${id}/users`, params)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["opportunityCampaignsUsers"])
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      },
    },
  )
}

export const useAdminDeleteOpportunityCampaign = (id: number) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).delete(`${prefix}/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
      },
    },
  )
}
