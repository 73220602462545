import { FormControlLabel, FormGroup, Stack, Switch, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC, useContext, useEffect, useState } from "react"
import { FindOpportunitiesFilter } from "./FindOpportunitiesFilter"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"

export const TermsSearchFilters: FC = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)
  const [proximityChecked, setProximityChecked] = useState(false)

  useEffect(() => {
    if (urlParamState.anchor_terms) {
      setProximityChecked(urlParamState.anchor_terms.length > 0)
    }
  }, [urlParamState.anchor_terms])

  const proximitySearchSwitch = (
    <Switch
      checked={proximityChecked}
      onChange={(event) => {
        setProximityChecked(!proximityChecked)

        if (!event.target.checked) {
          setUrlParamState({
            ...urlParamState,
            anchor_terms: [],
            page: 0,
          })
        }
      }}
      size="small"
    />
  )

  const proximityDisplay = proximityChecked ? "flex" : "none"

  if (isMobileScreen) {
    return (
      <Stack gap={1}>
        <Typography>At least one of these terms:</Typography>
        <FindOpportunitiesFilter placeholder="What opportunities would you like to find?" urlParamKey="terms" />
        <Typography>Anchor Terms:</Typography>
        <FindOpportunitiesFilter placeholder="Enter a search term/phrase" urlParamKey="anchor_terms" />
      </Stack>
    )
  }

  return (
    <>
      <Stack>
        <Typography sx={{ display: proximityDisplay }}>At least one of these terms:</Typography>
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <FindOpportunitiesFilter placeholder="What opportunities would you like to find?" urlParamKey="terms" />
          <FormGroup sx={{ minWidth: 170 }}>
            <FormControlLabel control={proximitySearchSwitch} label="Proximity Search" />
          </FormGroup>
        </Stack>
      </Stack>
      {proximityChecked && (
        <Stack>
          <Typography>Anchor Terms:</Typography>
          <FindOpportunitiesFilter placeholder="Enter a term/phrase" urlParamKey="anchor_terms" />
        </Stack>
      )}
    </>
  )
}
