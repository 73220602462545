import { AiChainRunnable } from "@interfaces/AiProducts/models"

export const compareAiChainRunnable = (
  aiChainRunnable1?: AiChainRunnable,
  aiChainRunnable2?: AiChainRunnable,
): boolean => {
  if (!aiChainRunnable1 || !aiChainRunnable2) return false
  return (
    aiChainRunnable1?.aiPrompt?.name === aiChainRunnable2.aiPrompt?.name &&
    aiChainRunnable1?.aiPrompt?.text === aiChainRunnable2.aiPrompt?.text
  )
}
