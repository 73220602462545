import { Skeleton } from "@mui/material"
import { GeographySelectorMultiple } from "@components/shared/Selectors/Multiple/Geographies"
import { Dispatch, FC, SetStateAction, useContext } from "react"
import { FilterParams } from "@components/Home/interface"
import { useFetchGeographies } from "@api/geographies"
import { InsightsContainerContext } from "@src/containers/InsightContainers/InsightsContainer"

export const InsightGeographiesAutocompletes: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(InsightsContainerContext)
  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, page: 0 })) as Dispatch<SetStateAction<FilterParams>>

  return (
    <>
      {!geographiesIsFetched || !geographiesData ? (
        <>
          <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
          <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
          <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
        </>
      ) : (
        <>
          <GeographySelectorMultiple
            options={geographiesData}
            label="State"
            type="states"
            filterParams={urlParamState}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData}
            label="County"
            type="counties"
            filterParams={urlParamState}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData}
            label="City"
            type="cities"
            filterParams={urlParamState}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "30ch 1 0" }}
          />
        </>
      )}
    </>
  )
}
