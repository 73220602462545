import * as Sentry from "@sentry/react"

// we need to disable Sentry for now
export const initializeSentry = (disableSentry: boolean = true) => {
  if (disableSentry) {
    return
  }

  const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT || "development"
  const replaysSessionSampleRate = sentryEnvironment == "production" ? 0.2 : 1.0
  const tracesSampleRate = sentryEnvironment == "production" ? 0.3 : 0.5

  if (sentryEnvironment == "development") {
    return
  }

  Sentry.init({
    dsn: "https://9e62194a3cf59de43ff73e24903e9560@o4508066859450368.ingest.us.sentry.io/4508088811192320",

    environment: sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
      /*
      For the JavaScript browser profiler to start, the document response header
      needs to include a Document-Policy header key with the js-profiling value.
      
      How you do this will depend on how your assets are served.
      
      If you're using a server like Express, you'll be able to use the response.set function to set the header value.
  
      response.set('Document-Policy', 'js-profiling') 
      */
      // Sentry.browserProfilingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
