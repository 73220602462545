import { useMutation, UseMutationOptions, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { OrganizationInsightsUser } from "@interfaces/organizationInsightsUser"
import { BaseMeeting } from "@interfaces/meeting"
import { BaseTranscriptionJob } from "./transcription_jobs"
import { BaseOrganization } from "@src/interfaces/organization"
import { PatchManyRequest } from "../interfaces"
import { Sort } from "@src/interfaces/default"
import { AxiosResponse } from "axios"

const prefix = "/admin/organizations"

export type OrganizationExtendedViews = "default" | "detailed" | "simple"

export const useFetchOrganizations = (options?: {
  page?: number
  pageSize?: number
  filterParams?: {
    counties: number[]
    states: number[]
  }
  userOrganizationId?: number | string
  archiveType?: string
  archiveStatusFilter?: string
  nameFilter?: string
  spamEnabledFilter?: string
  autoUpdate?: string
  channelTypeFilter?: string
  videoArchiveIdFilter?: string
  proxyShuffleFilter?: string
  oppAnalysisEnabledFilter?: string
  speakerIdentificationEnabledFilter?: string
  hasContactsFilter?: string
  sortBy?: Sort
  scope?: string
  extend?: OrganizationExtendedViews
}) => {
  const axiosInstance = useAuthAxios()
  const {
    page,
    pageSize,
    filterParams,
    userOrganizationId,
    archiveType,
    nameFilter,
    spamEnabledFilter,
    autoUpdate,
    channelTypeFilter,
    archiveStatusFilter,
    videoArchiveIdFilter,
    proxyShuffleFilter,
    oppAnalysisEnabledFilter,
    speakerIdentificationEnabledFilter,
    hasContactsFilter,
    sortBy,
  } = options || {}

  const { scope = "", extend = "default" } = options || { scope: "", extend: "default" }

  const filterParamsExtended = { ...filterParams, sort_by: sortBy }
  if (scope === "search") {
    return useQuery(
      [
        "organizations",
        {
          page,
          pageSize,
          filterParamsExtended,
          archiveType,
          nameFilter,
          spamEnabledFilter,
          autoUpdate,
          channelTypeFilter,
          archiveStatusFilter,
          videoArchiveIdFilter,
          proxyShuffleFilter,
          oppAnalysisEnabledFilter,
          speakerIdentificationEnabledFilter,
          hasContactsFilter,
        },
      ],
      async () => {
        const encodedFilterParams = btoa(JSON.stringify(filterParamsExtended))
        const response = await (
          await axiosInstance
        ).get(
          `${prefix}?page=${page}&page_size=${pageSize}&filter_params=${encodedFilterParams}&` +
            `video_archive_type=${archiveType}&name_filter=${nameFilter}&spam_enabled=${spamEnabledFilter}&` +
            `auto_update=${autoUpdate}&channel_type=${channelTypeFilter}&archive_status=${archiveStatusFilter}` +
            `&domain=${videoArchiveIdFilter}&shuffle_region=${proxyShuffleFilter}` +
            `&opp_analysis_enabled=${oppAnalysisEnabledFilter}` +
            `&speaker_identification_enabled=${speakerIdentificationEnabledFilter}&hasContacts=${hasContactsFilter}&scope=search&extend=detailed`,
        )
        return response.data
      },
    )
  } else if (scope === "user-organizations" && userOrganizationId) {
    return useQuery(["organizations", { user_organization_id: userOrganizationId.toString() }], async () => {
      const response = await (
        await axiosInstance
      ).get(`${prefix}?user_organization_id=${userOrganizationId}&scope=${scope}&extend=${extend}`)
      return response.data
    })
  } else {
    return useQuery(["admin_organizations"], async () => {
      const response = await (await axiosInstance).get(`${prefix}?extend=${extend}`)
      return response.data
    })
  }
}

export const useFetchOrganization = (id: string | number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organizations", { id: id.toString() }], async () => {
    const response = await (await axiosInstance).get<BaseOrganization>(`${prefix}/${id}`)

    return response.data
  })
}

export const useUpdateOrganization = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: BaseOrganization) => {
      return (await axiosInstance).put(`${prefix}/${params.id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin_organizations")
        queryClient.invalidateQueries("state_permissions")
        queryClient.invalidateQueries("permissions")
        if (onSuccess) {
          onSuccess()
        }
      },
      // When upgrading to @tanstack/react-queryu error should not be typed unknown anymore
      onError: (error: { response: any }) => {
        return error.response
      },
    },
  )
}

export const usePatchOrganization = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: Partial<BaseOrganization>) => {
      return (await axiosInstance).patch(`${prefix}/${params.id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin_organizations")
        queryClient.invalidateQueries("state_permissions")
        queryClient.invalidateQueries("permissions")
        if (onSuccess) {
          onSuccess()
        }
      },
      // When upgrading to @tanstack/react-queryu error should not be typed unknown anymore
      onError: (error: { response: any }) => {
        return error.response
      },
    },
  )
}

export const usePatchManyOrganizations = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: PatchManyRequest<BaseOrganization>) => {
      return (await axiosInstance).patch(`${prefix}/patch_many`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin_organizations")
        queryClient.invalidateQueries("state_permissions")
        queryClient.invalidateQueries("permissions")
        if (onSuccess) {
          onSuccess()
        }
      },
      // When upgrading to @tanstack/react-queryu error should not be typed unknown anymore
      onError: (error: { response: any }) => {
        return error.response
      },
    },
  )
}

export const getInsightsUsers = (organizationId: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organization", organizationId, "insightsUsers"], async () => {
    const response = await (
      await axiosInstance
    ).get<OrganizationInsightsUser[]>(`${prefix}/${organizationId}/briefings_users`)

    return response.data
  })
}

export const useCreateInsightsUser = (organizationId: string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { user_id: number; prompts: number[] }) => {
      return (await axiosInstance).post(`${prefix}/${organizationId}/briefings_users`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organization", organizationId, "insightsUsers"])
      },
    },
  )
}

export const useUpdateInsightsUser = (organizationId: string, id: string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { user_id: number; prompts: number[] }) => {
      return (await axiosInstance).put(`${prefix}/${organizationId}/briefings_users/${id}`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organization", organizationId, "insightsUsers"])
      },
    },
  )
}

export const useDestroyInsightsUser = (organizationId: string, id: string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).delete(`${prefix}/${organizationId}/briefings_users/${id}`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organization", organizationId, "insightsUsers"])
      },
    },
  )
}

export const getUserInsightSettings = (organizationId: unknown, userId: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["organization", organizationId, "insightsUsers", userId],
    async () => {
      const response = await (
        await axiosInstance
      ).get<OrganizationInsightsUser>(`${prefix}/${organizationId}/briefings_users?user_id=${userId}`)

      return response.data
    },
    {
      enabled: Boolean(organizationId && userId),
    },
  )
}

export const useGenerateMeetingsForOrg = (id: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: any) => {
      const queryParams = params.startYear ? `start_year=${params.startYear}&end_year=${params.endYear}` : ""
      return (await axiosInstance).post(`${prefix}/${id}/meetings/generate?${queryParams}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organizations", { id }])
        queryClient.invalidateQueries(["meetings", { organization_id: id }])
      },
    },
  )
}

export const useCreateMeetingsInBackground = () => {
  const axiosInstance = useAuthAxios()
  return useMutation(async (ids: number[]) => {
    return (await axiosInstance).post<null>(`${prefix}/generate_in_background`, {
      id: ids,
    })
  })
}

export interface FetchedOrgMeetings extends BaseMeeting {
  meetings: FetchedOrgMeeting[]
  total: number
}

export interface FetchedTranscriptionJob extends BaseTranscriptionJob {
  progress?: Progress
}

export interface FetchedOrgMeeting extends BaseMeeting {
  transcription_job: FetchedTranscriptionJob
  opportunities_count: number
}

export interface Progress {
  progress: string
  service: string
  stage: string
}

interface FetchOrganizationMeetingsParams {
  id: number
  meetingIds?: number[]
}

export const useFetchOrganizationMeetings = ({ id, meetingIds }: FetchOrganizationMeetingsParams) => {
  const axiosInstance = useAuthAxios()
  let queryParams: string = ""
  if (meetingIds) {
    queryParams = meetingIds.map((id) => `meetingIds[]=${id}`).join("&")
  }
  return useQuery(
    ["meetings", { organization_id: id }],
    async () => {
      const response = await (
        await axiosInstance
      ).get<FetchedOrgMeetings>(`/admin/organizations/${id}/meetings?${queryParams}`)
      return response.data
    },
    {
      refetchInterval: 10000,
    },
  )
}

export const useIdentifySpeakers = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  return useMutation(
    async (id: number[]) => {
      return (await axiosInstance).post(`/admin/organizations/identify_speakers`, { id })
    },
    {
      onSuccess,
    },
  )
}

export const useEnableOppAnalysis = (organizationId: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post(`${prefix}/${organizationId}/enable_opp_analysis`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organizations", { id: `${organizationId}` }])
      },
    },
  )
}

export const useDisableOppAnalysis = (organizationId: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post(`${prefix}/${organizationId}/disable_opp_analysis`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organizations", { id: `${organizationId}` }])
      },
    },
  )
}

export const useEnableSpeakerIdentification = (
  organizationId: unknown,
  options?: Omit<UseMutationOptions<AxiosResponse<unknown, unknown>, unknown, void, unknown>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).put(`${prefix}/${organizationId}`, { speaker_identification_enabled: true })
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        queryClient.invalidateQueries(["organizations", { id: `${organizationId}` }])
      },
    },
  )
}

export const useDisableSpeakerIdentification = (
  organizationId: unknown,
  options?: Omit<UseMutationOptions<AxiosResponse<unknown, unknown>, unknown, void, unknown>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).put(`${prefix}/${organizationId}`, { speaker_identification_enabled: false })
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        queryClient.invalidateQueries(["organizations", { id: `${organizationId}` }])
      },
    },
  )
}

export const useEnableManyOppAnalysis = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (organizationIds: number[]) => {
      return (await axiosInstance).post(`${prefix}/enable_opp_analysis`, { id: organizationIds })
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organizations"])
      },
    },
  )
}

export const useDisableManyOppAnalysis = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (organizationIds: number[]) => {
      return (await axiosInstance).post(`${prefix}/disable_opp_analysis`, { id: organizationIds })
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["organizations"])
      },
    },
  )
}

export const useEnableManySpeakerIdentification = (
  options?: Omit<UseMutationOptions<AxiosResponse<unknown, unknown>, unknown, number[], unknown>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (organizationIds) => {
      return (await axiosInstance).patch(`${prefix}/patch_many`, {
        ids: organizationIds,
        attributes: { speaker_identification_enabled: true },
      })
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        queryClient.invalidateQueries(["organizations"])
      },
    },
  )
}

export const useDisableManySpeakerIdentification = (
  options?: Omit<UseMutationOptions<AxiosResponse<unknown, unknown>, unknown, number[], unknown>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (organizationIds) => {
      return (await axiosInstance).patch(`${prefix}/patch_many`, {
        ids: organizationIds,
        attributes: { speaker_identification_enabled: false },
      })
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        queryClient.invalidateQueries(["organizations"])
      },
    },
  )
}
