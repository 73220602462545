import { FC, useContext } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import { InsightsUsersContext } from "./context"
import { useToast } from "../../../shared/ToastHook"
import { useCreateInsightsUser, useUpdateInsightsUser } from "../../../../api/admin/organizations"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "../../../../api/interfaces"
import { UserSelector } from "../../../shared/Selectors/User"
import { PromptsAutocomplete } from "../../../shared/PromptsAutocomplete"

const UpdateButton: FC<{
  id: number
  organizationId: string
  userId: number | null
  prompts: number[]
  onSuccess: () => void
}> = ({ id, organizationId, userId, prompts, onSuccess }) => {
  const { mutate: updatePrompt } = useUpdateInsightsUser(organizationId, id.toString(), onSuccess)

  return (
    <Button disabled={userId === null} onClick={() => userId !== null && updatePrompt({ user_id: userId, prompts })}>
      Update
    </Button>
  )
}

export const EditInsightsUsersModal: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const {
    openModal,
    close,
    id,
    userId,
    promptsInput,
    setUserId,
    setPromptsInput: setPromptInput,
  } = useContext(InsightsUsersContext)

  const toast = useToast()
  const onSuccess = () => {
    toast(`Insights User ${id === null ? "Created" : "Updated"}`)
    close()
  }

  const { mutate: createPrompt } = useCreateInsightsUser(organizationId, onSuccess)

  return (
    <Dialog open={openModal === "edit"} onClose={close} fullWidth>
      <DialogTitle>{`${id === null ? "Create" : "Edit"} Insights User`}</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack spacing={1}>
          <UserSelector userId={userId} setUserId={setUserId} />
          <Stack direction="row" spacing={1}>
            <PromptsAutocomplete
              selectedPrompts={{ prompts: promptsInput, setPrompts: setPromptInput }}
              enableMultiple={true}
            />
          </Stack>
        </Stack>
        <DialogActions>
          {id === null ? (
            <Button
              variant="contained"
              disabled={userId === null}
              onClick={() =>
                userId !== null && createPrompt({ user_id: userId, prompts: promptsInput.map((prompt) => prompt.id) })
              }
            >
              Create
            </Button>
          ) : (
            <UpdateButton
              id={id}
              organizationId={organizationId}
              userId={userId}
              prompts={promptsInput.map((prompt) => prompt.id)}
              onSuccess={onSuccess}
            />
          )}
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
