import { FC, useEffect, Dispatch, SetStateAction } from "react"
import { IconButton, Stack } from "@mui/material"
import { getPrompts } from "../../../api/prompts"
import { PromptSelect } from "./PromptSelect"
import AddCircleIcon from "@mui/icons-material/AddCircle"

export const PersonalInsightPromptSelector: FC<{
  selectedPrompts: Set<number>
  setSelectedPrompts: Dispatch<SetStateAction<Set<number>>>
  blockedPrompts?: string[]
}> = ({ selectedPrompts, setSelectedPrompts, blockedPrompts }) => {
  const { data: prompts, isFetched } = getPrompts("personal")
  const promptsMap = new Map(prompts?.map((prompt) => [prompt.id, prompt]))

  const availablePrompts =
    prompts?.filter(({ id, name }) => {
      if (selectedPrompts.has(id)) {
        return false
      } else if (blockedPrompts && blockedPrompts.includes(name)) {
        return false
      } else {
        return true
      }
    }) || []

  useEffect(() => {
    if (prompts && isFetched && selectedPrompts.size === 0) {
      if (availablePrompts.length === 0) {
        setSelectedPrompts(new Set())
      } else {
        setSelectedPrompts(new Set([availablePrompts[0].id]))
      }
    }
  }, [isFetched, availablePrompts.length])

  useEffect(() => {
    if (blockedPrompts?.length && prompts) {
      setSelectedPrompts(
        (prevValue) =>
          new Set(
            Array.from(prevValue).filter((selectedPrompt) => {
              const prompt = promptsMap.get(selectedPrompt)
              return Boolean(prompt && !blockedPrompts.includes(prompt.name))
            }),
          ),
      )
    }
  }, [blockedPrompts?.length])

  return (
    <Stack spacing={1} alignItems="flex-start">
      {Array.from(selectedPrompts).map((id, index) => {
        return (
          <PromptSelect
            key={id}
            id={id}
            availablePrompts={availablePrompts}
            handleChange={(event) => {
              const array = Array.from(selectedPrompts)
              if (typeof event.target.value === "number") {
                array[index] = event.target.value
              }
              setSelectedPrompts(new Set(array))
            }}
            handleDelete={() => {
              const newSet = new Set(selectedPrompts)
              newSet.delete(id)
              setSelectedPrompts(newSet)
            }}
            handleUp={
              index === 0
                ? undefined
                : () => {
                    const array = Array.from(selectedPrompts)
                    const temp = array[index]
                    array[index] = array[index - 1]
                    array[index - 1] = temp
                    setSelectedPrompts(new Set(array))
                  }
            }
            handleDown={
              index === selectedPrompts.size - 1
                ? undefined
                : () => {
                    const array = Array.from(selectedPrompts)
                    const temp = array[index]
                    array[index] = array[index + 1]
                    array[index + 1] = temp
                    setSelectedPrompts(new Set(array))
                  }
            }
          />
        )
      })}
      {Boolean(availablePrompts.length) && (
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedPrompts(new Set([...Array.from(selectedPrompts), availablePrompts[0].id]))
          }}
        >
          <AddCircleIcon />
        </IconButton>
      )}
    </Stack>
  )
}
