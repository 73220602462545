import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from "@mui/x-data-grid"
import { JSXElementConstructor } from "react"
import { CONTACT_DISCOVERY_MODAL_KEY, ContactDiscoveryModalData } from "../contactDiscoveryModal"
import { useOpenModalKey, useSetData } from "@components/shared/OpenModalHook"
import { Box, IconButton, Typography } from "@mui/material"
import { PlayArrow } from "@mui/icons-material"
import { BulkActionsMenu } from "./bulkActionsMenu"

export const AdminContactsToolbar: JSXElementConstructor<GridToolbarProps & ToolbarPropsOverrides> = (props) => {
  const { organizationId, handleOnSuccess, handleCreate } = props

  const setDataOnModal = useSetData<ContactDiscoveryModalData>()

  const handleOpenModal = () => {
    setDataOnModal({
      organizationIds: [organizationId],
      dryRun: true,
      handleOnSuccess,
    })
    openModal()
  }

  const openModal = useOpenModalKey(CONTACT_DISCOVERY_MODAL_KEY)

  return (
    <GridToolbarContainer sx={{ mx: 2 }}>
      <BulkActionsMenu handleCreate={handleCreate} />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector slotProps={{ tooltip: { title: "Change density" } }} />
      <Box sx={{ flexGrow: 1 }} />
      <IconButton color="primary" size="small" onClick={handleOpenModal}>
        <PlayArrow />
        <Typography variant="button">Run AI Contact Discovery</Typography>
      </IconButton>
    </GridToolbarContainer>
  )
}
