export const ModelOptions = [
  "gemini-1.5-pro-002",
  "gemini-1.5-flash-002",
  "gemini-2.0-flash-exp",
  "gemini-2.0-flash",
  "gemini-2.0-pro-exp-02-05",
] as const

export type LLMModelOptions = (typeof ModelOptions)[number]

export interface ModelConfigurations {
  model: LLMModelOptions
  useFineTunedModel?: boolean
  maxTokenSizePerChunk?: number
  temperature?: number
}
