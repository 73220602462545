import { useRef, forwardRef, useImperativeHandle, useEffect } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import React from "react"
import { FetchedMeeting } from "@api/meetings"
import { locationText, publishedAtText } from "./MeetingBasicInfo"

interface PrintableIframeProps {
  children: React.ReactNode
  onload?: () => void
  meeting?: FetchedMeeting
}

// I need to run this function in both useEffect and onLoad to work in both chrome and firefox
function loadIframeContent(iframe: HTMLIFrameElement, children: React.ReactNode, meeting?: FetchedMeeting) {
  if (children && iframe) {
    const fontStyle = document.createElement("link")
    fontStyle.rel = "stylesheet"
    fontStyle.href = "https://fonts.googleapis.com/css?family=Barlow:400,500,600,700,800&display=swap"
    iframe.contentWindow?.document.head.appendChild(fontStyle)

    const styleSheet = document.createElement("style")
    styleSheet.innerText = "body { font-family: 'Barlow', sans-serif; }"
    iframe.contentWindow?.document.head.appendChild(styleSheet)

    if (iframe.contentWindow?.document.body) {
      const content = (
        <>
          <img
            src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.ai.text.and.logo.png"
            style={{ display: "block", width: "5in", margin: "auto" }}
          />
          {meeting && (
            <>
              <h1>{meeting.title}</h1>
              <p>
                {`${publishedAtText(meeting.published_at)}${meeting.organization_name} in ${locationText(meeting as unknown as { city: string; county: string; state: string })}`}
              </p>
            </>
          )}
          {children}
        </>
      )
      iframe.contentWindow.document.body.innerHTML = renderToStaticMarkup(content)
    }
  }
}

const PrintableIframe = forwardRef<HTMLIFrameElement, PrintableIframeProps>(({ children, meeting, onload }, ref) => {
  const internalRef = useRef<HTMLIFrameElement>(null)

  useImperativeHandle(ref, () => internalRef.current!, [internalRef])
  useEffect(() => {
    loadIframeContent(internalRef.current!, children, meeting)
    onload?.()
  }, [])

  return (
    <iframe
      style={{ display: "none" }}
      ref={internalRef}
      onLoad={() => {
        loadIframeContent(internalRef.current!, children, meeting)
        onload?.()
      }}
    />
  )
})

PrintableIframe.displayName = "PrintableIframe"

export default PrintableIframe
