import { FC, useRef, useState } from "react"
import { marked } from "marked"
import DOMPurify from "dompurify"
import PrintableIframe from "@components/shared/PrintableIframe"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { useFetchMeeting } from "@api/meetings"
import { Link } from "@mui/material"

export const InsightContent: FC<{ promptResult: string }> = ({ promptResult }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeeting(meetingId)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_iframeReady, setIframeReady] = useState(false)
  // I just need the state above to re-render this component
  const printRef = useRef<HTMLIFrameElement>(null)
  if (promptResult) {
    const insightElement = (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(promptResult) as string),
        }}
      />
    )
    return (
      <>
        <Link marginBottom={1} sx={{ cursor: "pointer" }} onClick={printRef.current?.contentWindow?.print}>
          Insight PDF
        </Link>
        <PrintableIframe ref={printRef} onload={() => setIframeReady(true)} meeting={data?.meeting}>
          {insightElement}
        </PrintableIframe>
        {insightElement}
      </>
    )
  }
  return null
}
