import { FC } from "react"
import { Layout } from "../components/shared/Layout"
import { Redirect } from "react-router-dom"
import { useFetchPerson } from "@api/persons"
import { IconButton, Link, Stack, Typography } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { MeetingItemBase } from "@components/shared/MeetingItemBase"
import { Meeting } from "@interfaces/meeting"
import { useIsSpeakerIdentificationEnabled } from "@api/users"
import { decidePersonFields } from "@functions/person"
import Loading from "@components/shared/Loading"
import { MailOutline, PhoneOutlined } from "@mui/icons-material"
import { useToast } from "@components/shared/ToastHook"
import { obtainSourcePage } from "@functions/obtainSourcePage"

export const PersonPage: FC<{ match: { params: { personId: string } } }> = ({
  match: {
    params: { personId },
  },
}) => {
  const { data: person, isLoading } = useFetchPerson(parseInt(personId))
  const { data: isSpeakerIdentificationEnabled, isFetched } = useIsSpeakerIdentificationEnabled()

  const { name, title, organization } = decidePersonFields(person, false)

  const setToast = useToast()

  const copyAndToast = (text: string, toastMessage: string) => {
    navigator.clipboard.writeText(text)
    setToast(toastMessage)
  }

  const sourcePage = obtainSourcePage()

  return isLoading ? (
    <Loading />
  ) : (
    <Layout title={isLoading ? "" : name ?? "Unknown"}>
      <Stack spacing={0} marginBottom={1}>
        <Typography variant="h6">{title ? title : ""}</Typography>
        <Typography variant="h6">{`${organization ?? ""}`}</Typography>
        <Stack direction="column" spacing={0} mt={0.5}>
          {person?.contact?.phone && (
            <Stack direction="row" spacing={1} alignItems="center">
              <PhoneOutlined fontSize="small" color="action" />
              <Typography variant="body2" fontWeight={700}>
                {person.contact.phone}
              </Typography>
              <IconButton
                color="primary"
                size="small"
                onClick={() => copyAndToast(person.contact?.phone ?? "", "Phone number copied")}
              >
                <ContentCopyIcon sx={{ height: 18 }} />
              </IconButton>
            </Stack>
          )}
          {person?.contact?.email && (
            <Stack direction="row" spacing={1} alignItems="center">
              <MailOutline fontSize="small" color="action" />
              <Link href={`mailto:${person.contact.email}`} target="_blank" rel="noopener" underline="hover">
                <Typography variant="body2" fontWeight={700}>
                  {person.contact.email}
                </Typography>
              </Link>
              <IconButton
                color="primary"
                size="small"
                onClick={() => copyAndToast(person.contact?.email ?? "", "Email copied")}
              >
                <ContentCopyIcon sx={{ height: 18 }} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
      {person?.meetings && person?.meetings.length > 0 ? (
        <>
          <Typography>This person appears in these meetings:</Typography>
          {person.meetings.map((meeting: Meeting) => (
            <MeetingItemBase
              key={meeting.id}
              meeting={meeting}
              link={`/meetings/${meeting.id}`}
              sourcePage={sourcePage}
            />
          ))}
        </>
      ) : (
        <Typography>No associated meetings with this person</Typography>
      )}
      {isFetched && !isSpeakerIdentificationEnabled && <Redirect to="/" />}
    </Layout>
  )
}
