import WarningIcon from "@mui/icons-material/Warning"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"

export const insightAlreadyCreatedModalKey = "INSIGHT_ALREADY_CREATED_MODAL"

export interface InsightAlreadyCreatedModalProps {
  insightNames: string[]
  onConfirm: () => void
}

export const InsightAlreadyCreatedModal: FC<InsightAlreadyCreatedModalProps> = ({ insightNames, onConfirm }) => {
  const open = useModalKey(insightAlreadyCreatedModalKey)
  const closeModalKey = useCloseModalKey(insightAlreadyCreatedModalKey)

  const onConfirmHandler = () => {
    onConfirm()
    closeModalKey()
  }

  const insightString =
    insightNames.length > 1
      ? `You have already created insights: ${insightNames.join(", ")} for this meeting`
      : `You have already created a ${insightNames[0]} insight for this meeting`

  return (
    <Dialog open={open} onClose={closeModalKey} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row">
          <Box display="flex" alignItems="center" marginRight={1}>
            <WarningIcon />
          </Box>
          Warning
        </Stack>
      </DialogTitle>
      <DialogContent>{insightString}. Are you sure you want to continue?</DialogContent>
      <DialogActions>
        <Button onClick={closeModalKey}>No</Button>
        <Button variant="contained" onClick={onConfirmHandler}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
