import { AiChainRunnableSegmentTypes, PromptInputTypes } from "@interfaces/AiProducts/models"
import { MeetingIdInput } from "./MeetingIdInput"
import { MenuItem } from "@mui/material"
import { CampaignIdInput } from "./CampaignIdInput"

// to be corrected in the future
export const getInputComponentByType = (inputType: PromptInputTypes) => {
  const mapping: { [key in PromptInputTypes]: JSX.Element | null } = {
    [PromptInputTypes.MEETING_IDS]: <MeetingIdInput />,
    [PromptInputTypes.CAMPAIGN_ID]: <CampaignIdInput />,
    [PromptInputTypes.CHAIN_OUTPUT]: null,
  }
  return mapping[inputType]
}

export const getMenuItemsBySegmentType = (segmentType: AiChainRunnableSegmentTypes) => {
  const mapping: { [key in AiChainRunnableSegmentTypes]: JSX.Element | JSX.Element[] } = {
    [AiChainRunnableSegmentTypes.INITIAL]: [
      <MenuItem value={PromptInputTypes.MEETING_IDS}>{PromptInputTypes.MEETING_IDS}</MenuItem>,
      <MenuItem value={PromptInputTypes.CAMPAIGN_ID}>{PromptInputTypes.CAMPAIGN_ID}</MenuItem>,
    ],
    [AiChainRunnableSegmentTypes.MIDDLE]: (
      <MenuItem value={PromptInputTypes.CHAIN_OUTPUT}>{PromptInputTypes.CHAIN_OUTPUT}</MenuItem>
    ),
    [AiChainRunnableSegmentTypes.TAIL]: (
      <MenuItem value={PromptInputTypes.CHAIN_OUTPUT}>{PromptInputTypes.CHAIN_OUTPUT}</MenuItem>
    ),
    [AiChainRunnableSegmentTypes.TOTAL]: [
      <MenuItem value={PromptInputTypes.MEETING_IDS}>{PromptInputTypes.MEETING_IDS}</MenuItem>,
      <MenuItem value={PromptInputTypes.CAMPAIGN_ID}>{PromptInputTypes.CAMPAIGN_ID}</MenuItem>,
    ],
  }
  return mapping[segmentType]
}
