import { FormControlLabel, Switch } from "@mui/material"
import React, { FC, SetStateAction, createContext, useContext } from "react"
import { WarningDialog } from "./WarningDialog"
import { useIsSuperAdmin } from "../../../api/users"
import { useOpenModalKey } from "../OpenModalHook"
import { CampaignContext } from "../../Campaigns/CampaignListItem"

export const EnableGeneralInsightContext = createContext<{
  insight: boolean
  setInsight: React.Dispatch<SetStateAction<boolean>>
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<SetStateAction<boolean>>
}>({
  insight: false,
  setInsight: () => {
    /* placeholder for typescript */
  },
  dialogOpen: false,
  setDialogOpen: () => {
    /* placeholder for typescript */
  },
})

export const EnableGeneralInsight: FC<{
  generalBriefing: {
    insight: boolean
    setInsight: React.Dispatch<SetStateAction<boolean>>
  }
}> = ({ generalBriefing }) => {
  const campaignContext = useContext(CampaignContext)
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const generalInsightWarningModalKey = `GENERAL_INSIGHT_WARNING_MODAL_KEY_${campaignContext.campaign.id}`
  const open = useOpenModalKey(generalInsightWarningModalKey)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      open()
    } else {
      generalBriefing.setInsight(e.target.checked)
    }
  }

  const contextProviderObject = {
    ...generalBriefing,
    dialogOpen,
    setDialogOpen,
  }

  return (
    <>
      {isSuperAdmin && (
        <EnableGeneralInsightContext.Provider value={contextProviderObject}>
          <FormControlLabel
            control={<Switch checked={generalBriefing.insight} onChange={onChange} />}
            label="Enable General Insight"
          />
          <WarningDialog
            modalKey={generalInsightWarningModalKey}
            onCancel={() => generalBriefing.setInsight(false)}
            onSubmit={() => generalBriefing.setInsight(true)}
          />
        </EnableGeneralInsightContext.Provider>
      )}
    </>
  )
}
