import { InsightsContainerContext } from "@src/containers/InsightContainers/InsightsContainer"
import { Autocomplete, FormControl, TextField } from "@mui/material"
import { FC, useContext, useState } from "react"

export const InsightFineTuningAutocomplete: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(InsightsContainerContext)
  const [fineTuning, setFineTuning] = useState<string | null>(urlParamState.fine_tuning || null)

  const handleOnChange = (even: React.SyntheticEvent, value: string | null) => {
    setFineTuning(value)
    setUrlParamState({
      ...urlParamState,
      fine_tuning: value,
      page: 0,
    })
  }

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Autocomplete
        onChange={handleOnChange}
        options={["On", "Off"]}
        renderInput={(params) => <TextField {...params} label="Fine Tuning" />}
        value={fineTuning}
      />
    </FormControl>
  )
}
