import { useAuthAxios } from "@api/axiosInstance"
import { QueryParamsBuilder } from "@interfaces/content-acquisition/queryParamsBuilder"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { namespace } from "./shared"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { UsersAiFeatureResponse } from "@interfaces/AiProducts/responseObjectInterfaces"
import { serializeUsersAiFeatureResponse } from "@interfaces/AiProducts/serializers/responses/serializers"
import { UsersAiFeature } from "@interfaces/AiProducts/models"
import {
  serializeCreateUserAiFeatureRequest,
  serializeUpdateUserAiFeatureRequest,
} from "@interfaces/AiProducts/serializers/requests/usersAiFeatures"

const resourcePath = (featureId?: number) => {
  if (featureId) {
    return `${namespace}/ai-features/${featureId}/users-ai-features`
  } else {
    return `${namespace}/users-ai-features`
  }
}

export interface FetchUsersAiFeatureResponse {
  users_ai_feature: UsersAiFeatureResponse
}

export const useCreateUsersAiFeature = (onSuccess?: () => void, onFailure?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: { userAiFeature: UsersAiFeature }) => {
      const body = serializeCreateUserAiFeatureRequest(requestBody.userAiFeature)
      const response = await (
        await axiosInstance
      ).post<FetchUsersAiFeatureResponse>(resourcePath(body.ai_feature_id), body)
      return serializeUsersAiFeatureResponse(response?.data?.users_ai_feature)
    },
    {
      onSuccess: (_response) => {
        onSuccess && onSuccess()
        queryClient.invalidateQueries(["users-ai-features"])
      },
      onError: () => {
        onFailure && onFailure()
      },
    },
  )
}

export const useUpdateUsersAiFeature = (onSuccess?: () => void, onError?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: { userAiFeature: UsersAiFeature }) => {
      const body = serializeUpdateUserAiFeatureRequest(requestBody.userAiFeature)
      if (!body.id) {
        throw new Error("Assignment ID is required for update")
      }
      return (await axiosInstance).put<FetchUsersAiFeatureResponse>(
        `${resourcePath(body.ai_feature_id)}/${body.id}`,
        body,
      )
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess()
        queryClient.invalidateQueries(["ai-features"])
      },
      onError: () => {
        onError && onError()
      },
    },
  )
}

export interface FetchUsersAiFeatureResponse {
  users_ai_features: UsersAiFeatureResponse[]
  meta: {
    total: number
  }
}

export const useFetchUsersAiFeature = (aiFeatureId?: number, params?: CommonUrlParams, enabled?: boolean) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const featuresPath = resourcePath(aiFeatureId)
  const uri = `${featuresPath}${queryParams}`

  return useQuery(
    [paramBuilder.toQueryKey("users-ai-features"), aiFeatureId],
    async () => {
      const response = await (await axiosInstance).get<FetchUsersAiFeatureResponse>(uri)
      return response?.data?.users_ai_features.map((userAiFeatures) => serializeUsersAiFeatureResponse(userAiFeatures))
    },
    {
      enabled: enabled,
    },
  )
}
