import { Stack } from "@mui/material"
import { FC } from "react"
import { CampaignFields } from "@src/components/Opportunities/CampaignFields"
import { OpportunityChannelFilter } from "@src/components/Opportunities/DataFilters/OpportunityChannelFilter"
import { OpportunityGeographiesFilters } from "@src/components/Opportunities/DataFilters/OpportunityGeographiesFilters"
import { OpportunityIndustryFilter } from "@src/components/Opportunities/DataFilters/OpportunityIndustryFilter"
import { TermsSearchFilters } from "@components/Opportunities/DataFilters/TermsSearchFilters"

export const OpportunitiesDataFiltersContainer: FC = () => {
  return (
    <Stack gap={1} marginTop={2}>
      <TermsSearchFilters />
      <OpportunityIndustryFilter />
      <Stack direction="row" gap={1} flexWrap="wrap">
        <OpportunityGeographiesFilters />
        <OpportunityChannelFilter />
      </Stack>
      <Stack direction="row" gap={1}>
        <CampaignFields />
      </Stack>
    </Stack>
  )
}
