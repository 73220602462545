import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Person, PersonWithVoiceIds } from "@interfaces/person"
import { Contact } from "@interfaces/contact"
import { stringifyListParam } from "@functions/generateURL"

const prefix = "/persons"

export const useFetchPerson = (id: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["person", id], async () => {
    const response = await (await axiosInstance).get<Person<Contact>>(`${prefix}/${id}`)
    return response.data
  })
}

export const useSearchPersonsByName = (namePartial: string, enabled: boolean = true) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["person-by-name", namePartial],
    async () => {
      const response = await (
        await axiosInstance
      ).get<PersonWithVoiceIds[]>(`${prefix}/search?search_type=search_by_name&name=${namePartial}`)
      return response.data
    },
    {
      enabled,
    },
  )
}

export const useSearchPersonsById = (personIds: Set<number> | number[], enabled: boolean = true) => {
  const axiosInstance = useAuthAxios()
  const personIdsListString = stringifyListParam(Array.from(personIds))

  return useQuery(
    ["person-by-id", personIdsListString],
    async () => {
      const response = await (
        await axiosInstance
      ).get<PersonWithVoiceIds[]>(`${prefix}/search?search_type=search_by_id&person_ids=${personIdsListString}`)
      return response.data
    },
    {
      enabled,
    },
  )
}

export const useSearchPersonsByVoiceId = (voiceIds: Set<number> | number[], enabled: boolean = true) => {
  const axiosInstance = useAuthAxios()
  const voiceIdsListString = stringifyListParam(Array.from(voiceIds))

  return useQuery(
    ["person-by-voice-id", voiceIdsListString],
    async () => {
      const response = await (
        await axiosInstance
      ).get<PersonWithVoiceIds[]>(`${prefix}/search?search_type=search_by_voice_id&voice_ids=${voiceIdsListString}`)
      return response.data
    },
    {
      enabled,
    },
  )
}

export type PersonsTitleHitsResponse = {
  result_count: number
  result_examples: string[]
}

export const useFetchPersonsTitleHits = (searchTerm: string, enabled: boolean = true) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["persons-title-hits", searchTerm],
    async () => {
      const response = await (
        await axiosInstance
      ).get<PersonsTitleHitsResponse>(`${prefix}/hits?search_type=title_search&search_term=${searchTerm}`)
      return response.data
    },
    {
      enabled,
    },
  )
}

export type PersonsOrganizationHitsResponse = {
  result_count: number
  result_examples: string[]
}

export const useFetchPersonsOrganizationHits = (searchTerm: string, enabled: boolean = true) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["persons-organization-hits", searchTerm],
    async () => {
      const response = await (
        await axiosInstance
      ).get<PersonsOrganizationHitsResponse>(`${prefix}/hits?search_type=org_search&search_term=${searchTerm}`)
      return response.data
    },
    {
      enabled,
    },
  )
}
