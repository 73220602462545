import { OpportunitiesContainerContext } from "./OpportunitiesContainer"
import { Stack } from "@mui/material"
import { FC, useContext } from "react"
import { CampaignFields } from "@src/components/Opportunities/CampaignFields"
import { OpportunityChannelFilter } from "@src/components/Opportunities/DataFilters/OpportunityChannelFilter"
import { OpportunityGeographiesFilters } from "@src/components/Opportunities/DataFilters/OpportunityGeographiesFilters"
import { OpportunityIndustryFilter } from "@src/components/Opportunities/DataFilters/OpportunityIndustryFilter"
import { FineTuningFilter } from "@src/components/shared/FineTuningFilter"
import { GeneratedByModelFilter } from "@components/Admin/Opportunities/DataFilters/GeneratedByModelFilter"
import { TermsSearchFilters } from "@components/Opportunities/DataFilters/TermsSearchFilters"

export const OpportunitiesAdminDataFiltersContainer: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)

  return (
    <Stack gap={1} marginTop={2}>
      <TermsSearchFilters />
      <OpportunityIndustryFilter />
      <Stack direction="row" gap={1} flexWrap="wrap">
        <OpportunityGeographiesFilters />
        <OpportunityChannelFilter />
      </Stack>
      <Stack direction="row" gap={1}>
        <CampaignFields />
      </Stack>
      <Stack direction="row" gap={1}>
        <FineTuningFilter
          urlParamState={urlParamState}
          setUrlParamState={setUrlParamState as (newFilterParams: Record<string, unknown>) => void}
        />
        <GeneratedByModelFilter
          urlParamState={urlParamState}
          setUrlParamState={setUrlParamState as (newFilterParams: Record<string, unknown>) => void}
        />
      </Stack>
    </Stack>
  )
}
