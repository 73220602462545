import { RubyObject } from "./default"
import { Organization } from "./organization"

export enum ContactSource {
  PowerAlmanac = "power_almanac",
  LLM = "llm",
}

export interface Contact extends RubyObject {
  name: string
  role: string
  title: string
  email: string | null
  phone: string | null
  removed_at: Date | null
  organization?: Organization
}

export interface AdminContact extends RubyObject {
  first_name: string
  last_name: string
  organization_id: number
  phone_number?: string
  email_address?: string
  role: string
  title?: string
  removed_at?: string
  organization?: Organization
  power_almanac_person_id?: string
  power_almanac_record_id?: string
  source: ContactSource
}

interface EditableContactFields {
  first_name: string
  last_name: string
  organization_id: number
  phone_number?: string
  email_address?: string
  role: string
  title?: string
}
export interface CreateContact extends EditableContactFields {
  source: ContactSource
}

export interface UpdateContact extends EditableContactFields {
  id: number
}
