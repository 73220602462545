import { EditablePrompt } from "../AiChainEditModal"
import { AiPrompt } from "@interfaces/AiProducts/models"

export const isNewPrompt = (savedPrompts?: AiPrompt[], editablePrompt?: EditablePrompt) => {
  if (!savedPrompts || !editablePrompt) return true

  const foundPrompt = savedPrompts.find((prompt) => prompt.text === editablePrompt.text)
  if (foundPrompt) return false

  return true
}
