import { FC } from "react"
import { Stack } from "@mui/material"
import { InsightTextSearchInput } from "@src/components/Insights/InsightFilters/InsightTextSearchInput"
import { InsightTypeAutocomplete } from "@src/components/Insights/InsightFilters/InsightTypeAutocomplete"
import { InsightUsersAutocomplete } from "@src/components/Insights/InsightFilters/InsightUsersAutocomplete"
import { InsightGeographiesAutocompletes } from "@src/components/Insights/InsightFilters/InsightGeographiesAutocompletes"
import { InsightChannelAutocomplete } from "@src/components/Insights/InsightFilters/InsightChannelAutocomplete"

export const InsightsDataFiltersContainer: FC = () => {
  return (
    <>
      <Stack direction="row" marginTop={2} gap={1} flexWrap="wrap">
        <InsightTextSearchInput />
        <Stack gap={1} direction="row" flex="70ch 1 0">
          <InsightTypeAutocomplete />
          <InsightUsersAutocomplete />
        </Stack>
      </Stack>
      <Stack direction="row" gap={1} marginTop={1}>
        <InsightGeographiesAutocompletes />
        <InsightChannelAutocomplete />
      </Stack>
    </>
  )
}
