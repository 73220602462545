import { Layout } from "@src/components/shared/Layout"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import { urlParamsToInsightQueryObject } from "@src/api/insights"
import useSetUrlParamState from "@src/components/shared/UseSetUrlParamState"
import { Box } from "@mui/material"
import { InsightsAdminDataFilterContainer } from "./InsightsAdminDataFiltersContainer"
import { InsightsAdminDataGrid } from "@components/Admin/Insights/DataGrid/InsightsAdminDataGrid"
import { InsightsContainerContext } from "./InsightsContainer"

export const InsightsAdminContainer: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const urlParamState = urlParamsToInsightQueryObject(params)
  const setUrlParamState = useSetUrlParamState()

  return (
    <Layout title="All Insights (admin)">
      <InsightsContainerContext.Provider value={{ params, urlParamState, setUrlParamState }}>
        <InsightsAdminDataFilterContainer />
        <Box marginTop={2}>
          <InsightsAdminDataGrid />
        </Box>
      </InsightsContainerContext.Provider>
    </Layout>
  )
}
