import { Button, Chip, Fab, InputBase, Stack, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { FC, useContext, useState } from "react"

const searchInputStyle = {
  alignItems: "center",
  borderRadius: "29px",
  backgroundColor: "neutral.light",
  border: "1px solid #666666",
  display: "flex",
  overflow: "hidden",
  flex: 1,
}

export interface FindOpportunitiesFilterProps {
  placeholder?: string
  urlParamKey: string
}

export const FindOpportunitiesFilter: FC<FindOpportunitiesFilterProps> = ({ placeholder, urlParamKey }) => {
  const { urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)

  const urlParamValue = (urlParamState[urlParamKey] as string[]) || ([] as string[])

  const [input, setInput] = useState("")
  const addTerm = () => {
    if (input.trim()) {
      setUrlParamState({
        ...urlParamState,
        [urlParamKey]: [...urlParamValue, input],
        page: 0,
      })
      setInput("")
    }
  }

  return (
    <Stack direction="row" gap={0.5} alignItems="flex-start" padding={1} sx={searchInputStyle}>
      <Stack direction="row" flexWrap="wrap" gap={0.5} paddingY="4px">
        {urlParamValue.map((term) => (
          <Chip
            key={term}
            label={term}
            onDelete={() => {
              setUrlParamState({
                ...urlParamState,
                [urlParamKey]: urlParamValue.filter((t) => t !== term),
              })
            }}
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center" flexGrow="1" marginLeft="0 !important">
        <InputBase
          value={input}
          sx={{ flex: 1, minWidth: "22ch" }}
          inputProps={{
            "aria-label": `${placeholder}`,
            sx: {
              "&::placeholder": {
                opacity: "0.7",
              },
            },
          }}
          placeholder={placeholder}
          onChange={(event) => setInput(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter" && input.trim()) {
              addTerm()
            }
          }}
        />
        {input.trim() && (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                color: "white",
                flexShrink: "0",
                display: { xs: "none", sm: "flex" },
              }}
              disableElevation
              onClick={addTerm}
            >
              <Typography>Add Term</Typography>
            </Button>
            <Fab
              size="small"
              color="secondary"
              sx={{
                boxShadow: 0,
                color: "white",
                width: 36,
                height: 36,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={addTerm}
            >
              <AddIcon />
            </Fab>
          </>
        )}
      </Stack>
    </Stack>
  )
}
