import { InsightActionsDataGridRow } from "@components/Insights/DataGrid/InsightActionsDataGridRow"
import { Link as MuiLink } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"

const datestringToLocaleDate = (dateString: string) => new Date(dateString).toLocaleDateString()

export const publicInsightColumns: GridColDef[] = [
  {
    field: "meeting",
    flex: 1,
    headerName: "Meeting",
    renderCell: (params) => {
      return (
        <MuiLink component={Link} to={params.row.meeting.link}>
          {params.row.meeting.title}
        </MuiLink>
      )
    },
  },
  {
    field: "date",
    flex: 0.5,
    headerName: "Date",
    valueGetter: (value, row) => datestringToLocaleDate(row.date),
  },
  {
    field: "channel",
    flex: 1,
    headerName: "Channel",
    renderCell: (params) => {
      return (
        <MuiLink component={Link} to={params.row.channel.link}>
          {params.row.channel.name}
        </MuiLink>
      )
    },
  },
  { field: "location", flex: 0.5, headerName: "Location" },
  { field: "type", flex: 0.5, headerName: "Type" },
  { field: "owner", flex: 0.5, headerName: "Owner" },
  {
    field: "created_at",
    flex: 0.5,
    headerName: "Created At",
    valueGetter: (value, row) => datestringToLocaleDate(row.created_at),
  },
  {
    display: "flex",
    field: "actions",
    flex: 1,
    headerName: "Actions",
    renderCell: (params) => <InsightActionsDataGridRow insight={params.row.actions} />,
    sortable: false,
  },
]

export const adminInsightColumns = () => {
  const columns = [
    ...publicInsightColumns,
    {
      field: "fine_tuning",
      headerName: "Fine Tuning",
      flex: 0.5,
    },
    {
      field: "generated_by_model",
      headerName: "Generated by Model",
      flex: 0.5,
    },
  ]
  const order = [
    "meeting",
    "date",
    "channel",
    "location",
    "type",
    "owner",
    "created_at",
    "fine_tuning",
    "generated_by_model",
    "actions",
  ]
  return columns.sort((a, b) => order.indexOf(a.field) - order.indexOf(b.field))
}
