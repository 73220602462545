import { useMemo } from "react"
import { UserCampaign } from "../../interfaces/campaign"

export const goToSearch = (
  filterParams: any,
  searchInput: any,
  selectedCampaignId: string,
  locationSearchParams: string,
  searchDateRange: [Date, Date],
  mustIncludeInput: any = [],
) => {
  const urlSearchParams = new URLSearchParams(locationSearchParams)
  urlSearchParams.set("filter-params", btoa(JSON.stringify(filterParams)))
  urlSearchParams.set("search-terms", encodeURIComponent(searchInput.toString()))
  urlSearchParams.set("campaign-id", selectedCampaignId || "")
  urlSearchParams.set("date-range", searchDateRange.toString())
  urlSearchParams.set("must-include-terms", encodeURIComponent(mustIncludeInput))
  window.open(`/?${urlSearchParams}`)
}

export const update = (buttonState: string) => {
  return useMemo(() => buttonState.trim().toLowerCase() === "update", [buttonState])
}

// Determines if current campaign selected is the current users camapaign
export const userCampaignSelected = (selectedCampaignId: number | null, userCampaigns?: UserCampaign[]) => {
  return useMemo(() => {
    if (!selectedCampaignId || !userCampaigns) {
      return false
    }

    const selectedCampaign = userCampaigns.find((campaign) => campaign.id === selectedCampaignId)

    if (selectedCampaign) {
      return true
    }

    return false
  }, [selectedCampaignId, userCampaigns])
}
