import { FC } from "react"
import { marked } from "marked"
import DOMPurify from "dompurify"

export const InsightContent: FC<{ promptResult: string }> = ({ promptResult }) => {
  if (promptResult) {
    const insightElement = (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(promptResult) as string),
        }}
      />
    )
    return <>{insightElement}</>
  }
  return null
}
