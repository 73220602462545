import { FC, useContext } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material"
import { PromptContext } from "./context"
import { useToast } from "@components/shared/ToastHook"
import { useCreatePrompt, useUpdatePrompt } from "@api/admin/prompts"
import { useModalKey } from "@components/shared/OpenModalHook"

const UpdateButton: FC<{
  id: number
  onSuccess: () => void
}> = ({ id, onSuccess }) => {
  const { name, text, isPersonal } = useContext(PromptContext)
  const { mutate: updatePrompt } = useUpdatePrompt(id, onSuccess)

  return (
    <Button
      onClick={() =>
        updatePrompt({
          name,
          text,
          is_personal: isPersonal,
        })
      }
    >
      Update
    </Button>
  )
}

export const EDIT_PROMPT_MODAL_KEY = "EDIT_PROMPT"

export const EditPromptModal: FC = () => {
  const { id, name, setName, text, setText, isPersonal, setIsPersonal, close } = useContext(PromptContext)
  const isOpen = useModalKey(EDIT_PROMPT_MODAL_KEY)
  const toast = useToast()
  const onSuccess = () => {
    toast(`Prompt ${id === null ? "Created" : "Updated"}`)
    close()
  }

  const { mutate: createPrompt } = useCreatePrompt(onSuccess)

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>{`${id === null ? "Create" : "Edit"} Prompt`}</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack spacing={1}>
          <TextField
            size="small"
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Name"
            fullWidth
          />
          <TextField
            size="small"
            value={text}
            onChange={(event) => setText(event.target.value)}
            label="Text"
            fullWidth
            multiline
          />
          <FormControlLabel
            control={<Checkbox checked={isPersonal} onChange={(event) => setIsPersonal(event.target.checked)} />}
            label="Is personal summary prompt"
          />
        </Stack>
        <DialogActions>
          {id === null ? (
            <Button
              variant="contained"
              onClick={() =>
                createPrompt({
                  name,
                  text,
                  is_personal: isPersonal,
                })
              }
            >
              Create
            </Button>
          ) : (
            <UpdateButton id={id} onSuccess={onSuccess} />
          )}
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
