import { TextField, Chip, Stack, Typography } from "@mui/material"
import { FC, SetStateAction, useContext, useState } from "react"
import { UsersAiSettingsContext } from "@components/Admin/AiProducts/Playground/Contexts/UsersSettingsContext"

export const MeetingIdInput: FC = () => {
  const { userSettings, setUserSettings } = useContext(UsersAiSettingsContext)

  const [value, setValue] = useState("")

  const handleEnter = (e: { key: string }) => {
    if (e.key === "Enter" && value !== "" && !userSettings?.selectedUserAiFeature?.inputSettings?.includes(value)) {
      setUserSettings({
        ...userSettings,
        selectedUserAiFeature: {
          ...userSettings.selectedUserAiFeature,
          inputSettings: [...(userSettings?.selectedUserAiFeature?.inputSettings || []), value.trim()],
        },
      })
      setValue("")
    }
  }

  const handleValueChange = (e: { target: { value: SetStateAction<string> } }) => {
    setValue(e.target.value)
  }

  const handleValueDelete = (item: string) => {
    setUserSettings({
      ...userSettings,
      selectedUserAiFeature: {
        ...userSettings.selectedUserAiFeature,
        inputSettings: userSettings?.selectedUserAiFeature?.inputSettings?.filter((v) => v !== item),
      },
    })
  }

  return (
    <Stack spacing={1} direction="column">
      <Typography variant="body2">Example Data</Typography>
      <TextField
        label="Meeting Ids"
        size="small"
        fullWidth
        multiline
        minRows={3}
        autoFocus
        onKeyUp={handleEnter}
        value={value}
        onChange={handleValueChange}
        InputProps={{
          startAdornment:
            userSettings?.selectedUserAiFeature?.inputSettings?.length === 0
              ? null
              : userSettings?.selectedUserAiFeature?.inputSettings?.map((v) => {
                  return (
                    <Chip
                      sx={{ margin: "2px", fontSize: "0.75rem" }}
                      key={v}
                      label={v}
                      size="medium"
                      variant="filled"
                      color="primary"
                      onDelete={() => {
                        handleValueDelete(v)
                      }}
                    />
                  )
                }),
          style:
            userSettings?.selectedUserAiFeature?.inputSettings?.length === 0
              ? {}
              : {
                  display: "inline-block",
                  margin: 2,
                },
        }}
      />
    </Stack>
  )
}
