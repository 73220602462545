import { AiChainRunnable, PromptInputTypes } from "@interfaces/AiProducts/models"
import { AiChainRunnablesRequest } from "@interfaces/AiProducts/requestObjectInterfaces"

export const serializeAiChainRunnableRequest: (
  runnable?: AiChainRunnable,
  inputData?: string[],
) => AiChainRunnablesRequest = (runnable, inputData) => {
  if (!runnable) return {}
  if (inputData) {
    return {
      id: runnable?.id,
      ordinal: runnable.ordinal,
      segment_type: runnable.segmentType,
      ai_model: runnable?.aiModel?.id, // replace
      output_formatter: runnable?.aiOutputFormatter?.id || 0,
      prompt_input: {
        input_type: runnable.inputType || PromptInputTypes.MEETING_IDS,
        values: inputData,
      },
      ai_prompt: {
        id: runnable.aiPrompt.id,
        name: runnable.aiPrompt.name,
        text: runnable.aiPrompt.text,
      },
    }
  }
  return {
    id: runnable?.id,
    ordinal: runnable.ordinal,
    segment_type: runnable.segmentType,
    ai_model: runnable?.aiModel?.id, // replace
    output_formatter: runnable?.aiOutputFormatter?.id || 0,
    input_type: runnable.inputType || PromptInputTypes.MEETING_IDS,
    ai_prompt: {
      id: runnable.aiPrompt.id,
      name: runnable.aiPrompt.name,
      text: runnable.aiPrompt.text,
    },
  }
}
