import { FC, ReactNode, useEffect, useState } from "react"
import { UseQueryResult } from "react-query"
import { isEqual, isString } from "lodash"
import { PersonsOrganizationHitsResponse, useFetchPersonsOrganizationHits } from "@api/persons"
import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  CircularProgress,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material"

import { useGetFeatureFlagByName } from "@api/feature_flags"

type OrganizationSearchTermOption = {
  id: number
  title: string
  search_term: string
}

type OrganizationSearchTermsProps = {
  setSelectedSearchTerms: (newData: string[]) => void
  options?: OrganizationSearchTermOption[]
  selectedOptions?: OrganizationSearchTermOption[]
  setSelectedOptions?: (selectedOptions: OrganizationSearchTermOption[]) => void
  loading?: boolean
  textFieldProps?: TextFieldProps
  sx?: SxProps<Theme>
  size?: "medium" | "small"
}

type OrganizationSearchTermsWrapperProps = OrganizationSearchTermsProps & {
  selectedSearchTerms?: Set<string> | string[]
  labelElement?: ReactNode
}

// This is a workaround for the unnecessary warning in MUI 5.x mentioned here:
// - https://github.com/mui/material-ui/issues/18514
// - https://github.com/mui/material-ui/pull/43314
// @TODO - we should remove this code ass soon as we upgrade MUI to 6.x or higher
const mockOrganizationSearchTermOption: OrganizationSearchTermOption = {
  id: -2,
  title: "",
  search_term: "",
}

export const OrganizationSearchTermsWrapper: FC<OrganizationSearchTermsWrapperProps> = ({
  selectedSearchTerms: selectedSearchTerms = [],
  setSelectedSearchTerms,
  labelElement,
  textFieldProps,
  sx,
  size,
}) => {
  const initialSearchTermsOptions: OrganizationSearchTermOption[] = []

  selectedSearchTerms.forEach((searchTerm) => {
    initialSearchTermsOptions.push({
      id: Date.now() + Math.random(),
      title: `${searchTerm}`,
      search_term: `${searchTerm}`,
    })
  })

  const [providedSelectedSearchTerms, setProvidedSelectedSearchTerms] = useState(selectedSearchTerms)
  const [selectedOptions, setSelectedOptions] = useState<OrganizationSearchTermOption[]>(initialSearchTermsOptions)
  const { data: speakerIdPersonSelectorFlagData } = useGetFeatureFlagByName("SPEAKER_ID_PERSON_SELECTOR")

  useEffect(() => {
    if (!isEqual(providedSelectedSearchTerms, selectedSearchTerms)) {
      setProvidedSelectedSearchTerms(selectedSearchTerms)
      setSelectedOptions(initialSearchTermsOptions)
    }
  }, [selectedSearchTerms])

  const shouldShowComponent =
    typeof speakerIdPersonSelectorFlagData == "object" && speakerIdPersonSelectorFlagData["value"] == "true"

  return (
    <>
      {shouldShowComponent && (
        <>
          {labelElement}
          <OrganizationSearchTerms
            setSelectedSearchTerms={setSelectedSearchTerms}
            options={initialSearchTermsOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textFieldProps={textFieldProps}
            size={size}
            sx={sx}
          />
        </>
      )}
    </>
  )
}

export const OrganizationSearchTerms: FC<OrganizationSearchTermsProps> = ({
  setSelectedSearchTerms,
  options: initialOptions,
  selectedOptions,
  setSelectedOptions,
  textFieldProps,
  sx,
  size,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  // the mockOrganizationSearchTermOption should always be last
  const [options, setOptions] = useState<OrganizationSearchTermOption[]>([
    ...(initialOptions || []),
    mockOrganizationSearchTermOption,
  ])

  const preloadInputValue = "__sample__"
  const [inputValue, setInputValue] = useState("")
  const [debouncedInputValue, setDebouncedInputValue] = useState(preloadInputValue)
  const [triggerSearch, setTriggerSearch] = useState(false)

  const inputValueBlank = inputValue == ""
  let searchResult: UseQueryResult<PersonsOrganizationHitsResponse>

  if (inputValue == "") {
    searchResult = useFetchPersonsOrganizationHits(preloadInputValue, true)
  } else {
    searchResult = useFetchPersonsOrganizationHits(debouncedInputValue, triggerSearch)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedInputValue(inputValue)
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue])

  useEffect(() => {
    if (debouncedInputValue !== "") {
      setTriggerSearch(true)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    const resultData: PersonsOrganizationHitsResponse =
      typeof searchResult.data == "object"
        ? searchResult.data
        : {
            result_count: 0,
            result_examples: [],
          }
    const currentResultCount = resultData.result_count
    const currentResultExamples = resultData.result_examples

    const noResultsOption: OrganizationSearchTermOption = {
      id: -3.0,
      title: `no matches`,
      search_term: inputValue,
    }

    const hitResultsOption: OrganizationSearchTermOption = {
      id: Date.now(),
      title: `‘${inputValue}’ - ${currentResultCount} matching organizations`,
      search_term: inputValue,
    }

    let resultOptions: OrganizationSearchTermOption[] = [currentResultCount > 0 ? hitResultsOption : noResultsOption]

    if (inputValueBlank) {
      resultOptions = []
    }

    if (currentResultExamples.length > 0) {
      currentResultExamples.forEach((matchingText) => {
        resultOptions.push({
          id: -1 - Math.random(),
          title: `${matchingText}`,
          search_term: `${matchingText}`,
        })
      })
    }

    if (loading) {
      setOptions([mockOrganizationSearchTermOption])
    } else {
      setOptions([...resultOptions, mockOrganizationSearchTermOption])
    }
  }, [searchResult.data])

  const loading = searchResult?.isLoading || false

  const updateSelectedOptionsState = (newSelectedOptionsSet: Set<OrganizationSearchTermOption>) => {
    const searchTerms = Array.from(newSelectedOptionsSet).map((el) => {
      return el.search_term
    })
    setSelectedOptions?.(Array.from(newSelectedOptionsSet))
    setSelectedSearchTerms(searchTerms)
  }

  const handleOnChange: AutocompleteProps<OrganizationSearchTermOption, true, true, true>["onChange"] = (
    event,
    newValue: (OrganizationSearchTermOption | string)[],
  ) => {
    const newSelectedOptionsSet = new Set<OrganizationSearchTermOption>()

    if (newValue.length > 0) {
      newValue.forEach((item) => {
        if (isString(item)) {
          newSelectedOptionsSet.add({
            id: 1 - Math.random(),
            title: `${item}`,
            search_term: `${item}`,
          })
        } else {
          if (item.id && item.id > -2) {
            newSelectedOptionsSet.add(item)
          }
        }
      })
    }
    updateSelectedOptionsState(newSelectedOptionsSet)
  }

  const handleOnDelete = (newValue: OrganizationSearchTermOption) => {
    const newSelectedOptionsSet = new Set<OrganizationSearchTermOption>(selectedOptions)
    if (newValue) {
      newSelectedOptionsSet.delete(newValue)
      updateSelectedOptionsState(newSelectedOptionsSet)
    }
  }

  return (
    <>
      <Autocomplete<OrganizationSearchTermOption, true, true, true>
        multiple
        autoComplete
        limitTags={3}
        // IMPORTANT: clearOnBlur set to false will ensure that input works with a blank list
        clearOnBlur={false}
        freeSolo={true}
        sx={sx || { minWidth: 300 }}
        size={size || "medium"}
        open={isOpen}
        options={options}
        filterOptions={(options) => options.filter((option) => option.id !== -2)}
        loading={loading}
        getOptionLabel={(option: OrganizationSearchTermOption | string) => (isString(option) ? option : option.title)}
        isOptionEqualToValue={(option: OrganizationSearchTermOption, value: OrganizationSearchTermOption) => {
          return option.id === value.id || option.id == -2
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        value={selectedOptions}
        onChange={handleOnChange}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        renderTags={(options: OrganizationSearchTermOption[]) => {
          return options.map((searchTermOption) => {
            return (
              <Chip
                variant="outlined"
                label={searchTermOption.search_term}
                key={searchTermOption.id}
                onDelete={() => handleOnDelete(searchTermOption)}
              />
            )
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Person Organization"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            {...textFieldProps}
          />
        )}
        noOptionsText={"Input your search phrase"}
        groupBy={(option) => {
          if (option.id <= -2) {
            return ""
          } else {
            return option.id >= 0 ? "Matches" : "Options"
          }
        }}
        renderOption={(props, option: OrganizationSearchTermOption) => {
          return (
            <li {...props} key={option.id}>
              {option.title}
            </li>
          )
        }}
      />
    </>
  )
}
