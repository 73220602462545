import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { FilterParams } from "../../components/Home/interface"
import { SubscribedUser } from "../interfaces"
import { FetchedCampaignResponse } from "@interfaces/Responses/campaigns"
import { serializeCampaignResponse } from "@interfaces/Serializers/Response/campaigns"

interface CampaignParams {
  name: string
  filterParams: FilterParams
  searchTerms: string[]
  emailCadence: "Unsubscribed" | "Daily" | "Weekly"
  mustIncludeInput: string[]
  proximity: number | null
  exclude: boolean
  generalBriefing?: boolean
  briefings: number[]
}

const prefix = "/admin/campaigns"

export const useUpdateCampaign = (campaignId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      name,
      filterParams,
      searchTerms,
      emailCadence,
      mustIncludeInput,
      proximity,
      exclude,
      generalBriefing,
      briefings,
    }: CampaignParams) => {
      return (await axiosInstance).put(`${prefix}/${campaignId}`, {
        name,
        filterParams,
        searchTerms,
        email_cadence: emailCadence,
        mustIncludeTerms: mustIncludeInput,
        proximity,
        exclude,
        automated_general_briefings: generalBriefing,
        briefings,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("admin_campaigns")
        queryClient.invalidateQueries("user_organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useFetchSubscribers = (campaignId: string | number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin_subscribed_users", campaignId], async () => {
    const response = await (await axiosInstance).get<SubscribedUser[]>(`${prefix}/${campaignId}/subscribers`)
    // because of how this endpoint is written on the backend, the number array returns as a string like '{1,2,3}'
    const transformedResponse: SubscribedUser[] = response.data.map((subscribedUser) => ({
      ...subscribedUser,
      briefings: subscribedUser.briefings
        ? (subscribedUser.briefings as unknown as string)
            .slice(1, -1)
            .split(",")
            .map((stringNum) => parseInt(stringNum))
        : [],
    }))
    return transformedResponse
  })
}

export const useSendCampaignEmail = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: { id: string; emailTo: string }) => {
    return (await axiosInstance).post(`${prefix}/${params.id}/send_email`, { email_to: params.emailTo })
  })
}

export interface FetchedCampaignsResponse {
  campaigns: FetchedCampaignResponse[]
  total: number
}

export const useFetchUserCampaigns = (userId: number | undefined) => {
  const resource = "/admin/users/"
  const axiosInstance = useAuthAxios()
  const uri = `${resource}${userId}/campaigns`

  return useQuery(
    ["campaigns", userId],
    async () => {
      const response = await (await axiosInstance).get<FetchedCampaignsResponse>(uri)

      return response?.data.campaigns.map((campaign: FetchedCampaignResponse) => serializeCampaignResponse(campaign))
    },
    {
      enabled: !!userId,
    },
  )
}
