import { PublicInsight } from "@interfaces/insight"
import { InsightCreditUsage } from "@interfaces/insightCreditUsage"
import { InsightCardWithRetry } from "./InsightCardWithRetry"

export const constructInsightCards = (
  insights: PublicInsight[],
  insightCreditUsagesMap: Map<number, InsightCreditUsage[]>,
) => {
  return insights.map((insight) => (
    <InsightCardWithRetry
      key={insight.id}
      insight={insight}
      ownerIds={insightCreditUsagesMap.get(insight.id)?.map((insightCreditUsage) => insightCreditUsage.user_id)}
    />
  ))
}
