import { FunnelOrchestratorEditor } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { CreateSegment, DeleteSegment, EditSegment } from "../crudInterface"
import { AiChainRunnable, AiChainRunnableSegmentTypes, PromptInputTypes } from "@interfaces/AiProducts/models"

export type CrudAction = CreateSegment | DeleteSegment | EditSegment

// inital segment crud
export const addInitalSegment: CreateSegment = (state, newSegment) => {
  const workingSegment = { ...newSegment }
  if (state.initialSegment.length > 0) {
    // remove when multiple prompts allowed
    return state
  }

  if (state.middleSegment.length === 0 && !state.tailSegment) {
    workingSegment.segmentType = AiChainRunnableSegmentTypes.TOTAL
    state.initialSegment = state.initialSegment.map((segment: AiChainRunnable) => {
      segment.segmentType = AiChainRunnableSegmentTypes.TOTAL
      return segment
    })
  }

  workingSegment.ordinal = 0
  return {
    ...state,
    initialSegment: [...state.initialSegment, workingSegment],
  } as FunnelOrchestratorEditor
}

export const deleteInitalSegment: DeleteSegment = (state: FunnelOrchestratorEditor, index) => {
  return {
    ...state,
    initialSegment: state.initialSegment.filter((_, i) => i !== index),
  } as FunnelOrchestratorEditor
}

export const editInitalSegment: EditSegment = (state: FunnelOrchestratorEditor, index, newSegment) =>
  ({
    ...state,
    initialSegment: state.initialSegment.map((segment, i) => (i === index ? newSegment : segment)),
  }) as FunnelOrchestratorEditor

// middle segment crud
export const addMiddleSegment: CreateSegment = (state, newSegment) => {
  const workingSegment = { ...newSegment }
  state.initialSegment = state.initialSegment.map((segment: AiChainRunnable) => {
    segment.segmentType = AiChainRunnableSegmentTypes.INITIAL
    return segment
  })

  state.middleSegment = state.middleSegment.map((segment: AiChainRunnable) => {
    segment.segmentType = AiChainRunnableSegmentTypes.MIDDLE
    return segment
  })

  workingSegment.ordinal = state.middleSegment.length + 1
  workingSegment.segmentType = AiChainRunnableSegmentTypes.MIDDLE
  workingSegment.inputType = PromptInputTypes.CHAIN_OUTPUT

  return {
    ...state,
    middleSegment: [...state.middleSegment, workingSegment],
  } as FunnelOrchestratorEditor
}

export const deleteMiddleSegment: DeleteSegment = (state: FunnelOrchestratorEditor, index) => {
  if (state.middleSegment.length === 1 && !state.tailSegment) {
    state.initialSegment = state.initialSegment.map((segment: AiChainRunnable) => {
      segment.segmentType = AiChainRunnableSegmentTypes.TOTAL
      return segment
    })
  } else {
    state.middleSegment = state.middleSegment.filter((_, i) => i !== index)
    state.middleSegment.forEach((segment, i) => {
      segment.ordinal = i + 1
    })
    if (state.tailSegment) {
      state.tailSegment.ordinal = state.middleSegment.length + 1
    }
  }

  return {
    ...state,
  } as FunnelOrchestratorEditor
}

export const editMiddleSegment: EditSegment = (state: FunnelOrchestratorEditor, index, newSegment) =>
  ({
    ...state,
    middleSegment: state.middleSegment.map((segment, i) => (i === index ? newSegment : segment)),
  }) as FunnelOrchestratorEditor

export const addTailSegment: CreateSegment = (state, newSegment) => {
  const workingSegment = { ...newSegment }
  if (state.tailSegment) {
    state = addMiddleSegment(state, state.tailSegment)
    workingSegment.ordinal = state.middleSegment.length + 1
  } else {
    workingSegment.ordinal = 1
    state.initialSegment = state.initialSegment.map((segment: AiChainRunnable) => {
      segment.segmentType = AiChainRunnableSegmentTypes.INITIAL
      return segment
    })
  }
  state.tailSegment = workingSegment

  workingSegment.segmentType = AiChainRunnableSegmentTypes.TAIL
  workingSegment.inputType = PromptInputTypes.CHAIN_OUTPUT

  return {
    ...state,
  } as FunnelOrchestratorEditor
}

export const deleteTailSegment: DeleteSegment = (state) => {
  if (state.middleSegment.length !== 0) {
    state.tailSegment = state.middleSegment[state.middleSegment.length - 1]
    state.tailSegment.segmentType = AiChainRunnableSegmentTypes.TAIL
    state = deleteMiddleSegment(state, state.middleSegment.length - 1)
  } else {
    state.tailSegment = null

    state.initialSegment = state.initialSegment.map((segment: AiChainRunnable) => {
      segment.segmentType = AiChainRunnableSegmentTypes.TOTAL
      return segment
    })
  }
  // move last middle segment to tail
  //update the last middle segment to tail type
  //else set tail to null
  return {
    ...state,
  } as FunnelOrchestratorEditor
}

export const editTailSegment: EditSegment = (state, index, newSegment) =>
  ({
    ...state,
    tailSegment: newSegment,
  }) as FunnelOrchestratorEditor
