import { FC, useState, useMemo, useContext } from "react"
import { Link } from "react-router-dom"
import { DataTable } from "../shared/DataTable"
import { columns } from "../../tableColumns/bookmarkClipShareColumns"
import { ClipListItemActions } from "./ClipListItemActions"
import { EditClipModal } from "./EditClipModal"
import { DeleteClipsModal } from "./DeleteClipsModal"
import { useOpenModalKey, useSetData } from "../shared/OpenModalHook"
import { BookmarkContext } from "../../pages/BookmarksPage"
import { generateURL } from "../../functions/generateURL"
import { shareModalKey } from "../shared/ShareModal"
import { FetchedBookmarksItem, FetchedClipShare } from "../../api/bookmarks"

const ClipsList: FC<{ bookmark: FetchedBookmarksItem }> = ({ bookmark }) => {
  const openShareModal = useOpenModalKey(shareModalKey)
  const setDataOnModal = useSetData()
  const { isSharedTab } = useContext(BookmarkContext)
  const [selected, setSelected] = useState<number[]>([])

  const clips = useMemo(
    () =>
      bookmark?.clips?.map((clip: FetchedClipShare) => {
        return {
          ...clip,
          shareMethod: () => {
            openShareModal()
            setDataOnModal({ promoAllowed: false, ...clip })
          },
          title: (
            <Link
              target="_blank"
              to={generateURL(clip.meeting?.id, {
                terms: [clip.keyword],
                startTime: clip.start_time_in_seconds - 5,
                transcriptId: clip.transcript_id,
                activeTerm: clip.keyword,
              })}
            >
              {clip.title}
            </Link>
          ),
        }
      }),
    [bookmark.clips],
  )

  return (
    <>
      {!isSharedTab && <ClipListItemActions selected={selected} bookmarkId={bookmark.id} />}
      <DataTable
        data={clips}
        columns={columns}
        columnKey="id"
        tableStyle={{ height: { xs: 400, md: 400, xl: 600 } }}
        stickyHeader
        selected={isSharedTab ? undefined : selected}
        setSelected={setSelected}
        defaultSort="created_at"
      />
      {selected.length === 1 && (
        <EditClipModal
          bookmarkId={bookmark.id}
          clip={bookmark?.clips.find((clip: FetchedClipShare) => clip.id === selected[0])}
        />
      )}
      {selected.length > 0 && (
        <DeleteClipsModal
          bookmarkId={bookmark.id}
          clips={bookmark?.clips.filter((clip: FetchedClipShare) => selected.includes(clip.id))}
          clearSelected={() => setSelected([])}
        />
      )}
    </>
  )
}

export default ClipsList
