import { FC, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import {
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import {
  useFetchUserOrganization,
  useUpdateUserOrganization,
  useUpdateUserOrganizationLogo,
  useArchiveOrganization,
} from "@api/admin/user_organizations"
import { useFetchIndustries } from "@api/admin/industries"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Edit from "@mui/icons-material/Edit"
import { useToast } from "../../shared/ToastHook"

export const UserOrganizationPageHeader: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: userOrganization } = useFetchUserOrganization(userOrganizationId)
  const { data: industries } = useFetchIndustries(new URLSearchParams({ count: "-1" }))
  const history = useHistory()
  const [editing, setEditing] = useState(false)
  const [nameField, setNameField] = useState("")
  const [hubspotCompanyId, setHubspotCompanyId] = useState<"" | number>("")
  const [industrySelect, setIndustrySelect] = useState<null | number>(null)
  const [companySize, setCompanySize] = useState<"" | number>("")
  const [logoFile, setLogoFile] = useState<null | File>(null)
  const [salesforceEnabled, setSalesforceEnabled] = useState(false)
  const [insightsEnabled, setInsightsEnabled] = useState(false)
  const [speakerIdentificationEnabled, setSpeakerIdentificationEnabled] = useState(false)
  const [opportunitiesEnabled, setOpportunitiesEnabled] = useState(false)
  const setToast = useToast()
  const { mutate: archiveOrg } = useArchiveOrganization(() => setToast("Success"))

  const reset = () => {
    if (userOrganization) {
      setNameField(userOrganization.name)
      setHubspotCompanyId(userOrganization.hubspot_company_id ?? "")
      setIndustrySelect(userOrganization.industry_id)
      setCompanySize(userOrganization.size || "")
      setLogoFile(null)
      setSalesforceEnabled(userOrganization.salesforce_enabled === true)
      setInsightsEnabled(userOrganization.briefings_enabled === true)
      setSpeakerIdentificationEnabled(userOrganization.speaker_identification_enabled === true)
      setOpportunitiesEnabled(userOrganization.opportunities_enabled === true)
    }
  }

  const { mutate: updateUserOrganization, isLoading } = useUpdateUserOrganization(userOrganizationId, () => {
    reset()
    setEditing(false)
  })
  const { mutate: updateUserOrganizationLogo } = useUpdateUserOrganizationLogo(userOrganizationId)

  useEffect(reset, [userOrganization])

  if (!userOrganization) {
    return null
  }

  const handleHubspotCompanyIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    if (isNaN(value)) {
      setHubspotCompanyId("")
    } else {
      setHubspotCompanyId(value)
    }
  }

  return (
    <>
      <Stack direction="row" marginY={2} justifyContent="space-between">
        <Button onClick={() => history.push("/admin/user_organizations")} startIcon={<ArrowBack />}>
          Back
        </Button>
        {editing ? (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              onClick={() =>
                updateUserOrganization({
                  name: nameField,
                  hubspot_company_id: hubspotCompanyId || null,
                  industry_id: industrySelect,
                  size: companySize || null,
                  salesforce_enabled: salesforceEnabled,
                  briefings_enabled: insightsEnabled,
                  speaker_identification_enabled: speakerIdentificationEnabled,
                  opportunities_enabled: opportunitiesEnabled,
                })
              }
              endIcon={<Edit />}
            >
              {isLoading ? "Loading" : "Save"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setEditing(false)
                reset()
              }}
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Button variant="contained" onClick={() => setEditing(true)} endIcon={<Edit />}>
              Edit
            </Button>
            <Button
              variant="contained"
              onClick={() => archiveOrg(userOrganizationId)}
              color={userOrganization?.discarded_at !== null ? "error" : "primary"}
            >
              {userOrganization?.discarded_at !== null ? "Archived" : "Archive"}
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack spacing={1}>
          <Box component="img" src={userOrganization.logo_url || ""} maxHeight="100px" alignSelf="flex-start" />
          {editing && (
            <>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event) => setLogoFile(event.target.files ? event.target.files[0] : null)}
              />
              <Button
                variant="contained"
                disabled={!logoFile}
                onClick={() => {
                  if (logoFile) {
                    updateUserOrganizationLogo(logoFile)
                  }
                }}
              >
                Upload Logo
              </Button>
            </>
          )}
        </Stack>
        <Stack spacing={1} flex="1">
          {editing ? (
            <>
              <TextField label="Name" value={nameField} onChange={(event) => setNameField(event.target.value)} />
              <TextField
                label="Hubspot Company Id"
                onChange={handleHubspotCompanyIdChange}
                type="number"
                value={hubspotCompanyId || ""}
              />
              <FormControl>
                <InputLabel>Industry</InputLabel>
                <Select
                  value={industrySelect}
                  label="Industry"
                  onChange={(event) => setIndustrySelect(event.target.value as number)}
                >
                  {industries &&
                    industries.industries.map((industry) => (
                      <MenuItem key={industry.name} value={industry.id}>
                        {industry.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="Company Size"
                value={companySize}
                onChange={(event) => {
                  const result = parseInt(event.target.value)
                  if (!isNaN(result)) {
                    setCompanySize(result)
                  } else if (event.target.value === "") {
                    setCompanySize("")
                  }
                }}
              />
            </>
          ) : (
            <>
              <Typography variant="h1">{userOrganization?.name}</Typography>
              <Typography variant="h3">{userOrganization?.industry}</Typography>
              <Typography variant="h5">
                {userOrganization?.size && `Company size: ${userOrganization?.size}`}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      {editing ? (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={salesforceEnabled} onChange={(_, checked) => setSalesforceEnabled(checked)} />}
            label="Enable Salesforce Integration"
          />
          <FormControlLabel
            control={<Checkbox checked={insightsEnabled} onChange={(_, checked) => setInsightsEnabled(checked)} />}
            label="Enable General Insights"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={speakerIdentificationEnabled}
                onChange={(_, checked) => setSpeakerIdentificationEnabled(checked)}
              />
            }
            label="Enable Speaker Identification"
          />
          <FormControlLabel
            control={
              <Checkbox checked={opportunitiesEnabled} onChange={(_, checked) => setOpportunitiesEnabled(checked)} />
            }
            label="Enable Opportunities"
          />
        </FormGroup>
      ) : (
        <>
          {userOrganization?.salesforce_enabled && <Typography>Salesforce Integration Enabled</Typography>}
          {userOrganization?.briefings_enabled && <Typography>General Insights Enabled</Typography>}
          {userOrganization?.speaker_identification_enabled && <Typography>Speaker Identification Enabled</Typography>}
          {userOrganization?.opportunities_enabled && <Typography>Opportunities Enabled</Typography>}
        </>
      )}
    </>
  )
}
