import { Typography, Autocomplete, TextField, Stack } from "@mui/material"
import { FC, useContext, useState } from "react"
import { EditablePrompt } from "../AiChainEditModal"
import { AiChainRunnable } from "@interfaces/AiProducts/models"
import { updateAiChainRunnable } from "../../FunnelOrchestrator/aiChainRunnableCrud"
import {
  FunnelOrchestratorContext,
  editablePrompt,
} from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { isNewPrompt } from "./promptEditing"
import { EditChainRunnableInterface } from "../AiChainEditModal"
import { useFetchAiPrompts } from "@api/admin/AiProducts/aiPrompts"
import { AiChainRunnableValidator } from "@api/admin/AiProducts/validations"

export const EditChainRunnable: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const { data: aiPrompts } = useFetchAiPrompts()
  const aiChainRunnableEditable = { ...aiChainRunnable }
  const [newPromptName, setNewPromptName] = useState<string>(aiChainRunnableEditable?.aiPrompt?.name)

  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(configuration, index, aiChainRunnableEditable, segmentType)
    updateState(newConfig)
  }

  const customPromptOption: EditablePrompt = {
    ...editablePrompt,
    italicize: true,
  }

  let promptOptions: EditablePrompt[] = []
  if (aiPrompts) {
    promptOptions = ([customPromptOption, ...aiPrompts] as EditablePrompt[]).filter((prompt) => prompt.name)
  } else {
    promptOptions = [] as EditablePrompt[]
  }

  return (
    <Stack spacing={1} direction="column">
      <Typography variant="h5" component="span">
        Saved Prompts
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        options={promptOptions}
        renderOption={(props, option: EditablePrompt) => {
          return (
            <li {...props}>
              <Typography sx={option.italicize ? { fontStyle: "italic" } : {}}>
                {option.name}
                {}
                {option.italicize ? "..." : ""}
              </Typography>
            </li>
          )
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Options" />}
        value={
          !isNewPrompt(aiPrompts, aiChainRunnableEditable.aiPrompt)
            ? aiPrompts?.find((aiPrompt) => aiChainRunnableEditable.aiPrompt.text === aiPrompt.text)
            : null
        }
        onChange={(e, value) => {
          update({ ...aiChainRunnableEditable, aiPrompt: value || customPromptOption })
        }}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Name"
        variant="outlined"
        sx={{ borderRadius: 20 }}
        className={!new AiChainRunnableValidator(aiChainRunnable).validatePromptName() ? "required-input-field" : ""}
        disabled={!isNewPrompt(aiPrompts, aiChainRunnableEditable.aiPrompt)}
        value={aiChainRunnableEditable?.aiPrompt?.name}
        onChange={(e) => {
          setNewPromptName(e.target.value)
          update({
            ...aiChainRunnableEditable,
            aiPrompt: { ...aiChainRunnableEditable.aiPrompt, name: e.target.value },
          })
        }}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Text"
        variant="outlined"
        multiline
        sx={{ borderRadius: 8 }}
        className={!new AiChainRunnableValidator(aiChainRunnable).validatePromptText() ? "required-input-field" : ""}
        value={aiChainRunnableEditable?.aiPrompt?.text}
        onChange={(e) => {
          update({
            ...aiChainRunnableEditable,
            aiPrompt: {
              ...aiChainRunnableEditable.aiPrompt,
              text: e.target.value,
              name: newPromptName,
            },
          })
        }}
        rows={10}
      />
    </Stack>
  )
}
