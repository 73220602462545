import { Dispatch, createContext, FC, SetStateAction, useEffect } from "react"
import { MeetingList } from "../components/Home/MeetingList/index"
import { useLocation, useHistory } from "react-router"
import { Helmet } from "react-helmet"
import { defaultDateRange, defaultLocationFilterParams } from "../components/shared/config"
import { CampaignWizard } from "../components/shared/CampaignWizard"
import { Box, Stack } from "@mui/material"
import { MeetingSearchForm } from "../components/shared/MeetingSearchForm"
import { CampaignTilesContainer } from "../components/Home/CampaignTilesContainer"
import { FetchedSearch } from "../api/search"
import { SearchParams, SearchQueryParams } from "../interfaces/search"

export interface HomePageParams extends SearchParams {
  view: "list" | "table"
}

export enum HomePageQueryParams {
  // SearchQueryParams + "view"
  View = "view",
}

export const HomePageContext = createContext<{
  homePageParams: HomePageParams
  setHomePageParams: Dispatch<SetStateAction<HomePageParams>>
  meetingsSearchData?: FetchedSearch
}>({
  homePageParams: {
    searchTerms: [] as string[],
    mustIncludeTerms: [] as string[],
    base64FilterParams: ("" as string) || null,
    dateRange: [],
    campaignId: ("" as string) || null,
    searchDateRange: defaultDateRange,
    displayDateRange: defaultDateRange,
    page: 1,
    sortBy: "hits",
    proximity: null,
    exclude: false,
    view: "list",
    selectedPeopleIds: new Set(),
  },
  setHomePageParams: () => {
    /* placeholder for typescript */
  },
  meetingsSearchData: {
    meetings: [],
    meeting_hits: [],
    total_meeting_hits: 0,
    total_hits: 0,
    already_viewed: {},
  },
})

export const HomePage: FC = () => {
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)

  const showLogo =
    !queryParams.get(SearchQueryParams.SearchTerms) &&
    !queryParams.get(SearchQueryParams.MustIncludeTerms) &&
    !queryParams.get(SearchQueryParams.CampaignId)

  useEffect(() => {
    const simpleQuery = queryParams.get("q")
    if (simpleQuery) {
      queryParams.delete(SearchQueryParams.SearchTerms)
      simpleQuery.split(" ").forEach((term) => {
        queryParams.append(SearchQueryParams.SearchTerms, term)
      })
      queryParams.delete(SearchQueryParams.MustIncludeTerms)
      queryParams.delete(SearchQueryParams.DateRange)
      queryParams.append(SearchQueryParams.DateRange, `${defaultDateRange[0].valueOf()}`)
      queryParams.append(SearchQueryParams.DateRange, `${defaultDateRange[1].valueOf()}`)
      queryParams.set(SearchQueryParams.FilterParams, btoa(JSON.stringify(defaultLocationFilterParams)))
      queryParams.set(SearchQueryParams.Page, "1")
      queryParams.set(SearchQueryParams.SortBy, "hits")
      queryParams.delete("q")
      history.replace({ search: queryParams.toString() })
    }
  }, [locationSearchParams])

  return (
    <>
      <Helmet>
        <title>Cloverleaf.AI | Dashboard</title>
      </Helmet>
      {showLogo ? (
        <Stack
          width="40%"
          alignSelf="center"
          marginTop={1}
          marginBottom={{ xs: 0, sm: 2, md: 4 }}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Box
            width="20%"
            component="img"
            alignSelf="center"
            src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.square.clover.png"
          />
          <Box
            paddingTop="2%"
            component="img"
            src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cloverleaf.ai.logo.2.png"
          />
        </Stack>
      ) : undefined}
      <MeetingSearchForm showCampaign={!showLogo} />
      {showLogo ? <CampaignTilesContainer /> : <MeetingList />}
      <CampaignWizard />
    </>
  )
}
