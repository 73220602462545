import { Button } from "@mui/material"
import { FC, useContext, useEffect, useState } from "react"
import { OpportunityCampaignCardContext } from "./indexContext"
import { useDeleteOpportunityCampaign } from "@api/opportunityCampaigns"
import { ConfirmationModal } from "../ConfirmationModal"
import { useOpenModalKey } from "../OpenModalHook"
import { useToast } from "../ToastHook"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Error from "@mui/icons-material/Error"
import { useLocation } from "react-router-dom"

export interface ActionsParams {
  editIsLoading?: boolean
  isEditing: boolean
  saveOnClick: () => void
  reset: () => void
  setIsEditing: (isEditing: boolean) => void
  updateable: boolean
}

export const CardActions: FC<{ params: ActionsParams }> = ({ params }) => {
  const location = useLocation()
  const { editIsLoading, isEditing, saveOnClick, reset, setIsEditing, updateable } = params
  const { id: opportunityCampaignId } = useContext(OpportunityCampaignCardContext)
  const [renderDelete, setRenderDelete] = useState(false)

  const isAdminRoute = location.pathname.includes("admin") || false
  const {
    mutate: onDeleteOpportunityCampaign,
    isSuccess,
    isError,
  } = useDeleteOpportunityCampaign(opportunityCampaignId, isAdminRoute)

  const setToast = useToast()
  const callOpenModalKey = useOpenModalKey(`DELETE_OPPORTUNITY_CAMPAIGN_${opportunityCampaignId}`)

  const onOpenDeleteDialog = () => {
    onDeleteOpportunityCampaign()
    reset()
    setRenderDelete(false)
  }

  useEffect(() => {
    if (isSuccess) {
      setToast("Opportunity Campaign deleted successfully", 3000, <CheckCircleOutlineIcon />)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setToast("Error deleting opportunity campaign", 3000, <Error />, "error")
    }
  }, [isError])
  const openDeleteModal = () => {
    setRenderDelete(true)
    callOpenModalKey()
  }

  if (editIsLoading) {
    return null
  } else if (updateable) {
    return (
      <>
        {isEditing ? (
          <>
            <Button onClick={reset}>Cancel</Button>
            <Button variant="contained" onClick={saveOnClick}>
              Save
            </Button>
            <Button variant="outlined" color="error" onClick={openDeleteModal}>
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button size="small" variant="contained" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
            <Button size="small" variant="outlined" color="error" onClick={openDeleteModal}>
              Delete
            </Button>
          </>
        )}
        {renderDelete && (
          <DeleteDialog id={opportunityCampaignId} onClose={() => setRenderDelete(false)} onOpen={onOpenDeleteDialog} />
        )}
      </>
    )
  }

  return <></>
}

export const DeleteDialog: FC<{
  id: number
  onClose: () => void
  onOpen: () => void
}> = ({ id, onClose, onOpen }) => {
  return (
    <ConfirmationModal
      modalKey={`DELETE_OPPORTUNITY_CAMPAIGN_${id}`}
      onConfirm={onOpen}
      onCancel={onClose}
      title="Delete Opportunity Campaign"
      body="Are you sure you want to delete the opportunity campaign? This action is irreversible."
      disabled={false}
    />
  )
}
