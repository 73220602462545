import React, { FC, useState, useEffect, useRef } from "react"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  DialogContent,
  DialogTitle,
  Fab,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material"
import { marked } from "marked"
import DOMPurify from "dompurify"
import { useFetchCurrentUser } from "@api/users"
import {
  useFetchMeetingInsights,
  useFetchMeeting,
  useFetchMeetingInsightCreditUsagesMap,
  useCreatePersonalInsight,
} from "@api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { getPrompts } from "../../../api/prompts"
import { PersonalInsightCreation } from "./PersonalInsightCreation"
import { useToast } from "../../shared/ToastHook"
import AddIcon from "@mui/icons-material/Add"
import { InsightContent } from "../Shared/InsightContent"
import { PublicInsight } from "@src/interfaces/insight"
import { constructInsightsCollection } from "./constructInsightCollection"
import { constructInsightCards } from "./constructInsightCards"
import PrintableIframe from "@components/shared/PrintableIframe"

export const PersonalInsight: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { mutate: createPersonalInsight, isLoading: insightIsLoading } = useCreatePersonalInsight(meetingId)
  const { data: currentUser } = useFetchCurrentUser()
  const { data: fetchedMeeting } = useFetchMeeting(meetingId)
  const { data: insightCreditUsagesMap } = useFetchMeetingInsightCreditUsagesMap(meetingId)
  const { data: unfilteredInsights, refetch } = useFetchMeetingInsights(meetingId, {
    onSuccess: (insightData) => {
      if (
        insightData.some((insight) => {
          if (!insight.result) {
            const createTime = new Date(insight.created_at)
            if (Date.now() - createTime.valueOf() < 120000) {
              // insight was created less than 2 minutes ago so possibly still processing
              return true
            }
          }
          return false
        })
      ) {
        setTimeout(refetch, 10000) // check again in 10 seconds
      }
    },
  })
  const { data: prompts, isFetched: promptsIsFetched } = getPrompts("personal")
  const toast = useToast()
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [iframeReady, setIframeReady] = useState(false)
  // I just need the state above to re-render this component
  const printRef = useRef<HTMLIFrameElement>(null)
  const close = () => setIsDialogOpen(false)

  useEffect(() => {
    if (prompts && promptsIsFetched && selectedPrompts.size === 0) {
      setSelectedPrompts(new Set([prompts[0].id]))
    }
  }, [promptsIsFetched])

  const personalizedInsights = unfilteredInsights?.filter(({ is_personalized }) => is_personalized)

  let ownInsights: PublicInsight[] = []
  let othersInsights: PublicInsight[] = []
  if (personalizedInsights && currentUser) {
    const filteredInsights = personalizedInsights.filter(({ name }) => name)
    const insights = constructInsightsCollection(filteredInsights, currentUser)
    ownInsights = insights.ownInsights
    othersInsights = insights.othersInsights
  }

  let insights: PublicInsight[] = []
  if (ownInsights.length > 0 || othersInsights.length > 0) {
    insights = [...ownInsights, ...othersInsights]
  }

  let insightContent: JSX.Element = <></>
  if (othersInsights.length > 0 || ownInsights.length > 0) {
    if (insightCreditUsagesMap) {
      insightContent = (
        <>
          {ownInsights.length > 0 && <Typography variant="h6">My Insights</Typography>}
          {constructInsightCards(ownInsights, insightCreditUsagesMap)}
          {ownInsights.length > 0 && othersInsights.length > 0 && <Divider />}
          {othersInsights.length > 0 && <Typography variant="h6">All Insights</Typography>}
          {constructInsightCards(othersInsights, insightCreditUsagesMap)}
        </>
      )
    }
  } else if (personalizedInsights && personalizedInsights.length > 0) {
    const newestInsight = personalizedInsights[0]
    insightContent = <InsightContent promptResult={newestInsight.result} />
  }

  if (personalizedInsights && personalizedInsights.length > 0) {
    return (
      <>
        <Stack spacing={1}>
          <PrintableIframe ref={printRef} onload={() => setIframeReady(true)} meeting={fetchedMeeting?.meeting}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  marked.parse(
                    insights.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n---\n\n") || "",
                  ) as string,
                ),
              }}
            />
          </PrintableIframe>
          <Stack direction="row">
            <Stack direction="row" spacing={1} flex="1">
              <Button variant="contained" onClick={printRef.current?.contentWindow?.print} disabled={!iframeReady}>
                Full Insight PDF
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    insights.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n"),
                  )
                  toast("Full Insight Copied to clipboard")
                }}
              >
                Copy Full Insight to Clipboard
              </Button>
            </Stack>
            {insightIsLoading ? (
              <CircularProgress />
            ) : (
              <Fab size="small" color="primary" onClick={() => setIsDialogOpen(true)}>
                <AddIcon />
              </Fab>
            )}
          </Stack>
          {insightContent}
        </Stack>
        <Dialog open={isDialogOpen} onClose={close}>
          <DialogTitle>Create Personal Insight</DialogTitle>
          <DialogContent>
            <PersonalInsightCreation
              onConfirmCreatePrompt={createPersonalInsight}
              onSuccess={() => {
                close()
              }}
              currentUsersInsights={ownInsights}
              isLoading={insightIsLoading}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  } else {
    return (
      <Card>
        <CardContent>
          <PersonalInsightCreation onConfirmCreatePrompt={createPersonalInsight} isLoading={insightIsLoading} />
        </CardContent>
      </Card>
    )
  }
}
