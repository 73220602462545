import { FilterParams } from "@src/components/Home/interface"
import { OpportunityCampaignsUsers } from "@interfaces/opportunityCampaignsUsers"
import { createContext, Dispatch, SetStateAction } from "react"
import { EmailCadence } from "@interfaces/campaign"
import { User } from "@interfaces/user"

export const OpportunityCampaignCardContext = createContext<{
  emailCadences?: OpportunityCampaignsUsers[]
  filterParams: FilterParams
  id: number
  onEmailCadencesUpdate?: (newUserEmailCadenceMap: Map<number, EmailCadence>) => void
  ownEmailCadence?: string
  setEmailCadencesAction: Dispatch<SetStateAction<() => void>>
  setFilterParams: Dispatch<SetStateAction<FilterParams>>
  setOwnEmailCadenceAction: Dispatch<SetStateAction<() => void>>
  userMapById?: Map<number, User>
}>({
  filterParams: {
    organizations: [],
    cities: [],
    counties: [],
    states: [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  },
  id: 0,
  setEmailCadencesAction: () => null,
  setFilterParams: () => null,
  setOwnEmailCadenceAction: () => null,
})
