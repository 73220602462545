import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import { getInsights } from "@api/insights"
import { FC, useContext, useMemo } from "react"
import { InsightsContainerContext } from "@src/containers/InsightContainers/InsightsContainer"
import { publicInsightColumns } from "@src/dataGridSchema/insights/insightColumns"
import { publicInsightRows } from "@src/dataGridSchema/insights/insightRows"

export const InsightsDataGrid: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(InsightsContainerContext)

  let paginationModel = {
    pageSize: urlParamState.count,
    page: urlParamState.page,
  }

  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const { data, isLoading } = getInsights(params)

  const columns = publicInsightColumns
  const rows = useMemo(() => {
    if (data?.briefings) {
      return data.briefings.map((insight) => publicInsightRows(insight))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (mode: GridPaginationModel) => {
    setPaginationModel(mode)
    setUrlParamState({
      ...urlParamState,
      page: mode.page,
    })
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setUrlParamState({
      ...urlParamState,
      sort: sortModel[0]?.field,
      order: sortModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableRowSelectionOnClick
      loading={isLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      rowHeight={100}
      sortingMode="server"
    />
  )
}
