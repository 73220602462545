import { FC, useState } from "react"
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Stack } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject } from "../../../api/opportunities"
import { useCreateOpportunityCampaign } from "@src/api/opportunityCampaigns"
import { ConfirmationModal } from "@src/components/shared/ConfirmationModal"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { useToast } from "../../shared/ToastHook"
import { EmailCadence } from "@src/interfaces/campaign"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

const SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY = "SAVE_NEW_OPPORTUNITY_CAMPAIGN"

export const SaveCampaignButton: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const urlParamState = urlParamsToOpportunityQueryObject(params)

  const toast = useToast()

  const { mutate: createOpportunityCampaign } = useCreateOpportunityCampaign(() => toast("Campaign successfully saved"))

  const [campaignName, setCampaignName] = useState("")
  const [campaignEmailCadence, setCampaignEmailCadence] = useState<EmailCadence>("Daily")
  const openSaveNewOpportunityCampaignModal = useOpenModalKey(SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY)

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Opportunities Create Campaign", {
      campaignName,
      campaignEmailCadence,
      searchTerms: urlParamState.terms,
      anchorTerms: urlParamState.anchor_terms,
      industries: urlParamState.industry,
      states: urlParamState.states,
      counties: urlParamState.counties,
      cities: urlParamState.cities,
      channels: urlParamState.channel_types,
    })
  }

  return (
    <>
      <Button variant="outlined" onClick={openSaveNewOpportunityCampaignModal}>
        Save New Campaign
      </Button>
      <ConfirmationModal
        title="Save New Opportunity Campaign"
        body={
          <Stack spacing={1} marginTop={1}>
            <TextField
              label="Campaign Name"
              value={campaignName}
              onChange={(event) => setCampaignName(event.target.value)}
              sx={{ width: "64ch", marginTop: 1 }}
              required
            />
            <FormControl>
              <InputLabel>Email Cadence</InputLabel>
              <Select
                value={campaignEmailCadence}
                label="Email Cadence"
                onChange={(event) => {
                  setCampaignEmailCadence(event.target.value as EmailCadence)
                }}
              >
                <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        }
        modalKey={SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY}
        onConfirm={() => {
          callVitallyTrack()
          createOpportunityCampaign({ name: campaignName, data: urlParamState, email_cadence: campaignEmailCadence })
          setCampaignName("")
          setCampaignEmailCadence("Daily")
        }}
        onCancel={() => setCampaignName("")}
      />
    </>
  )
}
