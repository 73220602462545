import { useQuery } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { MetadataResponse } from "@interfaces/AiProducts/responseObjectInterfaces"
import { FetchedCampaignResponse } from "@interfaces/Responses/campaigns"
import { serializeCampaignResponse } from "@interfaces/Serializers/Response/campaigns"

export interface FetchUserCampaignNotificationsResponse extends MetadataResponse {
  subscriptions: FetchedCampaignResponse[]
}

const resourcePath = "admin/users/"

export const useFetchUserCampaignNotifications = (
  userId: number | undefined,
  params?: CommonUrlParams,
  enabled?: boolean,
) => {
  const axiosInstance = useAuthAxios()
  const uri = `${resourcePath}${userId}/users-campaigns-notifications`

  return useQuery(
    ["user-campaign-notifications", userId],
    async () => {
      const response = await (await axiosInstance).get<FetchUserCampaignNotificationsResponse>(uri)

      return response?.data.subscriptions.map((campaign: FetchedCampaignResponse) =>
        serializeCampaignResponse(campaign),
      )
    },
    {
      enabled: enabled && !!userId,
    },
  )
}
