import { FC, useEffect } from "react"
import { Typography, Button, Stack } from "@mui/material"
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid"
import { BaseTranscriptionJob, useFetchTranscriptionJobs } from "@src/api/admin/transcription_jobs"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import StartUATModal, { startTranscriptionEngineUatModalKey } from "./startModal"

const columns: GridColDef<BaseTranscriptionJob>[] = [
  { field: "id", headerName: "ID" },
  {
    field: "meeting_id",
    headerName: "Meeting Id",
  },
  {
    field: "transcoded_at",
    headerName: "Transcoded At",
  },
  {
    field: "diarized_at",
    headerName: "Diarized At",
  },
  {
    field: "transcribed_at",
    headerName: "Transcribed At",
  },
  {
    field: "indexed_at",
    headerName: "Indexed At",
  },
  {
    field: "finished_at",
    headerName: "Finished At",
  },
]

const selectedForUatParams = new URLSearchParams({ selected_for_uat: "true" })

const TranscriptionEngineUat: FC = () => {
  const { data: transcriptionJobs, refetch } = useFetchTranscriptionJobs(selectedForUatParams)
  const open = useOpenModalKey(startTranscriptionEngineUatModalKey)
  const apiRef = useGridApiRef()

  useEffect(() => {
    console.log(apiRef.current?.autosizeColumns())
  }, [transcriptionJobs])

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Transcription Engine UAT</Typography>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={open}>
          Start UAT
        </Button>
        <Button variant="outlined" onClick={() => refetch()} sx={{ alignSelf: "flex-start" }}>
          Refetch Status
        </Button>
      </Stack>
      <DataGrid apiRef={apiRef} rows={transcriptionJobs} columns={columns} autosizeOnMount />
      <StartUATModal />
    </Stack>
  )
}

export default TranscriptionEngineUat
