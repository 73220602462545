import { Autocomplete, Box, Skeleton, Stack, TextField } from "@mui/material"
import { ChipField } from "../ChipField"
import { Geographies } from "./Geographies"
import { useGetFeatureFlagByName } from "@src/api/feature_flags"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { useFetchIndustryIdsMap } from "@src/api/industries"
import { Dispatch, FC, SetStateAction } from "react"

interface EditingFormProps {
  termsSet: Set<string>
  setTermsSet: React.Dispatch<React.SetStateAction<Set<string>>>
  anchorTermsSet: Set<string>
  setAnchorTermsSet: React.Dispatch<React.SetStateAction<Set<string>>>
  newNaicsCodes: string[]
  setNewNaicsCodes: React.Dispatch<React.SetStateAction<string[]>>
  newIndustries: string[]
  setNewIndustries: React.Dispatch<React.SetStateAction<string[]>>
}

export const EditingForm: FC<EditingFormProps> = ({
  termsSet,
  setTermsSet,
  anchorTermsSet,
  setAnchorTermsSet,
  newNaicsCodes,
  setNewNaicsCodes,
  newIndustries,
  setNewIndustries,
}) => {
  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )
  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()

  return (
    <Stack spacing={1}>
      <ChipField terms={termsSet} setTerms={setTermsSet} placeholder="At least one of these terms:" />
      <ChipField terms={anchorTermsSet} setTerms={setAnchorTermsSet} placeholder="Anchor terms:" />
      {/* REMOVE THIS ONCE WE REMOVE NAICS CODES OPP SEARCH FEATURE FLAG */}
      {showNaicsCodesFilterLoading ? (
        <Skeleton variant="rectangular" width="100%" height={56} />
      ) : showNaicsCodesFilter === undefined || showNaicsCodesFilter.value === "true" ? (
        <Autocomplete
          multiple
          value={newNaicsCodes}
          onChange={(_, newValue) => setNewNaicsCodes(newValue)}
          getOptionLabel={(option) => {
            const naicsCode = naicsCodesMap?.get(option)
            return `${naicsCode?.code}: ${naicsCode?.title}`
          }}
          loading={showNaicsCodesFilterLoading}
          options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
          renderInput={(params) => <TextField {...params} label="NAICS Code" />}
          renderOption={(props, option) => {
            const naicsCode = naicsCodesMap?.get(option)
            return (
              <Box component="li" {...props} key={option}>
                {`${naicsCode?.code}: ${naicsCode?.title}`}
              </Box>
            )
          }}
        />
      ) : (
        <Autocomplete
          multiple
          value={newIndustries}
          onChange={(_, newValue) => setNewIndustries(newValue)}
          getOptionLabel={(option) => {
            const industry = industriesDataMap?.get(option)
            return industry?.name || ""
          }}
          loading={industriesLoading}
          options={industriesDataMap ? Array.from(industriesDataMap.keys()) : []}
          renderInput={(params) => <TextField {...params} label="Industry" />}
          renderOption={(props, option) => {
            const industry = industriesDataMap?.get(option)
            return (
              <Box component="li" {...props} key={option}>
                {industry?.name}
              </Box>
            )
          }}
        />
      )}
      <Geographies />
    </Stack>
  )
}
