import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Skeleton,
} from "@mui/material"
import { FC, createContext, useState, Dispatch, SetStateAction, useEffect, useContext } from "react"
import { ClipShareBookmarkView } from "../../../interfaces/clipShare"
import { useModalKey, useCloseModalKey, useData } from "../OpenModalHook"
import { CopyFormButton } from "./CopyFormButton"
import { CopyFormInput } from "./CopyFormInput"
import CloseIcon from "@mui/icons-material/Close"
import { useToast } from "../ToastHook"
import { SalesforceButton } from "./SalesforceButton"
import { SalesforceOptions } from "./SalesforceOptions"
import { useUpdateCreatedClipShare } from "../../../api/clip_shares"
import { EmailButton } from "./EmailButton"
import { SlackButton } from "./SlackButton"
import { TeamsButton } from "./TeamsButton"
import { MeetingContext } from "../../MeetingDetail/Shared/Context"
import { getShareLink } from "./generateUrls"
import { useIsSuperAdmin } from "@api/users"

export const shareModalKey = "SHARE_MODAL_KEY"

export const ShareOptionsContext = createContext<Dispatch<SetStateAction<string>>>(() => {
  /* placeholder for typescript */
})

export type shareTypes = "share" | "promotional"

export interface ShareModalData extends ClipShareBookmarkView {
  promoAllowed?: boolean // Decides if we present the option to share a promotional clip if the user is a super admin
  onShareTypeSelected?: (shareType: shareTypes) => void // Callback to trigger the correct creation type
}

export const ShareModal: FC = () => {
  const [activeShare, setActiveShare] = useState("")
  const open = useModalKey(shareModalKey)
  const closeModal = useCloseModalKey(shareModalKey)
  const clipData: ShareModalData | undefined = useData()
  const { mutate: updateClip } = useUpdateCreatedClipShare()
  const { data: isSuperAdmin, isLoading } = useIsSuperAdmin()
  const setToast = useToast()
  const theme = useTheme()
  const pageIsSmall = useMediaQuery(theme.breakpoints.down("md"))
  const head = document.querySelector("head")
  const script = document.createElement("script")
  const [needsToChooseShareType, setNeedsToChooseShareType] = useState(isSuperAdmin && clipData?.promoAllowed)
  const { meeting } = useContext(MeetingContext)
  const src = "https://teams.microsoft.com/share/launcher.js"

  useEffect(() => {
    if (clipData?.id) {
      updateClip(clipData)
    }
  }, [clipData])

  script.setAttribute("src", src)
  if (head) {
    head.appendChild(script)
  }

  useEffect(() => {
    if (isLoading || isSuperAdmin === undefined || !clipData || clipData?.id) {
      // If clip data id exists then we know the clip has been created already
      // and we don't need to prompt the user to choose a share type
      return
    }
    setNeedsToChooseShareType(isSuperAdmin && clipData.promoAllowed)
  }, [isLoading, isSuperAdmin, clipData])

  useEffect(() => {
    if (!isLoading && !isSuperAdmin && clipData && !clipData?.id) {
      // If the user is not a super admin and the clip data is available but not yet created
      setNeedsToChooseShareType(false)
      if (clipData.onShareTypeSelected) {
        clipData.onShareTypeSelected("share")
      }
    }
  }, [isLoading, isSuperAdmin, clipData])

  const updateShareType = (type: shareTypes) => {
    setNeedsToChooseShareType(false)
    clipData?.onShareTypeSelected && clipData?.onShareTypeSelected(type)
  }

  const copyLink = () => {
    if (!clipData?.id) {
      return
    }
    const shareLink = getShareLink(clipData, meeting?.id)
    if (shareLink) {
      const cb = navigator.clipboard
      cb.writeText(shareLink).then(() => {
        setToast("Clip Copied", 1750)
        closeModal()
      })
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <ShareOptionsContext.Provider value={setActiveShare}>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#F9F9F9",
            minWidth: "360px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h1">Share</Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {needsToChooseShareType ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Box sx={{ margin: 1, display: "flex", flexGrow: 1 }}>
                <Button fullWidth size="large" onClick={() => updateShareType("share")} variant="outlined">
                  Share
                </Button>
              </Box>
              <Box sx={{ margin: 1, display: "flex", flexGrow: 1 }}>
                <Button fullWidth size="large" onClick={() => updateShareType("promotional")} variant="outlined">
                  Promotional
                </Button>
              </Box>
            </Box>
          ) : !clipData?.id ? (
            <Skeleton variant="rectangular" height={50} />
          ) : (
            <Stack direction="column" spacing={2} alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <EmailButton clipShare={clipData} />
                <SalesforceButton />
                <SlackButton clipShare={clipData} />
                <TeamsButton clipShare={clipData} />
              </Stack>
              {activeShare === "salesforce" && <SalesforceOptions clipData={clipData} />}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: "white", borderRadius: 10, paddingBottom: 1, paddingLeft: { xs: 2, sm: 2 } }}
              >
                <Grid item sx={{ overflow: "hidden" }}>
                  <CopyFormInput clipData={clipData} />
                </Grid>
                {!pageIsSmall && (
                  <Grid item>
                    <CopyFormButton copy={copyLink} />
                  </Grid>
                )}
              </Grid>
              {pageIsSmall && <CopyFormButton copy={copyLink} />}
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </ShareOptionsContext.Provider>
  )
}
