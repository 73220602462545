import React, { createContext, useState, ReactNode } from "react"
import { UsersAiFeature } from "@interfaces/AiProducts/models"

interface UserAiSettings {
  userAiFeatures: UsersAiFeature[]
  selectedUserAiFeature: Partial<UsersAiFeature>
}

interface UserSettingsContextProps {
  userSettings: UserAiSettings
  setUserSettings: (newSettings: UserAiSettings) => void
}

export const defaultUserAiSettings: UserAiSettings = {
  userAiFeatures: [],
  selectedUserAiFeature: {} as UsersAiFeature,
}

export const UsersAiSettingsContext = createContext<UserSettingsContextProps>({
  userSettings: defaultUserAiSettings,
  setUserSettings: () => {},
})

export const UsersSettingsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userSettings, setUserSettings] = useState<UserAiSettings>(defaultUserAiSettings)

  return (
    <UsersAiSettingsContext.Provider value={{ userSettings, setUserSettings }}>
      {children}
    </UsersAiSettingsContext.Provider>
  )
}
