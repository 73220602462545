import { RubyEnum } from "@api/interfaces"

export enum OrchestratorType {
  FUNNEL = "funnel",
}

export enum AiChainRunnableSegmentTypes {
  INITIAL = "initial",
  MIDDLE = "middle",
  TAIL = "tail",
  TOTAL = "total", // if first segment == last segment
}

export enum PromptInputTypes {
  MEETING_IDS = "meeting_ids",
  CAMPAIGN_ID = "campaign_id",
  CHAIN_OUTPUT = "chain_output",
}

export enum AiProductType {
  INSIGHTS = "insights",
}

export enum AiFeatureStatus {
  DRAFT = "draft",
  LIVE = "live",
}

export interface AiPrompt {
  id?: number
  name: string
  text: string
}

export interface AiModel {
  id?: number
  name: string
}

export interface AiOutputFormatter {
  id?: number
  name: string
}

export interface AiChainRunnable {
  id?: number
  aiPrompt: AiPrompt
  aiModel: AiModel
  aiFeatureId?: number
  aiModelId?: number
  aiPromptId?: number
  aiOutputFormatterId?: number
  aiOutputFormatter: AiOutputFormatter
  segmentType: AiChainRunnableSegmentTypes
  ordinal: number
  inputType?: PromptInputTypes
}
// promptInput?: PromptInputData // should not get saved

export interface UsersAiFeature {
  id?: number
  userId: number
  aiFeatureId: number
  inputSettings: string[]
}

export interface PromptInputData {
  //prompting input data
  values: string[]
  inputType: PromptInputTypes
}

export interface AiFeature {
  id?: number
  inputType?: PromptInputTypes
  featureName?: string
  productType?: AiProductType
  featureStatus?: AiFeatureStatus
  orchestratorType?: OrchestratorType
  aiChainRunnablesId?: number
  createdAt?: string
  updatedAt?: string

  aiChainRunnables?: AiChainRunnable[]
}

export interface AiFeaturesMetadata {
  status: RubyEnum<AiFeatureStatus>[]
  productType: RubyEnum<AiProductType>[]
  orchestratorType: RubyEnum<OrchestratorType>[]
}
