import { useMutation, UseMutationOptions, useQuery } from "react-query"
import { useAuthAxios } from "@api/axiosInstance"
import { AdminContact, CreateContact, UpdateContact } from "@interfaces/contact"
import { generateMinnieURL } from "@functions/generateURL"
import { AxiosError } from "axios"
import { ApiError } from "@interfaces/apiError"
import { ApiErrorResponse } from "@api/api"

const prefix = "admin/contacts"
const minniePrefix = generateMinnieURL(`minnie/${prefix}`)

export const useCreateContacts = (
  options?: Omit<UseMutationOptions<AdminContact[], AxiosError<ApiError>, CreateContact[]>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useMutation<AdminContact[], AxiosError<ApiError>, CreateContact[]>(
    ["create-contacts"],
    async (contacts: CreateContact[]) => {
      const response = await (
        await axiosInstance
      ).post<AdminContact[]>(prefix, {
        contacts: contacts,
      })

      return response.data
    },
    {
      ...options,
    },
  )
}

export const useUpdateContact = (
  options?: Omit<UseMutationOptions<unknown, AxiosError<ApiErrorResponse>, UpdateContact>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useMutation<AdminContact, AxiosError<ApiErrorResponse>, UpdateContact>(
    ["update-contact"],
    async (contact: UpdateContact) => {
      const response = await (await axiosInstance).put<AdminContact>(`${prefix}/${contact.id}`, contact)

      return response.data
    },
    {
      ...options,
    },
  )
}

export const useDeleteContact = (
  options?: Omit<UseMutationOptions<unknown, AxiosError<ApiError>, number>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useMutation<AdminContact, AxiosError<ApiError>, number>(
    ["delete-contact"],
    async (id: number) => {
      const response = await (await axiosInstance).delete<AdminContact>(`${prefix}/${id}`)

      return response.data
    },
    {
      ...options,
    },
  )
}

export const useFetchContacts = (organizationId?: number) => {
  const axiosInstance = useAuthAxios()

  const params: URLSearchParams = new URLSearchParams()
  if (organizationId) {
    params.append("organization_id", organizationId.toString())
  }
  return useQuery(
    [
      "contacts",
      {
        organization_id: organizationId,
      },
    ],
    async () => {
      const response = await (await axiosInstance).get<AdminContact[]>(prefix + "?" + params.toString())

      return response.data
    },
  )
}

interface RunAIContactDiscoveryProps {
  dry_run?: boolean
  model?: string
  use_fine_tuned_model?: boolean
  max_token_size_per_chunk?: number
  filter_existing_contacts?: boolean
  temperature?: number
}

interface SingularRunAIContactDiscoveryProps extends RunAIContactDiscoveryProps {
  organization_id?: number
}

export const useRunAIContactDiscovery = ({
  organization_id,
  dry_run,
  model,
  use_fine_tuned_model,
  max_token_size_per_chunk,
  filter_existing_contacts,
  temperature,
}: SingularRunAIContactDiscoveryProps) => {
  const axiosInstance = useAuthAxios()

  return useMutation<AdminContact[], AxiosError<ApiError>>(
    [
      "run-ai-contact-discovery",
      {
        organization_id: organization_id,
        dry_run,
        model,
        use_fine_tuned_model,
        max_token_size_per_chunk,
        filter_existing_contacts,
        temperature,
      },
    ],
    async () => {
      const response = await (
        await axiosInstance
      ).post<AdminContact[]>(`${minniePrefix}/discover`, {
        organization_id: organization_id,
        dry_run,
        model,
        use_fine_tuned_model,
        max_token_size_per_chunk,
        filter_existing_contacts,
        temperature,
      })

      return response.data
    },
  )
}

export interface BulkRunAIContactDiscovery extends RunAIContactDiscoveryProps {
  organization_ids: number[]
}

export const useBulkRunAIContactDiscovery = ({
  organization_ids,
  dry_run,
  model,
  use_fine_tuned_model,
  max_token_size_per_chunk,
  filter_existing_contacts,
  temperature,
}: BulkRunAIContactDiscovery) => {
  const axiosInstance = useAuthAxios()

  return useMutation<AdminContact[], AxiosError<ApiError>>(
    [
      "run-ai-contact-discovery",
      {
        organization_ids,
        dry_run,
        model,
        use_fine_tuned_model,
        max_token_size_per_chunk,
        filter_existing_contacts,
        temperature,
      },
    ],
    async () => {
      const response = await (
        await axiosInstance
      ).post<AdminContact[]>(`${minniePrefix}/discover/bulk`, {
        organization_ids,
        dry_run,
        model,
        use_fine_tuned_model,
        max_token_size_per_chunk,
        filter_existing_contacts,
        temperature,
      })

      return response.data
    },
  )
}
