import { FetchedMeeting } from "@api/meetings"
import { meetingLocationString } from "@components/shared/config"
import { AdminInsight, PublicInsight } from "@interfaces/insight"

export const publicInsightRows = (insight: PublicInsight) => {
  return {
    id: insight.id,
    meeting: {
      link: `/meetings/${insight.meeting.id}#${insight.is_personalized ? "personal-briefing" : "briefing"}`,
      title: insight.meeting.title,
    },
    date: insight.meeting.published_at,
    channel: {
      link: `/channel-details/${insight.meeting.organization_id}`,
      name: insight.meeting.organization_name,
    },
    location: meetingLocationString(insight.meeting as FetchedMeeting),
    type: insight.name,
    owner: insight.user_email,
    created_at: insight.created_at,
    actions: insight,
  }
}

export const adminInsightRows = (insight: AdminInsight) => {
  return {
    ...publicInsightRows(insight),
    fine_tuning: insight.use_fine_tuning,
    generated_by_model: insight.generated_by_model,
  }
}
