import { AiFeature, PromptInputTypes } from "@interfaces/AiProducts/models"
import { UpsertAiFeatureRequest } from "@interfaces/AiProducts/requestObjectInterfaces"

export const serializeUpsertFeatureRequest = (feature: AiFeature): UpsertAiFeatureRequest => {
  if (!feature.featureName || !feature.productType || !feature.orchestratorType || !feature.featureStatus)
    throw new Error("Missing required fields")
  return {
    id: feature?.id,
    feature_name: feature.featureName,
    product_type: feature.productType,
    orchestrator_type: feature.orchestratorType,
    feature_status: feature.featureStatus,
    input_type: feature?.inputType || PromptInputTypes.MEETING_IDS,
  }
}
