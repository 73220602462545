import { FC, useState, useRef } from "react"
import { Button, Card, CardContent, Skeleton, Typography, Link, CircularProgress } from "@mui/material"
import { getMeetingInsight, getMeetingsInsightCost, useCreateGeneralInsight } from "../../../api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useFetchCurrentUser } from "../../../api/users"
import { useIsInsightsEnabled } from "../../../api/users"
import { CreditSpentConfirmation } from "../../shared/CreditSpentConfirmation"
import { useToast } from "../../shared/ToastHook"
import { InsightContent } from "../Shared/InsightContent"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { Stack } from "rsuite"
import { vitallyTrack } from "@config/vitally"

const generalInsightModalKey = "GENERAL_INSIGHT_COST_MODAL"

const InsightWrapper: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const createTime = useRef(0) // I use useRef here instead of useState because this isn't used for rendering
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data, refetch, isLoading } = getMeetingInsight(meetingId, (generalInsightText) => {
    if (!generalInsightText && Date.now() - createTime.current < 120000) {
      // insight was created less than 2 minutes ago so possibly still processing
      setTimeout(refetch, 10000) // check again in 10 seconds
    } else {
      setIsGenerating(false)
    }
  })
  const openModal = useOpenModalKey(generalInsightModalKey)
  const toast = useToast()
  const { data: insightCost } = getMeetingsInsightCost(parseInt(meetingId))
  const { mutate: createGeneralInsight } = useCreateGeneralInsight(meetingId)
  const [isGenerating, setIsGenerating] = useState(false)

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Meeting Create General Insight", { meetingId: meetingId })
  }

  if (data) {
    return <InsightContent promptResult={data} />
  }
  return (
    <>
      <Typography marginBottom={1}>Click the button below to create a generalized meeting insight.</Typography>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          startIcon={<PostAddIcon />}
          onClick={openModal}
          disabled={isGenerating || isLoading}
        >
          Create General Insight
        </Button>
        {isGenerating && (
          <>
            <Typography>Insight Processing</Typography>
            <CircularProgress />
          </>
        )}
      </Stack>
      {insightCost?.cost !== undefined && (
        <CreditSpentConfirmation
          modalKey={generalInsightModalKey}
          onConfirm={() => {
            callVitallyTrack()
            createGeneralInsight()
            toast("A General Insight is being created. We will email you when it's finished")
            createTime.current = Date.now()
            setIsGenerating(true)
            refetch()
          }}
          cost={insightCost.cost}
        />
      )}
    </>
  )
}

export const GeneralInsightCard: FC = () => {
  const { data: insightsEnabled, isFetched } = useIsInsightsEnabled()

  return (
    <Card>
      <CardContent>
        {!isFetched ? (
          <Skeleton />
        ) : insightsEnabled ? (
          <InsightWrapper />
        ) : (
          <Typography>
            {"Contact your Cloverleaf AI sales rep or "}
            <Link href="mailto:support@cloverleaf.ai">support@cloverleaf.ai</Link>
            {" to get Insights today!"}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
