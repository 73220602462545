import { FC } from "react"
import { Button, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useAdminFetchOpportunityCampaigns } from "@src/api/admin/userOrganizations/opportunityCampaigns"
import { useAdminFetchOpportunityCampaignsUsersMap } from "@src/api/admin/userOrganizations/opportunityCampaignsUsers"
import { useFetchUserOrganizationUsersMapById } from "@src/api/admin/user_organizations"
import {
  useAdminUpdateOpportunityCampaigns,
  useUpdateOpportunityCampaignsRelations,
} from "@src/api/admin/opportunityCampaigns"
import { OpportunityCampaignCard } from "@src/components/shared/OpportunityCampaignCard"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { CreateOpportunityCampaignModal, CREATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY } from "./CreateOpportunityCampaignModal"
import { useOpenModalKey } from "@src/components/shared/OpenModalHook"
import AddIcon from "@mui/icons-material/Add"
import { EmailCadence } from "@src/interfaces/campaign"
import { OpportunityCampaignCardSkeleton } from "@components/shared/OpportunityCampaignCard/Skeleton"

const OpportunityCampaignCardWithUpdate: FC<{ opportunityCampaign: OpportunityCampaign }> = ({
  opportunityCampaign,
}) => {
  const { mutate: adminUpdateOpportunityCampaign } = useAdminUpdateOpportunityCampaigns(opportunityCampaign.id)
  const { mutate: updateOpportunityCampaignsRelations } = useUpdateOpportunityCampaignsRelations(opportunityCampaign.id)
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: opportunityCampaignsUsersMap } = useAdminFetchOpportunityCampaignsUsersMap(userOrganizationId)
  const { data: userOrganizationUsersMapById } = useFetchUserOrganizationUsersMapById(userOrganizationId)
  return (
    <OpportunityCampaignCard
      key={opportunityCampaign.id}
      opportunityCampaign={opportunityCampaign}
      emailCadences={opportunityCampaignsUsersMap?.get(opportunityCampaign.id)}
      onEmailCadencesUpdate={(map) => {
        const opportunity_campaign_users_data: {
          user_id: number
          email_cadence: EmailCadence
        }[] = []
        map.forEach((value, key) => {
          opportunity_campaign_users_data.push({ user_id: key, email_cadence: value })
        })
        updateOpportunityCampaignsRelations({ opportunity_campaign_users_data })
      }}
      userMapById={userOrganizationUsersMapById}
      onUpdate={adminUpdateOpportunityCampaign}
    />
  )
}

export const OpportunityCampaigns: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: opportunityCampaigns } = useAdminFetchOpportunityCampaigns(userOrganizationId)
  const open = useOpenModalKey(CREATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY)
  return (
    <Stack gap={1} marginTop={1}>
      <Button variant="contained" sx={{ alignSelf: "flex-start" }} startIcon={<AddIcon />} onClick={open}>
        Create Opportunity Campaign
      </Button>
      {opportunityCampaigns ? (
        opportunityCampaigns?.map((opportunityCampaign) => (
          <OpportunityCampaignCardWithUpdate opportunityCampaign={opportunityCampaign} key={opportunityCampaign.id} />
        ))
      ) : (
        <>
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
        </>
      )}
      <CreateOpportunityCampaignModal />
    </Stack>
  )
}
