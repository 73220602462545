import { FC } from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
  Stack,
  Chip,
  Skeleton,
} from "@mui/material"
import { ChevronRight } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { formatDate } from "../../shared/config"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { BaseMeeting, Meeting } from "@interfaces/meeting"
import { locationText, unpackGeo } from "../MeetingBasicInfo"
import { LeadMeeting } from "@src/api/promotions/leads"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

export const MeetingItemBase: FC<{
  meeting: Meeting | BaseMeeting | LeadMeeting
  terms?: { term: string; count: number }[]
  link: string
  sourcePage?: string
  blur?: boolean | undefined
  hasViewed?: boolean | undefined
  authenticated?: boolean | undefined
}> = ({ meeting, terms, link, sourcePage, blur = false, children, hasViewed = false, authenticated = true }) => {
  const { data: organizationData } = useFetchOrganizationsMap(authenticated, [meeting.organization_id])
  let locationElement = <Skeleton width="32ch" />
  const organization = organizationData?.get(meeting.organization_id)
  if (organization) {
    const { stateName, countyName, cityName } = unpackGeo(organization)
    locationElement = (
      <>
        <strong>{organizationData?.get(meeting.organization_id)?.name}</strong>
        {` in ${locationText({ state: stateName, county: countyName, city: cityName })}`}
      </>
    )
  }

  let totalHits = 0
  if (terms) {
    terms.forEach(({ count }) => (totalHits += count))
  }

  const cardSx = { marginY: 1, filter: "blur(0px)", backgroundColor: "white" }
  if (blur) {
    cardSx.filter = "blur(10px)"
  }
  if (hasViewed) {
    cardSx.backgroundColor = "#8c929617"
  }

  let onLinkClick = null as null | (() => void)

  if (sourcePage && ["home", "persons"].includes(sourcePage)) {
    const { data: currentUser } = useFetchCurrentUser()
    const callVitallyTrack = () => {
      vitallyTrack(
        currentUser,
        "Meeting Link Clicked",
        {
          meetingId: meeting.id,
          meetingTitle: meeting.title,
          organizationName: organizationData?.get(meeting.organization_id)?.name,
          meetingPublishedAt: meeting.published_at,
        },
        true,
      )
    }

    onLinkClick = callVitallyTrack
  }

  return (
    <Card className="meeting" sx={{ ...cardSx }}>
      <CardContent>
        <Stack spacing={1}>
          <CardActionArea
            component={Link}
            to={link}
            onClick={(event: any) => {
              if (onLinkClick) {
                onLinkClick()
              }
              if (blur) {
                event.preventDefault()
              }
            }}
            sx={{
              "&:hover": {
                textDecoration: "none",
                color: "inherit",
              },
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={7}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h2" fontSize={{ xs: "1.2rem", sm: "1.625rem" }}>
                    {meeting.title}
                  </Typography>
                  {hasViewed && (
                    <Chip
                      sx={{ fontWeight: 600, marginTop: "1px !important", backgroundColor: "white" }}
                      color="primary"
                      label="VIEWED"
                      size="medium"
                      variant="outlined"
                    />
                  )}
                </Stack>
                <Typography variant="body2">{`Published: ${formatDate(meeting.published_at)}`}</Typography>
              </Grid>
              {terms !== undefined && (
                <Grid item sm={5} display={{ xs: "none", sm: "flex" }}>
                  <Grid container direction="column">
                    <Grid item display="flex" justifyContent="flex-end">
                      <Typography variant="h3">
                        {`Matches: ${totalHits}`}
                        <IconButton>
                          <ChevronRight />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography variant="body1">{locationElement}</Typography>
              {hasViewed && (
                <Chip sx={{ fontWeight: 500, display: "none" }} variant="filled" label="VIEWED" size="small" />
              )}
              {terms !== undefined && (
                <Typography variant="h3" display={{ xs: "inline", sm: "none" }} fontSize="1rem">
                  {`Matches: ${totalHits}`}
                </Typography>
              )}
            </Stack>
          </CardActionArea>
          <Divider />
          <Stack direction="row" flexWrap="wrap" gap={0.5}>
            {terms?.map(({ term, count }) => (
              <Chip key={term} label={term} icon={<Chip label={count} size="small" />} />
            ))}
          </Stack>
          <Stack direction="row">
            <Box
              component="img"
              width={200}
              src={meeting.thumbnail_url || "/no-video.png"}
              alt="meeting-thumbnail"
              alignSelf="flex-start"
              display={{ xs: "none", sm: "flex" }}
            />
            {children}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
