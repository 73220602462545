import { FC } from "react"
import { Stack } from "@mui/material"
import { useFetchOpportunityCampaigns, useUpdateOpportunityCampaigns } from "@src/api/opportunityCampaigns"
import { OpportunityCampaignCard } from "@src/components/shared/OpportunityCampaignCard"
import { useFetchOpportunityCampaignsUsersMap } from "@src/api/opportunityCampaignsUsers"
import { useToast } from "@src/components/shared/ToastHook"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { OpportunityCampaignCardSkeleton } from "@components/shared/OpportunityCampaignCard/Skeleton"

const OpportunityCampaignWithUpdate: FC<{ opportunityCampaign: OpportunityCampaign }> = ({ opportunityCampaign }) => {
  const toast = useToast()
  const { mutate: updateOpportunityCampaign } = useUpdateOpportunityCampaigns(opportunityCampaign.id, () =>
    toast("Campaign successfully updated"),
  )
  const { data: opportunityCampaignsUsersMap, isLoading: usersMapIsLoading } = useFetchOpportunityCampaignsUsersMap()
  return (
    <OpportunityCampaignCard
      opportunityCampaign={opportunityCampaign}
      ownEmailCadence={opportunityCampaignsUsersMap?.get(opportunityCampaign.id)?.email_cadence}
      onUpdate={
        opportunityCampaignsUsersMap?.get(opportunityCampaign.id)?.can_edit ? updateOpportunityCampaign : undefined
      }
      editIsLoading={usersMapIsLoading}
    />
  )
}

export const OpportunityCampaigns: FC = () => {
  const { data: opportunityCampaigns } = useFetchOpportunityCampaigns()
  return (
    <Stack gap={1} marginTop={1}>
      {opportunityCampaigns ? (
        opportunityCampaigns?.map((opportunityCampaign) => (
          <OpportunityCampaignWithUpdate key={opportunityCampaign.id} opportunityCampaign={opportunityCampaign} />
        ))
      ) : (
        <>
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
        </>
      )}
    </Stack>
  )
}
