import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material"
import { PublicInsight } from "@src/interfaces/insight"
import { FC } from "react"
import { InsightContent } from "../InsightContent"
import { useCloseModalKey, useModalKey, useOpenModalKey } from "@components/shared/OpenModalHook"

export const InsightActionsDataGridRow: FC<{ insight: PublicInsight }> = ({ insight }) => {
  const insightModalKey = `${insight.id} insight modal`
  const summaryModalKey = `${insight.id} summary modal`
  const insightOpened = useModalKey(insightModalKey)
  const summaryOpened = useModalKey(summaryModalKey)
  const openInsight = useOpenModalKey(insightModalKey)
  const openSummary = useOpenModalKey(summaryModalKey)
  const closeInsight = useCloseModalKey(insightModalKey)
  const closeSummary = useCloseModalKey(summaryModalKey)

  return (
    <>
      <Stack spacing={1}>
        <Button variant="contained" sx={{ textWrap: "nowrap", width: 110 }} onClick={openInsight}>
          Show Insight
        </Button>
        {insight.summary && (
          <Button variant="contained" sx={{ textWrap: "nowrap", width: 110 }} onClick={openSummary}>
            Show Summary
          </Button>
        )}
        <Dialog open={insightOpened} onClose={closeInsight} fullWidth>
          <DialogTitle>{`${insight.meeting.title} ${insight.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <InsightContent promptResult={insight.result} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeInsight}>Close</Button>
          </DialogActions>
        </Dialog>
      </Stack>
      {insight.summary && (
        <Dialog open={summaryOpened} onClose={closeSummary} fullWidth>
          <DialogTitle>{`${insight.meeting.title} ${insight.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <InsightContent promptResult={insight.summary} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSummary}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
