import { FunnelOrchestratorEditor } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { AiChainRunnable, AiChainRunnableSegmentTypes, OrchestratorType } from "@interfaces/AiProducts/models"
import { PromptingData } from "@interfaces/AiProducts/requestObjectInterfaces"

export const buildPromptingRequest: (
  configurations: FunnelOrchestratorEditor,
  segmentType: AiChainRunnableSegmentTypes,
  index?: number,
) => PromptingData = (configurations, segmentType, index) => {
  const requestConfigurations = structuredClone(configurations)

  if (index !== undefined) {
    if (segmentType === AiChainRunnableSegmentTypes.INITIAL) {
      requestConfigurations.initialSegment.forEach(
        (aiChainRunnable: AiChainRunnable) => (aiChainRunnable.segmentType = AiChainRunnableSegmentTypes.TOTAL),
      )
      requestConfigurations.tailSegment = null
    }

    if (segmentType === AiChainRunnableSegmentTypes.MIDDLE) {
      const tailSegmentSlice = requestConfigurations.middleSegment.slice(index, index + 1)
      if (tailSegmentSlice) {
        const tailSegment = tailSegmentSlice[0]
        tailSegment.segmentType = AiChainRunnableSegmentTypes.TAIL
        requestConfigurations.tailSegment = tailSegment
      }
      requestConfigurations.middleSegment = requestConfigurations.middleSegment.slice(0, index)
    }
  }
  const aiChainRunnables = [...requestConfigurations.initialSegment, ...requestConfigurations.middleSegment]
  if (requestConfigurations.tailSegment) {
    aiChainRunnables.push(requestConfigurations.tailSegment)
  }

  return {
    aiChainRunnables: aiChainRunnables,
    orchestratorType: OrchestratorType.FUNNEL,
  } as PromptingData
}
