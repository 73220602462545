import { FC } from "react"
import { ContactsTable } from "../Contacts/datagrid/contactsTable"
import { useFetchContacts } from "@api/admin/contacts"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import { Box } from "@mui/material"
import { CONTACT_DISCOVERY_MODAL_KEY, ContactDiscoveryModal } from "../Contacts/contactDiscoveryModal"
import { useModalKey } from "@components/shared/OpenModalHook"
import { AdminContactsToolbar } from "../Contacts/datagrid/adminToolbar"

export const OrgContactsPage: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: contacts, isLoading } = useFetchContacts(parseInt(organizationId))

  const isModalOpen = useModalKey(CONTACT_DISCOVERY_MODAL_KEY)

  return (
    <Box sx={{ width: "100%" }} padding={2} marginTop={2}>
      <ContactsTable
        contacts={contacts ?? []}
        loading={isLoading}
        customToolbar={AdminContactsToolbar}
        customSlotProps={{ organizationId: parseInt(organizationId) }}
        options={{ allowCreate: true, allowUpdate: true, allowDelete: true }}
      />
      {isModalOpen && <ContactDiscoveryModal />}
    </Box>
  )
}
