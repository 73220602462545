import { useMutation, useQuery, useQueries, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { UserCampaign, Campaign, EmailCadence } from "../interfaces/campaign"
import { updateUserQuickstart, updateQuickstartSubscribed } from "./users"
import { AxiosResponse } from "axios"
import { FilterParams } from "../components/Home/interface"
import { FilterParamsWithoutDates } from "../components/Home/interface"

const prefix = "/campaigns"

export const useFetchFilterList = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["campaigns"], async () => {
    const response = await (await axiosInstance).get<{ campaigns: Campaign[] }>(prefix)

    return response.data
  })
}

export const useFetchMyCampaigns = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["my_campaigns"], async () => {
    const response = await (await axiosInstance).get<{ campaigns: UserCampaign[] }>(`${prefix}/my_campaigns`)

    return response.data
  })
}

export const useFetchCampaign = (campaignId: string | null) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["campaign", campaignId],
    async () => {
      const response = await (await axiosInstance).get<{ campaign: Campaign }>(`${prefix}/${campaignId}`)

      return response.data
    },
    {
      enabled: campaignId ? true : false,
    },
  )
}

export const useFetchOrganizationsCampaigns = () => {
  const axiosInstance = useAuthAxios()
  return useQuery(["my_subscriptions"], async () => {
    const response = await (await axiosInstance).get<{ campaigns: Campaign[] }>(`${prefix}/my_subscriptions`)
    return response.data
  })
}

interface CampaignParams {
  name: string
  filterParams: FilterParams
  searchTerms: string[]
  emailCadence: "Unsubscribed" | "Daily" | "Weekly"
  mustIncludeInput: string[]
  proximity: number | null
  exclude: boolean
  generalBriefing?: boolean
  briefings?: number[]
}

export const useCreateCampaign = (
  onSuccess?: (
    data: AxiosResponse<{
      newFilter: {
        id: number
        name: string
      }
    }>,
  ) => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ name, filterParams, searchTerms, emailCadence, mustIncludeInput, proximity, exclude }: CampaignParams) => {
      return (await axiosInstance).post<{
        newFilter: {
          id: number
          name: string
        }
      }>("/campaigns", {
        name,
        filterParams,
        searchTerms,
        email_cadence: emailCadence,
        mustIncludeTerms: mustIncludeInput,
        proximity,
        exclude,
      })
    },
    {
      onSuccess: (data, { emailCadence }) => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        updateUserQuickstart(queryClient)
        if (emailCadence !== "Unsubscribed") {
          updateQuickstartSubscribed(queryClient)
        }
        if (onSuccess) {
          onSuccess(data)
        }
      },
    },
  )
}

export const useUpdateCampaign = (campaignId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      name,
      filterParams,
      searchTerms,
      emailCadence,
      mustIncludeInput,
      proximity,
      exclude,
      generalBriefing,
      briefings,
    }: CampaignParams) => {
      return (await axiosInstance).put(`${prefix}/${campaignId}`, {
        name,
        filterParams,
        searchTerms,
        email_cadence: emailCadence,
        mustIncludeTerms: mustIncludeInput,
        proximity,
        exclude,
        automated_general_briefings: generalBriefing,
        briefings,
      })
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("admin_campaigns")
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries(["campaigns_estimate_general_insight_cost", `${campaignId}`])
        if (params.emailCadence !== "Unsubscribed") {
          updateQuickstartSubscribed(queryClient)
        }
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useCloneCampaigns = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`${prefix}/${params.id}/clone`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
      },
    },
  )
}

export const useDeleteCampaign = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`${prefix}/${params.id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
      },
    },
  )
}

export const useAssignCampaign = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { userIds: string[]; campaignIds: string[]; emailCadence: EmailCadence }) => {
      return (await axiosInstance).post(`${prefix}/${params.campaignIds}/campaign_assignments`, {
        user_ids: params.userIds,
        email_cadence: params.emailCadence,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("admin_campaigns")
        queryClient.invalidateQueries("user_org_campaigns")
        queryClient.invalidateQueries("user_subscriptions")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
export const useUnassignCampaign = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { userIds: string[]; campaignIds: string[] }) => {
      return (await axiosInstance).delete(`${prefix}/${params.campaignIds}/campaign_assignments`, {
        params: { user_ids: params.userIds },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("admin_campaigns")
        queryClient.invalidateQueries("user_org_campaigns")
        queryClient.invalidateQueries("user_subscriptions")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useFetchSimilarKeywords = (keywords: string[]) => {
  const axiosInstance = useAuthAxios()

  return useQueries(
    keywords.map((keyword) => ({
      queryKey: ["similar_keywords", keyword],
      queryFn: async () => {
        return (await axiosInstance).get<Record<string, [number, string][]>>(
          `${prefix}/similar_keywords?keyword=${keyword}`,
        )
      },
      retry: 20, // prone to failure for unknown reason
    })),
  )
}

interface AdvancedSearch {
  must_include: string[]
  should_include: string[]
}

export const useFetchKeywordHits = (
  // TODO: refactor this to search only one keyword at a time
  keywords: string[],
  advancedTerms?: string[],
  filterParams?: FilterParamsWithoutDates,
) => {
  const axiosInstance = useAuthAxios()
  const advancedSearch: AdvancedSearch = { must_include: [], should_include: [] }
  let searchTerms = keywords
  if (advancedTerms) {
    advancedSearch.must_include = advancedTerms
    advancedSearch.should_include = keywords
    searchTerms = [...keywords, ...advancedTerms]
  }

  return useQuery(
    ["keyword_hits", keywords, advancedTerms, filterParams],
    async () => {
      const response = await (
        await axiosInstance
      ).post(`${prefix}/keyword_hits`, {
        searchTerms: searchTerms,
        advancedSearch: advancedSearch,
        filterParams: filterParams,
      })
      return response.data
    },
    {
      enabled: keywords.filter((term: string) => term !== "")?.length > 0,
    },
  )
}

export const useFetchEstimateGeneralInsightCost = (campaignId?: unknown, enabled = true) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["campaigns_estimate_general_insight_cost", `${campaignId}`],
    async () => {
      const response = await (await axiosInstance).get(`${prefix}/${campaignId}/estimate_automated_briefing_cost`)
      return response.data
    },
    {
      enabled: campaignId !== undefined && enabled,
    },
  )
}

export const useIsSubscribed = (campaignId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["campaigns_users_settings", campaignId.toString()], async () => {
    const response = await (await axiosInstance).get(`${prefix}/${campaignId}/is_subscribed`)
    return response.status === 200
  })
}
