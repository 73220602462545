import { FC, useState, useContext } from "react"
import {
  Typography,
  Box,
  MenuList,
  MenuItem,
  Chip,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  FormGroup,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { unpackSetters } from "../unpackSetters"
import { MeetingSearchFormContext } from "./"
import { SearchFormTerms, SearchFormInput, SearchFormButton } from "./SearchFormComponents"
import { useFetchSearchLogs } from "../../../api/searchLogs"
import { AdvancedSearch } from "./advancedSearch"

export const searchInputStyle = {
  borderRadius: "29px",
  backgroundColor: "neutral.light",
  border: "1px solid #666666",
  display: "flex",
  overflow: "hidden",
}

export const SearchTerms: FC<{ addSpacing: boolean }> = ({ addSpacing }) => {
  const { searchFormData, setSearchFormData, addTerm, updateSearchParams, resetNumericFilters } =
    useContext(MeetingSearchFormContext)
  const { input, terms, showAdvancedFilters, showAdvancedSearch } = searchFormData
  const { setInput, setTerms, setAdvancedTerms, setShowAdvancedFilters, setShowAdvancedSearch } = unpackSetters(
    searchFormData,
    setSearchFormData,
  )
  const { data: searchLogsData } = useFetchSearchLogs()
  const [inputFocused, setInputFocused] = useState(false)

  const searchSuggestions: string[][] = []
  if (searchLogsData) {
    const searchLogsSet = new Set<string>()
    searchLogsData.forEach((searchLog) => {
      const logsString = searchLog.searchTerms.toString()
      if (!searchLogsSet.has(logsString) && searchSuggestions.length < 5) {
        searchSuggestions.push(searchLog.searchTerms)
        searchLogsSet.add(logsString)
      }
    })
  }

  const showSuggestions = inputFocused && input === "" && !terms.size && searchLogsData

  return (
    <Box width={{ xs: "calc(100% + 16px)", sm: "100%" }}>
      {(addSpacing || showAdvancedSearch) && (
        <Typography alignSelf="flex-start" marginLeft={{ xs: 1, sm: 0 }}>
          {showAdvancedSearch ? "At least one of these terms:" : "\u00A0"}
        </Typography>
      )}
      <Box width="100%" position="relative" zIndex="10">
        {showSuggestions && (
          <Box paddingY={1} border="1px solid transparent" sx={{ opacity: "0" }}>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Box>
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems={{ xs: "stretch", sm: "flex-start" }}
          sx={{
            position: showSuggestions ? "absolute" : "relative",
            top: "0",
            width: "100%",
          }}
        >
          <Stack flex="1" sx={searchInputStyle}>
            <Stack
              direction="row"
              gap={0.5}
              alignItems="flex-start"
              paddingY={1}
              paddingX={2}
              paddingBottom={showSuggestions ? 0 : undefined}
            >
              <SearchFormTerms terms={terms} setTerms={setTerms} sx={{ display: { xs: "none", sm: "flex" } }} />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexGrow="1"
                flexShrink="0"
                marginLeft="0 !important"
              >
                <SearchFormInput
                  input={input}
                  setInput={setInput}
                  addTerm={addTerm}
                  setInputFocused={setInputFocused}
                  placeholder={`What do you want to search?`}
                />
                {input.trim() && <SearchFormButton addTerm={addTerm} />}
                <IconButton
                  aria-label="search"
                  onClick={() => updateSearchParams()}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Stack>
            </Stack>
            {showSuggestions && (
              <MenuList>
                {searchSuggestions.map((suggestion, index) => (
                  <MenuItem
                    key={`${suggestion.toString()}${index}`}
                    onClick={() => updateSearchParams(new Set(suggestion))}
                  >
                    <Stack direction="row" spacing={0.5}>
                      {suggestion
                        .filter((term) => term)
                        .map((term) => (
                          <Chip label={term} key={term} />
                        ))}
                    </Stack>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Stack>
          <SearchFormTerms
            terms={terms}
            setTerms={setTerms}
            sx={{
              display: { xs: "flex", sm: "none" },
              marginTop: "4px !important",
            }}
          />
          <FormGroup
            sx={{
              height: "58px",
              justifyContent: "space-evenly",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <FormControlLabel
              className="proximity-search-form-control-label"
              control={
                <Switch
                  className="proximity-search-switch"
                  size="small"
                  checked={showAdvancedSearch}
                  onChange={(event) => {
                    if (!event.target.checked) {
                      setAdvancedTerms(new Set())
                    }
                    setShowAdvancedSearch(event.target.checked)
                  }}
                />
              }
              label="Proximity Search"
            />
            <FormControlLabel
              className="advanced-filters-form-control-label"
              control={
                <Switch
                  className="advanced-filters-switch"
                  size="small"
                  checked={showAdvancedFilters}
                  onChange={(event) => {
                    setShowAdvancedFilters(event.target.checked)
                    if (!event.target.checked) {
                      resetNumericFilters()
                    }
                  }}
                />
              }
              label="Advanced Filters"
            />
          </FormGroup>
        </Stack>
      </Box>
      {showAdvancedSearch && <AdvancedSearch />}
    </Box>
  )
}
