import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"
import { AiFeaturesMetadataResponse, AiFeatureResponse } from "@interfaces/AiProducts/responseObjectInterfaces"
import { UpdateAiFeatureRequest } from "@interfaces/AiProducts/requestObjectInterfaces"
import { namespace } from "./shared"
import { AiFeature } from "@interfaces/AiProducts/models"
import { serializeUpsertFeatureRequest } from "@interfaces/AiProducts/serializers/requests/aiFeatures"
import { serializeAiFeatureResponse } from "@interfaces/AiProducts/serializers/responses/serializers"
import { MetadataResponse } from "@interfaces/AiProducts/responseObjectInterfaces"

const resourcePath = `${namespace}/ai-features`

export interface FetchAiFeaturesResponse extends MetadataResponse {
  ai_features: AiFeatureResponse[]
}

export const useFetchAiFeatures = (params?: CommonUrlParams, enabled?: boolean) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(
    paramBuilder.toQueryKey("ai-features"),
    async () => {
      const response = await (await axiosInstance).get<FetchAiFeaturesResponse>(uri)

      return {
        meta: response.data.meta,
        aiFeatures: response?.data?.ai_features.map((feature) => serializeAiFeatureResponse(feature)),
      }
    },
    {
      enabled,
    },
  )
}

export interface FetchAiFeatureResponse {
  ai_feature: AiFeatureResponse
}

export const useFetchAiFeature = (id?: string, params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}/${id}${queryParams}`

  return useQuery(
    ["ai-features", id],
    async () => {
      const response = await (await axiosInstance).get<FetchAiFeatureResponse>(uri)

      return serializeAiFeatureResponse(response?.data?.ai_feature)
    },

    {
      enabled: id !== undefined,
    },
  )
}

export const useFetchAiFeaturesMetadata = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    "ai-products-metadata",
    async () => {
      const response = await (await axiosInstance).get<AiFeaturesMetadataResponse>(`${resourcePath}/metadata`)

      return response.data
    },
    {
      enabled: true,
    },
  )
}

export const useCreateAiFeature = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (request: { feature: AiFeature; urlParams?: CommonUrlParams }) => {
      const paramBuilder = new QueryParamsBuilder()
      const queryParams = paramBuilder.build(request.urlParams)
      const body = serializeUpsertFeatureRequest(request.feature)
      const uri = `${resourcePath}${queryParams}`
      const response = await (await axiosInstance).post<FetchAiFeatureResponse>(uri, body)
      return serializeAiFeatureResponse(response?.data?.ai_feature)
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["ai-features"])
      },
    },
  )
}

export const useUpdateAiFeature = (onSuccess?: () => void, onFailure?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (request: { feature: AiFeature }) => {
      const uri = `${resourcePath}/${request.feature.id}`

      return (await axiosInstance).put<UpdateAiFeatureRequest>(uri, serializeUpsertFeatureRequest(request.feature))
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess()
        queryClient.invalidateQueries(["ai-features"])
      },
      onError: () => {
        onFailure && onFailure()
      },
    },
  )
}

export const useDeletelAiFeature = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      const response = await (await axiosInstance).delete<AiFeatureResponse>(`${resourcePath}/${id}`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ai-features"])
      },
    },
  )
}
