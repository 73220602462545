import { FC, useContext } from "react"
import { useTheme } from "@mui/material/styles"
import { Stack, useMediaQuery } from "@mui/material"
import { MeetingSearchFormContext } from "."
import { ChannelSelectorMultiple } from "../Selectors/Multiple/Channel"
import { GeographySelectorMultiple } from "../Selectors/Multiple/Geographies"
import { TypeSelectorMultiple } from "../Selectors/Multiple/Types"
import { RangeSlider } from "../Selectors/Multiple/Range"
import { useFetchGeographies } from "../../../api/geographies"
import { useFetchOrganizations } from "../../../api/organizations"
import { PersonSelectorMultipleWrapper } from "src/components/shared/Persons/PersonSelector"
import { TitleSearchTermsWrapper } from "@components/shared/Persons/TitleSearchTerms"
import { OrganizationSearchTermsWrapper } from "@components/shared/Persons/OrganizationSearchTerms"
import { SpeakerIdentificationComponent } from "@components/Admin/Meetings/SpeakerIdentificationTable"
import { useFetchCurrentUser, useHasSpeakerIdentificationAccess } from "@api/users"

const filterSx = {
  flexGrow: "1",
  minWidth: "0",
  ".MuiOutlinedInput-root": {},
}

const placeholderGeographies = { cities: [], counties: [], states: [] }

export const Filters: FC = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const { searchFormData, setSearchFormData, filterParams, setFilterParams, loadPeopleByVoiceId } =
    useContext(MeetingSearchFormContext)
  const { showAdvancedFilters } = searchFormData
  const { data: geographiesData } = useFetchGeographies()
  const { data: organizationsData } = useFetchOrganizations()
  const hasSpeakerIdentificationAccess = useHasSpeakerIdentificationAccess()

  const setSelectedPeopleVoiceIds = (newData: number[]) => {
    setFilterParams({
      ...filterParams,
      voice_ids: newData,
    })
  }

  const setSelectedPeopleIds = (newData: Set<number>) => {
    setSearchFormData({
      ...searchFormData,
      selectedPeopleIds: newData,
    })
  }

  const setSelectedTitleSearchTerms = (newData: string[]) => {
    setFilterParams({
      ...filterParams,
      title_search_terms: newData,
    })
  }

  const setSelectedOrganizationSearchTerms = (newData: string[]) => {
    setFilterParams({
      ...filterParams,
      organization_search_terms: newData,
    })
  }

  return (
    <>
      <Stack direction="row" spacing={0.5} width="100%" display={{ xs: "none", sm: "flex" }}>
        <GeographySelectorMultiple
          options={geographiesData || placeholderGeographies}
          label="State"
          type="states"
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "5ch", ...filterSx }}
        />
        <TypeSelectorMultiple
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "13ch", ...filterSx }}
        />
      </Stack>
      {showAdvancedFilters && (
        <>
          <Stack direction="row" spacing={0.5} width="100%" display={{ xs: "none", sm: "flex" }}>
            <GeographySelectorMultiple
              options={geographiesData || placeholderGeographies}
              label="County"
              type="counties"
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              size="small"
              sx={{ flexBasis: "12ch", ...filterSx }}
            />
            <GeographySelectorMultiple
              options={geographiesData || placeholderGeographies}
              label="City"
              type="cities"
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              size="small"
              sx={{ flexBasis: "12ch", ...filterSx }}
            />
            <ChannelSelectorMultiple
              options={organizationsData ? organizationsData.organizations : []}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              size="small"
              sx={{ flexBasis: "15ch", ...filterSx }}
            />
          </Stack>
          {hasSpeakerIdentificationAccess && (
            <Stack direction="row" spacing={0.5} width="100%" display={{ xs: "none", sm: "flex" }}>
              <PersonSelectorMultipleWrapper
                selectedPeopleVoiceIds={filterParams.voice_ids}
                selectedPeopleIds={searchFormData.selectedPeopleIds}
                loadPeopleByVoiceId={loadPeopleByVoiceId}
                setSelectedPeopleVoiceIds={setSelectedPeopleVoiceIds}
                setSelectedPeopleIds={setSelectedPeopleIds}
                size="small"
                sx={{ flexBasis: "13ch", ...filterSx }}
              />
              <TitleSearchTermsWrapper
                selectedSearchTerms={filterParams.title_search_terms}
                setSelectedSearchTerms={setSelectedTitleSearchTerms}
                size="small"
                sx={{ flexBasis: "13ch", ...filterSx }}
              />
              <OrganizationSearchTermsWrapper
                selectedSearchTerms={filterParams.organization_search_terms}
                setSelectedSearchTerms={setSelectedOrganizationSearchTerms}
                size="small"
                sx={{ flexBasis: "13ch", ...filterSx }}
              />
            </Stack>
          )}
          <Stack
            direction="row"
            justifyContent={{ xs: "space-evenly", md: "space-between" }}
            width="100%"
            display={{ xs: "none", sm: "flex" }}
          >
            <RangeSlider
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              filterType="city_population"
              label="City Population"
            />
            <RangeSlider
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              filterType="county_population"
              label="County Population"
            />
            {matches && (
              <>
                <RangeSlider
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  filterType="city_income"
                  label="City Household Income"
                />
                <RangeSlider
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  filterType="county_income"
                  label="County Household Income"
                />
              </>
            )}
          </Stack>
          {!matches && (
            <Stack
              direction="row"
              justifyContent={{ xs: "space-evenly", md: "space-between" }}
              width="100%"
              display={{ xs: "none", sm: "flex" }}
            >
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="city_income"
                label="City Household Income"
              />
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="county_income"
                label="County Household Income"
              />
            </Stack>
          )}
        </>
      )}
    </>
  )
}
