import { Link as MuiLink, useMediaQuery, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"

export const opportunityColumns = (showIndustry: boolean) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

  return [
    {
      display: isMediumScreen ? "block" : "flex",
      field: "title",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Title",
      renderCell: (params) => (
        <MuiLink component={Link} to={`/meetings/${params.row.meeting_id}/#opportunities`}>
          {params.row.name}
        </MuiLink>
      ),
      minWidth: 150,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "description",
      flex: isMediumScreen ? 0 : 2,
      headerName: "Description",
      renderCell: (params) => {
        let endSliceIndex = 3

        const splitDescription = params.row.description.split(" ")
        endSliceIndex = splitDescription.length

        if (isMediumScreen) {
          endSliceIndex = 10
        }

        const split = (endIndex: number) => splitDescription.slice(0, endIndex).join(" ")

        const description =
          endSliceIndex < splitDescription.length ? `${split(endSliceIndex)}...` : params.row.description

        return <>{description}</>
      },
      minWidth: isSmallScreen ? 150 : 250,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "actionability",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Actionability",
      minWidth: 100,
    },
    showIndustry
      ? {
          display: isMediumScreen ? "block" : "flex",
          field: "industry",
          flex: isMediumScreen ? 0 : 1,
          headerName: "Industry",
          minWidth: 150,
        }
      : {
          display: isMediumScreen ? "block" : "flex",
          field: "naics",
          flex: isMediumScreen ? 0 : 1,
          headerName: "NAICS Code",
          minWidth: 150,
        },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "actionable_date",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Actionable Date",
      minWidth: 100,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "speaker",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Key Speaker",
      minWidth: 125,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "date",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Meeting Published At",
      minWidth: 100,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "channel",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Channel",
      renderCell: (params) => (
        <MuiLink component={Link} to={`/channel-details/${params.row.channel_id}`}>
          {params.row.channel_name}
        </MuiLink>
      ),
      minWidth: 100,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      flex: isMediumScreen ? 0 : 1,
      field: "location",
      headerName: "Location",
      minWidth: 125,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      flex: isMediumScreen ? 0 : 1,
      field: "identified_date",
      headerName: "Identified On",
      minWidth: 100,
    },
  ] as GridColDef[]
}

export const opportunityAdminColumns = (showIndustry: boolean) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"))

  const columns = [
    ...opportunityColumns(showIndustry),
    {
      display: isMediumScreen ? "block" : "flex",
      field: "use_fine_tuning",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Fine Tuning",
      minWidth: 100,
    },
    {
      display: isMediumScreen ? "block" : "flex",
      field: "generated_by_model",
      flex: isMediumScreen ? 0 : 1,
      headerName: "Generated By Model",
      minWidth: 100,
      sortable: false,
    },
  ] as GridColDef[]
  return columns
}
