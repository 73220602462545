import { FC, useMemo, useState } from "react"
import { Typography, Card, CardContent, CardActions, Button, Stack, Box } from "@mui/material"
import { useParams } from "react-router-dom"
import { marked } from "marked"
import DOMPurify from "dompurify"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useSendInsightFromMeeting } from "../../../api/admin/users"
import { getMeetingInsights } from "../../../api/admin/meetings"
import { useToast } from "../../shared/ToastHook"
import { UserSelector } from "../../shared/Selectors/User"
import { InsightPDF } from "../Shared/InsightPDF"
import { useFetchCurrentUserCreditUsages } from "../../../api/users"
import { OutOfCreditsAlert, modalKey } from "./OutOfCreditsAlert"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { PromptsAutocomplete, SelectedPrompt } from "../../shared/PromptsAutocomplete"
import { Insight } from "@src/interfaces/insight"

export const SendInsightCard: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const [userId, setUserId] = useState<number | null>(null)
  const [prompts, setPrompts] = useState<SelectedPrompt[]>([])
  const { data: myCreditUsage } = useFetchCurrentUserCreditUsages()
  const { data: promptResults } = getMeetingInsights(meetingId, userId)
  const openModalKey = useOpenModalKey(modalKey)

  const toast = useToast()
  const { mutate: sendInsight } = useSendInsightFromMeeting(parseInt(meetingId), () => toast("Insight request sent"))

  const { refetch } = getMeetingInsights(meetingId, userId)

  let insight = null

  const promptResultFinished = useMemo(() => {
    if (promptResults && promptResults.length > 0) {
      const filterResults = promptResults.filter((promptResult) => promptResult.result)
      return filterResults.length > 0 ? filterResults[filterResults.length - 1] : null
    }
    return {} as Insight
  }, [promptResults])

  if (promptResultFinished && promptResultFinished.result) {
    const insightElement = (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(promptResultFinished.result) as string),
        }}
      />
    )
    insight = (
      <>
        <InsightPDF insightElement={insightElement} />
        {insightElement}
      </>
    )
  }

  const onClick = () => {
    if (myCreditUsage && myCreditUsage.credits_used >= myCreditUsage.credit_limit) {
      openModalKey()
    } else if (userId !== null && prompts) {
      sendInsight({ userId, prompts: prompts.map((prompt) => prompt.id) })
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography marginBottom={1}>Send Insight</Typography>
          <Stack spacing={1}>
            <UserSelector userId={userId} setUserId={setUserId} sx={{ width: 300 }} />
            <Stack direction="row" spacing={1}>
              <Box sx={{ width: 300 }}>
                <PromptsAutocomplete selectedPrompts={{ prompts, setPrompts }} enableMultiple={true} />
              </Box>
            </Stack>
            {insight}
          </Stack>
        </CardContent>
        <CardActions>
          <Button disabled={userId === null} onClick={() => refetch()}>
            Refresh Prompts-Reponses
          </Button>
          <Button onClick={onClick}>Send</Button>
        </CardActions>
      </Card>
      <OutOfCreditsAlert />
    </>
  )
}
