import { LLMModelOptions, ModelConfigurations, ModelOptions } from "@interfaces/modelConfigs"
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  MenuItem,
  Select,
  Slider,
  Stack,
} from "@mui/material"
import { Dispatch, FC, SetStateAction } from "react"

export interface CustomModelConfigProps {
  modelOptions: ModelConfigurations
  setModelOptions: Dispatch<SetStateAction<ModelConfigurations>>
  disabledFields?: (keyof ModelConfigurations)[]
}

export const CustomModelConfig: FC<CustomModelConfigProps> = ({ modelOptions, setModelOptions, disabledFields }) => {
  const handleOptionsChange = <K extends keyof ModelConfigurations>(key: K, value: ModelConfigurations[K]) => {
    setModelOptions((prevVal: ModelConfigurations) => {
      return { ...prevVal, [key]: value }
    })
  }

  return (
    <Box>
      <Stack direction={"column"} spacing={1} marginBottom={1}>
        <Stack direction="row" spacing={1} marginBottom={1}>
          <Select
            value={modelOptions?.model}
            onChange={(e) => handleOptionsChange("model", e.target.value as LLMModelOptions)}
            disabled={disabledFields?.includes("model")}
            displayEmpty
          >
            {ModelOptions.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    modelOptions?.model !== "gemini-1.5-flash-002" || disabledFields?.includes("useFineTunedModel")
                  }
                  checked={modelOptions?.useFineTunedModel}
                  onChange={(e) => handleOptionsChange("useFineTunedModel", e.target.checked)}
                />
              }
              label="Use Fine-tuned Model"
            />
          </FormGroup>
        </Stack>
        <Stack direction="row" spacing={1} marginBottom={1} alignItems="center">
          <FormLabel>Max Token Size Per Chunk</FormLabel>
          <Input
            type="number"
            value={modelOptions?.maxTokenSizePerChunk}
            disabled={disabledFields?.includes("maxTokenSizePerChunk")}
            onChange={(e) => handleOptionsChange("maxTokenSizePerChunk", parseInt(e.target.value))}
          />
        </Stack>
        <Stack direction="row" spacing={2} marginBottom={1} alignItems="center">
          <FormLabel>Temperature</FormLabel>
          <Slider
            value={modelOptions?.temperature ?? 0}
            disabled={disabledFields?.includes("temperature")}
            min={0}
            max={1}
            step={0.01}
            onChange={(_, value) => handleOptionsChange("temperature", value as number)}
            valueLabelDisplay="auto"
          />
        </Stack>
      </Stack>
    </Box>
  )
}
