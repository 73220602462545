import { useBulkRunAIContactDiscovery, useRunAIContactDiscovery } from "@api/admin/contacts"
import { useCloseModalKey, useData, useModalKey } from "@components/shared/OpenModalHook"
import { AdminContact } from "@interfaces/contact"
import { ModelConfigurations } from "@interfaces/modelConfigs"
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Stack, Tooltip } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { CustomModelConfig } from "../Meetings/SpeakerIdentificationTable/CustomModelConfig"
import { useToast } from "@components/shared/ToastHook"
import { HelpOutline } from "@mui/icons-material"
import Loading from "@components/shared/Loading"

export const CONTACT_DISCOVERY_MODAL_KEY = "CONTACT_DISCOVERY_MODAL_KEY"

export interface ContactDiscoveryModalData {
  organizationIds: number[]
  dryRun: boolean
  handleOnSuccess?: (data: AdminContact[]) => void
}

export interface ContactDiscoveryOptions extends ModelConfigurations {
  filter_existing_contacts: boolean
}

export const ContactDiscoveryModal: FC = () => {
  const open = useModalKey(CONTACT_DISCOVERY_MODAL_KEY)
  const closeModal = useCloseModalKey(CONTACT_DISCOVERY_MODAL_KEY)
  const { organizationIds, dryRun, handleOnSuccess } = useData<ContactDiscoveryModalData>()! // Assert data is not undefined

  const [modelConfigurations, setModelConfigurations] = useState<ModelConfigurations>({
    model: "gemini-2.0-flash",
    maxTokenSizePerChunk: 15000,
    useFineTunedModel: false,
    temperature: 0.0,
  })

  const setToast = useToast()

  const [filterExistingContacts, setFilterExistingContacts] = useState<boolean>(true)

  const {
    mutate: runDiscover,
    isLoading: isSingularLoading,
    isError: isSingularError,
    data: singularData,
    error: singularError,
  } = useRunAIContactDiscovery({
    organization_id: organizationIds[0],
    model: modelConfigurations.model,
    max_token_size_per_chunk: modelConfigurations.maxTokenSizePerChunk,
    dry_run: dryRun,
    use_fine_tuned_model: modelConfigurations.useFineTunedModel,
    temperature: modelConfigurations.temperature,
    filter_existing_contacts: filterExistingContacts,
  })

  const {
    mutate: runBulkDiscover,
    isLoading: isBulkLoading,
    isError: isBulkError,
    data: bulkData,
    error: bulkError,
  } = useBulkRunAIContactDiscovery({
    organization_ids: organizationIds,
    model: modelConfigurations.model,
    max_token_size_per_chunk: modelConfigurations.maxTokenSizePerChunk,
    dry_run: dryRun,
    use_fine_tuned_model: modelConfigurations.useFineTunedModel,
    temperature: modelConfigurations.temperature,
    filter_existing_contacts: filterExistingContacts,
  })

  useEffect(() => {
    if (isSingularError || isBulkError) {
      setToast(
        "Contact Discovery Failed: " +
          (singularError?.response?.data?.detail ?? bulkError?.response?.data?.detail ?? "Unknown Error"),
        15000,
        undefined,
        "error",
      )
    } else {
      const data = singularData || bulkData
      if (data) {
        setToast("Contact Discovery Successful", 15000, undefined, "info")
        handleOnSuccess && handleOnSuccess(data)
        closeModal()
      }
    }
  }, [isSingularError, isBulkError, singularData, bulkData])

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle align="center">Run Contact Discovery</DialogTitle>
      <DialogContent>
        {isSingularLoading || isBulkLoading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            height={100}
            padding={2}
            minWidth={"20vw"}
            position="relative"
          >
            <Loading useCloverleafIcon />
          </Box>
        ) : (
          <>
            <Stack direction="column" spacing={2} padding={2}>
              <Stack direction={"column"} spacing={2}>
                <CustomModelConfig
                  modelOptions={modelConfigurations}
                  setModelOptions={setModelConfigurations}
                  disabledFields={["maxTokenSizePerChunk", "useFineTunedModel"]}
                />
                <Stack direction="column" spacing={1} marginBottom={1}>
                  <Box display={"flex"} alignItems={"center"} paddingLeft={2}>
                    <FormControlLabel
                      control={
                        <Box
                          component="input"
                          type="checkbox"
                          checked={filterExistingContacts}
                          onChange={(e) => setFilterExistingContacts(e.target.checked)}
                        />
                      }
                      label="Filter Existing Power Almanac Contacts"
                    />
                    <Tooltip title="Do not include contacts discovered that already exist in results">
                      <HelpOutline />
                    </Tooltip>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} paddingLeft={2}>
                    <FormControlLabel
                      control={<Box component="input" type="checkbox" checked={dryRun} disabled />}
                      label="Dry Run"
                    />
                    <Tooltip title="Dry run will not save the results">
                      <HelpOutline />
                    </Tooltip>
                  </Box>
                </Stack>
              </Stack>
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (organizationIds.length === 0) {
                      setToast("No organization selected")
                    } else if (organizationIds.length === 1) {
                      runDiscover()
                    } else {
                      runBulkDiscover()
                    }
                  }}
                >
                  Run Contact Discovery {organizationIds.length > 1 ? "for All Selected Organizations" : ""}
                </Button>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
