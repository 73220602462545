import { FC, useContext, useEffect, useState } from "react"
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { OpportunityCampaignCardContext } from "./indexContext"
import { ConfirmationModal } from "../ConfirmationModal"
import { useOpenModalKey } from "../OpenModalHook"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { EmailCadence } from "@src/interfaces/campaign"

export const EmailCadencesList: FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { id, emailCadences, onEmailCadencesUpdate, userMapById, setEmailCadencesAction } =
    useContext(OpportunityCampaignCardContext)
  const modalKey = `ADD_OPPORTUNITY_CAMPAIGN_USER_${id}`
  const open = useOpenModalKey(modalKey)

  const [newEmailCadences, setNewEmailCadences] = useState(
    new Map(emailCadences?.map(({ user_id, email_cadence }) => [user_id, email_cadence])),
  )
  const [userIdToAdd, setUserIdToAdd] = useState<number | null>(null)

  const emailCadencesDependencyKey = emailCadences
    ? emailCadences.map(({ id, email_cadence }) => `${id}:${email_cadence}`).join("")
    : ""
  useEffect(() => {
    if (emailCadences) {
      setNewEmailCadences(new Map(emailCadences?.map(({ user_id, email_cadence }) => [user_id, email_cadence])))
    }
  }, [emailCadencesDependencyKey])

  const useEffectDependencyKey = Array.from(newEmailCadences.values()).flat().join("")
  useEffect(() => {
    if (emailCadences && onEmailCadencesUpdate) {
      setEmailCadencesAction(() => () => {
        onEmailCadencesUpdate(newEmailCadences)
      })
    } else {
      setEmailCadencesAction(() => () => {})
    }
  }, [useEffectDependencyKey])

  useEffect(() => {
    if (!isEditing) {
      setNewEmailCadences(new Map(emailCadences?.map(({ user_id, email_cadence }) => [user_id, email_cadence])))
    }
  }, [isEditing])

  if (!emailCadences) {
    return null
  }

  return (
    <>
      <Typography variant="h6" marginTop={1}>
        User Email Cadences
      </Typography>
      {isEditing ? (
        <Stack alignItems="flex-start" spacing={1}>
          {Array.from(newEmailCadences.entries()).map(([userId, emailCadence]) => (
            <Stack direction="row" spacing={1} key={userId} alignItems="center">
              <Typography>{`${userMapById?.get(userId)?.email || <Skeleton width="12ch" />}:`}</Typography>
              <FormControl size="small">
                <InputLabel>Email Cadence</InputLabel>
                <Select
                  sx={{ minWidth: "16ch" }}
                  value={emailCadence}
                  label="Email Cadence"
                  onChange={(event) => {
                    setNewEmailCadences((prevSet) => {
                      const newSet = new Map(prevSet)
                      newSet.set(userId, event.target.value as EmailCadence)
                      return newSet
                    })
                  }}
                >
                  <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                onClick={() =>
                  setNewEmailCadences((prevMap) => {
                    const newMap = new Map(prevMap)
                    newMap.delete(userId)
                    return newMap
                  })
                }
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          ))}
          <IconButton
            onClick={() => {
              setUserIdToAdd(null)
              open()
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {emailCadences.map(({ user_id, email_cadence }) => (
            <Typography
              key={user_id}
            >{`${userMapById?.get(user_id)?.email || <Skeleton width="12ch" />}: ${email_cadence}`}</Typography>
          ))}
        </Stack>
      )}
      {userMapById && (
        <ConfirmationModal
          modalKey={modalKey}
          title="Add Opportunity Campaign User"
          body={
            <Autocomplete
              sx={{ marginTop: 1 }}
              options={Array.from(userMapById.keys())}
              value={userIdToAdd}
              onChange={(_, newValue) => setUserIdToAdd(newValue)}
              getOptionLabel={(userId) => userMapById.get(userId)?.email || ""}
              renderInput={(params) => <TextField {...params} label="User" />}
            />
          }
          onConfirm={() => {
            if (userIdToAdd) {
              setNewEmailCadences((prevMap) => new Map(prevMap).set(userIdToAdd, "Unsubscribed"))
            }
          }}
          disabled={!userIdToAdd}
        />
      )}
    </>
  )
}
