import { OrchestratorType } from "@interfaces/AiProducts/models"
import { FunnelOrchestratorProvider } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { FunnelContainer } from "../FunnelOrchestrator/FunnelContainer"

export const createOrchestrator: (type: OrchestratorType) => React.ReactNode = (type) => {
  switch (type) {
    case OrchestratorType.FUNNEL:
      return (
        <FunnelOrchestratorProvider>
          <FunnelContainer />
        </FunnelOrchestratorProvider>
      )

    default:
      return null
  }
}
