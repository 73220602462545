import { FC } from "react"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material"

export const modalKey = "OUT_OF_CREDITS_ALERT"

export const OutOfCreditsAlert: FC = () => {
  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)

  return (
    <Dialog open={open} onClose={closeModalKey}>
      <DialogTitle>Out of Credits</DialogTitle>
      <DialogContent>
        <Typography>
          You are out of insight credits. Please reach out to your customer success representative or&nbsp;
          <Link href="mailto:sales@cloverleaf.ai">sales@cloverleaf.ai</Link>
          &nbsp;to purchase more. Thanks!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModalKey}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
