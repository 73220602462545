import { FC } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { BaseMeeting, Meeting } from "@interfaces/meeting"
import { MeetingItemBase } from "@components/shared/MeetingItemBase"
import { BaseClipShare } from "@src/interfaces/clipShare"
import { LeadMeeting } from "@src/api/promotions/leads"

const LinkBuilderResolver = (
  meeting: Meeting | BaseMeeting | LeadMeeting,
  searchWords: string[],
  industryId?: string,
  clip_share?: BaseClipShare,
  total_hits?: number,
) => {
  const searchTermsUriComponent = encodeURIComponent(searchWords.toString())
  let url = ""
  if (industryId && clip_share) {
    url = `/find-a-lead/${encodeURIComponent(industryId)}/meeting/${encodeURIComponent(meeting.id)}/clip/${encodeURIComponent(clip_share.id)}?searchTerms=${searchTermsUriComponent}&industryId=${encodeURIComponent(industryId)}&active-term=${encodeURIComponent(searchWords[0].toString())}`
  } else {
    url = `/share/${encodeURIComponent(meeting.id)}?searchTerms=${searchTermsUriComponent}&active-term=${encodeURIComponent(searchWords[0].toString())}`
  }
  if (clip_share?.transcript_id) {
    url += `&transcript_id=${encodeURIComponent(clip_share.transcript_id)}`
  }
  if (clip_share?.start_time_in_seconds) {
    url += `&start_time=${encodeURIComponent(clip_share.start_time_in_seconds)}`
  }
  if (total_hits) {
    url += `&totalHits=${encodeURIComponent(total_hits)}`
  }
  return url
}

export const MeetingListItem: FC<{
  meeting: Meeting | BaseMeeting | LeadMeeting
  searchWords: string[]
  industryId?: string
  blur?: boolean | undefined
  clip_share?: BaseClipShare
  total_hits?: number
}> = ({ meeting, searchWords, blur = false, industryId, clip_share, total_hits }) => {
  const link = LinkBuilderResolver(meeting, searchWords, industryId, clip_share, total_hits)
  const terms =
    "term_frequencies" in meeting
      ? Object.entries(meeting.term_frequencies)
          .filter(([, frequency]) => frequency)
          .map(([term, frequency]) => ({
            term,
            count: frequency,
          }))
      : undefined
  return (
    <MeetingItemBase meeting={meeting} terms={terms} link={link} blur={blur} authenticated={false}>
      <Box marginLeft={2} width={"100%"}>
        <Stack
          direction={{ sm: "column", md: "row" }}
          display={"flex"}
          spacing={{ xs: 2 }}
          justifyContent={"space-between"}
        >
          <Box maxWidth={{ xs: "90%", sm: "100%", md: "60%" }}>
            {clip_share && (
              <>
                <Typography variant="body1" fontWeight={600}>
                  Sales Insights
                </Typography>
                <Typography variant="body1">
                  {clip_share.description?.length > 60
                    ? `${clip_share.description.slice(0, 200)}...`
                    : clip_share.description || "Unavailable for this meeting, check back soon."}
                </Typography>
              </>
            )}
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#4cb2dc", paddingX: 4, paddingY: 1, height: "fit-content" }}
            href={link}
            disabled={blur}
          >
            <Typography variant="body1" fontWeight={700}>
              See Opportunity
            </Typography>
          </Button>
        </Stack>
      </Box>
    </MeetingItemBase>
  )
}
