import { useRetryInsight } from "@api/insights"
import { useFetchUsersMap } from "@api/users"
import { PublicInsight } from "@interfaces/insight"
import InsightCard from "@src/stories/InsightCard"
import { FC } from "react"

export const InsightCardWithRetry: FC<{
  insight: PublicInsight
  ownerIds?: number[]
}> = ({ insight, ownerIds }) => {
  const { data: usersMap } = useFetchUsersMap()
  const { mutate: retryInsight, isLoading: retryIsLoading } = useRetryInsight(insight.id, insight.meeting_id)

  return (
    <InsightCard
      insight={insight}
      isLoading={retryIsLoading}
      onRetryInsight={retryInsight}
      ownerEmails={ownerIds?.map((id) => usersMap?.get(id)?.email || "").filter((email) => email)}
    />
  )
}
