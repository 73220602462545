import { useModalKey } from "@components/shared/OpenModalHook"
import { Dialog, DialogActions, Button, Toolbar, Typography, SxProps } from "@mui/material"
import { FC } from "react"
import { theme } from "@src/theme"

export const AI_FEATURES_DIALOG_KEY = "ai-features-dialog-key"

type Size = "large" | "medium" | "small" | "extraSmall"

export const sizes = (size: Size) => {
  const sizes: { [key in Size]: SxProps } = {
    large: { fontSize: "2.441rem !important" },
    medium: { fontSize: "1.953rem !important" },
    small: { fontSize: "1.25rem !important" },
    extraSmall: { fontSize: ".75rem !important" },
  }

  return sizes[size]
}

export const CreateDialogContainer: FC<{
  title: string
  dialogBody: JSX.Element
  onSave: () => void
  onClose: () => void
}> = ({ title, dialogBody, onSave, onClose }) => {
  const open = useModalKey(AI_FEATURES_DIALOG_KEY)

  const onProceed = () => {
    onSave()
    close()
  }

  const closeModal = () => {
    onClose()
    close()
  }

  return (
    <Dialog open={open} onClose={closeModal}>
      <Toolbar
        sx={{
          backgroundColor: theme.palette.primary.light,
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography variant="h3" color="white" component="span" sx={sizes("medium")}>
          {title}
        </Typography>
      </Toolbar>
      {dialogBody}
      <DialogActions>
        <Button onClick={onProceed} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
