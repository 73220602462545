import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { PromptsAutocomplete, SelectedPrompt } from "@components/shared/PromptsAutocomplete"
import { useFetchOrganization, usePatchOrganization } from "@src/api/admin/organizations"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import SaveIcon from "@mui/icons-material/Save"
import CloseIcon from "@mui/icons-material/Close"
import { getPrompts } from "@api/admin/prompts"

export const GenerateInsightCheckbox: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: promptsData } = getPrompts()
  const { data: organization } = useFetchOrganization(organizationId)
  const { mutate } = usePatchOrganization()

  const [enableGeneric, setEnableGeneric] = useState(organization?.summary_prompt_id !== null)
  const [selectedPrompt, setSelectedPrompt] = useState<SelectedPrompt | null>(null)

  const reset = () => {
    setSelectedPrompt(organization?.summary_prompt_id ? { id: organization.summary_prompt_id, name: "" } : null)
    setEnableGeneric(organization?.summary_prompt_id !== null)
  }

  useEffect(reset, [organization?.summary_prompt_id])

  let showSave = false
  if (organization) {
    if (organization.summary_prompt_id === null && enableGeneric === true) {
      showSave = true
    } else if (organization.summary_prompt_id !== null && enableGeneric === false) {
      showSave = true
    } else if (organization.summary_prompt_id !== null && enableGeneric === true) {
      if (organization.summary_prompt_id !== selectedPrompt?.id) {
        showSave = true
      }
    }
  }

  useEffect(() => {
    if (organization && organization.summary_prompt_id && promptsData) {
      const prompt = promptsData?.find((prompt) => prompt.id === organization.summary_prompt_id)
      if (prompt) {
        setSelectedPrompt({ id: prompt.id, name: prompt.name })
      }
    }
  }, [organization, promptsData])

  return (
    <Stack direction="row" alignItems="center" marginY={1} spacing={1}>
      <FormControlLabel
        control={<Checkbox checked={enableGeneric} onChange={(_, value) => setEnableGeneric(value)} />}
        label="Generate generic insight"
      />
      {enableGeneric && (
        <>
          <Box sx={{ width: 300 }}>
            <PromptsAutocomplete
              selectedPrompts={{ prompts: selectedPrompt, setPrompts: setSelectedPrompt }}
              enableMultiple={false}
            />
          </Box>
          {showSave && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  if (organization && organization.state_id) {
                    const mutateObject: Record<string, string | number | null> = {
                      id: organizationId,
                      summary_prompt_id: enableGeneric && selectedPrompt ? selectedPrompt.id : null,
                      state_id: organization.state_id,
                    }
                    if (organization.county_id) {
                      mutateObject.county_id = organization.county_id
                    }
                    if (organization.city_id) {
                      mutateObject.city_id = organization.city_id
                    }
                    mutate(mutateObject)
                  }
                }}
                endIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button variant="outlined" onClick={reset} endIcon={<CloseIcon />}>
                Cancel
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  )
}
