import { Button } from "@mui/material"
import { GridToolbarContainer } from "@mui/x-data-grid"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"

export function AddFeaturesToTable() {
  const history = useHistory()
  const openPlayground = () => {
    history.push("/admin/llm-playground")
  }

  return (
    <GridToolbarContainer sx={{ padding: 2 }}>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={openPlayground}>
        Add Feature
      </Button>
    </GridToolbarContainer>
  )
}
