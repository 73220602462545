import { FC, useState, useContext } from "react"
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material"
import { AdminUserContext } from "./context"
import { useSendInsightFromUser } from "../../../api/admin/users"
import { useToast } from "../../shared/ToastHook"

export const SendInsightModal: FC<{
  handleClose: () => void
  open: boolean
}> = ({ handleClose, open }) => {
  const contextData = useContext(AdminUserContext)
  const { ids } = contextData
  const [meetingId, setMeetingId] = useState("")
  const toast = useToast()
  const { mutate: sendInsight } = useSendInsightFromUser(ids[0], () => toast("Insight request received"))

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Send Insight</DialogTitle>
      <DialogContent>
        <TextField value={meetingId} onChange={(event) => setMeetingId(event.target.value)} label="Meeting Id" />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => sendInsight({ meeting_id: meetingId })}>
          Send Insight
        </Button>
      </DialogActions>
    </Dialog>
  )
}
