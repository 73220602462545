import { GridColDef } from "@mui/x-data-grid"

export const aiProductsColumns: GridColDef[] = [
  {
    field: "id",
    type: "number",
    headerName: "ID",
    flex: 0.25,
  },
  {
    field: "featureName",
    type: "string",
    headerName: "Feature Name",
    flex: 1,
  },
  {
    field: "productType",
    type: "string",
    headerName: "Product Type",
    flex: 1,
  },
  {
    field: "featureStatus",
    type: "string",
    headerName: "Status",
    flex: 1,
  },
  {
    field: "orchestratorType",
    type: "string",
    headerName: "Orchestrator",
    flex: 1,
  },
  {
    field: "createdAt",
    type: "dateTime",
    headerName: "Created At",
    flex: 1,
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
  {
    field: "updatedAt",
    type: "dateTime",
    headerName: "Updated At",
    flex: 1,
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
]
