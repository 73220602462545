import { FormControl, InputLabel, Select, SelectChangeEvent, DialogContent, Stack } from "@mui/material"
import { FC, useContext, useMemo } from "react"
import { AiChainRunnableSegmentTypes, PromptInputTypes } from "@interfaces/AiProducts/models"
import { getInputComponentByType, getMenuItemsBySegmentType } from "./inputTypeMapping"
import { EditChainRunnableInterface } from "../AiChainEditModal"
import { FeatureContext } from "@components/Admin/AiProducts/Playground/Features/FeatureContext"
import { FunnelOrchestratorContext } from "@components/Admin/AiProducts/Playground/Contexts/FunnelContext"
import { updateAiChainRunnable } from "@components/Admin/AiProducts/Playground/FunnelOrchestrator/aiChainRunnableCrud"
import { AiChainRunnable } from "@interfaces/AiProducts/models"
import { UsersAiSettingsContext } from "@components/Admin/AiProducts/Playground/Contexts/UsersSettingsContext"

export const InputData: FC<EditChainRunnableInterface> = (editChainProps) => {
  const { feature, updateState: updateFeature } = useContext(FeatureContext)
  const { userSettings, setUserSettings } = useContext(UsersAiSettingsContext)
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const { aiChainRunnable } = editChainProps
  const aiChainRunnableEditable = { ...aiChainRunnable }

  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(
      configuration,
      editChainProps.index,
      aiChainRunnableEditable,
      editChainProps.segmentType,
    )
    updateState(newConfig)
  }

  const updateSelect = (e: SelectChangeEvent<PromptInputTypes>): PromptInputTypes => {
    if (!e?.target?.value) {
      return PromptInputTypes.MEETING_IDS
    }
    return e.target.value as PromptInputTypes
  }

  const inputType = useMemo(() => {
    if (aiChainRunnableEditable.ordinal !== undefined) {
      return aiChainRunnableEditable.ordinal !== 0 ? PromptInputTypes.CHAIN_OUTPUT : aiChainRunnableEditable.inputType
    } else {
      return PromptInputTypes.CHAIN_OUTPUT
    }
  }, [aiChainRunnableEditable])

  return (
    <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Stack direction="column" spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="ai-chain-runnable-input-type">Input Type</InputLabel>
          <Select
            labelId="ai-chain-runnable-input-type"
            id="demo-simple-select"
            value={inputType}
            label="Input Type"
            disabled={
              aiChainRunnableEditable.segmentType === AiChainRunnableSegmentTypes.MIDDLE ||
              aiChainRunnableEditable.segmentType === AiChainRunnableSegmentTypes.TAIL
            }
            onChange={(e: SelectChangeEvent<PromptInputTypes>) => {
              setUserSettings({
                ...userSettings,
                selectedUserAiFeature: {
                  ...userSettings.selectedUserAiFeature,
                  inputSettings: [],
                },
              })
              updateFeature({
                ...feature,
                inputType: updateSelect(e),
              })
              update({
                ...aiChainRunnableEditable,
                inputType: e.target.value as PromptInputTypes | undefined,
              })
            }}
          >
            {getMenuItemsBySegmentType(aiChainRunnableEditable.segmentType)}
          </Select>
        </FormControl>
        {getInputComponentByType(inputType || PromptInputTypes.MEETING_IDS)}
      </Stack>
    </DialogContent>
  )
}
