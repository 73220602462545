export class UrlBuilder {
  #url: string
  collectionParams: string[] = []
  stringParams: string[] = []
  numberParams: { name: string; defaultValue: number }[] = []
  onOffParams: string[] = []

  constructor(urlPath: string) {
    this.#url = urlPath
  }

  build(params?: URLSearchParams) {
    let url = this.#url

    if (params) {
      const buildParams = this.#buildParamCollection(params)
      url += `?${new URLSearchParams(buildParams).toString()}`
    }

    return url
  }

  #buildParamCollection(params: URLSearchParams) {
    const paramsCollection: string[][] = []

    this.collectionParams.forEach((param) => {
      params.getAll(param).forEach((value) => paramsCollection.push([`${param}`, value]))
    })

    this.stringParams.forEach((param) => {
      const value = params.get(param)
      if (value) {
        paramsCollection.push([param, value])
      }
    })

    this.numberParams.forEach(({ name, defaultValue }) => {
      const value = parseInt(params.get(name) ?? defaultValue.toString())
      paramsCollection.push([name, value.toString()])
    })

    this.onOffParams.forEach((param) => {
      const value = params.get(param)
      if (value) {
        paramsCollection.push([param, (value === "On").toString()])
      }
    })

    return paramsCollection
  }
}
