import { FC, useContext } from "react"
import { Stack, Box, Tabs, Tab } from "@mui/material"
import { theme } from "@src/theme"
import { unpackSetters } from "@src/components/shared/unpackSetters"
import { AiManagementTablesContext, AiManagementTabs } from "./TabNavigationContext"
import { FeaturesTable } from "./Tables/Features/FeaturesTable"
import { LegacyPrompts } from "./Tables/LegacyPrompts"

export const TabNavigation: FC = () => {
  const { value, setValue } = useContext(AiManagementTablesContext)
  const { tab } = value
  const { setTab } = unpackSetters(value, setValue)

  const containers = {
    [AiManagementTabs.LEGACY_PROMPTS]: <LegacyPrompts />,
    [AiManagementTabs.PRODUCTS]: <FeaturesTable />,
    [AiManagementTabs.PROMPTS]: <LegacyPrompts />,
  }

  const tabStyle = {
    fontSize: "1.953em !important",
    fontWeight: 20,
    "&.Mui-selected": {
      fontWeight: "bold",
      borderBottom: "5px solid #566F88",
    },
    borderBottom: "none",
    borderColor: theme.palette.primary.main,
    backGroundColor: "#D1E1F0 !important",
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 30,
    },
  }

  return (
    <Stack
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          fontSize: "1.25rem",
        },
        "& .super-app-theme--red-row": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.primary.contrastText,
          ":hover": {
            backgroundColor: theme.palette.error.dark,
          },
        },
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      }}
      bgcolor="#FFFFFF"
      padding={3}
    >
      <Box fontSize="16px" fontFamily={"Trirong"} sx={{ marginBottom: 2 }}>
        <Tabs
          value={tab}
          onChange={(_e, value: AiManagementTabs) => setTab(value)}
          aria-label="ai-services tabs"
          textColor="primary"
          sx={{ fontWeight: "30 !important" }}
          indicatorColor="primary"
          variant="fullWidth"
          TabIndicatorProps={{ style: { backgroundColor: "white", borderBottom: "None" } }}
        >
          <Tab value={AiManagementTabs.LEGACY_PROMPTS} label="Legacy Prompts" component="h4" sx={tabStyle} />
          <Tab value={AiManagementTabs.PRODUCTS} label="Features" component="h4" sx={tabStyle} />
        </Tabs>
      </Box>
      {containers[tab]}
    </Stack>
  )
}
