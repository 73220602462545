import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModesModel,
  GridSlots,
  useGridApiRef,
} from "@mui/x-data-grid"
import { aiProductsColumns } from "./columns"
import { FC, useEffect, useState } from "react"
import { theme } from "@src/theme"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { handleRowEditStop, handleRowModesModelChange } from "../DataGrid/editing"
import { AddFeaturesToTable } from "./AddFeatureToTable"
import { useDeletelAiFeature, useFetchAiFeatures } from "@api/admin/AiProducts/aiFeatures"
import { useHistory } from "react-router-dom"
import { AiFeature } from "@interfaces/AiProducts/models"

export const FeaturesTable: FC = () => {
  const apiRef = useGridApiRef()
  const history = useHistory()

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  })
  const [rows, setRows] = useState<AiFeature[]>([])
  const { mutate: deleteAiFeature } = useDeletelAiFeature()
  const { data: aiFeaturesResponse, isLoading: productsAreLoading } = useFetchAiFeatures()
  useEffect(() => setRows(aiFeaturesResponse?.aiFeatures || []), [aiFeaturesResponse?.aiFeatures])
  const actionColumns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => history.push(`/admin/llm-playground/${id}`)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => deleteAiFeature(id as number)}
          color="inherit"
        />,
      ],
    },
  ]

  return (
    <>
      <DataGrid
        autoHeight
        sx={{
          '.MuiDataGrid-booleanCell[data-value="true"]': { color: theme.palette.success.main },
          '.MuiDataGrid-booleanCell[data-value="false"]': { color: "red" },
          "&, [class^=MuiDataGrid]": { borderTop: "none", borderLeft: "none", borderRight: "none" },
        }}
        editMode="row"
        density="compact"
        loading={productsAreLoading}
        rows={rows}
        columns={[
          ...aiProductsColumns.map((column) => ({ ...column, headerClassName: "super-app-theme--header" })),
          ...actionColumns,
        ]}
        onProcessRowUpdateError={(_error) => console.log("Error updating row")} // replace with a toast
        rowModesModel={rowModesModel}
        apiRef={apiRef}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowEditStop={handleRowEditStop}
        onRowModesModelChange={handleRowModesModelChange(setRowModesModel)}
        slots={{
          toolbar: AddFeaturesToTable as GridSlots["toolbar"],
        }}
        slotProps={{ toolbar: { setRows, setRowModesModel, rows } }}
      />
    </>
  )
}
