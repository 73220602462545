import { FC } from "react"
import { TabNavigationContext } from "./TabNavigationContext"
import { TabNavigation } from "./TabNavigation"

export const AiProductsPage: FC = () => {
  return (
    <TabNavigationContext>
      <TabNavigation />
    </TabNavigationContext>
  )
}
