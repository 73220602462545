import { FC, useContext, useEffect } from "react"
import { Button, Stack } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import EditIcon from "@mui/icons-material/Edit"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { AI_FEATURES_DIALOG_KEY } from "../Dialogs/DialogContainer"
import { AiChainRunnableValidator, AiFeatureValidator } from "@api/admin/AiProducts/validations"
import { FeatureContext } from "./Features/FeatureContext"
import { AiChainRunnable } from "@interfaces/AiProducts/models"
import { useUpsertAiChainRunnable } from "@api/admin/AiProducts/aiChainRunnables"
import { UserProfileEditor } from "./UserProfiles/UserProfileEditor"
import { useToast } from "@components/shared/ToastHook"

export const ActionBar: FC<{ aiChainRunnables: AiChainRunnable[] }> = ({ aiChainRunnables }) => {
  const open = useOpenModalKey(AI_FEATURES_DIALOG_KEY)
  const { feature } = useContext(FeatureContext)
  const { mutate: upsertAiChainRunnable, status } = useUpsertAiChainRunnable()

  useEffect(() => {
    if (status === "success") {
      setToast("Success", 3000)
    } else if (status === "error") {
      setToast("Something went wrong", 3000)
    }
  }, [status])
  const setToast = useToast()

  const saveConfiguration = () => {
    if (feature.id) {
      upsertAiChainRunnable({ aiFeatureId: feature.id, aiChainRunnables })
    }
  }

  let saveConfigurationDisabled = false

  if (aiChainRunnables.length === 0) {
    saveConfigurationDisabled = true
  }
  const badConfigurationExists = aiChainRunnables.find((runnable) => {
    return new AiChainRunnableValidator(runnable).validate() === false
  })

  if (badConfigurationExists) {
    saveConfigurationDisabled = true
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
      <Button
        color="primary"
        onClick={open}
        variant="text"
        sx={{ alignSelf: "baseline" }}
        startIcon={
          <EditIcon
            sx={{
              backgroundColor: "secondary",
              border: "secondary",
              borderRadius: 5,
              borderStyle: "solid",
              padding: 0.5,
              borderColor: "secondary",
            }}
          />
        }
        size="medium"
      />
      <Stack direction="row" spacing={2}>
        <UserProfileEditor />
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={saveConfigurationDisabled}
          sx={{ fontSize: 20, fontWeight: 700, height: 50, borderRadius: 8, minWidth: 120 }}
          onClick={saveConfiguration}
          startIcon={
            <SaveIcon
              sx={{
                backgroundColor: "secondary",
                border: "white",
              }}
            />
          }
        >
          Save Configuration
        </Button>
      </Stack>
    </Stack>
  )
}
