import { FC, useContext, useMemo } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Stack } from "@mui/material"
import { UsersAiSettingsContext } from "../Contexts/UsersSettingsContext"
import { useFetchUsers } from "@api/admin/users"
import { UsersAiFeatureValidator } from "@api/admin/AiProducts/validations"
import { UsersAiFeature } from "@interfaces/AiProducts/models"
import { useCreateUsersAiFeature, useUpdateUsersAiFeature } from "@api/admin/AiProducts/userAiFeatures"
import { useCloseModalKey, useModalKey } from "@components/shared/OpenModalHook"
import { useToast } from "@components/shared/ToastHook"

export const ASSIGN_USER_SETTINGS_MODAL_KEY = "ASSIGN_USER_SETTINGS_MODAL_KEY"

export const AssignSettingsDialog: FC = () => {
  const { userSettings } = useContext(UsersAiSettingsContext)
  const { data: users } = useFetchUsers()

  const setToast = useToast()
  const successToast = () => setToast("Success", 3000)
  const errorToast = () => setToast("Something went wrong", 3000)
  const { mutate: createUserAiFeature } = useCreateUsersAiFeature(successToast, errorToast)
  const { mutate: updateUserAiFeature } = useUpdateUsersAiFeature(successToast, errorToast)
  const selectedUser = useMemo(
    () => users?.find((user) => user.id === userSettings.selectedUserAiFeature.userId) || null,
    [users, userSettings.selectedUserAiFeature.userId],
  )
  const open = useModalKey(ASSIGN_USER_SETTINGS_MODAL_KEY)
  const close = useCloseModalKey(ASSIGN_USER_SETTINGS_MODAL_KEY)
  const hasInputSettings =
    userSettings.selectedUserAiFeature.inputSettings && userSettings.selectedUserAiFeature.inputSettings.length > 0

  const update = () => {
    const usersAiFeaturesValidator = new UsersAiFeatureValidator(userSettings.selectedUserAiFeature as UsersAiFeature)

    if (usersAiFeaturesValidator.validate()) {
      if (userSettings.selectedUserAiFeature.id) {
        updateUserAiFeature({ userAiFeature: usersAiFeaturesValidator.usersAiFeature as UsersAiFeature })
      } else {
        createUserAiFeature({ userAiFeature: usersAiFeaturesValidator.usersAiFeature as UsersAiFeature })
      }
    }
    close()
  }
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Assign Settings</DialogTitle>
      <DialogContent>
        <Typography component="span">Assign this feature to&nbsp;</Typography>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {selectedUser?.email}
        </Typography>
        <Stack spacing={2}>
          <Typography marginTop={3} component="span">
            Input Settings
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} component="span">
            {userSettings.selectedUserAiFeature.inputSettings?.join(",") || "None"}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button disabled={!hasInputSettings} onClick={update}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}
