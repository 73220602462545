import { FC, useEffect, useState, createContext, Dispatch, SetStateAction, useMemo } from "react"
import axios from "axios"
import { Button, IconButton, Stack, Typography, Fab, useMediaQuery, useTheme } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import SearchIcon from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"
import TuneIcon from "@mui/icons-material/Tune"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { useHistory, useLocation } from "react-router"
import { unpackSetters } from "../unpackSetters"
import { FilterParams } from "../../Home/interface"
import { DatePicker } from "../../Home/DatePicker"
import { defaultDateRange, defaultLocationFilterParams } from "../config"
import {
  useFetchTrialStatus,
  useFetchRecommendedCampaigns,
  useIsAdmin,
  useIsSuperAdmin,
  useFetchCurrentUser,
} from "../../../api/users"
import { TrialOverModal } from "../../Home/TrialOverModal"
import { SearchTerms } from "./searchTerms"
import { Filters } from "./filters"
import { filterModalKey, FilterModal } from "./filterModal"
import { dateRangeModalKey, DateRangeModal } from "./dateRangeModal"
import { useOpenModalKey } from "../OpenModalHook"
import { campaignUpdateModalKey, CampaignUpdateContainer } from "./CampaignUpdateContainer"
import { campaignSaveModalKey, CampaignSaveContainer } from "./CampaignSaveContainer"
import moment from "moment"
import { userCampaignSelected } from "../../Home/SearchContainerFunctions"
import { useFetchFilterList, useFetchMyCampaigns } from "../../../api/campaigns"
import { Campaign } from "../../../interfaces/campaign"
import { getFilteredOptions } from "../Selectors/Multiple/Channel"
import { useFetchOrganizations } from "../../../api/organizations"
import { SearchParams, SearchQueryParams } from "../../../interfaces/search"
import { CampaignSelectContainer } from "./CampaignSelect/CampaignSelectContainer"
import { parseUniqueIntegerListParam, stringifyListParam } from "@functions/generateURL"
import { vitallyTrack } from "@config/vitally"

interface SearchFormData {
  input: string
  advancedInput: string
  terms: Set<string>
  advancedTerms: Set<string>
  showAdvancedFilters: boolean
  showAdvancedSearch: boolean
  searchDateRange: [Date, Date]
  proximity: number | null
  exclude: boolean
  selectedPeopleIds: Set<number>
}

interface MeetingSearchFormContextTypes {
  searchFormData: SearchFormData
  setSearchFormData: Dispatch<SetStateAction<SearchFormData>>
  initialParams: SearchParams
  addTerm: () => void
  addAdvancedTerm: () => void
  updateSearchParams: (newSearchTerms?: Set<string>) => void
  filterParams: FilterParams
  setFilterParams: Dispatch<SetStateAction<FilterParams>>
  resetNumericFilters: () => void
  loadPeopleByVoiceId: boolean
}

export const MeetingSearchFormContext = createContext<MeetingSearchFormContextTypes>({
  searchFormData: {
    input: "",
    advancedInput: "",
    terms: new Set(),
    advancedTerms: new Set(),
    showAdvancedFilters: false,
    showAdvancedSearch: false,
    searchDateRange: [new Date(), new Date()],
    proximity: null,
    exclude: false,
    selectedPeopleIds: new Set(),
  },
  setSearchFormData: () => {
    /* placeholder for typescript */
  },
  initialParams: {
    searchTerms: [] as string[],
    mustIncludeTerms: [] as string[],
    base64FilterParams: null,
    campaignId: null,
    dateRange: [],
    searchDateRange: [new Date(), new Date()],
    displayDateRange: [new Date(), new Date()],
    page: 0,
    sortBy: "",
    proximity: null,
    exclude: false,
    selectedPeopleIds: new Set(),
  },
  addTerm: () => {
    /* placeholder for typescript */
  },
  addAdvancedTerm: () => {
    /* placeholder for typescript */
  },
  updateSearchParams: () => {
    /* placeholder for typescript */
  },
  filterParams: {
    states: [],
    cities: [],
    counties: [],
    organizations: [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
    voice_ids: [],
    title_search_terms: [],
    organization_search_terms: [],
  },
  setFilterParams: () => {
    /* placeholder for typescript */
  },
  resetNumericFilters: () => {
    /* placeholder for typescript */
  },
  loadPeopleByVoiceId: false,
})

const millisecondsDay = 1000 * 60 * 60 * 24

export const MeetingSearchForm: FC<{
  pathname?: string
  showCampaign?: boolean
}> = ({ pathname, showCampaign }) => {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { data: currentUser } = useFetchCurrentUser()
  const { data: isAdmin } = useIsAdmin()
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: organizations } = useFetchOrganizations()
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)

  const initialParams: SearchParams = {
    searchTerms: queryParams.getAll(SearchQueryParams.SearchTerms),
    mustIncludeTerms: queryParams.getAll(SearchQueryParams.MustIncludeTerms),
    base64FilterParams: queryParams.get(SearchQueryParams.FilterParams),
    dateRange: queryParams.getAll(SearchQueryParams.DateRange).map((dateValueString) => parseInt(dateValueString, 10)),
    campaignId: queryParams.get(SearchQueryParams.CampaignId),
    searchDateRange: defaultDateRange,
    displayDateRange: defaultDateRange,
    page: 1,
    sortBy: "Most Recent",
    proximity: queryParams.get(SearchQueryParams.Proximity)
      ? Number(queryParams.get(SearchQueryParams.Proximity))
      : null,
    exclude: queryParams.get(SearchQueryParams.Exclude) === "true" || false,
    selectedPeopleIds: parseUniqueIntegerListParam(queryParams.get(SearchQueryParams.SelectedPeopleIds) || ""),
  }
  const campaignId = initialParams.campaignId ? parseInt(initialParams.campaignId, 10) : null
  const dateRange = initialParams.dateRange
  if (dateRange.length) {
    initialParams.searchDateRange = [new Date(dateRange[0]), new Date(dateRange[1])]
    initialParams.displayDateRange = [new Date(dateRange[0]), new Date(dateRange[1])]
  }
  const history = useHistory()
  const [filterParams, setFilterParams] = useState<FilterParams>(() => {
    if (initialParams.base64FilterParams !== null) {
      return JSON.parse(atob(initialParams.base64FilterParams))
    }
    return defaultLocationFilterParams
  })
  const [trialOver, setTrialOver] = useState(false)
  const demographicFiltersFilled = Boolean(
    (filterParams.city_population &&
      ((filterParams.city_population[0] >= 0 && typeof filterParams.city_population[0] !== "string") ||
        (filterParams.city_population[1] >= 0 && typeof filterParams.city_population[1] !== "string"))) ||
      (filterParams.county_population &&
        ((filterParams.county_population[0] >= 0 && typeof filterParams.county_population[0] !== "string") ||
          (filterParams.county_population[1] >= 0 && typeof filterParams.county_population[1] !== "string"))) ||
      (filterParams.city_income &&
        ((filterParams.city_income[0] >= 0 && typeof filterParams.city_income[0] !== "string") ||
          (filterParams.city_income[1] >= 0 && typeof filterParams.city_income[1] !== "string"))) ||
      (filterParams.county_income &&
        ((filterParams.county_income[0] >= 0 && typeof filterParams.county_income[0] !== "string") ||
          (filterParams.county_income[1] >= 0 && typeof filterParams.county_income[1] !== "string"))),
  )

  const advancedFiltersFilled: boolean =
    demographicFiltersFilled ||
    filterParams.counties.length > 0 ||
    filterParams.cities.length > 0 ||
    filterParams.organizations.length > 0 ||
    (filterParams.voice_ids && filterParams.voice_ids.length > 0) ||
    (filterParams.organization_search_terms && filterParams.organization_search_terms.length > 0) ||
    (filterParams.title_search_terms && filterParams.title_search_terms.length > 0) ||
    false

  const [searchFormData, setSearchFormData] = useState<SearchFormData>({
    input: "",
    advancedInput: "",
    terms: new Set(initialParams.searchTerms),
    advancedTerms: new Set(initialParams.mustIncludeTerms),
    showAdvancedFilters: advancedFiltersFilled,
    showAdvancedSearch: Boolean(new Set(initialParams.mustIncludeTerms).size),
    searchDateRange: initialParams.searchDateRange,
    proximity: initialParams.proximity,
    exclude: initialParams.exclude,
    selectedPeopleIds: new Set<number>(),
  })
  const { input, advancedInput, terms, advancedTerms, showAdvancedSearch, searchDateRange } = searchFormData
  const {
    setInput,
    setAdvancedInput,
    setTerms,
    setAdvancedTerms,
    setShowAdvancedFilters,
    setShowAdvancedSearch,
    setSearchDateRange,
    setProximity,
    setExclude,
    setSelectedPeopleIds,
  } = unpackSetters(searchFormData, setSearchFormData)
  const { isError, error } = useFetchTrialStatus()
  const openSaveCampaign = useOpenModalKey(campaignSaveModalKey)
  const openUpdateCampaign = useOpenModalKey(campaignUpdateModalKey)
  const openDateRange = useOpenModalKey(dateRangeModalKey)
  const openFilters = useOpenModalKey(filterModalKey)

  const { data: myCampaignData } = useFetchMyCampaigns()
  const { data: campaignsData } = useFetchFilterList()
  const { data: recommendedCampaigns } = useFetchRecommendedCampaigns()

  const recommendedCampaignsIdSet = useMemo(() => {
    if (recommendedCampaigns) {
      return new Set(recommendedCampaigns.map((campaign) => campaign.id))
    }
  }, [recommendedCampaigns])

  useEffect(() => {
    if (isError === true && axios.isAxiosError(error)) {
      if (error?.response?.data?.errors?.[0] === "Account Expired") {
        setTrialOver(true)
      }
    }
  }, [isError])

  useEffect(() => {
    let shouldUpdate = false
    if (
      initialParams.campaignId &&
      !queryParams.has(SearchQueryParams.SearchTerms) &&
      !queryParams.has(SearchQueryParams.MustIncludeTerms)
    ) {
      let campaign:
        | {
            data: {
              searchTerms?: string[]
              mustIncludeTerms?: string[]
              filterParams: FilterParams
              proximity?: number | null
              exclude?: boolean
            }
          }
        | undefined = myCampaignData?.campaigns.find((campaign) => campaign.id === campaignId)
      if (!campaign) {
        campaign = campaignsData?.campaigns.find((campaign: Campaign) => campaign.id === campaignId)
      }
      if (!campaign) {
        campaign = recommendedCampaigns?.find((campaign) => campaign.id === campaignId)
      }
      if (campaign) {
        shouldUpdate = true
        queryParams.delete(SearchQueryParams.SearchTerms)
        campaign.data.searchTerms
          ?.filter((term) => term)
          .forEach((term) => {
            queryParams.append(SearchQueryParams.SearchTerms, term)
          })
        queryParams.delete(SearchQueryParams.MustIncludeTerms)
        campaign.data.mustIncludeTerms
          ?.filter((term) => term)
          .forEach((term) => {
            queryParams.append(SearchQueryParams.MustIncludeTerms, term)
          })
        queryParams.set(SearchQueryParams.FilterParams, btoa(JSON.stringify(campaign.data.filterParams)))
        if (campaign.data.proximity) {
          queryParams.set(SearchQueryParams.Proximity, campaign.data.proximity.toString())
        }
        if (campaign.data.exclude) {
          queryParams.set(SearchQueryParams.Exclude, "true")
        }
      }
    }
    if (
      (queryParams.has(SearchQueryParams.SearchTerms) ||
        queryParams.has(SearchQueryParams.MustIncludeTerms) ||
        queryParams.has(SearchQueryParams.FilterParams)) &&
      queryParams.getAll(SearchQueryParams.DateRange).length < 2
    ) {
      shouldUpdate = true
      queryParams.delete(SearchQueryParams.DateRange)
      queryParams.append(SearchQueryParams.DateRange, `${defaultDateRange[0].valueOf()}`)
      queryParams.append(SearchQueryParams.DateRange, `${defaultDateRange[1].valueOf()}`)
    }
    if (shouldUpdate) {
      history.replace({ search: queryParams.toString() })
    }
  }, [locationSearchParams, myCampaignData, campaignsData, recommendedCampaigns])

  useEffect(() => {
    setTerms(new Set(initialParams.searchTerms))
    setAdvancedTerms(new Set(initialParams.mustIncludeTerms))
    setProximity(initialParams.proximity)
    setExclude(initialParams.exclude)
    setSelectedPeopleIds(initialParams.selectedPeopleIds)
    if (initialParams.base64FilterParams !== null) {
      setFilterParams(JSON.parse(atob(initialParams.base64FilterParams)))
    } else {
      setFilterParams(defaultLocationFilterParams)
    }
    setShowAdvancedFilters(advancedFiltersFilled)
    setShowAdvancedSearch(Boolean(new Set(initialParams.mustIncludeTerms).size))
  }, [locationSearchParams])

  const addTerm = () => {
    vitallyTrack(currentUser, "Meeting Add Search Term", {
      term: input.trim(),
    })
    setTerms((prevTerms) => {
      prevTerms.add(input.trim())
      return new Set(prevTerms)
    })
    setInput("")
  }
  const addAdvancedTerm = () => {
    setAdvancedTerms((prevTerms) => {
      prevTerms.add(advancedInput.trim())
      return new Set(prevTerms)
    })
    setAdvancedInput("")
  }
  const resetNumericFilters = () =>
    setFilterParams((prevFilterParams) => ({
      ...prevFilterParams,
      city_population: [-1, -1],
      county_population: [-1, -1],
      city_income: [-1, -1],
      county_income: [-1, -1],
    }))

  const updateSearchParams = (newSearchTerms?: Set<string>) => {
    if (input) {
      addTerm()
    }
    if (advancedInput) {
      addAdvancedTerm()
    }
    queryParams.delete(SearchQueryParams.SearchTerms)
    ;(newSearchTerms || terms).forEach((term) => {
      queryParams.append(SearchQueryParams.SearchTerms, term)
    })
    queryParams.delete(SearchQueryParams.MustIncludeTerms)
    advancedTerms.forEach((term) => {
      queryParams.append(SearchQueryParams.MustIncludeTerms, term)
    })
    queryParams.delete(SearchQueryParams.DateRange)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[0].valueOf()}`)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[1].valueOf()}`)
    queryParams.set(SearchQueryParams.FilterParams, btoa(JSON.stringify(filterParams)))
    queryParams.set(SearchQueryParams.Page, "1")
    queryParams.set(SearchQueryParams.SortBy, "hits")
    queryParams.delete(SearchQueryParams.Proximity)
    queryParams.delete(SearchQueryParams.Exclude)
    queryParams.set(SearchQueryParams.SelectedPeopleIds, stringifyListParam(searchFormData.selectedPeopleIds))
    if (advancedTerms.size > 0) {
      if (searchFormData.proximity) {
        queryParams.set(SearchQueryParams.Proximity, searchFormData.proximity.toString())
      }
      if (searchFormData.exclude) {
        queryParams.set(SearchQueryParams.Exclude, searchFormData.exclude.toString())
      }
    }
    history.push({ pathname: pathname, search: queryParams.toString() })
  }

  const isCampaignOwner = userCampaignSelected(campaignId, myCampaignData?.campaigns)
  const isCampaignAdmin = useMemo(
    () => (isSuperAdmin ? true : isAdmin ? true : isCampaignOwner),
    [isAdmin, isSuperAdmin, isCampaignOwner],
  )

  const channelOptions = getFilteredOptions(filterParams, organizations ? organizations.organizations : [])
  const channelLength = useMemo(() => {
    if (filterParams.organizations.length > 0) {
      return filterParams.organizations.length
    }
    return channelOptions.length
  }, [channelOptions, filterParams.organizations])

  const loadPeopleByVoiceId = typeof showCampaign == "undefined" ? false : showCampaign

  const callVitallyTrackOnSearch = () => {
    vitallyTrack(
      currentUser,
      "Search Button Clicked",
      {
        searchTerms: Array.from(terms),
        anchorTerms: Array.from(advancedTerms),
        campaignName: myCampaignData?.campaigns.find((campaign) => campaign.id === campaignId)?.name,
        filterParams: filterParams,
        dateRange: searchDateRange,
        proximitySeconds: searchFormData.proximity,
        exclude: searchFormData.exclude,
      },
      true,
    )
  }

  return (
    <MeetingSearchFormContext.Provider
      value={{
        searchFormData,
        setSearchFormData,
        initialParams,
        addTerm,
        addAdvancedTerm,
        updateSearchParams,
        filterParams,
        setFilterParams,
        resetNumericFilters,
        loadPeopleByVoiceId,
      }}
    >
      <Stack alignItems="center" spacing={1}>
        {showCampaign && <CampaignSelectContainer />}
        <SearchTerms addSpacing={!showCampaign} />
        <Filters />
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography gutterBottom variant="body2" color="error" fontSize={12} display={{ xs: "none", sm: "flex" }}>
              {moment(searchDateRange[1]).diff(moment(searchDateRange[0]), "days") >= 8 &&
                "Searches longer than 1 week may perform slowly."}
            </Typography>
            <Fab
              onClick={openFilters}
              size="small"
              sx={{
                boxShadow: 2,
                display: { xs: "flex", sm: "none" },
              }}
            >
              <TuneIcon />
            </Fab>
            <Fab
              onClick={openDateRange}
              size="small"
              sx={{
                boxShadow: 2,
                display: { xs: "flex", sm: "none" },
              }}
            >
              <CalendarTodayIcon />
              <Typography position="absolute" top={12} fontSize="0.95em">
                {Math.floor((Date.now() - searchDateRange[0].valueOf()) / millisecondsDay)}
              </Typography>
            </Fab>
            <Fab
              onClick={() => {
                if (showAdvancedSearch) {
                  setAdvancedTerms(new Set())
                }
                setShowAdvancedSearch(!showAdvancedSearch)
              }}
              size="small"
              sx={{
                boxShadow: 2,
                display: { xs: "flex", sm: "none" },
              }}
              color={showAdvancedSearch ? "secondary" : "default"}
            >
              <SearchIcon sx={{ position: "relative", right: 2, top: 1 }} />
              <AddIcon
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  width: "0.75em",
                  height: "0.75em",
                }}
              />
            </Fab>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {!mobileScreen && (
              <Typography fontWeight={600} variant="body1">
                Searching {channelLength} Channels
              </Typography>
            )}
            <IconButton
              onClick={() => setFilterParams(defaultLocationFilterParams)}
              disabled={
                !filterParams.channel_types?.length &&
                !filterParams.cities?.length &&
                !filterParams.counties?.length &&
                !filterParams.states?.length &&
                !filterParams.organizations?.length &&
                !demographicFiltersFilled
              }
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <RefreshIcon />
            </IconButton>
            {showCampaign && (
              <>
                <Button
                  variant="outlined"
                  sx={{
                    flexShrink: "0",
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                  onClick={openUpdateCampaign}
                  disabled={
                    !isCampaignAdmin ||
                    !initialParams.campaignId ||
                    recommendedCampaignsIdSet?.has(parseInt(initialParams.campaignId))
                  }
                >
                  Update Campaign
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    flexShrink: "0",
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                  onClick={openSaveCampaign}
                >
                  Save New Campaign
                </Button>
              </>
            )}
            <DatePicker
              searchDateRange={searchDateRange}
              setSearchDateRange={setSearchDateRange}
              placement="bottomEnd"
              onOk={() => updateSearchParams()}
              sx={{ display: { xs: "none", sm: "flex" } }}
            />
            <Button
              variant="contained"
              sx={{
                paddingX: 4,
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => {
                callVitallyTrackOnSearch()
                updateSearchParams()
              }}
              size="large"
            >
              Search
            </Button>
            <Fab
              onClick={() => updateSearchParams()}
              color="primary"
              sx={{
                display: { xs: "flex", sm: "none" },
              }}
            >
              <SearchIcon fontSize="large" />
            </Fab>
          </Stack>
        </Stack>
        {mobileScreen && (
          <Stack direction="row" justifyContent="center" width="100%">
            <Typography fontWeight={600} variant="body1" marginLeft={1}>
              Searching {channelLength} Channels
            </Typography>
          </Stack>
        )}
        <TrialOverModal open={trialOver} />
        <CampaignSaveContainer pathname={pathname} />
        <CampaignUpdateContainer pathname={pathname} />
      </Stack>
      <FilterModal />
      <DateRangeModal />
    </MeetingSearchFormContext.Provider>
  )
}
