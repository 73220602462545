import { useFetchOpportunities } from "@api/admin/opportunities"
import { DataGrid, GridPaginationModel, GridRowSelectionModel, GridSortModel } from "@mui/x-data-grid"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { opportunityAdminColumns } from "@src/dataGridSchema/opportunities/opportunityColumns"
import { opportunityAdminRow } from "@src/dataGridSchema/opportunities/opportunityRows"
import { FC, useContext, useMemo, useState } from "react"
import { Toolbar } from "./Toolbar"
import { useGetFeatureFlagByName } from "@api/feature_flags"

export const OpportunitiesAdminDataGrid: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)
  const { data, isLoading } = useFetchOpportunities(params)
  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )

  let paginationModel = {
    pageSize: urlParamState.count,
    page: urlParamState.page,
  }

  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: params.get("sort") ?? "date",
      sort: (params.get("order") as "asc" | "desc") ?? "desc",
    },
  ])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const columns = opportunityAdminColumns(showNaicsCodesFilter?.value.toLowerCase() == "false")
  const rows = useMemo(() => {
    if (data?.opportunities) {
      return data.opportunities.map((opportunity) => opportunityAdminRow(opportunity))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
    setUrlParamState({
      ...urlParamState,
      page: model.page,
    })
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
    if (model.length > 0) {
      setUrlParamState({
        ...urlParamState,
        sort: model[0]?.field,
        order: model[0]?.sort || "asc",
        page: 0,
      })
    }
  }

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      columns={columns}
      disableColumnFilter
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      loading={isLoading || showNaicsCodesFilterLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={(newSelection) => setRowSelectionModel(newSelection)}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      rowSelectionModel={rowSelectionModel}
      slots={{ toolbar: Toolbar }}
      slotProps={{ toolbar: { rowSelectionModel, setRowSelectionModel } }}
      sortingMode="server"
      sortModel={sortModel}
      sortingOrder={["asc", "desc"]}
    />
  )
}
