import { RubyObject } from "../../interfaces/default"
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"

export interface BaseTranscriptionJob extends RubyObject {
  google_operation_id: number
  started_at: string
  finished_at: string
  needs_retry: boolean
  audio_file_uri: string
  indexed_at: string
  source_video_url: string
  meeting_id: number
  scraped_at: string
  transcoded_at: string
  transcribed_at: string
  video_file_uri: string
  host_type: string
  transcription_started_at: string
  transcription_failed_at: string
  transcription_retries: number
  diarized_at: string
  speakers_identified_at: string
  started_download_at: string
  in_progress: boolean
}

const prefix = "/admin"

export const useFetchTranscriptionJobByMeeting = (meetingId?: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["transcription_jobs", meetingId],
    async () => {
      const response = await (
        await axiosInstance
      ).get<BaseTranscriptionJob>(`${prefix}/meetings/${meetingId}/transcription_jobs`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}

export const useFetchTranscriptionJob = (transcription_job_id?: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["transcription_jobs", transcription_job_id],
    async () => {
      const response = await (
        await axiosInstance
      ).get<BaseTranscriptionJob>(`${prefix}/transcription_jobs/${transcription_job_id}`)

      return response.data
    },
    {
      enabled: transcription_job_id !== undefined,
    },
  )
}

export const useFetchTranscriptionJobs = (searchParams?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["transcription_jobs", searchParams?.toString()], async () => {
    const response = await (
      await axiosInstance
    ).get<BaseTranscriptionJob[]>(`${prefix}/transcription_jobs?${searchParams}`)

    return response.data
  })
}

export const useUpdateTranscriptionJob = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (transcription_job: BaseTranscriptionJob) => {
      return (await axiosInstance).put(`${prefix}/transcription_jobs/${transcription_job.id}`, transcription_job)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["meetings", "transcription_jobs"])
      },
    },
  )
}

export const useStartTranscriptionUat = (
  options?: Omit<
    UseMutationOptions<
      // any is copied from the original code
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      unknown,
      {
        count: number
        before: Date
      },
      unknown
    >,
    "mutationFn"
  >,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ count, before }: { count: number; before: Date }) => {
      const response = await (await axiosInstance).post(`${prefix}/transcription_jobs/start_uat`, { count, before })

      return response.data
    },
    {
      ...options,
      onSuccess: (
        // any is copied from the original code
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        variables: {
          count: number
          before: Date
        },
        context: unknown,
      ) => {
        queryClient.invalidateQueries([
          "transcription_jobs",
          new URLSearchParams({ selected_for_uat: "true" }).toString(),
        ])
        options?.onSuccess?.(data, variables, context)
      },
    },
  )
}
