import { FC, useMemo, useState } from "react"
import { Button, Stack } from "@mui/material"
import { InsightsUsersContext } from "./context"
import { EditInsightsUsersModal } from "./EditModal"
import { DeleteInsightsUsersModal } from "./DeleteModal"
import { getInsightsUsers } from "../../../../api/admin/organizations"
import { DataTable } from "../../../shared/DataTable"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "../../../../api/interfaces"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"

import { SelectedPrompt } from "../../../shared/PromptsAutocomplete"
import { getPrompts } from "../../../../api/admin/prompts"
import { InsightsUsersColumns } from "./columns"
import { GenerateInsightCheckbox } from "./GenerateInsightCheckbox"

export const InsightsUsersTable: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: promptsData } = getPrompts()
  const { data } = getInsightsUsers(organizationId)

  const [openModal, setOpenModal] = useState("")
  const [id, setId] = useState<number | null>(null)
  const [userId, setUserId] = useState<number | null>(null)
  const [email, setEmail] = useState("")
  const [promptInput, setPromptInput] = useState<SelectedPrompt[]>([])

  const insightsUsersData = useMemo(() => {
    if (data && promptsData) {
      return data.map((insightsUser) => ({
        ...insightsUser,
        prompts: insightsUser.prompts.map((promptId) => {
          const prompt = promptsData.find((prompt) => prompt.id === promptId)
          return { id: promptId, name: prompt?.name || "" }
        }),
      }))
    }
  }, [data, promptsData])

  const openEdit = () => setOpenModal("edit")

  return (
    <InsightsUsersContext.Provider
      value={{
        openModal,
        id,
        userId,
        email,
        promptsInput: promptInput,
        setId,
        setUserId,
        setPromptsInput: setPromptInput,
        close: () => {
          setOpenModal("")
          setId(null)
          setUserId(null)
          setEmail("")
          setPromptInput([])
        },
      }}
    >
      <GenerateInsightCheckbox />
      <Button variant="contained" sx={{ alignSelf: "flex-start", marginTop: 1 }} onClick={openEdit}>
        Create
      </Button>
      <DataTable
        data={insightsUsersData?.map((insightsUser) => ({
          ...insightsUser,
          actions: (
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                endIcon={<EditIcon />}
                onClick={() => {
                  setId(insightsUser.id)
                  setUserId(insightsUser.user_id)
                  setPromptInput(insightsUser.prompts)
                  openEdit()
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  setId(insightsUser.id)
                  setEmail(insightsUser.email)
                  setOpenModal("delete")
                }}
              >
                Delete
              </Button>
            </Stack>
          ),
        }))}
        columns={InsightsUsersColumns}
      />
      <EditInsightsUsersModal />
      <DeleteInsightsUsersModal />
    </InsightsUsersContext.Provider>
  )
}
