import { SystemUserData } from "@src/providers/userContextProvider"
import moment from "moment"

declare const Vitally: any

interface VitallyParams {
  accountId: string // A unique ID assigned by your codebase to each Organization (i.e. a database ID)
  accountTraits: {
    name: string // A 'name' trait is required
    // Add any other traits here you'd like to attach to your Accounts in Vitally
    createdAt?: string
    updatedAt?: string
    trialStartedAt?: string
    trialExpiredAt?: string
  }
  userId: string // A unique ID assigned by your codebase to each user (i.e. a database ID)
  userTraits: {
    name?: string
    email: string
    createdAt: string // When possible, we recommending setting this to the date the user first started an account with your product
    // Add any other traits here you'd like to attach to your users in Vitally
    updatedAt?: string
    beenInvited?: string
    onboardingFinishedAt?: string
  }
}

export const vitallyInit = (user: SystemUserData | undefined, marketing: boolean = false) => {
  let params = {} as VitallyParams

  if (!user && marketing) {
    params = {
      accountId: "FREE_USER_ORGANIZATION_ID",
      accountTraits: {
        name: "Free Users",
      },
      userId: "FREE_USER_ID",
      userTraits: {
        name: "Free User",
        email: "free_user@cloverleaf.ai",
        createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      },
    }
  } else if (user) {
    params = {
      accountId: user.user_organization_id.toString(),
      accountTraits: {
        name: user.user_organization_name,
      },
      userId: user.id.toString(),
      userTraits: {
        email: user.email,
        createdAt: user.created_at,
        updatedAt: user.updated_at,
        beenInvited: user.been_invited.toString(),
        onboardingFinishedAt: user.onboarding_finished_at || undefined,
      },
    }
  }

  if (params.accountId) {
    Vitally.account({
      accountId: params.accountId,
      traits: {
        ...params.accountTraits,
      },
    })

    Vitally.user({
      accountId: params.accountId,
      userId: params.userId,
      traits: {
        ...params.userTraits,
      },
    })
  }
}

export const vitallyTrack = (
  user: SystemUserData | undefined,
  event: string,
  properties: unknown,
  addSource = false,
) => {
  if (user && properties) {
    const environment = process.env.REACT_APP_GOOGLE_ENV || "development"

    const cleanedProperties = Object.fromEntries(
      Object.entries(properties).map(([key, value]) => [key, determineValue(value)]),
    )

    Vitally.track({
      userId: user.id.toString(),
      event: addSource ? `${obtainSource()} ${event}` : event,
      properties: {
        ...cleanedProperties,
        environment,
      },
    })
  }
}

const determineValue = (value: unknown) => {
  let newValue = value

  if (value === undefined || value === null) {
    newValue = null
  } else if (Array.isArray(value) && value.length === 0) {
    newValue = null
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    newValue = null
  } else if (typeof value === "string" && value.trim().length === 0) {
    newValue = null
  }

  return newValue
}

const obtainSource = () => {
  const location = window.location

  let source = location.pathname.split("/")[1] ? location.pathname.split("/")[1] : "home"
  source = source.charAt(0).toUpperCase() + source.slice(1).toLowerCase()
  if (["Meetings", "Persons"].includes(source)) {
    source = source.slice(0, -1)
  }

  return source
}
