import { FC, useState, createContext, useEffect } from "react"
import { List, Typography, Tab, Tabs, Skeleton, ListItem } from "@mui/material"
import { FetchedBookmarksItem, useFetchAllUserBookmarks, useFetchSharedBookmarks } from "../api/bookmarks"
import BookmarkItem from "../components/Bookmarks/BookmarkItem"
import { useFetchIndustryBookmarks } from "../api/admin/bookmarks"
import { ShareModal } from "../components/shared/ShareModal"
import { useOpenModalKey } from "../components/shared/OpenModalHook"
import AddIcon from "@mui/icons-material/Add"
import { CreateFolderModal, createFolderKey } from "../components/Bookmarks/CreateFolderModal"
import { Layout } from "../components/shared/Layout"
import { useIsSuperAdmin } from "../api/users"

export enum TabsEnum {
  MY_FOLDERS = "My Folders",
  SHARED_FOLDERS = "Public Folders",
  INDUSTRY_FOLDERS = "Industry Folders",
}

export const BookmarkContext = createContext({
  isSharedTab: false,
  tab: TabsEnum.MY_FOLDERS,
})

const BookmarksPage: FC = () => {
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: myBookmarksData, isLoading: myBookmarksLoading } = useFetchAllUserBookmarks()
  const { data: sharedBookmarksData, isLoading: sharedBookmarksLoading } = useFetchSharedBookmarks()
  const { data: industryBookmarksData, isLoading: industryBookmarksLoading } = useFetchIndustryBookmarks()
  const [bookmarks, setBookmarks] = useState<FetchedBookmarksItem[]>([])
  const openCreateBookmark = useOpenModalKey(createFolderKey)
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.MY_FOLDERS)

  useEffect(() => {
    if (tab === TabsEnum.MY_FOLDERS) {
      if (myBookmarksData) {
        setBookmarks(myBookmarksData)
      }
    } else if (tab === TabsEnum.SHARED_FOLDERS) {
      if (sharedBookmarksData) {
        setBookmarks(sharedBookmarksData)
      }
    } else {
      if (isSuperAdmin && industryBookmarksData) {
        setBookmarks(industryBookmarksData)
      }
    }
  }, [tab, myBookmarksData, sharedBookmarksData, industryBookmarksData])

  const emptyShareMessage = "Nobody in your organization has public bookmarks."
  const emptyMyBookmarkMessage = [
    "You haven't created any folders yet.",
    "Save interesting video clips here the next time you are viewing a meeting.",
  ]

  const isLoading = !bookmarks || (myBookmarksLoading && sharedBookmarksLoading && industryBookmarksLoading)

  return (
    <BookmarkContext.Provider value={{ isSharedTab: tab === TabsEnum.SHARED_FOLDERS, tab }}>
      <Layout
        title="Bookmarks"
        actions={[
          {
            action: openCreateBookmark,
            text: "new folder",
            icon: <AddIcon />,
            disabled: tab === TabsEnum.INDUSTRY_FOLDERS,
          },
        ]}
      >
        <List sx={{ width: "inherit" }}>
          <Tabs value={tab} onChange={(_e, v) => setTab(v)} sx={{ fontSize: 500, marginBottom: 2 }}>
            <Tab value={TabsEnum.MY_FOLDERS} label={<Typography variant="h6">{TabsEnum.MY_FOLDERS}</Typography>} />
            <Tab
              value={TabsEnum.SHARED_FOLDERS}
              label={<Typography variant="h6">{TabsEnum.SHARED_FOLDERS}</Typography>}
            />
            {isSuperAdmin && (
              <Tab
                value={TabsEnum.INDUSTRY_FOLDERS}
                label={<Typography variant="h6">{TabsEnum.INDUSTRY_FOLDERS}</Typography>}
                disabled={!industryBookmarksData}
              />
            )}
          </Tabs>
          {isLoading &&
            Array(3)
              .fill(1)
              .map((_element, index) => (
                <ListItem
                  key={index}
                  sx={{
                    borderRadius: 1,
                    marginBottom: 2,
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height={118} />
                </ListItem>
              ))}
          {bookmarks?.length === 0 && (
            <Typography color="primary.main" variant="h6" gutterBottom>
              {tab === TabsEnum.MY_FOLDERS ? (
                <>
                  {emptyMyBookmarkMessage[0]}
                  <br />
                  {emptyMyBookmarkMessage[1]}
                </>
              ) : (
                emptyShareMessage
              )}
            </Typography>
          )}
          {bookmarks?.map((bookmark) => {
            return <BookmarkItem key={`bookmark-container-${bookmark.id}`} bookmark={bookmark} />
          })}
        </List>
        <ShareModal />
        <CreateFolderModal />
      </Layout>
    </BookmarkContext.Provider>
  )
}

export default BookmarksPage
