import { FC, memo } from "react"
import { Card, CardContent } from "@mui/material"
import { TranscriptListItemDescription, SelectedTranscriptListItemDescription } from "./TranscriptListItemDescription"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { FetchedMeeting } from "@src/api/meetings"

const UnmemoedTranscriptListItem: FC<{
  transcript: TranscriptWithPerson
  searchTerm: string
  isSelected?: boolean
  meetingData: FetchedMeeting
}> = ({ transcript, searchTerm, isSelected = false, meetingData }) => {
  return (
    <Card
      sx={{
        border: isSelected ? 3 : 1,
        borderColor: "primary.dark",
        marginBottom: 1,
        position: "relative",
      }}
      variant="outlined"
    >
      <CardContent sx={{ paddingY: 0, "&:last-child": { paddingBottom: 0.5 } }}>
        {isSelected ? (
          <SelectedTranscriptListItemDescription
            transcript={transcript}
            searchTerm={searchTerm}
            meeting={meetingData}
          />
        ) : (
          <TranscriptListItemDescription transcript={transcript} searchTerm={searchTerm} meeting={meetingData} />
        )}
      </CardContent>
    </Card>
  )
}

export const TranscriptListItem = memo(UnmemoedTranscriptListItem)
