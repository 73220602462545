import {
  Grid,
  Box,
  Typography,
  Stack,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { UserOrganizationStep4Props } from "@src/interfaces/newUserOrgForm"
import { FC } from "react"
import { DialogActionsContainer } from "../NewUserOrganizationWizardGeneralContent"
import { RolesOptions } from "@src/interfaces/roles"

export const ReviewUserOrgFormFields: FC<UserOrganizationStep4Props> = ({
  newUserOrganization,
  setStep,
  handleOnClose,
  handleSubmit,
}) => {
  const handleClickBack = () => {
    setStep((prevVal) => prevVal - 1)
  }

  const handleRoleNames = (role_ids: number[]) => role_ids.map((role_id) => RolesOptions[role_id - 1].label).join(", ")

  return (
    <>
      <Grid
        item
        width={"100%"}
        justifyContent={"center"}
        display={"flex"}
        justifyItems={"center"}
        style={{ paddingTop: 0 }}
      >
        <Box justifyContent={"center"}>
          <Typography variant="h3">Review and Confirm</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h6" marginBottom={1}>
          Organization General Settings
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Hubspot Company ID</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>Salesforce Enabled</TableCell>
                <TableCell>Insights Enabled</TableCell>
                <TableCell>Speaker Identification Enabled</TableCell>
                <TableCell>Total Credits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{newUserOrganization.name}</TableCell>
                <TableCell>{newUserOrganization.hubspot_company_id}</TableCell>
                <TableCell>{newUserOrganization.industry}</TableCell>
                <TableCell>{newUserOrganization.salesforce_enabled ? "Yes" : "No"}</TableCell>
                <TableCell>{newUserOrganization.briefings_enabled ? "Yes" : "No"}</TableCell>
                <TableCell>{newUserOrganization.speaker_identification_enabled ? "Yes" : "No"}</TableCell>
                <TableCell>{newUserOrganization.total_credits}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid item>
        <Typography variant="h6">
          Total States Subscribed to: {newUserOrganization?.state_permissions?.length ?? 0}
        </Typography>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Grid item>
        <Typography variant="h6">Users</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Credit Limit</TableCell>
                <TableCell>About</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newUserOrganization.user_list?.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.email}</TableCell>
                  {user.role_ids ? (
                    <TableCell>{handleRoleNames(user.role_ids)}</TableCell>
                  ) : (
                    <TableCell>Not Defined</TableCell>
                  )}
                  <TableCell>{user.credit_limit}</TableCell>
                  <TableCell>{JSON.stringify(user.about)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <DialogActionsContainer
        handleOnClose={handleOnClose}
        handleOnBack={handleClickBack}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
