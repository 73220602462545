import { AiChainRunnable, AiFeature, UsersAiFeature } from "@interfaces/AiProducts/models"

export class AiChainRunnableValidator {
  aiChainRunnable

  constructor(aiChainRunnable: AiChainRunnable) {
    this.aiChainRunnable = aiChainRunnable
  }

  validateName(): boolean {
    return !!this.aiChainRunnable.aiPrompt.name
  }

  validateInputData(): boolean {
    return true
  }

  validatePrompt(): boolean {
    return this.validatePromptName() && this.validatePromptText()
  }

  validatePromptText(): boolean {
    return !!this.aiChainRunnable.aiPrompt.text
  }

  validatePromptName(): boolean {
    return !!this.aiChainRunnable.aiPrompt.name
  }

  validate(): boolean {
    return this.validateName() && this.validateInputData() && this.validatePrompt()
  }
}

export class UsersAiFeatureValidator {
  usersAiFeature

  constructor(usersAiFeature: Partial<UsersAiFeature>) {
    this.usersAiFeature = usersAiFeature
  }

  validateInputSettings(): boolean {
    return !!this.usersAiFeature.inputSettings && this.usersAiFeature.inputSettings.length > 0
  }

  validateUserId(): boolean {
    return !!this.usersAiFeature.userId
  }

  validateAiFeatureId(): boolean {
    return !!this.usersAiFeature.aiFeatureId
  }

  validate(): boolean {
    return this.validateInputSettings() && this.validateUserId() && this.validateAiFeatureId()
  }
}

export class AiFeatureValidator {
  aiFeature

  constructor(aiFeature: AiFeature) {
    this.aiFeature = aiFeature
  }

  validateName(): boolean {
    return !!this.aiFeature.featureName
  }

  validateProductType(): boolean {
    return !!this.aiFeature.productType
  }

  validateStatus(): boolean {
    return !!this.aiFeature.featureStatus
  }

  validateOrchestratorType(): boolean {
    return !!this.aiFeature.orchestratorType
  }

  validate(): boolean {
    return this.validateName() && this.validateProductType() && this.validateStatus() && this.validateOrchestratorType()
  }
}
