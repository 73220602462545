import { createContext, FC } from "react"
import { Box } from "@mui/material"
import { useLocation } from "react-router-dom"
import { Layout } from "../../components/shared/Layout"
import { InsightQueryObject, urlParamsToInsightQueryObject } from "../../api/insights"
import useSetUrlParamState from "../../components/shared/UseSetUrlParamState"
import { InsightsDataFiltersContainer } from "./InsightsDataFiltersContainer"
import { InsightsDataGrid } from "@components/Insights/DataGrid/InsightsDataGrid"

export interface InsightsContainerProps {
  params: URLSearchParams
  urlParamState: InsightQueryObject
  setUrlParamState: (newFilterParams: Record<string, unknown>) => void
}

export const InsightsContainerContext = createContext<InsightsContainerProps>({
  params: new URLSearchParams(""),
  urlParamState: {
    states: [],
    cities: [],
    counties: [],
    organizations: [],
    channel_types: [],
    meetings: [],
    city_population: [0, 0],
    county_population: [0, 0],
    city_income: [0, 0],
    county_income: [0, 0],
    types: [],
    users: [],
    text: "",
    sort: "",
    order: "",
    page: 0,
    count: 25,
    fine_tuning: "",
    generated_by_model: "",
  },
  setUrlParamState: () => {
    /* Something here for linter */
  },
})

export const InsightsContainer: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const urlParamState = urlParamsToInsightQueryObject(params)
  const setUrlParamState = useSetUrlParamState()

  return (
    <Layout title="Insights">
      <InsightsContainerContext.Provider value={{ params, urlParamState, setUrlParamState }}>
        <InsightsDataFiltersContainer />
        <Box marginTop={2}>
          <InsightsDataGrid />
        </Box>
      </InsightsContainerContext.Provider>
    </Layout>
  )
}
