import { AiFeature } from "@interfaces/AiProducts/models"
import React from "react"
import { createContext } from "react"

interface ContextProps {
  feature: AiFeature
  updateState: (newState: AiFeature) => void
}

export const FeatureContext = createContext<ContextProps>({
  feature: {} as AiFeature,
  updateState: () => {},
})

export const FeatureContextProvider: React.FC<{
  feature: AiFeature
  setFeature: (feature: AiFeature) => void
  children: React.ReactNode
}> = ({ feature, setFeature, children }) => {
  const updateState = (newState: AiFeature) => {
    setFeature({ ...feature, ...newState })
  }

  return <FeatureContext.Provider value={{ feature, updateState }}>{children}</FeatureContext.Provider>
}
