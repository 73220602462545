import { useGetFeatureFlagByName } from "@api/feature_flags"
import { useFetchOpportunities } from "@api/opportunities"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"
import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { opportunityColumns } from "@src/dataGridSchema/opportunities/opportunityColumns"
import { opportunityRow } from "@src/dataGridSchema/opportunities/opportunityRows"
import { url } from "inspector"
import { FC, useContext, useEffect, useMemo } from "react"

export const OpportunitiesDataGrid: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const { params, urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)
  const { data, isLoading, isFetching } = useFetchOpportunities(params)
  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )

  let paginationModel = {
    pageSize: urlParamState.count,
    page: urlParamState.page,
  }

  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const columns = opportunityColumns(showNaicsCodesFilter?.value.toLowerCase() == "false")
  const rows = useMemo(() => {
    if (data?.opportunities) {
      return data.opportunities.map((opportunity) => opportunityRow(opportunity))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
    setUrlParamState({
      ...urlParamState,
      page: model.page,
    })
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setUrlParamState({
      ...urlParamState,
      sort: model[0]?.field,
      order: model[0]?.sort || "asc",
      page: 0,
    })
  }

  useEffect(() => {
    if (isFetching && currentUser) {
      vitallyTrack(currentUser, "Opportunities Search", {
        anchorTerms: urlParamState.anchor_terms,
        campaign: urlParamState.campaign,
        channels: urlParamState.channel_types,
        cities: urlParamState.cities,
        count: urlParamState.count,
        counties: urlParamState.counties,
        industries: urlParamState.industry,
        order: urlParamState.order,
        page: urlParamState.page,
        searchTerms: urlParamState.terms,
        sort: urlParamState.sort,
        states: urlParamState.states,
      })
    }
  }, [isFetching, currentUser])

  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableColumnFilter
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      loading={isLoading || showNaicsCodesFilterLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      sortingMode="server"
    />
  )
}
