import { UsersAiFeature } from "@interfaces/AiProducts/models"
import { CreateUserAiFeatureRequest, UpdateUserAiFeatureRequest } from "@interfaces/AiProducts/requestObjectInterfaces"

export const serializeCreateUserAiFeatureRequest = (requestBody: UsersAiFeature): CreateUserAiFeatureRequest => {
  if (!requestBody || !requestBody.inputSettings || !requestBody.userId || !requestBody.aiFeatureId) {
    throw new Error("Missing required fields")
  }
  return {
    input_settings: requestBody.inputSettings,
    user_id: requestBody.userId,
    ai_feature_id: requestBody.aiFeatureId,
  }
}

export const serializeUpdateUserAiFeatureRequest = (requestBody: UsersAiFeature): UpdateUserAiFeatureRequest => {
  if (
    !requestBody ||
    !requestBody.inputSettings ||
    !requestBody.userId ||
    !requestBody.aiFeatureId ||
    !requestBody.id
  ) {
    throw new Error("Missing required fields")
  }
  return {
    id: requestBody.id,
    input_settings: requestBody.inputSettings,
    user_id: requestBody.userId,
    ai_feature_id: requestBody.aiFeatureId,
  }
}
