import { PromptingData, PromptingRequest } from "@interfaces/AiProducts/requestObjectInterfaces"
import { AxiosInstance } from "axios"
import { serializeAiChainRunnableRequest } from "@interfaces/AiProducts/serializers/requests/aiChainRunnables"
import { UsersAiFeature } from "@interfaces/AiProducts/models"

const resourcePath = "/minnie/ai-products/admin/briefings"

interface PromptingResponseTypes {
  multiple_responses: { [key: string]: string }
  single_response: string
}

export interface PromptingResponse {
  metadata: any
  response: PromptingResponseTypes
}

export const serializeRunInsightsRequest = (request: PromptingRequest) => {
  return request
}

export const runInsight = async (
  axiosInstance: Promise<AxiosInstance>,
  requestBody: PromptingData,
  usersFeatureSetting: UsersAiFeature,
) => {
  const body: PromptingRequest = {
    ai_chain_runnables: requestBody.aiChainRunnables.map((runnable) => serializeAiChainRunnableRequest(runnable)),
    orchestrator_type: requestBody.orchestratorType,
    user_feature_settings: {
      user_id: usersFeatureSetting.userId,
      input_settings: usersFeatureSetting.inputSettings,
    },
  }

  const uri = `${resourcePath}/run`
  const response = await (await axiosInstance).post<PromptingResponse>(uri, body)
  return response.data
}
