import { createContext, Dispatch, FC, SetStateAction, useState } from "react"

export enum AiManagementTabs {
  PRODUCTS = 0,
  PROMPTS = 3,
  LEGACY_PROMPTS = 6,
}

export const AiModalKeys = {
  [AiManagementTabs.PRODUCTS]: "AI_PRODUCTS",
  [AiManagementTabs.PROMPTS]: "AI_PROMPTS",
  [AiManagementTabs.LEGACY_PROMPTS]: "AI_LEGACY_PROMPTS",
}

export const AiModalTitles = {
  [AiManagementTabs.PRODUCTS]: "Product",
  [AiManagementTabs.PROMPTS]: "Prompt",
  [AiManagementTabs.LEGACY_PROMPTS]: "Legacy Prompt",
}

export const getModalKey = (tab: AiManagementTabs, crudOperation: "delete" | "update" | "create") => {
  return `${AiModalKeys[tab]}-${crudOperation}`
}

// cross resource filtering options
export interface AiManagementTablesContext {
  tab: AiManagementTabs
}

export const AiManagementTablesContext = createContext<{
  value: AiManagementTablesContext
  setValue: Dispatch<SetStateAction<AiManagementTablesContext>>
}>({
  value: {
    tab: AiManagementTabs.PRODUCTS,
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})

// wrap here for more table contexts
export const TabNavigationContext: FC = ({ children }) => {
  const [value, setValue] = useState<AiManagementTablesContext>({
    tab: 0,
  })

  return <AiManagementTablesContext.Provider value={{ value, setValue }}>{children}</AiManagementTablesContext.Provider>
}
