import { useFetchAiChainRunnables } from "@api/admin/AiProducts/aiChainRunnables"
import { AiChainRunnable, OrchestratorType, AiChainRunnableSegmentTypes } from "@interfaces/AiProducts/models"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { ReactNode, useContext, useState, useEffect, createContext } from "react"
import { FeatureContext } from "../Features/FeatureContext"

interface FunnelContextProps {
  configuration: FunnelOrchestratorEditor
  updateState: (newState: FunnelOrchestratorEditor) => void
}

export type InitialSegment = AiChainRunnable[]
export type MiddleSegment = AiChainRunnable[]
export type TailSegment = AiChainRunnable | null

export interface FunnelOrchestratorEditor {
  initialSegment: InitialSegment
  middleSegment: MiddleSegment
  tailSegment: TailSegment
  orchestratorType: OrchestratorType.FUNNEL
}

const defaultFunnelOrchestrator: FunnelOrchestratorEditor = {
  initialSegment: [] as InitialSegment,
  middleSegment: [] as MiddleSegment,
  tailSegment: null as TailSegment,
  orchestratorType: OrchestratorType.FUNNEL,
}

export const editablePrompt = {
  id: undefined,
  name: "",
  text: "",
}

export const editableInitialAiChain: AiChainRunnable = {
  aiPrompt: editablePrompt,
  aiModel: { name: "gemini-2.0-flash-exp" },
  aiOutputFormatter: { name: "String Ouput" },
  segmentType: AiChainRunnableSegmentTypes.INITIAL,
  ordinal: 0,
}

export const FunnelOrchestratorContext = createContext<FunnelContextProps>({
  configuration: defaultFunnelOrchestrator,
  updateState: () => {},
})

export const FunnelOrchestratorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { feature } = useContext(FeatureContext)
  const urlParams: CommonUrlParams = { attributeFilters: { aiFeatureId: feature.id || 0 } }
  const { data: aiChainRunnables } = useFetchAiChainRunnables(urlParams, !!feature.id)

  const [configuration, setConfiguration] = useState<FunnelOrchestratorEditor>(defaultFunnelOrchestrator)

  useEffect(() => {
    if (aiChainRunnables) {
      const initialSegment = aiChainRunnables.filter(
        (runnable) =>
          runnable.segmentType === AiChainRunnableSegmentTypes.INITIAL ||
          runnable.segmentType === AiChainRunnableSegmentTypes.TOTAL,
      )
      const middleSegment = aiChainRunnables.filter(
        (runnable) => runnable.segmentType === AiChainRunnableSegmentTypes.MIDDLE,
      )
      const tailSegment = aiChainRunnables.find((runnable) => runnable.segmentType === AiChainRunnableSegmentTypes.TAIL)

      setConfiguration({
        ...configuration,
        initialSegment,
        middleSegment,
        tailSegment: tailSegment || null,
        orchestratorType: OrchestratorType.FUNNEL,
      })
    }
  }, [aiChainRunnables])

  const updateState = (newState: FunnelOrchestratorEditor) => {
    setConfiguration({ ...newState })
  }

  return (
    <FunnelOrchestratorContext.Provider value={{ configuration, updateState }}>
      {children}
    </FunnelOrchestratorContext.Provider>
  )
}
