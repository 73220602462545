import { FC, useState } from "react"
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Stack } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject } from "@api/opportunities"
import {
  useFetchOpportunityCampaignsMap,
  useUpdateOpportunityCampaigns,
  useUpdateOpportunityCampaignsOwnRelation,
} from "@src/api/opportunityCampaigns"
import { useFetchOpportunityCampaignsUsersMap } from "@src/api/opportunityCampaignsUsers"
import { ConfirmationModal } from "@src/components/shared/ConfirmationModal"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { useToast } from "../../shared/ToastHook"
import { EmailCadence } from "@src/interfaces/campaign"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"

const UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY = "UPDATE_OPPORTUNITY_CAMPAIGN"

export const UpdateCampaignButton: FC = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : undefined

  const urlParamState = urlParamsToOpportunityQueryObject(params)

  const { data: opportunityCampaigns } = useFetchOpportunityCampaignsMap()
  const { data: opportunityCampaignsUsers } = useFetchOpportunityCampaignsUsersMap()
  const toast = useToast()

  const { mutate: updateOpportunityCampaign } = useUpdateOpportunityCampaigns(campaignId, () =>
    toast("Campaign successfully updated"),
  )
  const { mutate: updateOpportunityCampaignsOwnRelation } = useUpdateOpportunityCampaignsOwnRelation(campaignId, {
    onSuccess: () => {
      toast("Campaign Permissions successfully updated")
    },
  })

  const [campaignName, setCampaignName] = useState("")
  const [campaignEmailCadence, setCampaignEmailCadence] = useState<EmailCadence>("Unsubscribed")
  const openUpdateOpportunityCampaignModal = useOpenModalKey(UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY)

  const callVitallyTrack = () => {
    vitallyTrack(currentUser, "Opportunities Update Campaign", {
      campaignName,
      campaignEmailCadence,
      searchTerms: urlParamState.terms,
      anchorTerms: urlParamState.anchor_terms,
      industries: urlParamState.industry,
      states: urlParamState.states,
      counties: urlParamState.counties,
      cities: urlParamState.cities,
      channels: urlParamState.channel_types,
    })
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          openUpdateOpportunityCampaignModal()
          if (campaignId) {
            setCampaignName(opportunityCampaigns?.get(campaignId)?.name || "")
            setCampaignEmailCadence(opportunityCampaignsUsers?.get(campaignId)?.email_cadence || "Unsubscribed")
          }
        }}
        disabled={!campaignId || !opportunityCampaignsUsers?.get(campaignId)?.can_edit}
      >
        Update Campaign
      </Button>
      <ConfirmationModal
        title="Update Opportunity Campaign"
        body={
          <Stack spacing={1} marginTop={1}>
            <TextField
              label="Campaign Name"
              value={campaignName}
              onChange={(event) => setCampaignName(event.target.value)}
              sx={{ width: "64ch", marginTop: 1 }}
              required
            />
            <FormControl>
              <InputLabel>Email Cadence</InputLabel>
              <Select
                value={campaignEmailCadence}
                label="Email Cadence"
                onChange={(event) => {
                  setCampaignEmailCadence(event.target.value as EmailCadence)
                }}
              >
                <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        }
        modalKey={UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY}
        onConfirm={() => {
          callVitallyTrack()
          updateOpportunityCampaign({ name: campaignName, data: urlParamState })
          updateOpportunityCampaignsOwnRelation({ email_cadence: campaignEmailCadence })
          setCampaignName("")
        }}
        onCancel={() => setCampaignName("")}
      />
    </>
  )
}
