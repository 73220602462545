import { useFetchAiFeaturesMetadata } from "@api/admin/AiProducts/aiFeatures"
import {
  DialogContent,
  FormControl,
  Typography,
  OutlinedInput,
  Autocomplete,
  SxProps,
  TextField,
  Stack,
} from "@mui/material"
import { FC } from "react"
import { RubyEnum } from "@api/interfaces"
import { AiFeature, OrchestratorType } from "@interfaces/AiProducts/models"
import { AiProductType } from "@interfaces/AiProducts/models"
import { AiFeatureStatus } from "@interfaces/AiProducts/models"

type Size = "large" | "medium" | "small" | "extraSmall"

export const sizes = (size: Size) => {
  const sizes: { [key in Size]: SxProps } = {
    large: { fontSize: "2.441rem !important" },
    medium: { fontSize: "1.953rem !important" },
    small: { fontSize: "1.25rem !important" },
    extraSmall: { fontSize: ".75rem !important" },
  }

  return sizes[size]
}

export const CreateDialog: FC<{
  feature: AiFeature
  setFeature: React.Dispatch<React.SetStateAction<AiFeature>>
}> = ({ feature, setFeature }) => {
  const updateFeature = (updatedFeature: AiFeature) => {
    const currentFeature = { ...feature }
    setFeature({ ...currentFeature, ...updatedFeature })
  }

  const { data: metadata } = useFetchAiFeaturesMetadata()

  return (
    <DialogContent>
      <Stack width={500} direction="column" justifyContent="center" spacing={2}>
        <FormControl fullWidth variant="outlined" sx={{ alignItems: "left" }}>
          <Typography sx={{ padding: 1, ...sizes("small") }} variant="h5">
            Feature Name
          </Typography>
          <OutlinedInput
            id="ai-product-type"
            sx={{ "#ai-product-type": sizes("small") as React.CSSProperties }}
            className={feature.featureName ? "" : "required-input-field"}
            fullWidth
            onChange={(e) => updateFeature({ ...feature, featureName: e.target.value })}
            value={feature.featureName}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" sx={{ alignItems: "center" }}>
          <Typography sx={{ padding: 0, ...sizes("small") }} variant="h6">
            Orchestrator Type
          </Typography>
          <Autocomplete
            fullWidth
            options={metadata?.orchestrator_type || []}
            renderOption={(props, option: RubyEnum<OrchestratorType>) => {
              return (
                <li {...props}>
                  <Typography typography="body1" sx={{ padding: 0, ...sizes("small") }}>
                    {option.id}
                  </Typography>
                </li>
              )
            }}
            sx={{ "& fieldset": { borderRadius: 0 } }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={feature.orchestratorType ? "" : "required-input-field"}
                sx={{ borderRadius: "0 !important" }}
              />
            )}
            inputValue={feature.orchestratorType || ""}
            getOptionLabel={(option) => option.value}
            value={metadata?.orchestrator_type.find((option) => option.value === feature.orchestratorType)}
            onChange={(e, value) =>
              updateFeature({ ...feature, orchestratorType: value?.value || OrchestratorType.FUNNEL })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" sx={{ alignItems: "center" }}>
          <Typography sx={{ padding: 0, ...sizes("small") }} variant="h6">
            Product Type
          </Typography>
          <Autocomplete
            fullWidth
            disablePortal
            getOptionLabel={(option) => option.value}
            options={metadata?.product_type || []}
            renderOption={(props, option: RubyEnum<AiProductType>) => {
              return (
                <li {...props}>
                  <Typography>{option.id}</Typography>
                </li>
              )
            }}
            sx={{ "& fieldset": { borderRadius: 0 } }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={feature.productType ? "" : "required-input-field"}
                sx={{ borderRadius: "0 !important" }}
              />
            )}
            inputValue={feature.productType || ""}
            value={metadata?.product_type.find((option) => option.value === feature.productType) || null}
            onChange={(e, value) => updateFeature({ ...feature, productType: value?.value || AiProductType.INSIGHTS })}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" sx={{ alignItems: "center" }}>
          <Typography sx={{ padding: 0, ...sizes("small") }} variant="h6">
            Status
          </Typography>
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => option.value}
            options={metadata?.feature_status || []}
            inputValue={feature.featureStatus || ""}
            renderOption={(props, option: RubyEnum<AiFeatureStatus>) => {
              return (
                <li {...props}>
                  <Typography>{option.id}</Typography>
                </li>
              )
            }}
            sx={{ "& fieldset": { borderRadius: 0 } }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={feature.featureStatus ? "" : "required-input-field"}
                sx={{ borderRadius: "0 !important" }}
              />
            )}
            value={metadata?.feature_status.find((option) => option.value === feature.featureStatus) || null}
            onChange={(e, value) => updateFeature({ ...feature, featureStatus: value?.value || AiFeatureStatus.DRAFT })}
          />
        </FormControl>
      </Stack>
    </DialogContent>
  )
}
