import { useAuthAxios } from "@api/axiosInstance"
import { AiPromptResponse } from "@interfaces/AiProducts/responseObjectInterfaces"
import { serializeAiPromptResponse } from "@interfaces/AiProducts/serializers/responses/serializers"
import { QueryParamsBuilder } from "@interfaces/content-acquisition/queryParamsBuilder"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { useQuery } from "react-query"
import { namespace } from "./shared"

const resourcePath = `${namespace}/ai-prompts`

export interface FetchAiPromptsResponse {
  ai_prompts: AiPromptResponse[]
}

export const useFetchAiPrompts = (params?: CommonUrlParams, enabled?: boolean) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(
    ["ai-prompts"],
    async () => {
      const response = await (await axiosInstance).get<FetchAiPromptsResponse>(uri)

      return response?.data?.ai_prompts.map((aiPrompts) => serializeAiPromptResponse(aiPrompts))
    },
    {
      enabled,
    },
  )
}
