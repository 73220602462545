import { useFetchGeographiesMap } from "@src/api/geographies"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { useFetchIndustryIdsMap } from "@src/api/industries"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"

export const createFilterTexts = (
  industry: string[] | undefined,
  naics_code: string[] | undefined,
  organizations: number[] | undefined,
  cities: number[] | undefined,
  counties: number[] | undefined,
  states: number[] | undefined,
) => {
  const filterTexts: string[] = []

  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const { data: organizationsMap } = useFetchOrganizationsMap()
  const { data: geographiesMap } = useFetchGeographiesMap()

  if (industry?.length) {
    filterTexts.push(
      industry
        .map((industryId) => {
          const industry = industriesDataMap?.get(industryId)
          return industry?.name
        })
        .join(", "),
    )
  } else if (naics_code?.length) {
    filterTexts.push(
      naics_code
        .map((code) => {
          const naicsCode = naicsCodesMap?.get(code)
          return `${naicsCode?.code}: ${naicsCode?.title}`
        })
        .join(", "),
    )
  }

  if (organizations?.length) {
    filterTexts.push(organizations?.map((id) => organizationsMap?.get(id)?.name).join(", "))
  }

  if (cities?.length) {
    filterTexts.push(cities?.map((id) => geographiesMap?.cities.get(id)?.name).join(", "))
  }
  if (counties?.length) {
    filterTexts.push(counties?.map((id) => geographiesMap?.counties.get(id)?.name).join(", "))
  }
  if (states?.length) {
    filterTexts.push(states?.map((id) => geographiesMap?.states.get(id)?.name).join(", "))
  }

  return filterTexts
}
