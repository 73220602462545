export const mapStringsToIntegers = (collection: string[] | Set<string>): number[] => {
  return Array.from(collection)
    .map((el) => {
      return parseInt(el, 10)
    })
    .filter((el) => {
      // parseInt can return NaN for blank or incorrect values, so we need to filter them out.
      // TS is not aware of it for some reason and assumes that parseInt always returns a number
      return !isNaN(el)
    })
}
