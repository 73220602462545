import { FC, useContext } from "react"
import { Stack, IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { MeetingSearchFormContext } from "."
import { ChannelSelectorMultiple } from "../Selectors/Multiple/Channel"
import { GeographySelectorMultiple } from "../Selectors/Multiple/Geographies"
import { TypeSelectorMultiple } from "../Selectors/Multiple/Types"
import { RangeSlider } from "../Selectors/Multiple/Range"
import { useFetchGeographies } from "../../../api/geographies"
import { useFetchOrganizations } from "../../../api/organizations"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"
import { PersonSelectorMultipleWrapper } from "src/components/shared/Persons/PersonSelector"
import { TitleSearchTermsWrapper } from "@components/shared/Persons/TitleSearchTerms"
import { OrganizationSearchTermsWrapper } from "@components/shared/Persons/OrganizationSearchTerms"
import { useFetchCurrentUser, useHasSpeakerIdentificationAccess } from "@api/users"

const filterSx = {
  ".MuiOutlinedInput-root": {},
}

const placeholderGeographies = { cities: [], counties: [], states: [] }

export const filterModalKey = "FILTER_MODAL"

export const FilterModal: FC = () => {
  const { searchFormData, setSearchFormData, filterParams, setFilterParams } = useContext(MeetingSearchFormContext)
  const { showAdvancedFilters } = searchFormData
  const { data: geographiesData } = useFetchGeographies()
  const { data: organizationsData } = useFetchOrganizations()
  const hasSpeakerIdentificationAccess = useHasSpeakerIdentificationAccess()

  const setSelectedPeopleVoiceIds = (newData: number[]) => {
    setFilterParams({
      ...filterParams,
      voice_ids: newData,
    })
  }

  const setSelectedPeopleIds = (newData: Set<number>) => {
    setSearchFormData({
      ...searchFormData,
      selectedPeopleIds: newData,
    })
  }

  const setSelectedTitleSearchTerms = (newData: string[]) => {
    setFilterParams({
      ...filterParams,
      title_search_terms: newData,
    })
  }

  const setSelectedOrganizationSearchTerms = (newData: string[]) => {
    setFilterParams({
      ...filterParams,
      organization_search_terms: newData,
    })
  }

  const closeModalKey = useCloseModalKey(filterModalKey)
  const open = useModalKey(filterModalKey)

  return (
    <Dialog open={open} onClose={closeModalKey} fullWidth>
      <DialogTitle>
        Filters
        <IconButton sx={{ position: "absolute", top: 4, right: 4 }} onClick={closeModalKey}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        <Stack spacing={1} width="100%">
          <GeographySelectorMultiple
            options={geographiesData || placeholderGeographies}
            label="State"
            type="states"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            size="small"
            sx={filterSx}
          />
          <GeographySelectorMultiple
            options={geographiesData || placeholderGeographies}
            label="County"
            type="counties"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            size="small"
            sx={filterSx}
          />
          <GeographySelectorMultiple
            options={geographiesData || placeholderGeographies}
            label="City"
            type="cities"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            size="small"
            sx={filterSx}
          />
          <TypeSelectorMultiple
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            size="small"
            sx={filterSx}
          />
          <ChannelSelectorMultiple
            options={organizationsData ? organizationsData.organizations : []}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            size="small"
            sx={filterSx}
          />
          {hasSpeakerIdentificationAccess && (
            <>
              <PersonSelectorMultipleWrapper
                selectedPeopleIds={searchFormData.selectedPeopleIds}
                setSelectedPeopleVoiceIds={setSelectedPeopleVoiceIds}
                setSelectedPeopleIds={setSelectedPeopleIds}
                size="small"
                sx={filterSx}
              />
              <TitleSearchTermsWrapper
                selectedSearchTerms={filterParams.title_search_terms}
                setSelectedSearchTerms={setSelectedTitleSearchTerms}
                size="small"
                sx={filterSx}
              />
              <OrganizationSearchTermsWrapper
                selectedSearchTerms={filterParams.organization_search_terms}
                setSelectedSearchTerms={setSelectedOrganizationSearchTerms}
                size="small"
                sx={filterSx}
              />
            </>
          )}
        </Stack>
        {showAdvancedFilters && (
          <>
            <Stack
              direction="row"
              justifyContent={{ xs: "space-evenly", md: "space-between" }}
              width="100%"
              display={{ xs: "none", sm: "flex" }}
            >
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="city_population"
                label="City Population"
              />
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="county_population"
                label="County Population"
              />
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="city_income"
                label="City Household Income"
              />
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="county_income"
                label="County Household Income"
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent={{ xs: "space-evenly", md: "space-between" }}
              width="100%"
              display={{ xs: "none", sm: "flex" }}
            >
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="city_income"
                label="City Household Income"
              />
              <RangeSlider
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterType="county_income"
                label="County Household Income"
              />
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
