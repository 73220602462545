import { Autocomplete, Skeleton, TextField } from "@mui/material"
import { getPrompts } from "@api/prompts"
import { FC, useContext } from "react"
import { InsightsContainerContext } from "@src/containers/InsightContainers/InsightsContainer"

export const InsightTypeAutocomplete: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(InsightsContainerContext)
  const { data: personalPrompts, isFetched: personalPromptsIsFetched } = getPrompts("personal")
  const { data: generalPrompts, isFetched: generalPromptsIsFetched } = getPrompts("general")

  return (
    <>
      {!personalPromptsIsFetched || !generalPromptsIsFetched || !personalPrompts || !generalPrompts ? (
        <Skeleton height={56} variant="rounded" sx={{ flex: 1 }} />
      ) : (
        <Autocomplete
          multiple
          options={[
            ...Array.from(generalPrompts.map(({ name }) => name)),
            ...Array.from(personalPrompts.map(({ name }) => name)),
          ]}
          renderInput={(params) => <TextField {...params} label="Type" />}
          sx={{ flex: 1 }}
          value={urlParamState.types}
          onChange={(_event, value) => setUrlParamState({ ...urlParamState, types: value || [], page: 0 })}
        />
      )}
    </>
  )
}
