import { useAuthAxios } from "@api/axiosInstance"
import { OpportunityIndexResult, OpportunityUrlBuilder, urlParamsToOpportunityQueryObject } from "@api/opportunities"
import { getPrefixForSearch } from "@api/search"
import { useIsOpportunitiesEnabled } from "@api/users"
import { useMutation, useQuery, useQueryClient } from "react-query"

const prefix = "/admin/opportunities"

export const useFetchOpportunities = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()
  const prefix = `${getPrefixForSearch()}/admin/opportunities`

  return useQuery(
    ["admin-opportunities", params && urlParamsToOpportunityQueryObject(params)],
    async () => {
      const url = new OpportunityUrlBuilder(prefix).build(params)
      const response = await (await axiosInstance).get<{ opportunities: OpportunityIndexResult[]; total: number }>(url)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}

export const useUpdateFineTuning = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ briefing_ids, fine_tuning }: { briefing_ids: number[]; fine_tuning: boolean }) => {
      return (await axiosInstance).patch(`${prefix}/update_fine_tuning`, {
        ids: briefing_ids,
        use_fine_tuning: fine_tuning,
      })
    },
    {
      onSuccess: () => {
        // Add timeout to allow for elasticsearch to update opportunity docs.
        // With no timeout 95% of time the UI would not reflect proper data until refreshed.
        setTimeout(() => {
          queryClient.invalidateQueries("admin-opportunities")
          if (onSuccess) {
            onSuccess()
          }
        }, 500)
      },
    },
  )
}

export const useFetchGeneratedByModelList = () => {
  const axiosInstance = useAuthAxios()

  return useQuery("admin-generated-by-model-list", async () => {
    const response = await (await axiosInstance).get<string[]>(`${prefix}/generated_by_model_list`)

    return response.data
  })
}
