import { createContext, FC, SetStateAction, useContext } from "react"
import { SubscribedUser } from "@api/interfaces"
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material"
import { SubscribersDialogContext } from "./SubscribersDialog"
import { getPrompts } from "@api/prompts"
import { FetchedCampaign } from "@api/admin/user_organizations"

export const CampaignListItemContext = createContext<{
  subscribersDialogOpen: boolean
  setSubscribersDialogOpen: React.Dispatch<SetStateAction<boolean>>
  campaign: FetchedCampaign
}>({
  subscribersDialogOpen: false,
  setSubscribersDialogOpen: () => {
    /* placeholder for typescript */
  },
  campaign: {} as FetchedCampaign,
})

export const SubscribersListItem: FC<{ user: SubscribedUser }> = ({ user }) => {
  const { data: prompts } = getPrompts("personal")
  const {
    emailCadences,
    setEmailCadences,
    generalBriefings,
    setGeneralBriefings,
    briefings,
    setBriefings,
    updateUserCampaignNotification,
  } = useContext(SubscribersDialogContext)
  const isOwner = (user_id: number) => {
    const context = useContext(CampaignListItemContext)
    return context.campaign.user?.id === user_id
  }

  const emailCadenceOnChange = (event: SelectChangeEvent<string>, user: SubscribedUser) => {
    setEmailCadences({ ...emailCadences, [user.user_id]: event.target.value })

    updateUserCampaignNotification({
      user_campaign_notification_id: user.user_campaign_notification_id,
      email_cadence: event.target.value,
      automated_general_briefings: generalBriefings[user.user_id],
      briefings: briefings[user.user_id],
    })
  }

  const generalInsightsOnChange = (event: SelectChangeEvent<boolean>, user: SubscribedUser) => {
    setGeneralBriefings({ ...generalBriefings, [user.user_id]: event.target.value === "true" })
    updateUserCampaignNotification({
      user_campaign_notification_id: user.user_campaign_notification_id,
      email_cadence: emailCadences[user.user_id],
      automated_general_briefings: event.target.value === "true",
      briefings: briefings[user.user_id],
    })
  }

  const insightsOnChange = (event: SelectChangeEvent<number[]>, user: SubscribedUser) => {
    setBriefings({ ...briefings, [user.user_id]: Array.isArray(event.target.value) ? event.target.value : [] })
    updateUserCampaignNotification({
      user_campaign_notification_id: user.user_campaign_notification_id,
      email_cadence: emailCadences[user.user_id],
      automated_general_briefings: generalBriefings[user.user_id],
      briefings: Array.isArray(event.target.value) ? event.target.value : [],
    })
  }

  return (
    <ListItem key={user.user_id} sx={{ marginBottom: 1 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack minWidth={180}>
          <Typography>
            {user.email}
            {isOwner(user.user_id) && " (owner)"}
          </Typography>
        </Stack>
        <Stack minWidth={140}>
          <FormControl fullWidth>
            <InputLabel id="signals-notifications-label">Signals Notifications</InputLabel>
            <Select
              labelId="signals-notifications-label"
              value={emailCadences[user.user_id]}
              onChange={(event) => emailCadenceOnChange(event, user)}
              label="Signals Notifications"
            >
              <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack minWidth={180}>
          <FormControl fullWidth>
            <InputLabel id="automated-general-insights-label">Automated General Insights</InputLabel>
            <Select
              labelId="automated-general-insights-label"
              value={generalBriefings[user.user_id]}
              onChange={(event) => generalInsightsOnChange(event, user)}
              label="Automated General Insights"
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel id="insights-label">Personal Insights</InputLabel>
          <Select
            multiple
            labelId="automated-general-insights-label"
            value={briefings[user.user_id] || []}
            onChange={(event) => insightsOnChange(event, user)}
            label="Personal Insights"
          >
            {prompts?.map((prompt) => (
              <MenuItem key={prompt.id} value={prompt.id}>
                {prompt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </ListItem>
  )
}
