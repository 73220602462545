import { Autocomplete, TextField, Typography, InputAdornment } from "@mui/material"
import { FC, useEffect, useState, useContext, useMemo } from "react"
import { FetchedUsers, useFetchUsers } from "@api/admin/users"
import { UsersAiSettingsContext } from "@components/Admin/AiProducts/Playground/Contexts/UsersSettingsContext"
import { useFetchUsersAiFeature } from "@api/admin/AiProducts/userAiFeatures"
import { FeatureContext } from "../Features/FeatureContext"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { useFetchCurrentUser } from "@api/users"
import SaveIcon from "@mui/icons-material/Save"
import { ASSIGN_USER_SETTINGS_MODAL_KEY, AssignSettingsDialog } from "./AssignSettingsDialog"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { UsersAiFeatureValidator } from "@api/admin/AiProducts/validations"

export const UserProfileEditor: FC = () => {
  const { feature } = useContext(FeatureContext)
  const { userSettings, setUserSettings } = useContext(UsersAiSettingsContext)
  const { data: users } = useFetchUsers()
  const { data: user } = useFetchCurrentUser()
  const openAssignModal = useOpenModalKey(ASSIGN_USER_SETTINGS_MODAL_KEY)

  const usersAiFeaturesParams: CommonUrlParams = useMemo(() => {
    return {
      attributeFilters: {
        userId: userSettings?.selectedUserAiFeature?.userId
          ? userSettings?.selectedUserAiFeature?.userId
          : user?.id ?? 0,
      },
    }
  }, [user?.id, userSettings?.selectedUserAiFeature?.userId])

  const { data: usersAiFeatures } = useFetchUsersAiFeature(
    feature.id,
    usersAiFeaturesParams,
    !!user?.id && !!feature.id,
  )
  const [userSearch, setUserSearch] = useState<string | null>(null)

  const handleUserSearchChange = (e: React.SyntheticEvent, value: FetchedUsers | string | null) => {
    if (typeof value === "string" || !value) {
      setUserSearch(value)
      setUserSettings({
        ...userSettings,
        selectedUserAiFeature: { aiFeatureId: feature.id as number, inputSettings: [] },
      })
    } else if (value?.id) {
      setUserSearch(null)
      setUserSettings({
        ...userSettings,
        selectedUserAiFeature: { userId: value.id, aiFeatureId: feature.id as number, inputSettings: [] },
      })
    }
  }

  const handleDelete = (e: React.SyntheticEvent, value: FetchedUsers | string | null) => {
    if (!value) {
      setUserSearch(null)
      setUserSettings({
        ...userSettings,
        selectedUserAiFeature: { aiFeatureId: feature.id as number, inputSettings: [] },
      })
    }

    if (typeof value === "string") {
      setUserSearch(value)
    }
  }

  useEffect(() => {
    if (user) {
      setUserSettings({
        ...userSettings,
        selectedUserAiFeature: { userId: user.id, aiFeatureId: feature.id, inputSettings: [] },
      })
    }
  }, [user])

  useEffect(() => {
    const newSettings = { ...userSettings, userAiFeatures: usersAiFeatures || [] }
    const featureSettings = usersAiFeatures?.find(() => true)
    if (featureSettings) {
      setUserSettings({
        ...newSettings,
        selectedUserAiFeature: featureSettings,
      })
    } else {
      setUserSettings(newSettings)
    }
  }, [usersAiFeatures])

  const hasUserSettingSelected = useMemo(
    () => new UsersAiFeatureValidator(userSettings.selectedUserAiFeature).validate(),
    [userSettings.selectedUserAiFeature],
  )
  return (
    <>
      <Autocomplete
        freeSolo
        className={hasUserSettingSelected ? "user-profile-editor" : "required-input-field"}
        color="primary"
        options={users || []}
        onInputChange={handleDelete}
        renderOption={(props, option: FetchedUsers | string) => {
          if (typeof option === "string") {
            return null
          }
          return (
            <li {...props}>
              <Typography>{option.email}</Typography>
            </li>
          )
        }}
        sx={{ width: 350, borderColor: "primary", borderRadius: 20, border: "none" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User Profile"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <InputAdornment sx={{ paddingRight: 1 }} onClick={() => openAssignModal()} position="end">
                    <SaveIcon color="primary" />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
        getOptionLabel={(option) => (typeof option === "string" ? option : option?.email || "")}
        value={
          userSearch ? userSearch : users?.find((user) => user.id === userSettings.selectedUserAiFeature.userId) || null
        }
        onChange={handleUserSearchChange}
      />
      <AssignSettingsDialog />
    </>
  )
}
