import React, { FC, ReactElement, useState } from "react"
import {
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Stack,
  CardHeader,
  Box,
} from "@mui/material"
import { PublicInsight } from "@interfaces/insight"
import { marked } from "marked"
import DOMPurify from "dompurify"
import { useToast } from "@components/shared/ToastHook"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ReplayIcon from "@mui/icons-material/Replay"
import moment from "moment"

export const LoadingText: FC = () => (
  <Stack direction="row" spacing={2} alignItems="center">
    <CircularProgress />
    <Typography>Generating insight...</Typography>
  </Stack>
)

export interface InsightCardProps {
  /** The insight data to display */
  insight: Pick<PublicInsight, "created_at" | "name" | "result" | "summary" | "updated_at">
  /** Code to run when you click the retry button */
  onRetryInsight?: () => void
  /** Emails of people who own this insight */
  ownerEmails?: string[]
  /** Is the data for this insight loading? */
  isLoading?: boolean
}

/** Card for displaying insight data to clients */
export const InsightCard: FC<InsightCardProps> = ({
  insight: { created_at, name, result, summary, updated_at },
  ownerEmails,
  onRetryInsight,
  isLoading,
}) => {
  const [isFull, setIsFull] = useState(false)
  const toast = useToast()

  let error = false

  let insightContent: ReactElement
  if (isLoading) {
    insightContent = <LoadingText />
  } else if (result || summary) {
    insightContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(isFull ? result : summary) as string),
        }}
      />
    )
  } else {
    const updatedDate = new Date(updated_at)
    if (Date.now() - updatedDate.valueOf() > 120000) {
      // 2 * 60 * 1000 = 2 minutes
      error = true
      insightContent = (
        <Typography>The generation of this insight has errored. Please contact Cloverleaf AI.</Typography>
      )
    } else {
      insightContent = <LoadingText />
    }
  }

  let action = null
  if (error) {
    if (onRetryInsight) {
      action = (
        <Button startIcon={<ReplayIcon />} variant="contained" onClick={onRetryInsight}>
          Retry
        </Button>
      )
    }
  } else {
    action = (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(isFull ? result : summary)
          toast("Copied to clipboard")
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    )
  }

  const createdDateTime = moment(created_at).format("M/D/YY, h:mm:ss a")
  const subTitle = (
    <Stack direction="column" spacing={-0.3}>
      <Box>Created: {createdDateTime}</Box>
      <Box>{ownerEmails?.join(", ")}</Box>
    </Stack>
  )

  return (
    <Card>
      <CardHeader title={name} subheader={subTitle} action={action} />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography>{insightContent}</Typography>
      </CardContent>
      {(result || summary) && (
        <CardActions>
          <Button
            onClick={() => setIsFull((prevValue) => !prevValue)}
            startIcon={isFull ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            variant="outlined"
          >
            {isFull ? "Show short insight section" : "Show full insight section"}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default InsightCard
