import { FC, useRef, useContext, CSSProperties, useEffect } from "react"
import { Skeleton } from "@mui/material"
import ReactPlayer from "react-player"
import { useLocation, useParams } from "react-router-dom"
import { unpackSetters } from "@components/shared/unpackSetters"
import { IMeetingRouteParams } from "@api/interfaces"
import { useFetchMeeting } from "@api/meetings"
import { Context } from "../Shared/Context"
import { vitallyTrack } from "@config/vitally"
import { useFetchCurrentUser } from "@api/users"

export const VideoPlayer: FC<{
  width?: string
  height?: string
  style?: CSSProperties
}> = ({ width, height, style }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data, isLoading } = useFetchMeeting(meetingId)
  const { data: currentUser } = useFetchCurrentUser()

  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)
  const startTimeSeconds = params.get("startTimeSeconds")

  const { state, setState, progress, setProgress } = useContext(Context)
  const { playingVideo, isReady, videoStartTime } = state
  const { setPlayingVideo, setIsReady, setVideoStartTime } = unpackSetters(state, setState)
  const videoPlayerRef = useRef<ReactPlayer>(null)

  useEffect(() => {
    if (videoStartTime.change === true && isReady) {
      videoPlayerRef.current?.seekTo(videoStartTime.startTime)
      setVideoStartTime({ ...videoStartTime, change: false })
      setPlayingVideo(true)
    }
  }, [videoStartTime.change, isReady])

  useEffect(() => {
    if (playingVideo) {
      vitallyTrack(currentUser, "Meeting Video Played", {
        meetingId: meetingId,
        videoStartTime: videoPlayerRef.current?.getCurrentTime()?.toFixed(2),
      })
    }
  }, [playingVideo])

  if (isLoading) {
    return <Skeleton height={300} variant="rectangular" />
  } else {
    return (
      <ReactPlayer
        ref={videoPlayerRef}
        url={data?.meeting?.source_video_url}
        playing={playingVideo}
        onPlay={() => setPlayingVideo(true)}
        onPause={() => setPlayingVideo(false)}
        onReady={() => setIsReady(true)}
        config={{
          youtube: {
            onUnstarted: () => setPlayingVideo(false),
          },
        }}
        onProgress={(status) => setProgress([progress[1], status.playedSeconds])}
        controls
        style={{
          backgroundImage: isReady
            ? "url(https://storage.googleapis.com/cloverleaf-ai-public-assets/audio_recording_thumbnail.png)"
            : "none",
          backgroundSize: "cover",
          ...style,
        }}
        onStart={() => {
          if (startTimeSeconds && !videoStartTime.change) {
            videoPlayerRef.current?.seekTo(videoStartTime.startTime)
          }
        }}
        width={width}
        height={height}
      />
    )
  }
}
