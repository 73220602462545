import { FC, useContext, useState } from "react"
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  Typography,
  Stack,
  Chip,
} from "@mui/material"
import { FetchedBookmarksItem, FetchedClipShare, useDeleteBookmark } from "../../api/bookmarks"
import ClipsList from "./ClipsList"
import { formatDate, formatSeconds, rowsToCsvDownload } from "../shared/config"
import ExpandingText from "../shared/ExpandingText"
import { formatChannelDetails } from "../../config/clipShareConfig"
import { useOpenModalKey } from "../shared/OpenModalHook"
import { EditBookmarkModal } from "./EditBookmarkModal"
import { BookmarkContext } from "@src/pages/BookmarksPage"
import ShareIcon from "@mui/icons-material/Share"
import { useToast } from "../shared/ToastHook"
import { ConstructionOutlined } from "@mui/icons-material"
import { useFetchCurrentUser } from "@api/users"
import { callVitallyTrack } from "./callVitallyTrack"

const BookmarkItem: FC<{ bookmark: FetchedBookmarksItem }> = ({ bookmark }) => {
  const { data: currentUser } = useFetchCurrentUser()
  const [isViewingClips, setIsViewingClips] = useState(false)
  const [dialogOpen, setDialogOpen] = useState("")
  const setToast = useToast()

  const { mutate: deleteBookmark } = useDeleteBookmark()
  const { isSharedTab, tab } = useContext(BookmarkContext)

  const editBookmarkKey = `EDIT_TITLE_${bookmark.id}`
  const openBookmarkEditor = useOpenModalKey(editBookmarkKey)

  const exportToCsv = () => {
    const headers = [
      [
        '"Clip Title"',
        '"Description"',
        '"Keyword"',
        '"Channel Details"',
        '"Meeting Title"',
        '"Publish Date"',
        '"Transcript Snippet"',
        '"URL"',
        '"Timestamp"',
      ],
    ]
    const content: any[] = []
    bookmark?.clips.forEach((clip: FetchedClipShare) => {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.set(
        "startTimeSeconds",
        clip.start_time_in_seconds ? (parseInt(clip.start_time_in_seconds.toString()) - 5).toString() : "0",
      )
      const url = clip.raw_text
        ? `${window.location.origin}/share/${clip.id}?type=clip`
        : `${window.location.origin}/share/${clip?.meeting?.id}`
      if (clip.organization) {
        content.push([
          `"${clip?.title || ""}"`,
          `"${clip?.description || ""}"`,
          `"${clip.keyword}"`,
          `"${formatChannelDetails(clip.organization)}"`,
          `"${clip.title}"`,
          `"${formatDate(clip.meeting.published_at)}"`,
          `"${clip.raw_text}"`,
          `"${url}"`,
          `"${formatSeconds(clip?.start_time_in_seconds)}"`,
        ])
      }
    })
    const rows = [...headers, ...content]
    rowsToCsvDownload(rows, bookmark.title)
  }

  const handleExportClick = () => {
    callVitallyTrack({ currentUser, bookmark, tab, event: "Bookmarks Export" })
    exportToCsv()
  }

  return (
    <ListItem
      sx={{
        border: 1,
        borderColor: "primary.main",
        backgroundColor: "white",
        borderRadius: 1,
        marginBottom: 2,
      }}
    >
      <Grid container direction="column" marginTop={1} paddingLeft={1}>
        <Grid container direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" color="primary" paddingLeft={1} paddingTop={2} paddingBottom={2}>
              {bookmark?.title}
            </Typography>
            {isSharedTab && bookmark.visible && (
              <Chip sx={{ margin: 1 }} color="primary" icon={<ShareIcon />} label="Public" />
            )}
            {bookmark.industry_id && (
              <Chip sx={{ margin: 1 }} color="primary" icon={<ConstructionOutlined />} label="Industry" />
            )}
          </Stack>
          <Grid container direction="column">
            <Grid item>
              <ExpandingText text="Bookmarks" isViewing={isViewingClips} setIsViewing={setIsViewingClips} />
            </Grid>
            <Grid item container xs={12}>
              <Collapse in={isViewingClips} sx={{ width: "100%" }}>
                <ClipsList bookmark={bookmark} />
              </Collapse>
            </Grid>
            <Grid item xs={11} marginY={1} paddingX={4}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container marginLeft={1} justifyContent="space-between">
            <Grid container item spacing={1}>
              <Grid item>
                <Button
                  onClick={openBookmarkEditor}
                  color="primary"
                  variant="outlined"
                  disabled={isSharedTab || Boolean(bookmark.industry_id)}
                >
                  Update Folder
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleExportClick()} color="primary" variant="outlined">
                  Export Bookmarks
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setDialogOpen("delete")}
                  color="error"
                  variant="outlined"
                  disabled={isSharedTab || Boolean(bookmark.industry_id)}
                >
                  Delete Folder
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen !== ""} onClose={() => setDialogOpen("")}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this folder? This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen("")}>Cancel</Button>
          <Button
            onClick={() => {
              callVitallyTrack({ currentUser, bookmark, tab, event: "Bookmarks Delete Folder" })
              deleteBookmark({ id: bookmark.id })
              setDialogOpen("")
              setToast("Folder Deleted", 1750)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <EditBookmarkModal bookmark={bookmark} />
    </ListItem>
  )
}

export default BookmarkItem
