import { FC, useState, useEffect } from "react"
import { AiFeature, AiFeatureStatus } from "@interfaces/AiProducts/models"
import { Chip, Stack, Typography } from "@mui/material"
import { CreateDialogContainer } from "../Dialogs/DialogContainer"
import { CreateDialog } from "../Dialogs/Features/CreateDialog"
import { AI_FEATURES_DIALOG_KEY } from "../Dialogs/DialogContainer"
import { useCloseModalKey, useOpenModalKey } from "@components/shared/OpenModalHook"
import { useHistory, useParams } from "react-router-dom"
import { useCreateAiFeature, useFetchAiFeature, useUpdateAiFeature } from "@api/admin/AiProducts/aiFeatures"
import { createOrchestrator } from "./Orchestrators/orchestratorFactory"
import { FeatureContextProvider } from "./Features/FeatureContext"
import { AiFeatureValidator } from "@api/admin/AiProducts/validations"
import { UsersSettingsContextProvider } from "@components/Admin/AiProducts/Playground/Contexts/UsersSettingsContext"
import { theme } from "@src/theme"
import { useToast } from "@components/shared/ToastHook"

export const PlaygroundContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [editableFeature, setEditableFeature] = useState<AiFeature>({} as AiFeature)
  const open = useOpenModalKey(AI_FEATURES_DIALOG_KEY)
  const close = useCloseModalKey(AI_FEATURES_DIALOG_KEY)
  const { data: aiFeature, isLoading } = useFetchAiFeature(id)
  const { mutateAsync: createFeature } = useCreateAiFeature()
  const setToast = useToast()
  const { mutate: updateFeature } = useUpdateAiFeature(
    () => setToast("Success", 5000),
    () => setToast("Error", 5000),
  )
  const history = useHistory()

  useEffect(() => {
    if (aiFeature) {
      setEditableFeature(aiFeature)
      if (!aiFeature?.orchestratorType) {
        open()
      }
    } else {
      setEditableFeature(editableFeature)
      if (!isLoading) {
        open()
      }
    }
  }, [aiFeature])

  const upsertFeature = async () => {
    if (editableFeature.id) {
      updateFeature({ feature: editableFeature })
      close()
    } else {
      const response = await createFeature({ feature: editableFeature })
      if (response?.id) {
        close()
        history.push(`/admin/llm-playground/${response.id}`)
      }
    }
  }

  const closeFeatureModal = () => {
    if (new AiFeatureValidator(editableFeature).validate()) {
      close()
    }
  }

  return (
    <FeatureContextProvider feature={editableFeature} setFeature={setEditableFeature}>
      <UsersSettingsContextProvider>
        <Stack
          direction="column"
          sx={{
            paddingLeft: 5,
            paddingBottom: 5,
            paddingRight: 5,
            backgroundColor: "white",
            borderLeft: "thick solid #d7d7d778",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="flex-start" paddingTop={2}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Stack direction="column" spacing={1} marginTop={1}>
                <Typography variant="h1" color="primary">
                  AI Feature Development Sandbox
                </Typography>
                <Stack direction="row" spacing={1} alignItems={"end"}>
                  <Typography variant="h5" color="primary" textTransform="capitalize">
                    {editableFeature?.productType}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Chip
              variant="filled"
              size="medium"
              sx={{
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: 20,
                borderWidth: 20,
                backgroundColor:
                  editableFeature?.featureStatus === AiFeatureStatus.LIVE ? theme.palette.success.light : "#FFCC00",
              }}
              color="success"
              label={editableFeature?.featureStatus || AiFeatureStatus.DRAFT}
            />
          </Stack>
          {editableFeature?.orchestratorType && createOrchestrator(editableFeature?.orchestratorType)}
          <CreateDialogContainer
            title="Create a Feature"
            dialogBody={<CreateDialog feature={editableFeature} setFeature={setEditableFeature} />}
            onSave={upsertFeature}
            onClose={closeFeatureModal}
          />
        </Stack>
      </UsersSettingsContextProvider>
    </FeatureContextProvider>
  )
}
