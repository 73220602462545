import { IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid"
import { FC, useState } from "react"
import { RowUnionType } from "./contactsTable"
import { Menu as MenuIcon } from "@mui/icons-material"

interface BulkActionsMenuProps {
  handleCreate: (rows: RowUnionType[]) => void
}

export const BulkActionsMenu: FC<BulkActionsMenuProps> = ({ handleCreate }) => {
  const apiRef = useGridApiContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const canBulkCreate = (): boolean => {
    const selectedRows = apiRef.current.getSelectedRows()
    if (selectedRows.size === 0) {
      return false
    }
    // Check if any of the selected rows are already saved.
    return Array.from(selectedRows.values()).every((row) => !(row as RowUnionType).created_at)
  }

  const handleBulkCreate = () => {
    // Retrieve selected rows from the grid's API.
    const selectedRowsMap = apiRef.current.getSelectedRows()
    const selectedRows = Array.from(selectedRowsMap.values())
    handleCreate(selectedRows as RowUnionType[])
    // Deselct all rows.
    apiRef.current.setRowSelectionModel([])
    handleMenuClose()
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen} size="small" color="primary">
        <MenuIcon sx={{ mr: 1 }} />
        <Typography variant="button" fontSize={"small"}>
          Actions
        </Typography>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleBulkCreate} disabled={!canBulkCreate()}>
          Bulk Create
        </MenuItem>
      </Menu>
    </>
  )
}
