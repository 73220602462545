import { FetchedMeeting } from "@src/api/meetings"
import { OpportunityIndexResult } from "@src/api/opportunities"
import { meetingLocationString } from "@src/components/shared/config"
import moment from "moment"

const dateStringToLocaleDate = (dateString: string) => moment(dateString, "YYYY-MM-DD").format("M/D/YYYY")
export const opportunityRow = (opportunity: OpportunityIndexResult) => {
  return {
    actionability: opportunity.actionability,
    actionable_date: opportunity.actionable_date ? dateStringToLocaleDate(opportunity.actionable_date) : null,
    channel_id: opportunity.channel_id,
    channel_name: opportunity.channel_name,
    date: opportunity.published_at ? dateStringToLocaleDate(opportunity.published_at) : null,
    description: opportunity.description,
    id: opportunity.id,
    identified_date: dateStringToLocaleDate(opportunity.created_at),
    location: meetingLocationString({
      state: opportunity.state_name,
      county: opportunity.county_name,
      city: opportunity.city_name,
    } as FetchedMeeting),
    meeting_id: opportunity.meeting_id,
    naics: `${opportunity.naics_code.code}: ${opportunity.naics_code.title}`,
    industry: `${opportunity.naics_code?.industries?.map((industry) => industry.name).join(", ")}`,
    name: opportunity.name,
    speaker: opportunity.key_decision_maker,
    // truncatedDescription: `${opportunity.description.slice(0, lastSpaceIndex)}...`,
  }
}

export const opportunityAdminRow = (opportunity: OpportunityIndexResult) => {
  return {
    ...opportunityRow(opportunity),
    generated_by_model: opportunity.generated_by_model,
    use_fine_tuning: opportunity.use_fine_tuning,
  }
}
