import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"
import {
  AiChainRunnablesResponse,
  SharedRequsetAttributes,
  SharedResponseAttributes,
} from "@interfaces/AiProducts/responseObjectInterfaces"
import { AiChainRunnablesRequest } from "@interfaces/AiProducts/requestObjectInterfaces"
import { namespace } from "./shared"
import { serializeAiChainRunnableResponse } from "@interfaces/AiProducts/serializers/responses/serializers"
import { serializeAiChainRunnableRequest } from "@interfaces/AiProducts/serializers/requests/aiChainRunnables"
import { AiChainRunnable } from "@interfaces/AiProducts/models"

const resourcePath = `${namespace}/ai-chain-runnables`

export interface FetchAiChainRunnablesResponse {
  ai_chain_runnables: AiChainRunnablesResponse[]
}

export const useFetchAiChainRunnables = (params?: CommonUrlParams, enabled?: boolean) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(
    paramBuilder.toQueryKey("ai-chain-runnables"),
    async () => {
      const response = await (await axiosInstance).get<FetchAiChainRunnablesResponse>(uri)

      return response.data.ai_chain_runnables.map((runnable) => serializeAiChainRunnableResponse(runnable))
    },
    {
      enabled,
    },
  )
}

export interface FetchAiChainRunnableResponse {
  ai_chain_runnable: AiChainRunnablesResponse
}

export const useFetchAiChainRunnable = (id?: number, params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}/${id}${queryParams}`

  return useQuery(
    ["ai-chain-runnables", paramBuilder.toQueryKey("ai-chain-runnables"), id],
    async () => {
      const response = await (await axiosInstance).get<FetchAiChainRunnableResponse>(uri)

      return serializeAiChainRunnableResponse(response.data.ai_chain_runnable)
    },
    {
      enabled: !!id,
    },
  )
}

interface UpsertAiChainRunnableRequest {
  ai_chain_runnable: AiChainRunnablesRequest
  ai_chain_runnables: AiChainRunnablesRequest[]
}

export const useUpsertAiChainRunnable = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: {
      aiFeatureId: number
      aiChainRunnable?: AiChainRunnable
      aiChainRunnables?: AiChainRunnable[]
    }) => {
      const body = {} as UpsertAiChainRunnableRequest
      const resourcePath = `${namespace}/ai-features/${requestBody.aiFeatureId}/ai-chain-runnables`
      if (requestBody.aiChainRunnable) {
        body["ai_chain_runnable"] = serializeAiChainRunnableRequest(requestBody.aiChainRunnable)
      } else if (requestBody.aiChainRunnables) {
        body["ai_chain_runnables"] = requestBody.aiChainRunnables.map((runnable) =>
          serializeAiChainRunnableRequest(runnable),
        )
      }
      const response = await (await axiosInstance).post<AiChainRunnablesResponse>(resourcePath, body)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["ai-chain-runnables"])
      },
    },
  )
}

export const useUpdateAiChainRunnable = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<AiChainRunnablesRequest & SharedRequsetAttributes>) => {
      const response = await (
        await axiosInstance
      ).put<AiChainRunnablesResponse & SharedResponseAttributes>(`${resourcePath}/${requestBody.id}`, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["ai-chain-runnables"])
      },
    },
  )
}

export const useDeletelAiChainRunnable = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      const response = await (await axiosInstance).delete<AiChainRunnablesResponse>(`${resourcePath}/${id}`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ai-chain-runnables"])
      },
    },
  )
}
