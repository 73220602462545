import { FC, useState, useEffect } from "react"
import { Stack, Chip, Typography, Link as MuiLink, TextField, Box, Divider } from "@mui/material"
import { Link } from "react-router-dom"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { FilterParams } from "@src/components/Home/interface"
import { OpportunityCampaignsUsers } from "@src/interfaces/opportunityCampaignsUsers"
import { User } from "@src/interfaces/user"
import { EmailCadencesList } from "./EmailCadencesList"
import { OwnEmailCadence } from "./OwnEmailCadence"
import { EmailCadence } from "@src/interfaces/campaign"
import { OpportunityCampaignUpdateParams } from "@src/api/opportunityCampaigns"
import { OpportunityCampaignCardContext } from "./indexContext"
import { EditingForm } from "./EditingForm"
import { createFilterTexts } from "./createFilterTexts"
import { OpportunityCampaignCardSkeleton } from "./Skeleton"
import { CardActions } from "./CardActions"

export const OpportunityCampaignCard: FC<{
  opportunityCampaign: OpportunityCampaign
  ownEmailCadence?: string
  emailCadences?: OpportunityCampaignsUsers[]
  onEmailCadencesUpdate?: (newUserEmailCadenceMap: Map<number, EmailCadence>) => void
  userMapById?: Map<number, User>
  onUpdate?: (updateParams: OpportunityCampaignUpdateParams) => void
  editIsLoading?: boolean
}> = ({
  opportunityCampaign,
  ownEmailCadence,
  emailCadences,
  onEmailCadencesUpdate,
  userMapById,
  onUpdate,
  editIsLoading,
}) => {
  const {
    id,
    name,
    data: { anchor_terms, terms, industry, naics_code, organizations, cities, counties, states },
  } = opportunityCampaign
  const [isEditing, setIsEditing] = useState(false)
  const [ownEmailCadenceAction, setOwnEmailCadenceAction] = useState(() => () => {})
  const [emailCadencesAction, setEmailCadencesAction] = useState(() => () => {})
  const [campaignName, setCampaignName] = useState(name)
  const [anchorTermsSet, setAnchorTermsSet] = useState<Set<string>>(new Set())
  const [termsSet, setTermsSet] = useState<Set<string>>(new Set(terms))
  const [newNaicsCodes, setNewNaicsCodes] = useState(naics_code || [])
  const [newIndustries, setNewIndustries] = useState<string[]>(industry || [])

  const defaultFilterParams: FilterParams = {
    organizations: organizations || [],
    cities: cities || [],
    counties: counties || [],
    states: states || [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  }
  const [filterParams, setFilterParams] = useState<FilterParams>(defaultFilterParams)

  const reset = () => {
    setFilterParams(defaultFilterParams)
    setCampaignName(name)
    setAnchorTermsSet(new Set(anchor_terms))
    setTermsSet(new Set(terms))
    setIsEditing(false)
  }
  useEffect(reset, [opportunityCampaign])
  const filterTexts = createFilterTexts(industry, naics_code, organizations, cities, counties, states)

  let termsJoined: string[] = []
  if (terms) {
    termsJoined = termsJoined.concat(terms)
  }
  if (anchor_terms) {
    termsJoined = termsJoined.concat(anchor_terms)
  }

  const handleActionSaveClick = () => {
    if (onUpdate) {
      onUpdate({
        name: campaignName,
        data: {
          ...filterParams,
          naics_code: newNaicsCodes,
          industry: newIndustries,
          terms: Array.from(termsSet),
          anchor_terms: Array.from(anchorTermsSet),
        },
      })
      ownEmailCadenceAction()
      emailCadencesAction()
      reset()
    }
  }

  const callCardActions = () => {
    return (
      <CardActions
        params={{
          editIsLoading,
          isEditing,
          saveOnClick: handleActionSaveClick,
          reset,
          setIsEditing,
          updateable: onUpdate ? true : false,
        }}
      />
    )
  }

  return (
    <OpportunityCampaignCardContext.Provider
      value={{
        id,
        ownEmailCadence,
        emailCadences,
        filterParams,
        onEmailCadencesUpdate,
        userMapById,
        setOwnEmailCadenceAction,
        setEmailCadencesAction,
        setFilterParams,
      }}
    >
      <OpportunityCampaignCardSkeleton
        title={
          isEditing ? (
            <TextField
              value={campaignName}
              onChange={(event) => {
                setCampaignName(event.target.value)
              }}
              fullWidth
              label="Name"
              size="small"
            />
          ) : (
            <MuiLink component={Link} to={`/opportunities?campaign=${id}`}>
              {name}
            </MuiLink>
          )
        }
        content={
          <Stack spacing={1}>
            {isEditing ? (
              <EditingForm
                termsSet={termsSet}
                setTermsSet={setTermsSet}
                anchorTermsSet={anchorTermsSet}
                setAnchorTermsSet={setAnchorTermsSet}
                newNaicsCodes={newNaicsCodes}
                setNewNaicsCodes={setNewNaicsCodes}
                newIndustries={newIndustries}
                setNewIndustries={setNewIndustries}
              />
            ) : (
              <>
                {termsJoined.length > 0 && (
                  <Stack direction="row" gap={1}>
                    {termsJoined.map((term) => (
                      <Chip key={term} label={term} />
                    ))}
                  </Stack>
                )}
                {filterTexts.length > 0 && <Typography>{filterTexts.join(" in ")}</Typography>}
              </>
            )}
            <EmailCadencesList isEditing={isEditing} />
            <OwnEmailCadence opportunityCampaignId={id} isEditing={isEditing} />
            <Divider />
          </Stack>
        }
        // actions={callCardActions()}
        actions={callCardActions()}
      />
    </OpportunityCampaignCardContext.Provider>
  )
}
