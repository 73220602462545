import { Card, CardActions, CardContent, CardHeader, Chip, Skeleton, Stack, Typography } from "@mui/material"
import { FC } from "react"

export const OpportunityCampaignCardSkeleton: FC<{
  title?: React.ReactNode
  content?: React.ReactNode
  actions?: React.ReactNode
}> = ({ title, content, actions }) => (
  <Card>
    <CardHeader title={title || <Skeleton width="24ch" />} sx={{ paddingBottom: 0 }} />
    <CardContent sx={{ paddingTop: 1 }}>
      {content || (
        <>
          <Stack direction="row" gap={1}>
            <Chip label={<Skeleton width="6ch" />} />
            <Chip label={<Skeleton width="6ch" />} />
            <Chip label={<Skeleton width="6ch" />} />
          </Stack>
          <Typography>
            <Skeleton sx={{ maxWidth: "64ch" }} />
          </Typography>
        </>
      )}
    </CardContent>
    {actions !== null && <CardActions>{actions || <Skeleton sx={{ width: "24ch" }} />}</CardActions>}
  </Card>
)
